import { WidgetsPlugin, WidgetParams } from './interfaces'
import { ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (widgetsStore:any) {
  const api: WidgetsPlugin = {
    // ? ________ getters ________
    // * Получить список всех виджетов
    get widgets () : ResponseData<WidgetParams> | null {
      return widgetsStore.widgets
    },
    // * Получить виджет по id
    getWidgetById (id: number) : WidgetParams | undefined {
      return widgetsStore.getWidgetById(id)
    },
    // * Получить параметры виджета, который мы редактируем или создаем
    get currentWidget () {
      return widgetsStore.currentWidget
    },

    get validators (): ValidatorParams {
      return widgetsStore.validators
    },
    // ? ________ setters ________
    // * Установить все виджеты
    set widgets (value: ResponseData<WidgetParams> | null) {
      widgetsStore.setWidgets(value)
    },
    // * Установить параметры виджета, который мы редактируем или создаем
    set currentWidget (value: any) {
      widgetsStore.setCurrentWidget(value)
    },
    // * Очистить параметры currentWidget
    resetCurrentWidget () {
      widgetsStore.resetCurrentWidget()
    },

    resetWidgets () {
      widgetsStore.resetWidgets()
    },
    // ? ________ actions ________
    // * Запрос на список всех виджетов
    getWidgets (): Promise<ResponseData<WidgetParams>> {
      return widgetsStore.getWidgets()
    },
    /**
     * * Запрос на удаление виджета по id
     * @param id - id выбраного виджета
     */
    removeWidget (id: number): Promise<WidgetParams> {
      return widgetsStore.removeWidget(id)
    },
    // * запрос на создание виджета
    createWidget (): Promise<WidgetParams[]> {
      return widgetsStore.createWidget()
    },
    // * запрос на изменеие виджета
    editWidget (): Promise<WidgetParams> {
      return widgetsStore.editWidget()
    },
    /**
     * * Запрос на загрузки виджета по id
     * @param id - id выбраного виджета
     */
    getWidget (id: number): Promise<WidgetParams> {
      return widgetsStore.getWidget(id)
    }
  }

  return api
}
