import { Params, Ids, DeliveryPrice, DeliveryPricesPlugin, Filters } from './interfaces'
import DeliveryPricesModule from './store'
import { ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (store: DeliveryPricesModule) {
  const api: DeliveryPricesPlugin = {
    // ? ________ getters ________
    get filters (): Filters {
      return store.filters
    },

    get deliveryPrices (): ResponseData<DeliveryPrice> {
      return store.deliveryPrices
    },

    get deliveryPrice (): DeliveryPrice {
      return store.deliveryPrice
    },

    deliveryPriceById (id: number): DeliveryPrice | undefined {
      return store.deliveryPriceById(id)
    },

    get validators (): ValidatorParams {
      return store.validators
    },

    // ? ________ setters ________

    set filters (filters: Filters) {
      store.setFilters(filters)
    },

    resetFilters (): void {
      store.resetFilters()
    },

    set deliveryPrices (deliveryPrices: ResponseData<DeliveryPrice>) {
      store.setDeliveryPrices(deliveryPrices)
    },

    set deliveryPrice (deliveryPrice: DeliveryPrice) {
      store.setDeliveryPrice(deliveryPrice)
    },

    resetDeliveryPrices () {
      store.resetDeliveryPrices()
    },

    resetDeliveryPrice () {
      store.resetDeliveryPrice()
    },

    // ? ________ actions ________

    getDeliveryPrices (params: Params): Promise<ResponseData<DeliveryPrice>> {
      return store.getDeliveryPrices(params)
    },

    getDeliveryPriceById (ids: Ids) {
      return store.getDeliveryPriceById(ids)
    },

    createDeliveryPrice (serviceId: number) {
      return store.createDeliveryPrice(serviceId)
    },

    editDeliveryPrice (serviceId: number) {
      return store.editDeliveryPrice(serviceId)
    },

    removeDeliveryPrice (ids: Ids) {
      return store.removeDeliveryPrice(ids)
    }
  }

  return api
}
