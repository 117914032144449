import { Middleware } from '@nuxt/types'

const authMiddleware: Middleware = (context) => {
  const isAuth = context.$auth.isAuth
  if (!isAuth && context.route.path !== '/signin') {
    context.app.$cookies.set('prevLink', context.route.fullPath)
    context.$auth.logout()
  } else if (isAuth && context.route.path === '/signin') {
    context.redirect({ path: '/' })
  }
}

export default authMiddleware
