/**
 * Дни недели
 */
export enum DAYS {
  /**
   * Понедельник
   */
  MONDAY = 'monday',
  /**
   * Вторник
   */
  TUESDAY = 'tuesday',
  /**
   * Среда
   */
  WEDNESDAY = 'wednesday',
  /**
   * Четверг
   */
  THURSDAY = 'thursday',
  /**
   * Пятница
   */
  FRIDAY = 'friday',
  /**
   * Суббота
   */
  SATURDAY = 'saturday',
  /**
   * Воскресенье
   */
  SUNDAY = 'sunday',
}
/**
 * Конфигурация триггера
*/
export interface TriggerConfig {
   /**
   * Описание шаблона письма
   */
   emailTemplate: EmailTemplate;
   /**
    * Минимальный промежуток времени в днях, с момента последнего заказа пользователя по сегодняшний день
    */
   minOrderIntervalDays: number;
   /**
    * Название промокода
    */
   name: string;
   /**
    * Промокод
    */
   promocode: string;
   /**
    * Конфигурация расписания проверки пользователей и рассылки писем
    */
   schedule: Schedule;
}

/**
 * Триггер
 */
export interface Trigger {
  /**
   * Код триггера
   */
  code: string;
  /**
   * Конфигурация триггера
   */
  config: TriggerConfig
  /**
   * Id триггера
   */
  id: number | null;
  /**
   * Название триггера
   */
  name: string;
  /**
   * Активность
   */
  active: boolean;
}

/**
 * Описание шаблона письма
 */
export interface EmailTemplate {
  /**
   * Id шаблона email_templates
   */
  id: number | null;
  /**
   * Плейсхолдер в шаблоне для подстановки промокода
   */
  promocodePlacehodler: string;
}

/**
 * Конфигурация расписания проверки пользователей и рассылки писем
 */
export interface Schedule {
  /**
   * Параметры ежедневного уведомления
   */
  daily?: DailySchedule;
  /**
   * Параметры еженедельного уведомления
   */
  weekly?: WeeklySchedule;
}

interface DailySchedule {
  /**
   * Время уведомления
   */
  time: string;
}

interface WeeklySchedule extends DailySchedule {
  /**
   * День уведомления
   */
  day: DAYS;
}

export type TriggerKeys = keyof Trigger
                        | keyof TriggerConfig
                        | keyof EmailTemplate
                        | keyof Schedule
                        | keyof DailySchedule
                        | keyof WeeklySchedule

export type TriggerType<K> = K extends keyof Trigger ? Trigger[K] :
                             K extends keyof TriggerConfig ? TriggerConfig[K] :
                             K extends keyof EmailTemplate ? EmailTemplate[K] :
                             K extends keyof Schedule ? Schedule[K] :
                             K extends keyof DailySchedule ? DailySchedule[K] :
                             K extends keyof WeeklySchedule ? WeeklySchedule[K] : never

/**
 * Параметры action`ов с запросами
 */
export interface RequestActionParams {
  /**
   * * Адрес апи сайта
   */
  siteApiUrl: string
}

/**
 * Плагин модуля EventTriggers
 */
export default interface EventTriggersPlugin {
  /**
   * Список триггеров
   */
  list: Trigger[];
  /**
   * Триггер
   */
  item: Trigger;
  /**
   * Сеттер свойства триггера неопределенной вложенности по набору ключей
   * @param value значение свойства
   * @param keys список ключей свойства в порядке вложенности
   */
  setItemPropByKeys<K extends TriggerKeys> (value: TriggerType<K>, ...keys: K[]): void
  /**
   * Триггер по id
   */
  itemByIdentifier (identifier: number): Trigger | undefined;
  /**
   * Рессетер списка триггеров
   */
  resetList(): void
  /**
   * Рессетер триггера
   */
  resetItem(): void
  /**
   * Запрос на получение списка триггеров
   */
  getList(save?: boolean): Promise<Trigger[] | undefined>;
  /**
   * Запрос на обновление promo-sender`а
   */
  updatePromoSender(): Promise<void | undefined>;
}
