import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class OrderStatusGroupsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$orderStatusGroups.resetOrderStatusGroup()
    next()
  }

  /**
   * * Запрос на получение списка групп статусов заказа
   * @param param - параметры пагинатора (необязательные)
   * @returns список групп статусов заказа
   */
  async getOrderStatusGroups (params?: {page?: number, pageSize?: number, sort?: string, order?: string}) {
    try {
      this.$wait.start('getStatusGroups')
      return await this.$orderStatusGroups.getOrderStatusGroups({
        page: +(params?.page || 1),
        pageSize: params?.pageSize,
        sort: params?.sort,
        order: params?.order
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getStatusGroups')
    }
  }

  /**
   * * Запрос на создание группы
   * @returns группа статуса заказов
   */
  async createStatusGroup () {
    try {
      this.$wait.start('createStatusGroup')
      const data = await this.$orderStatusGroups.createOrderStatusGroup()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Группа ${data.name} добавлена`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createStatusGroup')
    }
  }

  /**
   * * Запрос на получение группы по id
   * @returns группа статуса заказов
   */
  async getOrderStatusGroupById (id:number) {
    try {
      this.$wait.start('getOrderStatusGroupById')
      return await this.$orderStatusGroups.getOrderStatusGroupById(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getOrderStatusGroupById')
    }
  }

  /**
   * * Запрос на редактирование группы
   * @returns группа статуса заказов
   */
  async editStatusGroup () {
    try {
      this.$wait.start('editStatusGroup')
      const data = await this.$orderStatusGroups.editOrderStatusGroup()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Группа ${data.name} изменена`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editStatusGroup')
    }
  }

  /**
   * * Запрос на удаление группы
   * @returns группа статуса заказов
   */
  async removeStatusGroup (id: number) {
    try {
      this.$wait.start('removeStatusGroup')
      const data = await this.$orderStatusGroups.removeOrderStatusGroup(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Группа ${data.name} удалена`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeStatusGroup')
    }
  }
}
