import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ResponseData, PageParams, ValidatorParams } from '../../interfaces'
import { WordReplace, FiltersParams } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'
@Module({
  name: 'wordReplaces',
  stateFactory: true,
  namespaced: true
})

export default class WordReplacesModule extends VuexModule {
  /**
   * * Массив всех wordReplace исходя из запроса
   */
  wordReplaces: ResponseData<WordReplace> = defaultData
  /**
   * * Текущая wordReplace
   */
  wordReplace: WordReplace = {
    word: undefined,
    replaceWord: undefined
  }

  filtersValues: FiltersParams = {
    word: undefined,
    replaceWord: undefined
  }

  // ? ______________ getters ______________

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      word: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите исходное слово', trigger: ['blur'] }],
      replaceWord: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите замену слова', trigger: ['blur'] }]
    }
  }

  /**
   * * Получить filters
   */
  get filters (): FiltersParams {
    return this.filtersValues
  }

  /**
   * * Получить массив wordReplaces и пагинацией
   */
  get wordReplacesList (): ResponseData<WordReplace> {
    return this.wordReplaces
  }

  /**
   * * Валидна ли форма
   */
  get validateData (): boolean {
    return !!(this.wordReplace.word && this.wordReplace.replaceWord)
  }

  /**
   * * Получить wordReplace из массива wordReplaces
   */
  get wordReplaceById () {
    const wordReplaces = this.wordReplaces
    return function (id: number): WordReplace | undefined {
      return wordReplaces.data.find(wordReplace => wordReplace.id === id)
    }
  }

  /**
   * * Получить текущую wordReplace для измения или создания wordReplace
   */
  get currentWordReplace () {
    return this.wordReplace
  }

  // ? ______________ setters ______________

  /**
   * * Установить массив WordReplaces
   * @param wordReplaces массив WordReplaces
   */
  @Mutation
  setWordReplacesList (wordReplaces: ResponseData<WordReplace>) {
    this.wordReplaces = wordReplaces
  }

  /**
   * * Установить CurrentWordReplace для измения или создания wordReplace
   * @param wordReplace текущая WordReplace, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentWordReplace (wordReplace: WordReplace) {
    this.wordReplace = wordReplace
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentWordReplace () {
    this.wordReplace = {
      word: undefined,
      replaceWord: undefined
    }
  }

  /**
   * * Установить filters
   */
  @Mutation
  setFilters (filters: FiltersParams) {
    this.filtersValues = filters
  }

  @Mutation
  resetWordReplaces () {
    this.wordReplaces = defaultData
  }

  /**
   * * Обнулить фильтры
   */
  @Mutation
  resetFilters () {
    this.filtersValues = {
      word: undefined,
      replaceWord: undefined
    }
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список WordReplaces
   */
  @Action({
    rawError: true,
    commit: 'setWordReplacesList'
  })
  async getWordReplaces (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/seo/word-replace', { params: { ...pageParams, ...this.filters } })
      const response: ResponseData<WordReplace> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить wordReplace по id
   * @param id id wordReplace, который мы хотим получить
   */
  @Action({
    rawError: true, commit: 'setCurrentWordReplace'
  })
  async getWordReplace (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/seo/word-replace/${id}`)
      const response: WordReplace = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить wordReplace
   */
  @Action({
    rawError: true
  })
  async editWordReplace () {
    try {
      const { id, ...wordReplace } = this.currentWordReplace
      const { data: { data } } = await $axios.put(`/seo/word-replace/${id}`, wordReplace)
      const response: WordReplace = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать WordReplace
   */
  @Action({
    rawError: true
  })
  async createWordReplace () {
    try {
      const { data: { data } } = await $axios.post('/seo/word-replace', this.currentWordReplace)
      const response: WordReplace = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить wordReplace по id
   * @param id  id wordReplace, который мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removeWordReplace (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/seo/word-replace/${id}`)
      const response: WordReplace = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
