import { Context } from '@nuxt/types'
import { OrderOffersPlugin, OrderOffer, OrderOfferData, Ids, Params, Filters } from './interfaces'
import OrderOffersModule from './store'
import { ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (this: Context, orderOffersStore: OrderOffersModule) {
  const ctx = this
  const api: OrderOffersPlugin = {
    // ? ------ getters ------ //

    get orderOffers (): ResponseData<OrderOffer> {
      return orderOffersStore.orderOffers
    },

    get orderOfferData (): OrderOfferData {
      return orderOffersStore.orderOfferData
    },

    get validators (): ValidatorParams {
      return orderOffersStore.validators
    },

    get filters (): Filters {
      return orderOffersStore.filters
    },

    // ? ------ setters ------ //

    set filters (filters: Filters) {
      orderOffersStore.setFilters(filters)
    },

    resetFilters () {
      orderOffersStore.resetFilters()
    },

    set orderOffers (orderOffers: ResponseData<OrderOffer>) {
      orderOffersStore.setOrderOffers(orderOffers)
    },

    set orderOfferData (orderOfferData: OrderOfferData) {
      orderOffersStore.setOrderOfferData(orderOfferData)
    },

    resetOrderOfferData () {
      orderOffersStore.resetOrderOfferData()
    },

    resetOrderOffers () {
      orderOffersStore.resetOrderOffers()
    },

    // ? ------ actions ------ //

    getOrderOffers (params: Params): Promise<ResponseData<OrderOffer>> {
      return orderOffersStore.getOrderOffers({ siteApiUrl: ctx.$config.SITE_API, params })
    },

    createOrderOffer (): Promise<Partial<OrderOfferData>> {
      return orderOffersStore.createOrderOffer(ctx.$config.SITE_API)
    },

    editOrderOffer (): Promise<Partial<OrderOfferData>> {
      return orderOffersStore.editOrderOffer(ctx.$config.SITE_API)
    },

    removeOrderOffer (ids: Ids): Promise<Ids> {
      return orderOffersStore.removeOrderOffer({ ids, siteApiUrl: ctx.$config.SITE_API })
    },

    exportExcel (orderId: number): Promise<Blob> {
      return orderOffersStore.exportExcel(orderId)
    }
  }

  return api
}
