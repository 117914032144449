import { Component, Vue } from 'nuxt-property-decorator'
import { Ids, Params } from './interfaces'

@Component
export default class ProductOffersMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$productOffers.resetProductOffer()
    next()
  }

  /**
   * Получение списка торговых предложений
   * @param params - параметры пагинации и id товара
   * @returns список торговых предложений
   */
  async getProductOffers (params: Params) {
    try {
      this.$wait.start('getProductOffers')
      return await this.$productOffers.getProductOffers(params)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getProductOffers')
    }
  }

  /**
   * * Получение торгового предложения по id`s
   * @param ids - id`s товара и торгового предложения
   * @returns торговое предложениe
   */
  async getProductOfferId (ids: Ids) {
    try {
      this.$wait.start('getProductOfferId')
      return await this.$productOffers.getProductOfferById(ids)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getProductOfferId')
    }
  }

  /**
   * * Создание торгового предложения
   * @param productId - id товара
   * @returns торговое предложениe
   */
  async createProductOffer (productId: number) {
    try {
      this.$wait.end('createProductOffer')
      const data = await this.$productOffers.createProductOffer(productId)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Торговое предложение ${data.id} добавлено`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createProductOffer')
    }
  }

  /**
   * * Изменение торгового предложения
   * @param productId - id товара
   * @returns торговое предложениe
   */
  async editProductOffer (productId: number) {
    try {
      this.$wait.start('editProductOffer')
      const data = await this.$productOffers.editProductOffer(productId)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Торговое предложение ${data.id} изменено`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editProductOffer')
    }
  }

  /**
   * * Удаление торгового предложения
   * @param ids - id`s товара и торгового предложения
   * @returns торговое предложения
   */
  async removeProductOffer (ids: Ids) {
    try {
      this.$wait.start('removeOrderOffer')
      const data = await this.$productOffers.removeProductOffer(ids)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Торговое предложение ${data.id} удалено`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    }
  }
}
