import { Ids, Banner, BannerBlock, BannerParams, BannerBlocksPlugin, BannersFilters, BlocksFilters } from './interface'
import BannerBlocksStore from './store'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'
export default function getPluginApi (store: BannerBlocksStore) {
  const api: BannerBlocksPlugin = {
    // ? ________ getters ________

    // ? --- Блоки баннеров ---

    get blocksFilters (): BlocksFilters {
      return store.blocksFilters
    },

    get bannerBlocks (): ResponseData<BannerBlock> {
      return store.bannerBlocks
    },

    get bannerParams (): BannerParams[] {
      return store.bannerParams
    },

    get currentBlock (): BannerBlock {
      return store.currentBlock
    },

    get bannerParamsValidators (): ValidatorParams {
      return store.bannerParamsValidators
    },

    get blockValidators (): ValidatorParams {
      return store.blockValidators
    },

    bannerBlockById (id: number): BannerBlock | undefined {
      return store.bannerBlockById(id)
    },

    // ? --- Баннеры ---

    get bannersFilters (): BannersFilters {
      return store.bannersFilters
    },

    get blockBanners (): ResponseData<Banner> {
      return store.blockBanners
    },

    get bannerValidators (): ValidatorParams {
      return store.bannerValidators
    },

    get currentBanner (): Banner {
      return store.currentBanner
    },

    bannerById (id: number): Banner | undefined {
      return store.bannerById(id)
    },

    // ? ________ setters ________

    // ? --- Блоки баннеров ---

    set blocksFilters (blocksFilters: BlocksFilters) {
      store.setBlocksFilters(blocksFilters)
    },

    set bannerBlocks (bannerBlocks: ResponseData<BannerBlock>) {
      store.setBannerBlocks(bannerBlocks)
    },

    set currentBlock (bannerBlock: BannerBlock) {
      store.setCurrentBlock(bannerBlock)
    },

    set bannerParams (bannerParams: BannerParams[]) {
      store.setBannerParams(bannerParams)
    },

    resetBlocksFilters (): void {
      store.resetBlocksFilters()
    },

    resetBlocks (): void {
      store.resetBlocks()
    },

    resetCurrentBlock (): void {
      store.resetCurrentBlock()
    },

    // ? --- Баннеры ---

    set bannersFilters (bannerFilters: BannersFilters) {
      store.setBannersFilters(bannerFilters)
    },

    resetBannersFilters () {
      store.resetBannersFilters()
    },

    set blockBanners (blockBanners: ResponseData<Banner>) {
      store.setBlockBanners(blockBanners)
    },

    set currentBanner (banner: Banner) {
      store.setCurrentBanner(banner)
    },

    resetBanners (): void {
      store.resetBanners()
    },

    resetCurrentBanner (): void {
      store.resetCurrentBanner()
    },

    resetBannerParams () {
      store.resetBannerParams()
    },

    // ? ________ actions ________

    // ? --- Блоки баннеров ---

    getBannerBlocks (pageParams: PageParams | null = null): Promise<ResponseData<BannerBlock>> {
      return store.getBannerBlocks(pageParams)
    },

    getBannerParams (): Promise<BannerParams[]> {
      return store.getBannerParams()
    },
    getBannerBlockById (id: number) {
      return store.getBannerBlockById(id)
    },

    createBannerBlock () {
      return store.createBannerBlock()
    },

    editBannerBlock () {
      return store.editBannerBlock()
    },

    removeBannerBlock (id: number) {
      return store.removeBannerBlock(id)
    },

    // ? --- Баннеры ---

    getBanners (pageParams: PageParams | null = null): Promise<ResponseData<Banner>> {
      return store.getBanners(pageParams)
    },

    getBannerById (ids: Ids) {
      return store.getBannerById(ids)
    },

    createBanner () {
      return store.createBanner()
    },

    editBanner () {
      return store.editBanner()
    },

    removeBanner (id: number) {
      return store.removeBanner(id)
    }
  }

  return api
}
