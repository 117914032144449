import { PermissionsPlugin, Permission } from './interfaces'

export default function getPluginApi (permissionsStore:any) {
  const api: PermissionsPlugin = {
    // ? ------ getters ------ //
    get permissions (): Permission[] {
      return permissionsStore.permissions
    },

    // ? ------ setters ------ //
    set permissions (permissions: Permission[]) {
      permissionsStore.setPermissions(permissions)
    },

    resetPermissions () {
      permissionsStore.resetPermissions()
    },

    // ? ------ actions ------ //

    async getPermissions (): Promise<Permission[]> {
      return await permissionsStore.getPermissions()
    }
  }

  return api
}
