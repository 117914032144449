import { Component, Vue } from 'nuxt-property-decorator'
import { ProductGroupInput } from './interfaces'

@Component
export default class ProductGroupsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$productGroups.resetFiles()
    this.$productGroups.resetFilters()
    this.$productGroups.resetCurrentProductGroup()
    next()
  }

  /**
   * * Получение спииска групп товаров
   * @param pageParams - параметры пагинации (необязательные)
   * @returns спиисок групп товаров
   */
  async getProductGroups (pageParams: ProductGroupInput | null = null) {
    try {
      this.$wait.start('getProductGroups')
      return await this.$productGroups.getProductGroups(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getProductGroups')
    }
  }

  /**
   * * Получение группы товаров по id
   * @param id - id группы товаров
   * @returns группа товаров
   */
  async getProductGroupById (id: number) {
    try {
      this.$wait.start('getProductGroupById')
      return await this.$productGroups.getProductGroupById(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getProductGroupById')
    }
  }

  /**
   * * Создание группы товаров
   * @returns группа товаров
   */
  async createProductGroup () {
    try {
      this.$wait.start('createProductGroup')
      const data = await this.$productGroups.createProductGroup()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлена новая группа ${data.title}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createProductGroup')
    }
  }

  /**
   * * Изменение группы товаров
   * @returns группа товаров
   */
  async editProductGroup () {
    try {
      this.$wait.start('editProductGroup')
      const data = await this.$productGroups.editProductGroup()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Изменена группа ${data.title}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editProductGroup')
    }
  }

  /**
   * * Удаление группы товаров
   * @param id - id группы товаров
   * @returns группа товаров
   */
  async removeProductGroup (id: number) {
    try {
      this.$wait.start('removeProductGroup')
      const data = await this.$productGroups.removeProductGroup(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Группа товаров ${data.title} удалена`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeProductGroup')
    }
  }

  /**
   * * Обновление групп товаров
   */
  async updateAllProducts () {
    try {
      this.$wait.start('updateAll')
      await this.$productGroups.updateAll()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Группы товаров обновлены'
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('updateAll')
    }
  }

  /**
   * * Изменение группы товаров с помощью файлов
   */
  async loadFromFile () {
    try {
      this.$wait.start('loadFromFile')
      await this.$productGroups.loadFromFile()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('loadFromFile')
    }
  }

  /**
   * * Выгрузка excel файла группы
   * @param id - id группы
   */
  async getProductGroupExcel (id: number): Promise<Blob> {
    try {
      this.$wait.start('getProductGroupExcel')
      return await this.$productGroups.getProductGroupExcel(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getProductGroupExcel')
    }
  }
}
