import { WordPluralsPlugin, WordPlural } from './interfaces'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (wordPluralsStore:any) {
  const api: WordPluralsPlugin = {
    // ? ________ getters ________

    // * Получить список wordPlurals и пагинатор
    get wordPluralsList (): ResponseData<WordPlural> {
      return wordPluralsStore.wordPluralsList
    },

    get validators (): ValidatorParams {
      return wordPluralsStore.validators
    },

    // * Получить wordPlural по id из массва wordPlurals
    wordPluralById (id: number): WordPlural | undefined {
      return wordPluralsStore.wordPluralById(id)
    },

    // * Получить текущий wordPlural
    get currentWordPlural (): WordPlural {
      return wordPluralsStore.currentWordPlural
    },

    // ? ________ setters ________

    // * Установить список wordPlurals и пагинатор
    set wordPluralsList (wordPlurals: ResponseData<WordPlural>) {
      wordPluralsStore.setWordPluralsList(wordPlurals)
    },

    // * Очистить форму
    resetCurrentWordPlural () {
      wordPluralsStore.resetCurrentWordPlural()
    },

    resetWordPlurals () {
      wordPluralsStore.resetWordPlurals()
    },

    // * Установить текщий wordPlural
    set currentWordPlural (wordPlural: WordPlural) {
      wordPluralsStore.setCurrentWordPlural(wordPlural)
    },

    // ? ________ actions ________

    // * Запрос на получение списка wordPlurals и пагинатор
    getWordPlurals (pageParams: PageParams | null = null): Promise<ResponseData<WordPlural>> {
      return wordPluralsStore.getWordPlurals(pageParams)
    },

    // * Запрос на получение wordPlural по id
    getWordPlural (id: number): Promise<WordPlural> {
      return wordPluralsStore.getWordPlural(id)
    },

    // * Запрос на удаление wordPlural по id
    removeWordPlural (id: number): Promise<WordPlural> {
      return wordPluralsStore.removeWordPlural(id)
    },

    // * Запрос на изменение wordPlural
    editWordPlural (): Promise<WordPlural> {
      return wordPluralsStore.editWordPlural()
    },

    // * Запрос на создание wordPlural
    createWordPlural (): Promise<WordPlural> {
      return wordPluralsStore.createWordPlural()
    }
  }
  return api
}
