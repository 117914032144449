import { Component, Vue } from 'nuxt-property-decorator'
import { PAGINATION_DEFAULT_STATE } from './constants'

@Component
export default class Newsletters extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    next()
  }

  //* Получить все рассылки
  async getNewsletters ({ page, pageSize }: { page?: number, pageSize?: number }) {
    try {
      this.$wait.start('getNewsletters')
      return await this.$newsletters.getNewsletters({ page, pageSize })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('getNewsletters')
    }
  }

  //* Создать рассылку
  async createNewsletter () {
    try {
      // @ts-ignore
      if (!this.validateForms([{ ref: this.$refs.form }])?.isValid) { return }

      this.$wait.start('createNewsletter')
      await this.$newsletters.createNewsletter()

      // @ts-ignore
      await this.getNewsletters(PAGINATION_DEFAULT_STATE);
      (this as any).dialogs.type = '';
      (this as any).dialogs.state = false
      this.$router.push({ query: { ...this.$route?.query, modal: undefined, id: undefined } })

      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Тип рассылки создан'
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('createNewsletter')
    }
  }

  //* Редактировать рассылку
  async editNewsletter () {
    try {
      // @ts-ignore
      if (!this.validateForms([{ ref: this.$refs.form }])?.isValid) { return }

      this.$wait.start('editNewsletter')
      await this.$newsletters.editNewsletter()

      // @ts-ignore
      await this.getNewsletters(PAGINATION_DEFAULT_STATE);
      (this as any).dialogs.type = '';
      (this as any).dialogs.state = false
      this.$router.push({ query: { ...this.$route?.query, modal: undefined, id: undefined } })
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Тип рассылки изменен'
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('editNewsletter')
    }
  }

  //* Получить тип рассылки по id
  async getNewsletterById (id: number) {
    try {
      this.$wait.start('getNewsletterById')
      await this.$newsletters.getNewsletterById(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('getNewsletterById')
    }
  }

  //* Получить тестовые почты
  async getWhitelist ({ page, pageSize }: { page?: number, pageSize?: number }) {
    try {
      this.$wait.start('getWhitelist')
      await this.$newsletters.getWhitelist({ page, pageSize })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('getWhitelist')
    }
  }

  //* Получить заблокированные почты
  async getBlacklist ({ page, pageSize }: { page?: number, pageSize?: number }) {
    try {
      this.$wait.start('getBlacklist')
      await this.$newsletters.getBlacklist({ page, pageSize })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('getBlacklist')
    }
  }

  //* Получить источники получателей
  async getRecipientSources () {
    try {
      this.$wait.start('getRecipientSources')
      await this.$newsletters.getRecipientSources()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('getRecipientSources')
    }
  }

  //* Получить источники получателей
  async removeEmail (
    listName: string,
    email: string,
    distributionTypeId?: number,
    status?: string
  ): Promise<void> {
    try {
      await this.$newsletters.removeEmail(listName, email, distributionTypeId, status)
      listName === 'recipients'
        // @ts-ignore
        ? await this.getWhitelist(PAGINATION_DEFAULT_STATE)
        // @ts-ignore
        : await this.getBlacklist(PAGINATION_DEFAULT_STATE)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    }
  }

  //* Загрузить новую почту
  async loadNewEmail (listName: string): Promise<void> {
    try {
      this.$wait.start('loadNewEmail')

      const status = (listName === 'recipients' && 'test') || undefined

      await this.$newsletters.loadNewEmail(listName, status)

      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Почта добавлена'
      })

      status
        // @ts-ignore
        ? await this.getWhitelist(PAGINATION_DEFAULT_STATE)
        // @ts-ignore
        : await this.getBlacklist(PAGINATION_DEFAULT_STATE)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('loadNewEmail')
    }
  }

  //* Загрузка эксель файла
  async uploadFileHandler (_: any, fileList: { raw: any }[]) {
    try {
      const file = fileList.map(file => file.raw)[0]
      const uploadedFiles = await (this as any).uploadFile(
        { files: [file], target: 'pages', type: file.type },
        { notifyMessage: true }
      )

      if ((this as any).dialogs.type !== 'create' && (this as any).dialogs.type !== 'edit') {
        this.$newsletters.setNewFile(uploadedFiles[0].id)

        return
      }

      this.$newsletters.setFormNewslettersState({
        key: 'recipientSourceParams',
        value: { fileId: uploadedFiles[0].id }
      })
    } catch (e: any) {
      console.error(e)
    }
  }

  //* Удаление эксель файла
  removeFile () {
    try {
      if ((this as any).dialogs.type !== 'edit' && (this as any).dialogs.type !== 'create') {
        return this.$newsletters.setNewFile(undefined)
      }

      this.$newsletters.setFormNewslettersState({
        key: 'recipientSourceParams',
        value: undefined
      })
    } catch (e: any) {
      console.error(e)
    }
  }

  //* Сохранить список загруженный через эксель
  async saveBlacklist () {
    try {
      this.$wait.start('saveBlacklist')
      await this.$newsletters.saveBlacklist()

      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Почты сохранены'
      })

      // @ts-ignore
      await this.getBlacklist(PAGINATION_DEFAULT_STATE)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('saveBlacklist')
    }
  }

  //* Запустить рассылку
  async sendNewsletter (id: number, status?: string) {
    try {
      this.$wait.start(`sendNewsletter${id}`)
      await this.$newsletters.sendNewsletter(id, status)

      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Рассылка запущена'
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end(`sendNewsletter${id}`)
    }
  }

  //* Запустить рассылку по неразосланным почтам
  async sendUnsentNewsletter (id: number) {
    if (!id) {
      return this.$notify({
        type: 'error',
        title: 'Ошибка',
        message: 'Рассылка еще не запускалась'
      })
    }

    try {
      this.$wait.start(`sendUnsentNewsletter${id}`)
      await this.$newsletters.sendUnsentNewsletter(id)

      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Рассылка запущена'
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end(`sendUnsentNewsletter${id}`)
    }
  }

  //* Получить информацию о рассылках по типу рассылки
  async viewStatsNewsletter (id: number) {
    try {
      this.$wait.start('viewStatsNewsletter')
      this.$wait.start(`viewStatsNewsletter${id}`)
      await this.$newsletters.viewStatsNewsletter(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end(`viewStatsNewsletter${id}`)
      this.$wait.end('viewStatsNewsletter')
    }
  }

  //* Скачать файл с информацией о рассылках по типу рассылки
  async downloadStatsOfNewsletter () {
    try {
      this.$wait.start('downloadStatsOfNewsletter')

      if (!this.$newsletters.statsOfNewsletters?.[this.$newsletters.currentStatsOfNewslettersId as number]?.[0]?.id) {
        return this.$notify({
          type: 'error',
          title: 'Ошибка',
          message: 'У типа рассылки отсутствуют рассылки'
        })
      }

      //* Запрос на excel файл
      const data = await this.$newsletters.downloadStatsOfNewsletter()

      //* Проверка наличия файла в ответе
      if (!data) { return }

      //* Загрузка файла
      this.$download.downloadFile(data, { name: 'статистика.xlsx', type: 'xlsx' })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('downloadStatsOfNewsletter')
    }
  }
}
