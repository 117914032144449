import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { Ids, Params, DeliveryPrice, Filters } from './interfaces'
import { defaultData, FormError, ResponseData, ValidatorParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'
@Module({
  name: 'deliveryPrices',
  stateFactory: true,
  namespaced: true
})
export default class DeliveryPricesModule extends VuexModule {
  /**
   * * Значения фильтров
   */
  filtersValue: Filters = {
    name: undefined,
    code: undefined
  }

  /**
   * * Список типов доставок
   */
  deliveryPricesValue: ResponseData<DeliveryPrice> = defaultData

  /**
   * * Тип доставки
   */
  deliveryPriceValue: DeliveryPrice = {
    code: '',
    name: '',
    price: 0,
    imageId: null
  }

  // ? ______________ getters ______________

  /**
   * * Получить фильтры
   */
  get filters (): Filters {
    return this.filtersValue
  }

  /**
   * * Получить список типов доставок
   */
  get deliveryPrices (): ResponseData<DeliveryPrice> {
    return this.deliveryPricesValue
  }

  /**
   * * Получить тип доставки
   */
  get deliveryPrice (): DeliveryPrice {
    return this.deliveryPriceValue
  }

  /**
   * * Получить тип доставки по id
   */
  get deliveryPriceById () {
    const deliveryPrices = this.deliveryPrices.data
    return function (id: number): DeliveryPrice | undefined {
      return deliveryPrices.find(deliveryPrice => deliveryPrice.id === id)
    }
  }

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      title: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите название группы доставок',
        trigger: ['blur']
      }],
      price: [{
        required: true,
        message: 'Введите цену',
        trigger: ['blur']
      },
      {
        pattern: validatorsPattern.notWholeNumbers,
        message: 'Введите корректную цену',
        trigger: ['change', 'blur']
      }
      ]
    }
  }

  // ? ______________ setters ______________

  /**
   * * Установить фильтры
   * @param filters - значение фильтров
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  /**
   * * Сбросить фильтры
   */
  @Mutation
  resetFilters (): void {
    this.filtersValue = {
      name: undefined,
      code: undefined
    }
  }

  /**
   * * Установить тип доставки
   * @param deliveryPrices список типов доставки
   */
  @Mutation
  setDeliveryPrices (deliveryPrices: ResponseData<DeliveryPrice>) {
    this.deliveryPricesValue = deliveryPrices
  }

  /**
   * * Установить тип доставки
   * @param deliveryPrice тип доставки
   */
  @Mutation
  setDeliveryPrice (deliveryPrice: DeliveryPrice) {
    this.deliveryPriceValue = deliveryPrice
  }

  /**
   * * Сброс типов доставки
   */
  @Mutation
  resetDeliveryPrices () {
    this.deliveryPricesValue = defaultData
  }

  /**
   * * Сбросить тип доставки
   */
  @Mutation
  resetDeliveryPrice () {
    this.deliveryPriceValue = {
      code: '',
      name: '',
      price: 0,
      imageId: null
    }
  }

  // ? ______________ actions ______________

  /**
   * * Запрос на получение списка типов доставки
   * @param params - параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setDeliveryPrices'
  })
  async getDeliveryPrices (params: Params) {
    try {
      const { data } = await $axios.get(`/delivery/${params.serviceId}/prices`, { params: { ...params, ...this.filters } })
      const response: ResponseData<DeliveryPrice> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение типа доставки по id
   * @param ids id типа доставки и id сервиса
   */
  @Action({
    rawError: true,
    commit: 'setDeliveryPrice'
  })
  async getDeliveryPriceById (ids: Ids) {
    try {
      const { data: { data } } = await $axios.get(`/delivery/${ids.serviceId}/prices/${ids.id}`)
      const response: DeliveryPrice = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание типа доставки по id
   * @param serviceId id сервиса
   */
  @Action({
    rawError: true
  })
  async createDeliveryPrice (serviceId: number) {
    try {
      const { data: { data } } = await $axios.post(`/delivery/${serviceId}/prices`, this.deliveryPrice)
      const response: DeliveryPrice = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение типа доставки
   * @param serviceId id сервиса
   */
  @Action({
    rawError: true
  })
  async editDeliveryPrice (serviceId: number) {
    try {
      const { id, ...newDeliveryPrice } = this.deliveryPrice
      const { data: { data } } = await $axios.put(`/delivery/${serviceId}/prices/${id}`, newDeliveryPrice)
      const response: DeliveryPrice = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на удаление типа доставки
   * @param ids id типа доставки и id сервиса
   */
  @Action({
    rawError: true
  })
  async removeDeliveryPrice (ids: Ids) {
    try {
      const { data: { data } } = await $axios.delete(`/delivery/${ids.serviceId}/prices/${ids.id}`)
      const response: DeliveryPrice = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
