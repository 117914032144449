import { OffersPlugin, RequestOffer, OffersListData, RequestOfferStatus, Filters } from './interfaces'
import OfferStore from './store'
import { PageParams } from '~/store/interfaces'

export default function getPluginApi (offerStore: OfferStore) {
  const api: OffersPlugin = {
    // ? ________ getters ________
    get filters (): Filters {
      return offerStore.filters
    },

    get currentOffer (): RequestOffer {
      return offerStore.currentOffer
    },

    get offerStatuses (): RequestOfferStatus[] {
      return offerStore.offerStatuses
    },

    offerById (id: number): RequestOffer | undefined {
      return offerStore.offerById(id)
    },

    get offersList (): OffersListData {
      return offerStore.offersList
    },

    // ? ________ setters ________
    // set offersList (offers: ResponseData<RequestOffer>) {
    //   offerStore.setOrdersList(offers)
    // },

    set filters (filters: Filters) {
      offerStore.setFilters(filters)
    },

    resetFilters () {
      offerStore.resetFilters()
    },

    set currentOffer (offer: RequestOffer) {
      offerStore.setCurrentOffer(offer)
    },
    resetCurrentOffer () {
      offerStore.resetCurrentOffer()
    },

    resetOffersList () {
      offerStore.resetOffersList()
    },
    // ? ________ actions ________

    getOffers (pageParams: PageParams | null = null): Promise<OffersListData> {
      return offerStore.getOffers(pageParams)
    },

    changeRequestOfferStatus (id: number, status: number): Promise<RequestOffer> {
      return offerStore.changeRequestOfferStatus({ id, status })
    },

    getOffer (id: number): Promise<RequestOffer> {
      return offerStore.getOffer(id)
    },

    getRequestOfferStatuses (): Promise<RequestOfferStatus[]> {
      return offerStore.getRequestOfferStatuses()
    },

    createOrderByOffer (id: number) {
      return offerStore.createOrderByOffer(id)
    }
  }
  return api
}
