import { BasketsPlugin, Basket, Filters } from './interfaces'
import { PageParams, ResponseData } from '~/store/interfaces'

export default function getPluginApi (basketsStore:any) {
  const api: BasketsPlugin = {
    // ? ________ getters ________
    get filters (): Filters {
      return basketsStore.filters
    },
    get basketsList (): ResponseData<Basket> {
      return basketsStore.basketsList
    },

    // ? ________ setters ________
    set filters (filters: Filters) {
      basketsStore.setFilters(filters)
    },
    set basketsList (baskets: ResponseData<Basket>) {
      basketsStore.setBasketsList(baskets)
    },

    resetFilters (): void {
      basketsStore.resetFilters()
    },

    resetBaskets (): void {
      basketsStore.resetBaskets()
    },
    // ? ________ actions ________
    getBaskets (pageParams: PageParams): Promise<ResponseData<Basket>> {
      return basketsStore.getBaskets(pageParams)
    }
  }
  return api
}
