import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ResponseData, PageParams, ValidatorParams } from '../../interfaces'
import { SearchLog, Filters } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'searchLogs',
  stateFactory: true,
  namespaced: true
})
export default class SearchLogsModule extends VuexModule {
  /**
   * * Поисковые логи
   */
  searchLogsValue: ResponseData<SearchLog> = defaultData

  /**
   * * Поисковой лог
   */
  searchLogValue: SearchLog = {
    query: '',
    count: 0,
    searchUpdate: 0,
    active: false,
    description: '',
    params: {},
    options: ''
  }

  filtersValue: Filters = {}

  // ? ______________ getters ______________

  /**
   * * Получить список поисковых логов
   */
  get searchLogs (): ResponseData<SearchLog> {
    return this.searchLogsValue
  }

  /**
   * * Получить поисковой лог
   */
  get searchLog (): SearchLog {
    return this.searchLogValue
  }

  /**
   * * Получить поисковой лог по query
   */
  get searchLogByQuery () {
    const searchLogs = this.searchLogsValue
    return function (query: string): SearchLog | undefined {
      return searchLogs.data.find(searchLog => searchLog.query === query)
    }
  }

  get filters (): Filters {
    return this.filtersValue
  }

  /**
   * * валидна ли форма
   */
  get validators (): ValidatorParams {
    return {
      name: [{ required: true, message: 'Введите название сайта (Город)', trigger: ['blur', 'change'] }],
      code: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите код сайта', trigger: ['blur'] }],
      maintenanceMessage: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите сообщение', trigger: ['blur'] }]
    }
  }

  // ? ______________ setters ______________

  /**
   * * Установить список поисковых логов
   */
  @Mutation
  setSearchLogs (searchLogs: ResponseData<SearchLog>) {
    this.searchLogsValue = searchLogs
  }

  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  @Mutation
  resetFilters () {
    this.filtersValue = {}
  }

  /**
   * * Установить поисковой лог
   */
  @Mutation
  setSearchLog (searchLog: SearchLog) {
    this.searchLogValue = searchLog
  }

  /**
   * * Сбросить поисковой лог
   */
  @Mutation
  resetSearchLog () {
    this.searchLogValue = {
      query: '',
      count: 0,
      searchUpdate: 0,
      active: false,
      description: '',
      params: {},
      options: ''
    }
  }

  /**
   * * Сбросить поисковой лог
   */
  @Mutation
  resetSearchLogs () {
    this.searchLogsValue = defaultData
  }

  // ? ______________ actions ______________

  /**
   * * Получить список поисковых логов
   * @param params - параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setSearchLogs'
  })
  async getSearchLogs (params: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/search-log', {
        params: {
          ...params,
          ...this.filters,
          sort: params?.sort || 'count',
          order: params?.order || 'desc'
        }
      })
      const response: ResponseData<SearchLog> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить поисковой лог по id
   */
  @Action({
    rawError: true,
    commit: 'setSearchLog'
  })
  async getSearchLogByQuery (query: string) {
    try {
      const { data: { data } } = await $axios.get(`/search-log/${encodeURI(query)}`)
      const response: SearchLog = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать поисковой лог
   */
  @Action({
    rawError: true
  })
  async createSearchLog () {
    try {
      const { data: { data } } = await $axios.post('/search-log', this.searchLog)
      const response: SearchLog = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить поисковой лог
   */
  @Action({
    rawError: true
  })
  async editSearchLog () {
    try {
      const { data: { data } } = await $axios.put(`/search-log/${this.searchLog.query}`, this.searchLog)
      const response: SearchLog = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить поисковой лог
   */
  @Action({
    rawError: true
  })
  async removeSearchLog (query: string) {
    try {
      const { data: { data } } = await $axios.delete(`/search-log/${query}`)
      const response: SearchLog = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
