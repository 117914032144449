import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { OrdersParams, InputParams, FormsParams, StatusesParams, StatusesByDayResp } from './interfaces'
import { FormError } from '~/store/interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'mainPage',
  stateFactory: true,
  namespaced: true
})
export default class MainPageModule extends VuexModule {
  formsValue: FormsParams = {
    forms: []
  }

  ordersValue: OrdersParams = {
    orderCountByCity: {},
    newOrdersCount: 0,
    totalOrdersSum: '',
    sumPerNewOrder: '',
    newUsersCount: 0
  }

  statusesValue: StatusesParams = {
    orders: []
  }

  daysValue: StatusesParams = {
    orders: []
  }

  monthsValue: StatusesParams = {
    orders: []
  }

  /**
   * * Список ежедневной статистики по заказам с учетом статуса
   */
  statusesByDayValue: StatusesByDayResp = {
    orders: []
  }

  // ? ______ getters ______ //

  /**
   * * Получить orders
   */
  get orders (): OrdersParams {
    return this.ordersValue
  }

  /**
   * * Получить forms
   */
  get forms (): FormsParams {
    return this.formsValue
  }

  /**
   * * Получить statuses
   */
  get statuses (): StatusesParams {
    return this.statusesValue
  }

  /**
   * * Получить days
   */
  get days (): StatusesParams {
    return this.daysValue
  }

  /**
   * * Получить months
   */
  get months (): StatusesParams {
    return this.monthsValue
  }

  /**
   * * Получить список ежедневной статистики по заказам с учетом статуса
   */
  get statusesByDay (): StatusesByDayResp {
    return this.statusesByDayValue
  }

  // ? ______ setters ______ //

  @Mutation
  resetMainPage () {
    this.formsValue = {
      forms: []
    }

    this.ordersValue = {
      orderCountByCity: {},
      newOrdersCount: 0,
      totalOrdersSum: '',
      sumPerNewOrder: '',
      newUsersCount: 0
    }

    this.statusesValue = {
      orders: []
    }

    this.daysValue = {
      orders: []
    }

    this.monthsValue = {
      orders: []
    }
  }

  /**
   * * Установить orders
   * @param params - значения orders
   */
  @Mutation
  setOrders (params: OrdersParams) {
    this.ordersValue = { ...this.ordersValue, ...params }
  }

  /**
   * * Установить forms
   * @param params - значения forms
   */
  @Mutation
  setForms (params: FormsParams) {
    this.formsValue = params
  }

  /**
   * * Установить statuses
   * @param params - значения statuses
   */
  @Mutation
  setStatuses (params: StatusesParams) {
    this.statusesValue = params
  }

  /**
   * * Установить days
   * @param params - значения days
   */
  @Mutation
  setDays (params: StatusesParams) {
    this.daysValue = params
  }

  /**
   * * Установить months
   * @param params - значения months
   */
  @Mutation
  setMonths (params: StatusesParams) {
    this.monthsValue = params
  }

  /**
   * * Установить список ежедневной статистики по заказам с учетом статуса
   */
  @Mutation
  setStatusesByDay (params: StatusesByDayResp) {
    this.statusesByDayValue = params
  }

  // ? ______ actions ______ //

  /**
   * * Запрос на получение orders
   * @param params - параметры запроса
   * @returns orders
   */
  @Action({
    rawError: true,
    commit: 'setOrders'
  })
  async getOrdersGeography (params: InputParams | null = null): Promise<{orderCountByCity: Record<string, number>}> {
    try {
      const { data: { data: { orderCountByCity } } } = await $axios.get('/main-page/orders', { params })
      const response: {orderCountByCity: Record<string, number>} = { orderCountByCity }
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение orders
   * @param params - параметры запроса
   * @returns orders
   */
  @Action({
    rawError: true,
    commit: 'setOrders'
  })
  async getOrderUser (params: InputParams | null = null): Promise<OrdersParams> {
    try {
      const { data: { data: { newOrdersCount, totalOrdersSum, sumPerNewOrder, newUsersCount } } } = await $axios.get('/main-page/orders', { params })
      const response: OrdersParams = { newOrdersCount, totalOrdersSum, sumPerNewOrder, newUsersCount }
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение forms
   * @param params - параметры запроса
   * @returns forms
   */
  @Action({
    rawError: true,
    commit: 'setForms'
  })
  async getForms (params: InputParams | null = null): Promise<FormsParams> {
    try {
      const { data: { data } } = await $axios.get('/main-page/forms', { params })
      const response: FormsParams = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение statuses
   * @param params - параметры запроса
   * @returns statuses
   */
  @Action({
    rawError: true,
    commit: 'setStatuses'
  })
  async getStatuses (params: InputParams | null = null): Promise<StatusesParams> {
    try {
      const { data: { data } } = await $axios.get('/main-page/statuses', {
        params
      })
      const response: StatusesParams = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение days
   * @param params - параметры запроса
   * @returns days
   */
  @Action({
    rawError: true,
    commit: 'setDays'
  })
  async getDays (params: InputParams | null = null): Promise<StatusesParams> {
    try {
      const { data: { data } } = await $axios.get('/main-page/days', {
        params
      })
      const response: StatusesParams = { orders: data.orders.sort((a: any, b: any) => a.order_day - b.order_day) }
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение months
   * @param params - параметры запроса
   * @returns months
   */
  @Action({
    rawError: true,
    commit: 'setMonths'
  })
  async getMonths (params: InputParams | null = null): Promise<StatusesParams> {
    try {
      const { data: { data } } = await $axios.get('/main-page/month', {
        params
      })
      const response: StatusesParams = { orders: data.orders.sort((a: any, b: any) => a.order_month - b.order_month) }
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на список ежедневной статистики по заказам с учетом статуса
   * @param params - параметры запроса
   * @returns список ежедневной статистики
   */
  @Action({
    rawError: true,
    commit: 'setStatusesByDay'
  })
  async getStatusesByDay (params: InputParams | null = null): Promise<StatusesByDayResp> {
    try {
      const { data: { data } } = await $axios.get('/main-page/statuses-by-day', {
        params
      })
      const response: StatusesByDayResp = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на excel файл со списоком ежедневной статистики по заказам с учетом статуса
   * @param params - параметры запроса
   * @returns excel файл списком ежедневной статистики
   */
  @Action({
    rawError: true
  })
  async getStatusesByDayExcel (params: InputParams | null = null): Promise<Blob> {
    try {
      const { data } = await $axios.get('/main-page/statuses-by-day-excel', {
        params,
        responseType: 'blob'
      })
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
