import { PromoCodesPlugin, PromoCode, Filters } from './interfaces'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (promoCodesStore:any) {
  const api: PromoCodesPlugin = {
    // ? ________ getters ________

    get filters (): Filters {
      return promoCodesStore.filters
    },

    get promoCodesList (): ResponseData<PromoCode> {
      return promoCodesStore.promoCodesList
    },

    get validators (): ValidatorParams {
      return promoCodesStore.validators
    },

    promoCodeById (id: number): PromoCode | undefined {
      return promoCodesStore.promoCodeById(id)
    },

    get currentPromoCode (): PromoCode {
      return promoCodesStore.currentPromoCode
    },

    // ? ________ setters ________

    set filters (filters: Filters) {
      promoCodesStore.setFilters(filters)
    },

    resetFilters (): void {
      promoCodesStore.resetFilters()
    },

    set promoCodesList (promoCodes: ResponseData<PromoCode>) {
      promoCodesStore.setPromoCodesList(promoCodes)
    },

    resetCurrentPromoCode () {
      promoCodesStore.resetCurrentPromoCode()
    },

    resetPromoCodes () {
      promoCodesStore.resetPromoCodes()
    },

    set currentPromoCode (promoCode: PromoCode) {
      promoCodesStore.setCurrentPromoCode(promoCode)
    },

    // ? ________ actions ________

    getPromoCodes (pageParams: PageParams): Promise<ResponseData<PromoCode>> {
      return promoCodesStore.getPromoCodes(pageParams)
    },

    getPromoCode (id: number): Promise<PromoCode> {
      return promoCodesStore.getPromoCode(id)
    },

    removePromoCode (id: number): Promise<PromoCode> {
      return promoCodesStore.removePromoCode(id)
    },

    editPromoCode (): Promise<PromoCode> {
      return promoCodesStore.editPromoCode()
    },

    createPromoCode (): Promise<PromoCode> {
      return promoCodesStore.createPromoCode()
    }
  }
  return api
}
