import pageSizes from '~/utils/page-sizes'

export const defaultPaginator = {
  pageSize: pageSizes.default,
  currentPage: 0,
  totalItems: 0,
  totalPages: 0
}

export const defaultPagMeta = {
  currentPage: 1,
  offset: 0,
  pageCount: 0,
  perPage: 20,
  totalCount: 0
}

export const defaultData = {
  data: [],
  paginator: { ...defaultPaginator, ...defaultPagMeta },
  meta: defaultPagMeta
}
