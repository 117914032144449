import { Robot, RobotsPlugin } from './interfaces'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (robotsStore:any) {
  const api: RobotsPlugin = {
    // ? ________ getters ________

    // * Получить список robots и пагинатор
    get robotList (): ResponseData<Robot> {
      return robotsStore.robotList
    },

    // * Получить текущий robot
    get currentRobot (): Robot {
      return robotsStore.currentRobot
    },

    get validators (): ValidatorParams {
      return robotsStore.validators
    },

    // * Получить robot по id из списка robots
    getRobotById (id: number): Robot | undefined {
      return robotsStore.getRobotById(id)
    },
    // ? ________ setters ________

    // * Установить список robots с пагинацией
    set robotList (robotList: ResponseData<Robot>) {
      robotsStore.setRobotList(robotList)
    },

    // * Установить текущий robot
    set currentRobot (currentRobot: Robot) {
      robotsStore.setCurrentRobot(currentRobot)
    },

    // * Очистить текущий robot
    resetCurrentRobot () {
      robotsStore.resetCurrentRobot()
    },

    resetRobots () {
      robotsStore.resetRobots()
    },

    // ? ________ actions ________

    // * Запрос на получение всех robots и пагинатор
    getRobots (pageParams: PageParams | null = null): Promise<ResponseData<Robot>> {
      return robotsStore.getRobots(pageParams)
    },

    // * Запрос на получение robot по id
    getRobot (id: number): Promise<Robot> {
      return robotsStore.getRobot(id)
    },

    // * Запрос на создание robot
    createRobot () {
      return robotsStore.createRobot()
    },

    // * Запрос на изменение robot
    editRobot () {
      return robotsStore.editRobot()
    },

    // * Запрос на удаление robot по id
    deleteRobot (id: number) {
      return robotsStore.deleteRobot(id)
    }
  }
  return api
}
