import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'
import defaultPageSize from '~/utils/page-sizes'

@Component
export default class SeoPagesMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$seoPages.resetFilters()
    this.$seoPages.resetCurrentSeoPage()
    this.$seoPages.resetLinkTypes()
    next()
  }

  /**
   * * Запрос на список типов ссылок
   * @returns список типов ссылок
   */
  async getLinkTypes () {
    try {
      this.$wait.start('getLinkTypes')
      return await this.$seoPages.getLinksTypes()
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally { this.$wait.end('getLinkTypes') }
  }

  /**
   * * Запрос на список seoPages
   * @param pageParams - обьект пагинатора
   * @returns список seoPages
   */
  async getSeoPages (pageParams: PageParams) {
    try {
      this.$wait.start('getSeoPages')
      return await this.$seoPages.getSeoPages({
        page: +(pageParams.page ?? 1),
        pageSize: +(pageParams.pageSize ?? defaultPageSize.default),
        sort: pageParams.sort,
        order: pageParams.order
      })
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getSeoPages') }
  }

  /**
   * * Удаление seo/page по id
   * @param id - id SEO страницы
   * @returns SEO страница
   */
  async removeSeoPage (id: number) {
    try {
      this.$wait.start('removeSeoPage')
      const data = await this.$seoPages.removeSeoPage(id)
      this.$notify({
        type: 'success',
        title: 'Удалено',
        message: `Сео страница ${data.url} удалена`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removeSeoPage') }
  }

  /**
     * * Выгрузить в excel
     */
  async exportExcelSeo (pageParams: PageParams) {
    try {
      this.$wait.start('exportExcelSeo')
      return await this.$seoPages.exportExcel(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('exportExcelSeo')
    }
  }

  /**
   * * Создание seo/page
   * @returns SEO страница
   */
  async createSeoPage () {
    try {
      this.$wait.start('changeSeoPage')
      const data = await this.$seoPages.createSeoPage()
      this.$notify({
        title: 'Успешно',
        message: `Сео страница ${data.url} создана`,
        type: 'success'
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('changeSeoPage')
    }
  }

  /**
   * * Изменение seo/page
   * @returns SEO страница
   */
  async editSeoPage () {
    try {
      this.$wait.start('changeSeoPage')
      const data = await this.$seoPages.editSeoPage()
      this.$notify({
        title: 'Успешно',
        message: `Сео страница ${data.url} изменена`,
        type: 'success'
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('changeSeoPage')
    }
  }

  /**
   * * Генерация sitemap
   */
  async generateSiteMap () {
    try {
      this.$wait.start('generateSiteMap')
      await this.$seoPages.generateSiteMap()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Sitemap сгенерирован'
      })
      this.$wait.end('generateSiteMap')
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    }
  }

  async updateCategories () {
    try {
      this.$wait.start('updateCategories')
      await this.$seoPages.updateCategories()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Категории обновлены'
      })
      this.$wait.end('updateCategories')
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    }
  }

  async updateContent (type: string) {
    try {
      this.$wait.start(`updateContent-${type}`)
      await this.$seoPages.updateContent(type)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Модуль ${type} обновлен`
      })
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end(`updateContent-${type}`)
    }
  }
}
