import { CustomFiltersPlugin, CustomFilters } from './interfaces'
import CustomFiltersStore from './store'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (customFiltersStore: CustomFiltersStore) {
  const api: CustomFiltersPlugin = {
    // ? ________ getters ________

    // * Получить список customFilters и пагинатор
    get customFiltersList (): ResponseData<CustomFilters> {
      return customFiltersStore.customFiltersList
    },

    get validators (): ValidatorParams {
      return customFiltersStore.validators
    },

    // * Получить customFilter по id из массва customFilters
    customFilterById (id: number): CustomFilters | undefined {
      return customFiltersStore.customFilterById(id)
    },

    // * Получить текущий customFilter
    get currentCustomFilter (): CustomFilters {
      return customFiltersStore.currentCustomFilter
    },

    // ? ________ setters ________

    // * Установить список customFilters и пагинатор
    set customFiltersList (customFilters: ResponseData<CustomFilters>) {
      customFiltersStore.setCustomFiltersList(customFilters)
    },

    // * Очистить форму
    resetCurrentCustomFilter () {
      customFiltersStore.resetCurrentCustomFilter()
    },

    resetCustomFilters () {
      customFiltersStore.resetCustomFilters()
    },

    // * Установить текщий customFilter
    set currentCustomFilter (customFilter: CustomFilters) {
      customFiltersStore.setCurrentCustomFilter(customFilter)
    },

    // ? ________ actions ________

    // * Запрос на получение списка customFilters и пагинатор
    getCustomFilters (pageParams: PageParams | null = null): Promise<ResponseData<CustomFilters>> {
      return customFiltersStore.getCustomFilters(pageParams)
    },

    // * Запрос на получение customFilter по id
    getCustomFilter (id: number): Promise<CustomFilters> {
      return customFiltersStore.getCustomFilter(id)
    },

    // * Запрос на удаление customFilter по id
    removeCustomFilter (id: number): Promise<CustomFilters> {
      return customFiltersStore.removeCustomFilter(id)
    },

    // * Запрос на изменение customFilter
    editCustomFilter (): Promise<CustomFilters> {
      return customFiltersStore.editCustomFilter()
    },

    // * Запрос на создание customFilter
    createCustomFilter (): Promise<CustomFilters> {
      return customFiltersStore.createCustomFilter()
    }
  }
  return api
}
