import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ResponseData, PageParams, ValidatorParams } from '../../interfaces'
import { Filters, OrderStatus } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'orderStatuses',
  stateFactory: true,
  namespaced: true
})

export default class OrderStatusesModule extends VuexModule {
  /**
   * * Значения фильтров
   */
  filtersValue: Filters = {
    name: undefined
  }

  /**
   * * Текущий статус заказов
   */
  orderStatusValue: OrderStatus = {
    name: '',
    sort: 0,
    groupId: null
  }

  /**
   * * Массив статусов заказов
   */
  orderStatusesValue: ResponseData<OrderStatus> = defaultData

  // ? ______________ getters ______________
  /**
   * * Получить значения фильтров
   */
  get filters (): Filters {
    return this.filtersValue
  }

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      name: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите название', trigger: ['blur'] }],
      sort: [{ pattern: validatorsPattern.wholeNumbers, message: 'Введите целое число', trigger: ['blur'] }]
    }
  }

  /**
   * * Получить массив статусов заказов с пагинацией
   */
  get orderStatuses (): ResponseData<OrderStatus> {
    return this.orderStatusesValue
  }

  /**
   * * Получить текущий статус заказа
   */
  get currentOrderStatus (): OrderStatus {
    return this.orderStatusValue
  }

  /**
   * * Получить статус заказа из массива по id
   */
  get orderStatusById () {
    const orderStatuses = this.orderStatusesValue
    return function (id: number): OrderStatus | undefined {
      return orderStatuses.data.find(orderStatus => orderStatus.id === id)
    }
  }

  /**
   * * Получить статусы заказа по groupId из массива списка заказов
   */
  get orderStatusesByGroupId () {
    const orderStatuses = this.orderStatusesValue
    return function (id: number): OrderStatus[] | [] {
      return orderStatuses.data.filter(status => status.groupId === id)
    }
  }

  // ? ______________ setters ______________
  /**
   * * Установить значения фильтров
   * @param filters - значения фильтров
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  /**
   * * Сбросить значения фильтров
   */
  @Mutation
  resetFilters (): void {
    this.filtersValue = {
      name: undefined
    }
  }

  /**
   * * Установить массив статусов заказав с пагинацией
   * @param orderStatuses массив статусов заказов
   */
  @Mutation
  setOrderStatuses (orderStatuses: ResponseData<OrderStatus>) {
    this.orderStatusesValue = orderStatuses
  }

  /**
   * * Установить текущий статус заказа
   * @param orderStatus текущий статус заказа
   */
  @Mutation
  setCurrentOrderStatus (orderStatus: OrderStatus) {
    this.orderStatusValue = orderStatus
  }

  /**
   * * Очистить форму
   */
  @Mutation
  resetCurrentOrderStatus () {
    this.orderStatusValue = {
      name: '',
      sort: 0,
      groupId: null
    }
  }

  /**
   * * Очистить форму
   */
  @Mutation
  resetOrderStatuses () {
    this.orderStatusesValue = defaultData
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список статусов заказов
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setOrderStatuses'
  })
  async getOrderStatuses (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/shop/order-statuses', { params: { ...pageParams, ...this.filters } })
      const response: ResponseData<OrderStatus> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить статус заказа
   * @param id id статуса заказа
   */
  @Action({
    rawError: true,
    commit: 'setCurrentOrderStatus'
  })
  async getOrderStatus (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/shop/order-statuses/${id}`)
      const response: OrderStatus = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * создать статус заказа
   */
  @Action({
    rawError: true
  })
  async createOrderStatus () {
    try {
      const { data: { data } } = await $axios.post('/shop/order-statuses', this.currentOrderStatus)
      const response: OrderStatus = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить список статусов заказов
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true
  })
  async editOrderStatus () {
    try {
      const { id, ...params } = this.currentOrderStatus
      const { data: { data } } = await $axios.put(`/shop/order-statuses/${id}`, params)
      const response: OrderStatus = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить список статусов заказов
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true
  })
  async deleteOrderStatus (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/shop/order-statuses/${id}`)
      const response: OrderStatus = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
