import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ResponseData, PageParams, ValidatorParams } from '../../interfaces'
import { UserType, UserTypeParams } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'userTypes',
  stateFactory: true,
  namespaced: true
})

export default class UserTypesModule extends VuexModule {
  /**
   * * Массив всех userType исходя из запроса
   */
  userTypes: ResponseData<UserType> = defaultData

  userTypeParams: ResponseData<UserTypeParams> = defaultData

  /**
   * * Текущая userType
   */
  userType: UserType = {
    name: '',
    description: '',
    percent: null,
    params: {},
    priority: null
  }

  // ? ______________ getters ______________

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      name: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите название', trigger: ['blur'] }],
      percent: [{ pattern: validatorsPattern.floatFrom1To99, message: 'Введите значение от 1 до 99', trigger: ['blur'] }]
    }
  }

  /**
   * * Получить массив userTypesParams и пагинацией
   */
  get userTypeParamsList (): ResponseData<UserTypeParams> {
    return this.userTypeParams
  }

  /**
   * * Получить массив userTypes и пагинацией
   */
  get userTypesList (): ResponseData<UserType> {
    return this.userTypes
  }

  /**
   * * Получить userType из массива userTypes
   */
  get userTypeById () {
    const userTypes = this.userTypes
    return function (id: number): UserType | undefined {
      return userTypes.data.find(userType => userType.id === id)
    }
  }

  /**
   * * Получить текущую userType для измения или создания userType
   */
  get currentUserType () {
    return this.userType
  }

  // ? ______________ setters ______________

  /**
   * * Установить массив UserTypes
   * @param userTypes массив UserTypes
   */
  @Mutation
  setUserTypesList (userTypes: ResponseData<UserType>) {
    this.userTypes = userTypes
  }

  /**
   * * Установить массив UserTypesParams
   * @param userTypeParams массив userTypeParams
   */
  @Mutation
  setUserTypeParamsList (userTypeParams: ResponseData<UserTypeParams>) {
    this.userTypeParams = userTypeParams
  }

  /**
   * * Установить CurrentUserType для измения или создания userType
   * @param userType текущая UserType, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentUserType (userType: UserType) {
    this.userType = userType
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentUserType () {
    this.userType = {
      name: '',
      description: '',
      percent: null,
      params: {},
      priority: null
    }
  }

  @Mutation
  resetUserTypes () {
    this.userTypes = defaultData
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список UserTypes
   */
  @Action({
    rawError: true,
    commit: 'setUserTypesList'
  })
  async getUserTypes (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/user/type', { params: pageParams })
      const response: ResponseData<UserType> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить список UserTypesParams
   */
  @Action({
    rawError: true,
    commit: 'setUserTypeParamsList'
  })
  async getUserTypeParams () {
    try {
      const { data } = await $axios.get('/user/type/params')
      const response: ResponseData<UserTypeParams> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить userType по id
   * @param id id userType, который мы хотим получить
   */
  @Action({
    rawError: true, commit: 'setCurrentUserType'
  })
  async getUserType (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/user/type/${id}`)
      const response: UserType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить userType
   */
  @Action({
    rawError: true
  })
  async editUserType () {
    try {
      const { id, ...userType } = this.currentUserType
      const { data: { data } } = await $axios.put(`/user/type/${id}`, userType)
      const response: UserType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать UserType
   */
  @Action({
    rawError: true
  })
  async createUserType () {
    try {
      const { data: { data } } = await $axios.post('/user/type', this.currentUserType)
      const response: UserType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить userType по id
   * @param id  id userType, который мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removeUserType (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/user/type/${id}`)
      const response: UserType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
