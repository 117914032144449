import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { Filters, NewsItem } from './interfaces'
import { defaultData, PageParams, FormError, ResponseData, ValidatorParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'news',
  stateFactory: true,
  namespaced: true
})
export default class NewsModule extends VuexModule {
  filtersValue: Filters = {
    name: undefined,
    tag: undefined
  }

  newsValue: ResponseData<NewsItem> = defaultData

  // * Состояние сущности новости, дефолтные значения
  newsItemValue: NewsItem = {
    name: '',
    code: '',
    preview: '',
    text: '',
    position: 0,
    sort: 0,
    siteId: 1,
    fileId: null,
    previewId: null,
    tags: [],
    // Даты имеют значение null, т.к. могут не указываться
    publicationDate: null,
    endDate: null,
    showDetailImage: false,
    button: null,
    active: true
  }

  //* Стейт тегов
  tagsValue: string[] = []

  // ? ______ getters ______ //

  get validators (): ValidatorParams {
    return {
      name: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите название новости', trigger: ['blur'] }],
      code: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите код новости', trigger: ['blur'] }],
      text: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите текст новости', trigger: ['blur'] }],
      preview: [{ required: true, message: 'Введите предварительный просмотр новости', trigger: ['blur'] }],
      sort: [{ pattern: validatorsPattern.wholeNumbers, message: 'Введите целое число', trigger: ['blur'] }],
      position: [{ pattern: validatorsPattern.naturalNumbers, message: 'Введите натуральное число', trigger: ['blur'] }],
      fileId: [{ required: true, message: 'Прикрепите картинку', trigger: ['blur'] }],
      mainImageId: [{ required: false, message: 'Введите просмотр изображения для главной страницы', trigger: ['blur'] }]
    }
  }

  get buttonValidators (): ValidatorParams {
    return {
      title: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите текст кнопки', trigger: ['blur'] }],
      url: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите ссылку', trigger: ['blur'] }]
    }
  }

  // * Получить фильтры
  get filters (): Filters {
    return this.filtersValue
  }

  // * Получить список новостей
  get news (): ResponseData<NewsItem> {
    return this.newsValue
  }

  // * Получить новость по id
  get newsItemById () {
    const news = this.newsValue.data
    return function (id: number): NewsItem | undefined {
      return news.find(newsItem => newsItem.id === id)
    }
  }

  // * Получить новость
  get newsItem () {
    return this.newsItemValue
  }

  // * Валидна ли форма новости
  get validateNewsItem () {
    return !!this.newsItemValue.name && (this.newsItemValue.position > -1) && !!this.newsItemValue.code && !!this.newsItemValue.text && !!this.newsItemValue.preview && (this.newsItemValue.sort > -1) &&
           !!this.newsItemValue.fileId
  }

  //* Получить теги
  get tags (): string[] {
    return this.tagsValue
  }

  // ? ______ setters ______ //

  // * Установить фильтры
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  //* Сбросить фильтры
  @Mutation
  resetFilters () {
    this.filtersValue = {
      name: undefined,
      tag: undefined
    }
  }

  // * Установить новсти
  @Mutation
  setNews (news: ResponseData<NewsItem>) {
    this.newsValue = news
  }

  // * Установить новость
  @Mutation
  setNewsItem (newsItem: NewsItem) {
    this.newsItemValue = newsItem
  }

  // * Сбросить новость
  @Mutation
  resetNewsItem () {
    this.newsItemValue = {
      name: '',
      code: '',
      preview: '',
      text: '',
      position: 0,
      sort: 0,
      siteId: 1,
      fileId: null,
      previewId: null,
      tags: [],
      // Датам устанавливаются значение null, т.к. могут не указываться
      publicationDate: null,
      endDate: null,
      showDetailImage: false,
      button: null,
      active: true
    }
  }

  @Mutation
  resetNews () {
    this.newsValue = defaultData
  }

  //* Записать теги
  @Mutation
  setTags (tags: string[]) {
    this.tagsValue = tags
  }

  // ? ______ actions ______ //

  // * Запрос на получение списка новостей
  @Action({
    rawError: true,
    commit: 'setNews'
  })
  async getNews (pageParams: PageParams | null = null): Promise<ResponseData<NewsItem>> {
    try {
      const { data } = await $axios.get('/news', { params: { ...pageParams, ...this.filters } })
      const response: ResponseData<NewsItem> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  // * Запрос на создание новости
  @Action({
    rawError: true
  })
  async createNewsItem (): Promise<NewsItem> {
    try {
      const { data: { data } } = await $axios.post('/news', this.newsItem)
      const response: NewsItem = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  // * Запрос на получение новости по id
  @Action({
    rawError: true,
    commit: 'setNewsItem'
  })
  async getNewsItemById (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/news/${id}`)
      const response: NewsItem = data
      // если у нас есть активная кновпка в button, дублируем её в buttons
      if (this.newsItemValue?.button?.css?.display === 'inline-flex' && (!this.newsItemValue?.button?.buttons || !this.newsItemValue?.button?.buttons?.length)) {
        this.newsItemValue.button.buttons = [{ ...this.newsItemValue.button }]
      }
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  // * Запрос на изменение новости
  @Action({
    rawError: true
  })
  async editNewsItem (): Promise<NewsItem> {
    try {
      const { id, ...newNewsItem } = this.newsItem
      const { data: { data } } = await $axios.put(`/news/${id}`, newNewsItem)
      const response: NewsItem = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  // * Запрос на удаление новости
  @Action({
    rawError: true
  })
  async removeNewsItem (id: number): Promise<NewsItem> {
    try {
      const { data: { data } } = await $axios.delete(`/news/${id}`)
      const response: NewsItem = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  //* Запрос на получение тегов
  @Action({
    rawError: true,
    commit: 'setTags'
  })
  async getTags (): Promise<string[]> {
    try {
      const { data: { data } } = await $axios.get('/news/tags')
      return data as string[]
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
