import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { Filters, WaitingItem } from './interfaces'
import { defaultData, FormError, ResponseData, PageParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'listWaiting',
  stateFactory: true,
  namespaced: true
})
export default class ListWaitingModule extends VuexModule {
  filtersValue: Filters = {
    email: undefined,
    product: undefined
  }

  waitingListValue: ResponseData<WaitingItem> = defaultData

  // ? ______ getters ______ //

  /**
   * * Получить фильтры
   */
  get filters (): Filters {
    return this.filtersValue
  }

  /**
   * * Получить лист ожидания
   */
  get waitingList (): ResponseData<WaitingItem> {
    return this.waitingListValue
  }

  // ? ______ setters ______ //

  /**
   * * Установить фильтры
   * @param filters - значения фильтров
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  /**
   * * Установить лист ожидания
   * @param waitingList - лист ожидания и пагинатор
   */
  @Mutation
  setWaitingList (waitingList: ResponseData<WaitingItem>) {
    this.waitingListValue = waitingList
  }

  /**
   * * Сбросить фильтры
   */
  @Mutation
  resetFilters () {
    this.filtersValue = {
      email: undefined,
      product: undefined
    }
  }

  @Mutation
  resetWaitingLists () {
    this.waitingListValue = defaultData
  }

  // ? ______ actions ______ //

  /**
   * * Запрос на получение листа ожидания
   * @param pageParams - номер страницы, размер страницы
   * @returns лист ожидания и пагинатор
   */
  @Action({
    rawError: true,
    commit: 'setWaitingList'
  })
  async getWaitingList (pageParams: PageParams | null = null): Promise<ResponseData<WaitingItem>> {
    try {
      const { data } = await $axios.get('/shop/offer-group-notify', {
        params: {
          ...pageParams,
          ...this.filters
        }
      })
      const response: ResponseData<WaitingItem> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
