import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class RegionsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$regions.resetCurrentRegion()
    next()
  }

  /**
   * * Получение списка региона по query
   * @param pageParams - обьект пагинатора (необязательное)
   * @returns список региона
   */
  async getRegions (pageParams?: PageParams) {
    try {
      this.$wait.start('getRegions')
      return await this.$regions.getRegions({
        page: pageParams?.page ? +pageParams?.page : undefined,
        pageSize: pageParams?.pageSize ? +pageParams?.pageSize : undefined,
        sort: pageParams?.sort,
        order: pageParams?.order
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({ type: 'error', title: e.error_code, message: e.error_message })
      throw e
    } finally { this.$wait.end('getRegions') }
  }

  /**
   * * Удаление региона
   * @param id - id региона
   * @returns регион
   */
  async deleteRegion (id: number) {
    try {
      this.$wait.start('deleteRegion')
      const data = await this.$regions.removeRegion(+id)
      this.$notify({ type: 'success', title: 'Выполнено', message: `Удален регион ${data?.name}` })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({ type: 'error', title: e.error_code, message: e.error_message })
      throw e
    } finally { this.$wait.end('deleteRegion') }
  }

  /**
   * * Запрос на изменение региона
   * @returns регион
   */
  async editRegionRequest () {
    try {
      const data = await this.$regions.editRegion()
      this.$notify({ type: 'success', title: 'Выполнено', message: `Создан регион ${data?.name}` })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({ type: 'error', title: e.error_code, message: e.error_message })
      throw e
    } finally { this.$wait.end('changeRegion') }
  }

  /**
   * * Запрос на создание региона
   * @returns регион
   */
  async createRegion () {
    try {
      const data = await this.$regions.createRegion()
      this.$notify({ type: 'success', title: 'Выполнено', message: `Создан регион ${data?.name}` })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({ type: 'error', title: e.error_code, message: e.error_message })
      throw e
    } finally { this.$wait.end('changeRegion') }
  }
}
