import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class NewsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$news.resetFilters()
    this.$news.resetNewsItem()
    next()
  }

  /**
   * * Получение списка новостей
   * @param pageParams - параметры пагинации
   * @returns список новостей
   */
  async getNews (pageParams: PageParams) {
    try {
      this.$wait.start('getNews')
      return await this.$news.getNews(pageParams)
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getNews')
    }
  }

  /**
   * * Получение новости по id
   * @param id - id новости
   * @returns новость
   */
  async getNewsItemById (id: number) {
    try {
      this.$wait.start('getNewsItemById')
      return await this.$news.getNewsItemById(id)
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getNewsItemById')
    }
  }

  /**
   * * Создание новости
   * @returns новость
   */
  async createNewsItem () {
    try {
      this.$wait.start('createNewsItem')
      const data = await this.$news.createNewsItem()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлена новость ${data.name}`
      })
      return data
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createNewsItem')
    }
  }

  /**
   * * Изменение новости
   * @returns новость
   */
  async editNewsItem () {
    try {
      this.$wait.start('createNewsItem')
      const data = await this.$news.editNewsItem()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Изменена новость ${data.name}`
      })
      return data
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createNewsItem')
    }
  }

  /**
   * * Удаление новости
   * @param id - id новости
   * @returns новость
   */
  async removeNewsItem (id: number) {
    try {
      this.$wait.start('removeNewsItem')
      const data = await this.$news.removeNewsItem(id)
      this.$notify({
        type: 'success',
        title: 'Успех',
        message: `Новость ${data.name} удалена`
      })
      return data
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeNewsItem')
    }
  }

  /**
   * * Получить все существующие теги
   */
  async getTags (): Promise<string[]> {
    try {
      this.$wait.start('getTags')
      return await this.$news.getTags()
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getTags')
    }
  }
}
