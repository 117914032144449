export default {
  pageSizes: [10, 20, 40, 60, 80, 100],
  default: 20,
  rowSize (paginator: any) {
    if (paginator.pageSize >= this.pageSizes[2]) {
      if (paginator.totalPages === paginator.currentPage) {
        if (paginator.totalItems - (paginator.currentPage * paginator.pageSize) >= this.pageSizes[2]) {
          return 'mini'
        } else { return 'default' }
      } else { return 'mini' }
    }
    return 'default'
  },
  pageCheck (paginator: any): number {
    if (paginator.totalItems === paginator.pageSize * (paginator.currentPage - 1) + 1 &&
        paginator.currentPage > 1) {
      return paginator.currentPage - 1
    } else {
      return paginator.currentPage
    }
  }
}
