import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'
import defaultPageSize from '~/utils/page-sizes'

@Component
export default class SeoTextsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$seoTexts.resetCurrentSeoText()
    next()
  }

  /**
   * * Запрос на список SEO текстов
   * @param pageParams обьект пагинатора (необязательный)
   * @returns список SEO текстов
   */
  async getSeoTexts (pageParams?: PageParams) {
    try {
      this.$wait.start('getSeoTexts')
      return await this.$seoTexts.getSeoTexts({
        page: +(pageParams?.page ?? 1),
        pageSize: +(pageParams?.pageSize ?? defaultPageSize.default),
        sort: pageParams?.sort,
        order: pageParams?.order
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getSeoTexts') }
  }

  /**
   * * Запрос на список SEO текст targets
   * @returns SEO текст targets
   */
  async getSeoTextTargets () {
    try {
      this.$wait.start('getSeoTextTargets')
      return await this.$seoTexts.getSeoTextTargets()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getSeoTextTargets') }
  }

  /**
   * * Удаление seo/seoText по id
   * @param id - id SEO текста
   * @returns SEO текст
   */
  async removeSeoText (id: number) {
    try {
      this.$wait.start('removeSeoText')
      const data = await this.$seoTexts.removeSeoText(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `SEO текст ${data?.title || data?.id} удален`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removeSeoText') }
  }

  /**
   * * Создание seo/seoText
   * @returns SEO текст
   */
  async createSeoText () {
    try {
      this.$wait.start('createSeoText')
      const data = await this.$seoTexts.createSeoText()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `SEO текст ${data?.title || data?.id} создан`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('createSeoText') }
  }

  /**
   * * Изменение seo/seoText
   * @returns SEO текст
   */
  async editSeoText () {
    try {
      this.$wait.start('editSeoText')
      const data = await this.$seoTexts.editSeoText()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `SEO текст ${data?.title || data?.id} изменен`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('editSeoText') }
  }
}
