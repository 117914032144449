import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class PaymentsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$payments.resetCurrentPaymentType()
    next()
  }

  /**
   * * Получение списка способов оплаты
   * @returns способы оплаты
   */
  async getPaymentTypes () {
    this.$wait.start('getPaymentTypes')
    try {
      return await this.$payments.getPaymentTypes()
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      console.error(e)
      throw e
    } finally {
      this.$wait.end('getPaymentTypes')
    }
  }

  /**
   * * Изменение способа оплаты
   * @returns способ оплаты
   */
  async editPaymentType () {
    this.$wait.start('editPaymentType')
    try {
      const data = await this.$payments.editPaymentTypeById()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Изменен способ ${data.name}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editPaymentType')
    }
  }
}
