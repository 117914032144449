import { Vue, Component } from 'nuxt-property-decorator'
import { QueryParams } from './interfaces'

@Component
export default class BonusTypeMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$bonusType.resetBonusType()
    next()
  }

  /**
   * * Получение списка типов бонусов
   * @param pageParams - параметры пагинации (необязательные)
   * @param save - если true, то сохраняет в стор и не возвращает,
   *  если false, не сохраняет в стор но возварщает (необязательный default=true)
   * @returns список типов бонусов
   */
  async getBonusTypes (queryParams: QueryParams | null = null, save: boolean = true) {
    try {
      this.$wait.start('getBonusTypes')
      const data = await this.$bonusType.getBonusTypes(queryParams, save)
      if (!save) {
        return data
      }
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getBonusTypes')
    }
  }

  /**
   * * Создание типа бонусов
   * @returns тип бонусов
   */
  async createBonusType () {
    try {
      this.$wait.start('createBonusType')
      const data = await this.$bonusType.createBonusType()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлен тип бонусов ${data.name}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createBonusType')
    }
  }

  /**
   * * Получение типа бонусов по id
   * @param id - id типа бонусов
   * @returns тип бонусов
   */
  async getBonusTypeById (id: number) {
    try {
      this.$wait.start('getBonusTypeById')
      return await this.$bonusType.getBonusTypeById(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getBonusTypeById')
    }
  }

  /**
   * * Изменение типа бонусов
   * @returns тип бонусов
   */
  async editBonusType () {
    try {
      this.$wait.start('editBonusType')
      const data = await this.$bonusType.editBonusType()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Тип бонусов ${data.name} изменён`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editBonusType')
    }
  }

  /**
   * * Удаление типа бонусов
   * @param id - id типа бонусов
   * @returns тип бонусов
   */
  async removeBonusType (id: number) {
    try {
      this.$wait.start('removeBonusType')
      const data = await this.$bonusType.removeBonusType(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Тип бонусов ${data.name} удалён`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeBonusType')
    }
  }
}
