import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, PageParams, defaultData, ResponseData, ValidatorParams } from '../../interfaces'
import { Sticker } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'stickers',
  stateFactory: true,
  namespaced: true
})
export default class StickersModule extends VuexModule {
  /**
   * * Массив стикеров
   */
  stickerListValue: ResponseData<Sticker> = defaultData

  /**
   * * Текущий стикер
   */
  currentStickerValue: Sticker = {
    name: undefined,
    code: '',
    isSystem: false,
    color: '',
    backgroundColor: '',
    active: false,
    activeFrom: null,
    activeTo: null,
    isFilter: false,
    dates: [],
    afterImageId: null,
    beforeImageId: null,
    backgroundImageId: null,
    sort: 0
  }

  // ? ______________ getters ______________

  get validators (): ValidatorParams {
    return {
      name: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите название стикера', trigger: 'blur' }]
    }
  }

  /**
   * * Получить массив стикеров и пагинатор
   */
  get stickerList (): ResponseData<Sticker> {
    return this.stickerListValue
  }

  /**
   * * Получить текущий стикер
   */
  get currentSticker (): Sticker {
    return this.currentStickerValue
  }

  /**
   * * Получить стикер по id
   */
  get getStickerById () {
    const stickers = this.stickerList
    return function (id: number): Sticker | undefined {
      return stickers.data.find(stickers => stickers.id === id)
    }
  }

  // ? ______________ setters ______________

  /**
   * * Установить массив стикеров
   * @param stickerList массив стикеров и пагинатор
   */
  @Mutation
  setStickerList (stickerList: ResponseData<Sticker>) {
    this.stickerListValue = stickerList
  }

  /**
   * * Установить текущий стикер
   * @param sticker текущий стикер
   */
  @Mutation
  setCurrentSticker (sticker: Sticker) {
    this.currentStickerValue = sticker
  }

  /**
   * * Обнулить форму
   */

  @Mutation
  resetCurrentSticker () {
    this.currentStickerValue = {
      name: undefined,
      code: '',
      isSystem: false,
      color: '',
      backgroundColor: '',
      active: false,
      activeFrom: null,
      activeTo: null,
      isFilter: false,
      dates: [],
      afterImageId: null,
      beforeImageId: null,
      backgroundImageId: null,
      sort: 0
    }
  }

  @Mutation
  resetStickers () {
    this.stickerListValue = defaultData
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список стикеров и пагинатор
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setStickerList'
  })
  async getStickers (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/shop/stickers', { params: pageParams })
      const response: ResponseData<Sticker> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать стикер
   */
  @Action({
    rawError: true
  })
  async createSticker (): Promise<Sticker> {
    try {
      const { data: { data } } = await $axios.post('/shop/stickers', this.currentSticker)
      const response: Sticker = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Редактировать стикер
   */
  @Action({
    rawError: true
  })
  async editSticker (): Promise<Sticker> {
    const { id, ...sticker } = this.currentSticker
    try {
      const { data } = await $axios.put(`/shop/stickers/${id}`, sticker)
      const response: Sticker = data.data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить стикер по id
   * @param id id стикерa, который мы удаляем
   */
  @Action({
    rawError: true
  })
  async removeSticker (id: number): Promise<Sticker> {
    try {
      const { data } = await $axios.delete(`/shop/stickers/${id}`)
      const response: Sticker = data.data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить стикер по id
   * @param id id стикерa, который мы хотим получить
   */
  @Action({
    rawError: true,
    commit: 'setCurrentSticker'
  })
  async getSticker (id: number): Promise<Sticker> {
    try {
      const { data } = await $axios.get(`/shop/stickers/${id}`)
      const response: Sticker = data.data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
