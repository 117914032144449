import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class CustomFiltersMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$customFilters.resetCurrentCustomFilter()
    next()
  }

  /**
   * * Запрос на customFilters
   * @param pageParams параметры пагинатора (необязательный)
   * @returns список customFilters
   */
  async getCustomFilters (pageParams?: PageParams) {
    try {
      this.$wait.start('getCustomFilters')
      return await this.$customFilters.getCustomFilters({
        page: +(pageParams?.page ?? 1),
        pageSize: pageParams?.pageSize,
        sort: pageParams?.sort,
        order: pageParams?.order
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getCustomFilters') }
  }

  /**
   * * Удаление customFilter по id
   * @param id - id customFilter
   * @returns customFilter
   */
  async removeCustomFilter (id: number) {
    try {
      this.$wait.start('removeCustomFilter')
      const data = await this.$customFilters.removeCustomFilter(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Фильтр ${data.name} удален`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removeCustomFilter') }
  }

  /**
   * * Создание customFilter
   * @returns customFilter
   */
  async createCustomFilter () {
    try {
      this.$wait.start('createCustomFilter')
      const data = await this.$customFilters.createCustomFilter()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Фильтр ${data.name} создан`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('createCustomFilter') }
  }

  /**
   * * Изменение customFilter
   * @returns customFilter
   */
  async editCustomFilter () {
    try {
      this.$wait.start('editCustomFilter')
      const data = await this.$customFilters.editCustomFilter()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Фильтр ${data.name} изменен`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('editCustomFilter') }
  }
}
