import Vue from 'vue'
import YmapPlugin from 'vue-yandex-maps'

/**
 * Настройки яндекс карт
 */
const settings = {
  apiKey: 'f40d1718-e045-478d-833c-76a6a23accd2',
  lang: 'ru_RU'
}

Vue.use(YmapPlugin, settings)
