import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, PageParams, defaultData, ResponseData, ValidatorParams } from '../../interfaces'
import { Role } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'roles',
  stateFactory: true,
  namespaced: true
})
export default class RolesModule extends VuexModule {
  /**
   * * Роли
   */
  rolesValue: ResponseData<Role> = defaultData

  /**
   * * Роль
   */
  roleValue: Role = {
    name: '',
    description: '',
    permissions: []
  }

  // ? ______________ getters ______________

  /**
   * * Получить роли
   */
  get roles (): ResponseData<Role> {
    return this.rolesValue
  }

  /**
   * * Получить роль
   */
  get role (): Role {
    return this.roleValue
  }

  /**
   * * Получить роль по id
   */
  get roleByName () {
    const roles = this.roles
    return function (name: string): Role | undefined {
      return roles.data.find(role => role.name === name)
    }
  }

  /**
   * * валидна ли форма
   */
  get validators (): ValidatorParams {
    return {
      name: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите код роли', trigger: ['blur'] }],
      description: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите название роли', trigger: ['blur'] }]
    }
  }

  // ? ______________ setters ______________

  /**
   * * Установить роли
   * @param roles роли
   */
  @Mutation
  setRoles (roles: ResponseData<Role>) {
    this.rolesValue = roles
  }

  /**
   * * Установить роль
   * @param role роль
   */
  @Mutation
  setRole (role: Role) {
    this.roleValue = role
  }

  /**
   * * Сбросить роль
   */
  @Mutation
  resetRole () {
    this.roleValue = {
      name: '',
      description: '',
      permissions: []
    }
  }

  /**
   * * Сбросить роль
   */
  @Mutation
  resetRoles () {
    this.rolesValue = defaultData
  }

  // ? ______________ actions ______________

  /**
   * * Запрос на получени ролей
   * @param pageParams параметры пагинации
   */
  @Action({
    rawError: true,
    commit: 'setRoles'
  })
  async getRoles (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/roles', { params: pageParams })
      const response: ResponseData<Role> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение роли по имени
   * @param name имя роли
   */
  @Action({
    rawError: true,
    commit: 'setRole'
  })
  async getRoleByName (name: string) {
    try {
      const { data: { data } } = await $axios.get(`/roles/${name}`)
      const response: Role = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание роли
   */
  @Action({
    rawError: true
  })
  async createRole () {
    try {
      const { data: { data } } = await $axios.post('/roles', this.role)
      const response: Role = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение роли
   */
  @Action({
    rawError: true
  })
  async editRole () {
    try {
      const { name, ...newRole } = this.role
      const { data: { data } } = await $axios.put(`/roles/${name}`, newRole)
      const response: Role = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на удаление роли
   * @param name имя роли
   */
  @Action({
    rawError: true
  })
  async removeRole (name: string) {
    try {
      const { data: { data } } = await $axios.delete(`/roles/${name}`)
      const response: Role = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
