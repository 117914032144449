import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'
import defaultPageSize from '~/utils/page-sizes'

@Component
export default class WordReplacesMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    next()
  }

  /**
   * * Запрос на wordReplace
   * @param pageParams параметры пагинатора (необязательный)
   * @returns список wordReplace
   */
  async getWordReplaces (pageParams?: PageParams) {
    try {
      this.$wait.start('getWordReplaces')
      return await this.$wordReplaces.getWordReplaces({
        page: +(pageParams?.page ?? 1),
        pageSize: +(pageParams?.pageSize ?? defaultPageSize.default),
        sort: pageParams?.sort,
        order: pageParams?.order
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getWordReplaces') }
  }

  /**
   * * Удаление seo/wordReplace по id
   * @param id - id wordReplace
   * @returns wordReplace
   */
  async removeWordReplace (id: number) {
    try {
      this.$wait.start('removeWordReplace')
      const data = await this.$wordReplaces.removeWordReplace(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Склонение слова ${data.word} удалено`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removeWordReplace') }
  }

  /**
   * * Создание seo/wordReplace
   * @returns wordReplace
   */
  async createWordReplace () {
    try {
      this.$wait.start('createWordReplace')
      const data = await this.$wordReplaces.createWordReplace()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Склонение слова ${data.word} создано`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('createWordReplace') }
  }

  /**
   * * Изменение seo/wordReplace
   * @returns wordReplace
   */
  async editWordReplace () {
    try {
      this.$wait.start('editWordReplace')
      const data = await this.$wordReplaces.editWordReplace()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Склонение слова ${data.word} изменено`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('editWordReplace') }
  }
}
