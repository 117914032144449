import { Ids, Filters, Discount, DiscountGroup, DiscountGroupsPlugin, DiscountFilters } from './interfaces'
import DiscountGroupsModule from './store'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (store: DiscountGroupsModule) {
  const api: DiscountGroupsPlugin = {
    // ? ________ getters ________
    get filters (): Filters {
      return store.filters
    },

    get discountGroups (): ResponseData<DiscountGroup> {
      return store.discountGroups
    },

    get discountGroup (): DiscountGroup {
      return store.discountGroup
    },

    discountGroupById (id: number): DiscountGroup | undefined {
      return store.discountGroupById(id)
    },

    get discountFilters (): DiscountFilters {
      return store.discountFilters
    },

    get validatorsDiscountGroupData (): ValidatorParams {
      return store.validatorsDiscountGroupData
    },
    get validatorsDiscountData (): ValidatorParams {
      return store.validatorsDiscountData
    },

    get discounts (): ResponseData<Discount> {
      return store.discounts
    },

    get discount (): Discount {
      return store.discount
    },

    discountById (id: number): Discount | undefined {
      return store.discountById(id)
    },

    // ? ________ setters ________

    set filters (filters: Filters) {
      store.setFilters(filters)
    },

    set discountGroups (discountGroups: ResponseData<DiscountGroup>) {
      store.setDiscountGroups(discountGroups)
    },

    set discountGroup (discountGroup: DiscountGroup) {
      store.setDiscountGroup(discountGroup)
    },

    set discountFilters (filters: DiscountFilters) {
      store.setDiscountFilters(filters)
    },

    set discounts (discounts: ResponseData<Discount>) {
      store.setDiscounts(discounts)
    },

    set discount (discount: Discount) {
      store.setDiscount(discount)
    },

    resetDiscountGroup () {
      store.resetDiscountGroup()
    },

    resetDiscountGroups () {
      store.resetDiscountGroups()
    },

    resetDiscountFilters () {
      store.resetDiscountFilters()
    },

    resetDiscounts () {
      store.resetDiscounts()
    },

    resetDiscount () {
      store.resetDiscount()
    },

    // ? ________ actions ________

    getDiscountGroups (pageParams: PageParams | null = null): Promise<ResponseData<DiscountGroup>> {
      return store.getDiscountGroups(pageParams)
    },

    getDiscountGroupById (id: number) {
      return store.getDiscountGroupById(id)
    },

    createDiscountGroup () {
      return store.createDiscountGroup()
    },

    editDiscountGroup () {
      return store.editDiscountGroup()
    },

    removeDiscountGroup (id: number) {
      return store.removeDiscountGroup(id)
    },

    getDiscounts (pageParams: PageParams | null = null): Promise<ResponseData<Discount>> {
      return store.getDiscounts(pageParams)
    },

    getDiscountById (ids: Ids) {
      return store.getDiscountById(ids)
    },

    createDiscount () {
      return store.createDiscount()
    },

    editDiscount () {
      return store.editDiscount()
    },

    removeDiscount (id: number) {
      return store.removeDiscount(id)
    }
  }

  return api
}
