import { Vue, Component } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class CategoriesMixin extends Vue {
  /**
   * * Data
   */
  treeNodeMap = new Map()
  treeNodeCascader = new Map()
  categoriesSort = {} as Record<number, number>
  ids: any = []

  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$categories.resetCurrentCategory()
    next()
  }

  /**
   * * Запрос на получение списка категорий
   * @param pageParams - параметры запроса(parentId - id родительской категории, active - активная ли категория,
   * showAll - показать все категории, sort - поле сортировки, order - 'asc' | 'desc' порядок сортировки )
   * @param save - если true, то сохраняет в стор, если false, не сохраняет в стор (необязательный default=true)
   * @returns список категорий
   */
  async getCategories (pageParams : { parentId?: number | null, active?: 0 | 1, showAll?: 0 | 1, sort?: string, order?: 'asc' | 'desc' }, save?: boolean) {
    try {
      this.$wait.start('getCategories')
      return await this.$categories.getCategories({ parentId: pageParams.parentId || null, showAll: pageParams?.showAll, active: pageParams.active, sort: pageParams?.sort || 'sort', order: pageParams?.order || 'desc' }, save)
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getCategories') }
  }

  /**
   * * Запрос на получение списка всех категорий
   * @param pageParams - параметры запрос: page - номер страницы, pageSize: - размер страницы
   * @returns список категорий
   */
  async getAllCategories (pageParams?: PageParams) {
    try {
      this.$wait.start('getAllCategories')
      return await this.$categories.getAllCategories(pageParams)
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally { this.$wait.end('getAllCategories') }
  }

  /**
   * * Запрос на получение category по id
   * @param id - id категории
   * @param save - если true, то сохраняет в стор и не возвращает значение, если false, не сохраняет в стор и возвращает значение(необязательный default=true)
   * @returns категория
   */
  async getCategoryById (id: number, save?: boolean) {
    this.$wait.start('getCategoryById')
    try {
      const data = await this.$categories.getCategory(id, save)
      if (!save) {
        return data
      }
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally { this.$wait.end('getCategoryById') }
  }

  /**
   * * Запрос на обновление сортировки категории
   * @param id - id категории
   */
  async updateCategorySort (id: number) {
    this.$wait.start('updateCategorySort')
    try {
      await this.$categories.updateCategorySort(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Сортировка категории № ${id} обновлена`
      })
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('updateCategorySort') }
  }

  /**
   * * Запрос на удаление category
   * @param id - id категории
   * @returns категория
   */
  async removeCategory (id: number) {
    try {
      this.$wait.start('removeCategory')
      const data = await this.$categories.removeCategory(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Удалена категория ${data.title}`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removeCategory') }
  }

  /**
   * * Запрос на изменение category
   * @returns категория
   */
  async editCategory () {
    try {
      this.$wait.start('changeCategory')
      const data = await this.$categories.editCategory()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Изменена категория ${data.title}`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('changeCategory') }
  }

  /**
   * * Обновляет дерево категорий при изменение category
   * @param data - категория листья которой мы хотим обновить
   */
  async refreshLeafsCategories (data: any) {
    try {
      const node = this.treeNodeMap.get(data.parentId)
      if (node === undefined) {
        await this.getCategories({ parentId: null, sort: 'sort', order: 'desc' }, true)
        this.$categories.categoriesList.data.map((category) => { // заполнение сортировки
          this.categoriesSort = {
            ...this.categoriesSort,
            [String(category.id)]: category.sort
          }
        })
      } else {
        const { treeNode, resolve } = node
        this.$wait.start('getCategories')
        await this.loadCategories({ id: data.parentId }, treeNode, resolve)
        this.$wait.end('getCategories')
      }
    } catch (e) {}
  }

  /**
   * * Запрос на создание category
   * @returns категория
   */
  async createCategory () {
    try {
      this.$wait.start('changeCategory')
      const data = await this.$categories.createCategory()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлена новая категория ${data.title}`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('changeCategory') }
  }

  /**
   * * Лоудер для категорий в каскадере
   * @param value - id категории, листья которой мы открываем
   * @param resolve - функция записи значения
   */
  async loadCategoriesCascader ({ value }: {value: number}, resolve: (arg0: any) => void) {
    try {
      const categories = await this.$categories.getCategories({ parentId: value, sort: 'sort', order: 'desc' }, false)
      this.ids = [...this.ids, ...categories.data.map((item) => { return item.id })]
      this.treeNodeCascader.set(value, { data: categories.data, resolve })
      resolve(categories.data)
    } catch (e:any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    }
  }

  /**
   * * Лоудер для категорий в таблице
   * @param category - id категории, листья которой мы открываем
   * @param resolve - функция записи значения
   */
  async loadCategories (category: any, treeNode: any, resolve: (arg0: any) => void) {
    try {
      const categories = await this.$categories.getCategories({ sort: category.sort || 'sort', order: category.order || 'desc', parentId: category.id }, false)
      categories.data.map((category) => { // заполнение сортировки
        this.categoriesSort = {
          ...this.categoriesSort,
          [String(category.id)]: category.sort
        }
      })
      this.treeNodeMap.set(category.id, { treeNode, resolve })
      resolve(categories.data)
    } catch (e:any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    }
  }
}
