import { FixedProductsPlugin, FixedProduct } from './interfaces'
import { PageParams, ResponseData } from '~/store/interfaces'

export default function getPluginApi (fixedProductsStore:any) {
  const api: FixedProductsPlugin = {
    // ? ________ getters ________

    // * Получить список fixedProducts и пагинатор
    get fixedProductsList (): ResponseData<FixedProduct> {
      return fixedProductsStore.fixedProductsList
    },

    // * Получить fixedProduct по id из массва fixedProducts
    fixedProductById (id: number): FixedProduct | undefined {
      return fixedProductsStore.fixedProductById(id)
    },

    // * Получить текущий fixedProduct
    get currentFixedProduct (): FixedProduct {
      return fixedProductsStore.currentFixedProduct
    },

    // ? ________ setters ________

    // * Установить список fixedProducts и пагинатор
    set fixedProductsList (fixedProducts: ResponseData<FixedProduct>) {
      fixedProductsStore.setFixedProductsList(fixedProducts)
    },

    // * Очистить форму
    resetCurrentFixedProduct () {
      fixedProductsStore.resetCurrentFixedProduct()
    },

    resetFixedProducts () {
      fixedProductsStore.resetFixedProducts()
    },

    // * Установить текщий fixedProduct
    set currentFixedProduct (fixedProduct: FixedProduct) {
      fixedProductsStore.setCurrentFixedProduct(fixedProduct)
    },

    // ? ________ actions ________

    // * Запрос на получение списка fixedProducts и пагинатор
    getFixedProducts (pageParams: PageParams | null = null): Promise<ResponseData<FixedProduct>> {
      return fixedProductsStore.getFixedProducts(pageParams)
    },

    // * Запрос на получение fixedProduct по id
    getFixedProduct (id: number): Promise<FixedProduct> {
      return fixedProductsStore.getFixedProduct(id)
    },

    // * Запрос на удаление fixedProduct по id
    removeFixedProduct (id: number): Promise<FixedProduct> {
      return fixedProductsStore.removeFixedProduct(id)
    },

    // * Запрос на изменение fixedProduct
    editFixedProduct (): Promise<FixedProduct> {
      return fixedProductsStore.editFixedProduct()
    },

    // * Запрос на создание fixedProduct
    createFixedProduct (): Promise<FixedProduct> {
      return fixedProductsStore.createFixedProduct()
    }
  }
  return api
}
