import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class PartnersMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$partners.resetCurrentPartner()
    next()
  }

  /**
   * * Запрос на изменение бренда
   * @returns бренд
   */
  async editPartner () {
    try {
      this.$wait.end('changePartner')
      const data = await this.$partners.editPartner()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Изменен бренд ${data.title}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('changePartner') }
  }

  /**
   * * Запрос на получение списка бреднов
   * @param pageParams - параметры пагинатора (необязательный)
   * @returns cписок бреднов
   */
  async getPartners (pageParams: PageParams | null = null) {
    try {
      this.$wait.start('getPartners')
      return await this.$partners.getPartners(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getPartners') }
  }

  /**
   * * Запрос на создание бренда
   * @returns бренд
   */
  async createPartner () {
    try {
      this.$wait.start('changePartner')
      const data = await this.$partners.createPartner()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлен новый бренд ${data.title}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('changePartner') }
  }

  /**
   * * Запрос на удаление бренда
   * @param id - id бренда
   * @returns бренд
   */
  async removePartner (id: number) {
    try {
      this.$wait.start('removePartner')
      const data = await this.$partners.removePartner(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Бренд ${data.title} удален`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removePartner') }
  }
}
