export default function (_to, _from, savedPosition) {
  // * Вызов скрола страницы до сохранённой позиции при её наличии
  if (savedPosition?.y) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: savedPosition.y || 0, behavior: 'smooth' })
      }, 500)
    })
  }
}
