import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, PageParams, defaultData, ValidatorParams, ResponseData } from '../../interfaces'
import { EmailTemplate, Filters } from './interfaces'
import { $axios } from '~/utils/api'
import defaultTemplate from '~/utils/defaultTemplate'

@Module({
  name: 'emailTemplates',
  stateFactory: true,
  namespaced: true
})
export default class EmailTemplatesModule extends VuexModule {
  filtersValue: Filters = {}
  /**
   * * Массив шаблонов
   */
  templates: ResponseData<EmailTemplate> = defaultData

  /**
   * * Текущий шаблон
   */
  template: EmailTemplate = {
    name: '',
    subject: '',
    template: defaultTemplate,
    groupId: 1
  }

  // ? ______________ getters ______________
  get filters () {
    return this.filtersValue
  }

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      name: [{ required: true, message: 'Введите название шаблона', trigger: ['blur'] }],
      template: [{ required: true, message: 'Введите текст шаблона', trigger: ['blur'] }],
      groupId: [{ required: true, message: 'Выберите группу шаблонов' }]
    }
  }

  /**
   * * Получить массив шаблонов
   */
  get emailTemplates (): ResponseData<EmailTemplate> {
    return this.templates
  }

  /**
   * * Получить текущий шаблон
   */
  get currentTemplate (): EmailTemplate {
    return this.template
  }

  /**
   * * Валидна ли форма
   */
  get validateData (): boolean {
    return !!(this.template.name && this.template.template)
  }

  /**
   * * Получить шаблон по id
   */
  get templateById () {
    const templates = this.emailTemplates
    return function (id: number): EmailTemplate | undefined {
      return templates.data.find(template => template.id === id)
    }
  }

  // ? ______________ setters ______________

  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  /**
   * * Установить массив шаблонов
   * @param templates массив шаблонов
   */
  @Mutation
  setEmailTemplates (templates: ResponseData<EmailTemplate>) {
    this.templates = templates
  }

  /**
   * * Установить текущий шаблон
   * @param template текущий шаблон
   */
  @Mutation
  setCurrentTemplate (template: EmailTemplate) {
    this.template = template
  }

  /**
   * * Обнулить форму
   */
  @Mutation
  resetCurrentTemplate () {
    this.template = {
      name: '',
      subject: '',
      template: defaultTemplate,
      groupId: 1
    }
  }

  /**
   * * Обнулить cписок шаблонов
   */
  @Mutation
  resetEmailTemplates () {
    this.templates = defaultData
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список шаблонов писем
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true
  })
  async getEmailTemplates (pageParams: PageParams | null = null, save: boolean = true) {
    try {
      const params = save ? { ...pageParams, ...this.filters } : { ...pageParams }
      const { data } = await $axios.get('/email-templates', { params })
      const response: ResponseData<EmailTemplate> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить шаблон писем по id
   * @param id шаблона
   */
  @Action({
    rawError: true,
    commit: 'setCurrentTemplate'
  })
  async getEmailTemplateById (id: number) {
    try {
      const { data } = await $axios.get(`/email-templates/${id}`)
      const response: EmailTemplate = data.data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать шаблон
   */
  @Action({
    rawError: true
  })
  async createEmailTemplate () {
    try {
      const { data } = await $axios.post('/email-templates', this.currentTemplate)
      const response: EmailTemplate = data.data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Редактировать шаблон
   */
  @Action({
    rawError: true
  })
  async editEmailTemplate () {
    const { id, ...newEmailTemplate } = this.currentTemplate
    try {
      const { data } = await $axios.put(`/email-templates/${id}`, newEmailTemplate)
      const response: EmailTemplate = data.data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить шаблон
   */
  @Action({
    rawError: true
  })
  async removeEmailTemplate (id: number) {
    try {
      const { data } = await $axios.delete(`/email-templates/${id}`)
      const response: EmailTemplate = data.data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Отправить письмо
   */
  @Action({
    rawError: true
  })
  async sendEmail (id: number) {
    try {
      await $axios.post(`/email-templates/${id}/send`)
      return true
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
