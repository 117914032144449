import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { EmailTemplateGroup } from './interfaces'
import { FormError, PageParams, ResponseData, defaultData } from '~/store/interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'emailTemplateGroups',
  stateFactory: true,
  namespaced: true
})
export default class EmailTemplateGroupsModule extends VuexModule {
  emailTemplateGroupsValue: ResponseData<EmailTemplateGroup> = defaultData

  emailTemplateGroupValue: EmailTemplateGroup = {
    name: ''
  }

  get emailTemplateGroups (): ResponseData<EmailTemplateGroup> {
    return this.emailTemplateGroupsValue
  }

  get currentEmailTemplateGroup (): EmailTemplateGroup {
    return this.emailTemplateGroupValue
  }

  get groupById () {
    return function (id: number): EmailTemplateGroup | undefined {
      return [...this.emailTemplateGroupsValue.data].find((group: { id: number}) => group.id === id)
    }
  }

  @Mutation
  setEmailTemplateGroups (groups: ResponseData<EmailTemplateGroup>) {
    this.emailTemplateGroupsValue = groups
  }

  @Mutation
  setCurrentEmailTemplateGroup (group: EmailTemplateGroup) {
    this.emailTemplateGroupValue = group
  }

  @Mutation
  resetCurrentGroup (): void {
    this.emailTemplateGroupValue = {
      name: ''
    }
  }

  @Mutation
  resetEmailTemplateGroups (): void {
    this.emailTemplateGroupsValue = defaultData
  }

  @Action({
    rawError: true,
    commit: 'setEmailTemplateGroups'
  })
  async getEmailTemplateGroups (pageParams?: PageParams | null) {
    try {
      const { data } = await $axios.get('/email-template-groups', { params: pageParams })
      return data as ResponseData<EmailTemplateGroup>
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true,
    commit: 'setCurrentEmailTemplateGroup'
  })
  async getEmailTemplateGroupById (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/email-template-groups/${id}`)
      return data as EmailTemplateGroup
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async createEmailTemplateGroup () {
    try {
      const { data: { data } } = await $axios.post('/email-template-groups', this.currentEmailTemplateGroup)
      return data as EmailTemplateGroup
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async editEmailTemplateGroup () {
    try {
      const { id, ...group } = this.currentEmailTemplateGroup
      const { data: { data } } = await $axios.put(`/email-template-groups/${id}`, group)
      return data as EmailTemplateGroup
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async removeEmailTemplateGroup (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/email-template-groups/${id}`)
      return data as EmailTemplateGroup
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  // editEmailTemplateGroup: () => Promise<EmailTemplateGroup>
}
