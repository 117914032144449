import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, PageParams, defaultData, ResponseData, ValidatorParams } from '../../interfaces'
import { Ids, Filters, Discount, DiscountGroup, DiscountFilters } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'discountGroups',
  stateFactory: true,
  namespaced: true
})
export default class DiscountGroupsModule extends VuexModule {
  /**
   * * Фильтры
   */
  filtersValue: Filters = {
    name: undefined,
    active: undefined
  }

  // ? ----- Группы скидок -----

  /**
   * * Список групп скидок
   */
  discountGroupsValue: ResponseData<DiscountGroup> = defaultData

  /**
   * * Группа скидок
   */
  discountGroupValue: DiscountGroup = {
    title: '',
    code: '',
    active: true,
    isSticker: false,
    dateFrom: null,
    dateTo: null,
    priority: 0,
    productGroups: [],
    discounts: [],
    dates: []
  }

  // ? ----- Скидки -----

  /**
   * * Фильтры скидок
   */
  discountFiltersValue: DiscountFilters = {
    active: undefined
  }

  /**
   * * Список скидок
   */
  discountsValue: ResponseData<Discount> = defaultData

  /**
   * * Скидка
   */
  discountValue: Discount = {
    sequence: '',
    discountValue: 0,
    sort: 0,
    active: true,
    role: '',
    siteId: 1,
    currencyId: 1
  }

  // ? ______________ getters ______________

  /**
   * * Получить фильтры
   */
  get filters (): Filters {
    return this.filtersValue
  }

  // ? ----- Группы скидок -----

  /**
   * * Получить список групп скидок
   */
  get discountGroups (): ResponseData<DiscountGroup> {
    return this.discountGroupsValue
  }

  /**
   * * Получить группу скидок
   */
  get discountGroup (): DiscountGroup {
    return this.discountGroupValue
  }

  /**
   * * Получить группу скидок по id
   */
  get discountGroupById () {
    const discountGroups = this.discountGroups
    return function (id: number): DiscountGroup | undefined {
      return discountGroups.data.find(discountGroup => discountGroup.id === id)
    }
  }

  /**
   * * Валидатор для формы DiscountGroupData
   */
  get validatorsDiscountGroupData (): ValidatorParams {
    return {
      title: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите название группы скидок',
        trigger: ['blur']
      }],
      code: [{
        required: true,
        pattern: validatorsPattern.emptyStringEmpty,
        message: 'Введите код',
        trigger: ['blur']
      }],
      // dateTo: [{
      //   required: true,
      //   type: 'number',
      //   message: 'Введите продолжительность скидок',
      //   trigger: ['blur', 'change']
      // }],
      productGroups: [{
        required: true,
        type: 'array',
        min: 1,
        message: 'Выберите группы товаров',
        trigger: ['blur']
      }
      ],
      priority: [{ pattern: validatorsPattern.naturalNumbers, message: 'Введите натуральное число', trigger: ['blur', 'change'] }]
    }
  }
  // ? ----- Скидки -----

  /**
   * * Получить фильтры скидок
   */
  get discountFilters (): DiscountFilters {
    return this.discountFiltersValue
  }

  /**
   * * Получить список скидок
   */
  get discounts (): ResponseData<Discount> {
    return this.discountsValue
  }

  /**
   * * Получить скидку
   */
  get discount (): Discount {
    return this.discountValue
  }

  /**
   * * Получить скидку по id
   */
  get discountById () {
    const discounts = this.discounts
    return function (id: number): Discount | undefined {
      return discounts.data.find(discount => discount.id === id)
    }
  }

  /**
   * * Валидатор для формы DiscountGroupData
   */
  get validatorsDiscountData (): ValidatorParams {
    return {
      role: [{
        required: true,
        message: 'Выберите роль',
        trigger: ['blur']
      }],
      siteId: [{
        required: true,
        message: 'Выберите сайт',
        trigger: ['blur']
      }],
      currencyId: [{
        required: true,
        message: 'Выберите валюту',
        trigger: ['blur']
      }],
      sequence: [{
        required: true,
        message: 'Выберите тип скидки',
        trigger: ['blur']
      }],
      discountValue: [{
        pattern: validatorsPattern.notWholeNumbers,
        message: 'Введите корректное значение скидки',
        trigger: ['change', 'blur']
      }],
      sort: [{ pattern: validatorsPattern.wholeNumbers, message: 'Введите целое число', trigger: ['blur', 'change'] }]
    }
  }

  // ? ______________ setters ______________

  /**
   * * Установить фильтры
   * * @param filters фильтры
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  // ? ----- Группы скидок -----

  /**
   * * Установить список групп скидок
   * @param discountGroups список групп скидок
   */
  @Mutation
  setDiscountGroups (discountGroups: ResponseData<DiscountGroup>) {
    this.discountGroupsValue = discountGroups
  }

  /**
   * * Установить группу скидок
   * @param discountGroup группа скидок
   */
  @Mutation
  setDiscountGroup (discountGroup: DiscountGroup) {
    this.discountGroupValue = discountGroup
  }

  /**
   * * Сбросить группу скидок
   */
  @Mutation
  resetDiscountGroup () {
    this.discountGroupValue = {
      title: '',
      code: '',
      active: true,
      isSticker: false,
      dateFrom: null,
      dateTo: null,
      priority: 0,
      productGroups: [],
      discounts: [],
      dates: []
    }
  }

  @Mutation
  resetDiscountGroups () {
    this.discountGroupsValue = defaultData
  }

  // ? ----- Скидки-----

  /**
   * * Установить фильтры скидок
   * @param filters - значение фильтров
   */
  @Mutation
  setDiscountFilters (filters: DiscountFilters) {
    this.discountFiltersValue = filters
  }

  /**
   * * Сбросить фильтры скидок
   */
  @Mutation
  resetDiscountFilters () {
    this.discountFiltersValue = {
      active: undefined
    }
  }

  /**
   * * Установить список скидок
   * @param discounts скидки
   */
  @Mutation
  setDiscounts (discounts: ResponseData<Discount>) {
    this.discountsValue = discounts
  }

  /**
   * * Установить скидку
   * @param discount скидка
   */
  @Mutation
  setDiscount (discount: Discount) {
    this.discountValue = discount
  }

  /**
   * * Сбросить список скидок
   */

  @Mutation
  resetDiscounts () {
    this.discountsValue = defaultData
  }

  /**
   * * Сбросить скидку
   */
  @Mutation
  resetDiscount () {
    this.discountValue = {
      sequence: '',
      discountValue: 0,
      sort: 0,
      active: true,
      role: '',
      siteId: 1,
      currencyId: 1
    }
  }

  // ? ______________ actions ______________

  // ? ----- Группы скидок -----

  /**
   * * Получить список групп скидок
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setDiscountGroups'
  })
  async getDiscountGroups (pageParams: PageParams | null = null) {
    try {
      const params = { ...pageParams, ...this.filters }
      const { data } = await $axios.get('/discounts/discount-groups', { params })
      const response: ResponseData<DiscountGroup> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить группу скидок по id
   * @param id id группы скидок
   */
  @Action({
    rawError: true,
    commit: 'setDiscountGroup'
  })
  async getDiscountGroupById (id: number) {
    try {
      const { data: { data } } = await $axios.get(`discounts/discount-groups/${id}`)
      const response: DiscountGroup = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать группу скидок
   */
  @Action({
    rawError: true
  })
  async createDiscountGroup () {
    try {
      const { data: { data } } = await $axios.post('/discounts/discount-groups', this.discountGroup)
      const response: DiscountGroup = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить группу скидок
   */
  @Action({
    rawError: true
  })
  async editDiscountGroup () {
    try {
      const { id, ...newDiscountGroup } = this.discountGroup
      const { data: { data } } = await $axios.put(`/discounts/discount-groups/${id}`, newDiscountGroup)
      const response: DiscountGroup = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить группу скидок
   * @param id id группы скидок
   */
  @Action({
    rawError: true
  })
  async removeDiscountGroup (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/discounts/discount-groups/${id}`)
      const response: DiscountGroup = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  // ? ----- Скидки -----

  /**
   * * Получить список скидок
   */
  @Action({
    rawError: true,
    commit: 'setDiscounts'
  })
  async getDiscounts (pageParams: PageParams | null = null) {
    try {
      const discountGroupId = this.discountGroup.id
      const { data } = await $axios.get(`/discounts/discount-groups/${discountGroupId}/discounts`, { params: { ...pageParams, ...this.discountFilters } })
      const response: ResponseData<Discount> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить скидку по id
   * @param ids - id группы скидок и скидки
   */
  @Action({
    rawError: true,
    commit: 'setDiscount'
  })
  async getDiscountById (ids: Ids) {
    try {
      const { data: { data } } = await $axios.get(`/discounts/discount-groups/${ids.discountGroupId}/discounts/${ids.discountId}`)
      const response: Discount = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать скидку
   */
  @Action({
    rawError: true
  })
  async createDiscount () {
    try {
      const newDiscount = {
        sequence: this.discount.sequence,
        discountValue: this.discountValue.discountValue,
        sort: this.discountValue.sort,
        active: this.discountValue.active,
        role: this.discountValue.role,
        siteId: this.discountValue.siteId,
        currencyId: this.discountValue.currencyId
      }
      const discountGroupId = this.discountGroup.id
      const { data: { data } } = await $axios.post(`/discounts/discount-groups/${discountGroupId}/discounts`, newDiscount)
      const response: Discount = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить скидку
   */
  @Action({
    rawError: true
  })
  async editDiscount () {
    try {
      const { id, ...newDiscount } = this.discount
      const discountGroupId = this.discountGroup.id
      const { data: { data } } = await $axios.put(`/discounts/discount-groups/${discountGroupId}/discounts/${id}`, newDiscount)
      const response: Discount = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить скидку
   */
  @Action({
    rawError: true
  })
  async removeDiscount (id: number) {
    try {
      const discountGroupId = this.discountGroup.id
      const { data: { data } } = await $axios.delete(`/discounts/discount-groups/${discountGroupId}/discounts/${id}`)
      const response: Discount = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
