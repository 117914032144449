import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, PageParams, ResponseData } from '../../interfaces'
import { FixedProduct } from './interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'fixedProducts',
  stateFactory: true,
  namespaced: true
})

export default class FixedProductsModule extends VuexModule {
  /**
   * * Массив всех fixedProduct исходя из запроса
   */
  fixedProducts: ResponseData<FixedProduct> = defaultData

  /**
   * * Текущая fixedProduct
   */
  fixedProduct: FixedProduct = {
    productData: {}
  }

  // ? ______________ getters ______________

  /**
   * * Получить массив fixedProducts и пагинацией
   */
  get fixedProductsList (): ResponseData<FixedProduct> {
    return this.fixedProducts
  }

  /**
   * * Получить fixedProduct из массива fixedProducts
   */
  get fixedProductById () {
    const fixedProducts = this.fixedProducts
    return function (id: number): FixedProduct | undefined {
      return fixedProducts.data.find(fixedProduct => fixedProduct.id === id)
    }
  }

  /**
   * * Получить текущую fixedProduct для измения или создания fixedProduct
   */
  get currentFixedProduct () {
    return this.fixedProduct
  }

  // ? ______________ setters ______________

  /**
   * * Установить массив FixedProducts
   * @param fixedProducts массив FixedProducts
   */
  @Mutation
  setFixedProductsList (fixedProducts: ResponseData<FixedProduct>) {
    this.fixedProducts = fixedProducts
  }

  /**
   * * Установить CurrentFixedProduct для измения или создания fixedProduct
   * @param fixedProduct текущая FixedProduct, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentFixedProduct (fixedProduct: FixedProduct) {
    this.fixedProduct = fixedProduct
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentFixedProduct () {
    this.fixedProduct = {
      productData: {}
    }
  }

  @Mutation
  resetFixedProducts () {
    this.fixedProducts = defaultData
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список FixedProducts
   */
  @Action({
    rawError: true,
    commit: 'setFixedProductsList'
  })
  async getFixedProducts (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/shop/product-fixed-data', { params: pageParams })
      const response: ResponseData<FixedProduct> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить fixedProduct по id
   * @param id id fixedProduct, который мы хотим получить
   */
  @Action({
    rawError: true, commit: 'setCurrentFixedProduct'
  })
  async getFixedProduct (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/shop/product-fixed-data/${id}`)
      const response: FixedProduct = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить fixedProduct
   */
  @Action({
    rawError: true
  })
  async editFixedProduct () {
    try {
      const { id, ...fixedProduct } = this.currentFixedProduct
      const { data: { data } } = await $axios.put(`/shop/product-fixed-data/${id}`, fixedProduct)
      const response: FixedProduct = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать FixedProduct
   */
  @Action({
    rawError: true
  })
  async createFixedProduct () {
    try {
      const { data: { data } } = await $axios.post('/shop/product-fixed-data', this.currentFixedProduct)
      const response: FixedProduct = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить fixedProduct по id
   * @param id  id fixedProduct, который мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removeFixedProduct (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/shop/product-fixed-data/${id}`)
      const response: FixedProduct = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
