import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, PageParams, defaultData, ResponseData, ValidatorParams } from '../../interfaces'
import { Robot } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'
@Module({
  name: 'robots',
  stateFactory: true,
  namespaced: true
})
export default class RobotsModule extends VuexModule {
  /**
   * * Массив robots
   */
  robotListValue: ResponseData<Robot> = defaultData

  /**
   * * Текущий robot
   */
  currentRobotValue: Robot = {
    content: ''
  }

  // ? ______________ getters ______________

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      content: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите содержание файла', trigger: ['blur'] }]
    }
  }

  /**
   * * Получить массив robot и пагинатор
   */
  get robotList (): ResponseData<Robot> {
    return this.robotListValue
  }

  /**
   * * Получить текущий robot
   */
  get currentRobot (): Robot {
    return this.currentRobotValue
  }

  /**
   * * Получить robot по id из robotList
   */
  get getRobotById () {
    const robots = this.robotList
    return function (id: number): Robot | undefined {
      return robots.data.find(robots => robots.id === id)
    }
  }

  // ? ______________ setters ______________

  /**
   * * Установить массив robots
   * @param robotList массив robots и пагинатор
   */
  @Mutation
  setRobotList (robotList: ResponseData<Robot>) {
    this.robotListValue = robotList
  }

  /**
   * * Установить текущий robot
   * @param robot текущий robot
   */
  @Mutation
  setCurrentRobot (robot: Robot) {
    this.currentRobotValue = robot
  }

  /**
   * * Обнулить форму
   */
  @Mutation
  resetCurrentRobot () {
    this.currentRobotValue = {
      content: ''
    }
  }

  @Mutation
  resetRobots () {
    this.robotListValue = defaultData
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список robots и пагинатор
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setRobotList'
  })
  async getRobots (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/robots', { params: pageParams })
      data.data = data.data.sort((a: { id: number }, b: { id: number }) => b.id - a.id)
      const response: ResponseData<Robot> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать robot
   */
  @Action({
    rawError: true
  })
  async createRobot () {
    try {
      const { data: { data } } = await $axios.post('/robots', this.currentRobot)
      const response: Robot = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Редактировать robot
   */
  @Action({
    rawError: true
  })
  async editRobot () {
    const { id, ...Robot } = this.currentRobot
    try {
      const { data } = await $axios.put(`/robots/${id}`, Robot)
      const response: Robot = data.data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить robot по id
   * @param id id robot, который мы удаляем
   */
  @Action({
    rawError: true
  })
  async deleteRobot (id: number) {
    try {
      const { data } = await $axios.delete(`/robots/${id}`)
      const response: Robot = data.data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить robot по id
   * @param id id robot, который мы хотим получить
   */
  @Action({
    rawError: true, commit: 'setCurrentRobot'
  })
  async getRobot (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/robots/${id}`)
      const response: Robot = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
