import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class SitesMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$sites.resetSite()
    next()
  }

  /**
   * * Запрос на получение списка сайтов
   * @param pageParams - параметры запрос: page - номер страницы, pageSize: - размер страницы (необязательный)
   * @returns список сайтов
   */
  async getSites (pageParams?: PageParams) {
    try {
      this.$wait.start('getSites')
      return await this.$sites.getSites({
        page: pageParams?.page || 1,
        pageSize: pageParams?.pageSize,
        sort: pageParams?.sort,
        order: pageParams?.order
      })
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getSites')
    }
  }

  /**
   * * Запрос на создание сайта
   * @returns сайт
   */
  async createSite () {
    try {
      this.$wait.start('createSite')
      const data = await this.$sites.createSite()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Сайт ${data.name} добавлен`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createSite')
    }
  }

  /**
   * * Запрос на изменение сайта
   * @returns сайт
   */
  async editSite () {
    try {
      this.$wait.start('editSite')
      const data = await this.$sites.editSite()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Сайт ${data.name} изменён`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editSite')
    }
  }

  /**
   * * Запрос на удаление сайта
   * @param siteId - id сайта
   * @returns сайт
   */
  async removeSite (siteId: number) {
    try {
      this.$wait.start('removeSite')
      const data = await this.$sites.removeSite(siteId)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Сайт ${data.name} удалён`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeSite')
    }
  }
}
