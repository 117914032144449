import { Module, Mutation, Action, VuexModule } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { Filters, OrderBill, Bill, Params, BillRequestData } from './interfaces'
import { defaultData, FormError, ResponseData, ValidatorParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'

// Типы счетов на оплату заказа
const PAYMENTS_TYPES_NAMES: any = {
  1: 'Оплата банковской картой',
  2: 'Оплата через расчетный счет',
  3: 'Другие варианты оплаты'
}
@Module({
  name: 'orderBills',
  stateFactory: true,
  namespaced: true
})
export default class OrderBillsModule extends VuexModule {
  /**
   * * Фильтры
   */
  filtersValue: Filters = {
    sort: undefined,
    order: undefined
  }

  /**
   * * Счёт
   */
  orderBillValue: OrderBill = {
    typeId: 1,
    currency: '',
    user: {
      fullName: '',
      email: '',
      phone: ''
    },
    data: [],
    // * Дефолтные значения params
    params: {
      url: '',
      price: 0
    }
  }

  /**
   * * Список счетов и пагинатор
   */
  orderBillsValue: ResponseData<Bill> = defaultData

  // ? ------ getters ------ //

  /**
   * * Получить фильтры
   */
  get filters (): Filters {
    return this.filtersValue
  }

  /**
   * * Получить счёт
   */
  get orderBill (): OrderBill {
    return this.orderBillValue
  }

  /**
   * * Получить список счетов
   */
  get orderBills (): ResponseData<Bill> {
    return this.orderBillsValue
  }

  get validators (): ValidatorParams {
    return {
      data: [{
        required: true, message: 'Выберите товары', trigger: ['blur, change'], type: 'array', min: 1
      }],
      title: [{
        pattern: /^([^\s]{1,}[\s]*)*$/, required: true, message: 'Введите назначение платежа', trigger: ['blur, change']
      }],
      price: [{
        required: true, message: 'Введите сумму платежа', trigger: ['blur, change'], type: 'number', min: 1
      }],
      url: [{
        required: true, message: 'Введите корректную ссылку на оплату', trigger: ['blur, change']
      }]
    }
  }

  // ? ------ setters ------ //

  /**
   * * Установить фильтры
   * @param filters - значения фильтров
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  /**
   * * Сбросить фильтры
   */
  @Mutation
  resetFilters (): void {
    this.filtersValue = {
      sort: undefined,
      order: undefined
    }
  }

  /**
   * * Установить счёт
   * @param orderBill - значения счёта
   */
  @Mutation
  setOrderBill (orderBill: OrderBill) {
    this.orderBillValue = orderBill
  }

  /**
   * * Сбросить счёт
   */
  @Mutation
  resetOrderBill (): void {
    this.orderBillValue = {
      typeId: 1,
      currency: '',
      user: {
        fullName: '',
        email: '',
        phone: ''
      },
      data: []
    }
  }

  /**
   * * Установить список счетов
   * @param orderBills - список счетов и пагинатор
   */
  @Mutation
  setOrderBills (orderBills: ResponseData<Bill>) {
    this.orderBillsValue = orderBills
  }

  /**
   * * Сбросить список счетов
   */
  @Mutation
  resetOrderBills (): void {
    this.orderBillsValue = defaultData
  }

  // ? ------ actions ------ //

  /**
   * * Запрос на список счетов
   * @param params - параметры запроса
   * @returns - список счетов и пагинатор
   */
  @Action({
    rawError: true,
    commit: 'setOrderBills'
  })
  async getOrderBills (params: Params): Promise<ResponseData<Bill>> {
    try {
      const { orderId, ...reqParams } = params
      const { data } = await $axios.get(`/shop/orders/${orderId}/bills`, { params: { ...reqParams, ...this.filters } })
      const response: ResponseData<Bill> = data

      response.data = response.data.map((item: any) => {
        return {
          ...item,
          typeName: PAYMENTS_TYPES_NAMES[item.typeId]
        }
      })

      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на список счетов
   * @param params - параметры запроса
   * @returns - список счетов и пагинатор
   */
  @Action({
    rawError: true
  })
  async changeOrderBillStatus (params : {id: number, orderId: number, status: number, siteApiUrl: string}): Promise<OrderBill> {
    try {
      const { data: { data } } = await $axios.put(`${params.siteApiUrl}/orders/v1/${params.orderId}/bills/${params.id}`, { status: params.status },
        {
          headers: {
            common: {
              Authorization: ''
            }
          }
        })
      const response: OrderBill = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание счёта
   * @param orderId - id заказа
   * @param billRequestData
   * @param siteApiUrl
   * @returns - счёт
   */
  @Action({
    rawError: true
  })
  async createOrderBill ({ orderId, billRequestData, siteApiUrl }: { orderId: number, billRequestData: BillRequestData | undefined, siteApiUrl: string }): Promise<OrderBill> {
    try {
      let res
      if (billRequestData) {
        res = await $axios.post(`${siteApiUrl}/orders/v1/${orderId}/bills`, billRequestData)
      } else {
        res = await $axios.post(`/shop/orders/${orderId}/bills`, this.orderBill)
      }

      return res.data as OrderBill
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на добавление документа в счёт
   * @param orderId - id заказа
   */

  @Action({
    rawError: true
  })
  async addOrderBillDoc (orderId: number): Promise<OrderBill> {
    try {
      const { id } = this.orderBill
      const { data: { data } } = await $axios.post(`/shop/orders/${orderId}/bills/${id}/doc`)
      const response: OrderBill = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
