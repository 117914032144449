import { Params, OrderStatusGroup, OrderStatusGroupsPlugin, Filters } from './interfaces'
import OrderStatusGroupsModule from './store'
import { ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (store: OrderStatusGroupsModule) {
  const api: OrderStatusGroupsPlugin = {
    // ? ________ getters ________

    get filters (): Filters {
      return store.filters
    },

    get validators (): ValidatorParams {
      return store.validators
    },

    get orderStatusGroups (): ResponseData<OrderStatusGroup> {
      return store.orderStatusGroups
    },

    get orderStatusGroup (): OrderStatusGroup {
      return store.orderStatusGroup
    },

    orderStatusGroupById (id: number): OrderStatusGroup | undefined {
      return store.orderStatusGroupById(id)
    },

    // ? ________ setters ________

    set filters (filters: Filters) {
      store.setFilters(filters)
    },

    resetFilters () {
      store.resetFilters()
    },

    set orderStatusGroups (orderStatusGroups: ResponseData<OrderStatusGroup>) {
      store.setOrderStatusGroups(orderStatusGroups)
    },

    set orderStatusGroup (orderStatusGroup: OrderStatusGroup) {
      store.setOrderStatusGroup(orderStatusGroup)
    },

    resetOrderStatusGroup () {
      store.resetOrderStatusGroup()
    },

    resetOrderStatusGroups () {
      store.resetOrderStatusGroups()
    },

    // ? ________ actions ________

    getOrderStatusGroups (params?: Params | null): Promise<ResponseData<OrderStatusGroup>> {
      return store.getOrderStatusGroups(params)
    },

    getOrderStatusGroupById (id: number): Promise<OrderStatusGroup> {
      return store.getOrderStatusGroupById(id)
    },

    createOrderStatusGroup (): Promise<OrderStatusGroup> {
      return store.createOrderStatusGroup()
    },

    editOrderStatusGroup (): Promise<OrderStatusGroup> {
      return store.editOrderStatusGroup()
    },

    removeOrderStatusGroup (id: number): Promise<OrderStatusGroup> {
      return store.removeOrderStatusGroup(id)
    }
  }
  return api
}
