import { SearchLogsPlugin, SearchLog, Filters } from './interfaces'
import SearchLogsModule from './store'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (searchLogsStore: SearchLogsModule) {
  const api: SearchLogsPlugin = {
  // ? ________ getters ________

    get searchLogs (): ResponseData<SearchLog> {
      return searchLogsStore.searchLogs
    },

    get searchLog (): SearchLog {
      return searchLogsStore.searchLog
    },

    get filters (): Filters {
      return searchLogsStore.filters
    },

    searchLogByQuery (query: string): SearchLog | undefined {
      return searchLogsStore.searchLogByQuery(query)
    },

    get validators (): ValidatorParams {
      return searchLogsStore.validators
    },

    // ? ________ getters ________

    set searchLogs (searchLogs: ResponseData<SearchLog>) {
      searchLogsStore.setSearchLogs(searchLogs)
    },

    set filters (filters: Filters) {
      searchLogsStore.setFilters(filters)
    },

    set searchLog (searchLog: SearchLog) {
      searchLogsStore.setSearchLog(searchLog)
    },

    resetFilters () {
      searchLogsStore.resetFilters()
    },

    resetSearchLog () {
      searchLogsStore.resetSearchLog()
    },

    resetSearchLogs () {
      searchLogsStore.resetSearchLogs()
    },
    // ? ________ actions ________

    getSearchLogs (params: PageParams | null = null) {
      return searchLogsStore.getSearchLogs(params)
    },

    getSearchLogByQuery (query: string) {
      return searchLogsStore.getSearchLogByQuery(query)
    },

    createSearchLog () {
      return searchLogsStore.createSearchLog()
    },

    editSearchLog () {
      return searchLogsStore.editSearchLog()
    },

    removeSearchLog (query: string) {
      return searchLogsStore.removeSearchLog(query)
    }
  }

  return api
}
