import { Filters, OrderStatus, OrderStatusesPlugin } from './interfaces'
import OrderStatusesModule from './store'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (store: OrderStatusesModule) {
  const api: OrderStatusesPlugin = {
    // ? ________ getters ________

    get filters (): Filters {
      return store.filters
    },

    get validators (): ValidatorParams {
      return store.validators
    },

    get orderStatuses (): ResponseData<OrderStatus> {
      return store.orderStatuses
    },

    get currentOrderStatus (): OrderStatus {
      return store.currentOrderStatus
    },

    orderStatusesByGroupId (id: number): OrderStatus[] {
      return store.orderStatusesByGroupId(id)
    },

    orderStatusById (id: number): OrderStatus | undefined {
      return store.orderStatusById(id)
    },

    // ? ________ setters ________

    set filters (filters: Filters) {
      store.setFilters(filters)
    },

    resetFilters () {
      store.resetFilters()
    },

    resetCurrentOrderStatus () {
      store.resetCurrentOrderStatus()
    },

    resetOrderStatuses () {
      store.resetOrderStatuses()
    },

    set orderStatuses (orderStatuses: ResponseData<OrderStatus>) {
      store.setOrderStatuses(orderStatuses)
    },

    set currentOrderStatus (orderStatus: OrderStatus) {
      store.setCurrentOrderStatus(orderStatus)
    },

    // ? ________ actions ________
    getOrderStatuses (pageParams?: PageParams | null): Promise<ResponseData<OrderStatus>> {
      return store.getOrderStatuses(pageParams)
    },

    createOrderStatus (): Promise<OrderStatus> {
      return store.createOrderStatus()
    },

    editOrderStatus (): Promise<OrderStatus> {
      return store.editOrderStatus()
    },

    getOrderStatus (id: number): Promise<OrderStatus> {
      return store.getOrderStatus(id)
    },

    deleteOrderStatus (id: number): Promise<OrderStatus> {
      return store.deleteOrderStatus(id)
    }
  }
  return api
}
