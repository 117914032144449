import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { Params, Filters, Review, ReviewParam, ReviewType } from './interfaces'
import { $axios } from '~/utils/api'
import { FormError, PageParams, defaultData, ResponseData, ValidatorParams } from '~/store/interfaces'
import { dateOneDayLater } from '~/utils/date'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'reviews',
  stateFactory: true,
  namespaced: true
})
export default class ReviewsModule extends VuexModule {
  // * Фильтры запроса списка обзоров
  filtersValue: Filters = {
    typeId: undefined,
    siteId: undefined,
    userId: undefined,
    productId: undefined,
    active: undefined,
    fio: undefined,
    email: undefined,
    response: undefined,
    dateFrom: undefined,
    dateTo: undefined
  }

  // * Отзывы
  reviewsValue: ResponseData<Review> = defaultData

  // * Отзыв
  reviewValue: Review = {
    fio: '',
    email: '',
    typeId: null,
    userId: null,
    rate: 0,
    text: '',
    response: '',
    sort: 0,
    active: true,
    params: [],
    createdAt: null,
    updatedAt: null,
    siteId: 1,
    images: [],
    responseAuthor: null,
    product: {
      id: 0,
      title: ''
    }
  }

  // * Параметры отзывов
  reviewParamsValue: ResponseData<ReviewParam> = defaultData

  // * Типы отзывов
  reviewTypesValue: ResponseData<ReviewType> = defaultData

  // ? ------ getters ------ //

  // * Получить фильтры
  get filters (): Filters {
    return this.filtersValue
  }

  // * Получить отзывы
  get reviews (): ResponseData<Review> {
    return this.reviewsValue
  }

  // * Получить отзыв
  get review (): Review {
    return this.reviewValue
  }

  // * Получить отзыв по id
  get reviewById () {
    const reviews = this.reviewsValue.data
    return function (id: number): Review | undefined {
      return reviews.find(review => review.id === id)
    }
  }

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      fio: [{ required: true, message: 'Введите ФИО', trigger: 'blur' },
        {
          pattern: validatorsPattern.fioInput,
          message: 'Введите корректное ФИО',
          trigger: ['blur']
        }],
      email: [{ required: true, message: 'Введите email', trigger: 'blur' },
        { type: 'email', message: 'Введите корректный email', trigger: ['blur'] }
      ],
      typeId: [{ required: true, message: 'Выберите тип отзыва', trigger: 'blur' },
        {
          pattern: validatorsPattern.naturalNumbers,
          message: 'Введите натуральное число',
          trigger: ['blur']
        }],
      userId: [{ required: true, message: 'Введите id пользователя', trigger: 'blur' },
        {
          pattern: validatorsPattern.naturalNumbers,
          message: 'Введите натуральное число',
          trigger: ['blur']
        }],
      siteId: [{ required: true, message: 'Выберите сайт', trigger: 'blur' },
        {
          pattern: validatorsPattern.naturalNumbers,
          message: 'Введите натуральное число',
          trigger: ['blur']
        }],
      rate: [{ required: true, message: 'Введите оценку', trigger: ['blur'] }],
      sort: [{ required: true, message: 'Введите сортировку', trigger: ['blur'] }],
      text: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите содержание отзыва', trigger: ['blur'] }]
      // productId: [{ required: true, message: 'Введите id товара', trigger: 'blur' },
      //   { pattern: validatorsPattern.naturalNumbers, message: 'Введите натуральное число', trigger: ['blur'] }]
    }
  }

  // * Получить параметры отзывов
  get reviewParams (): ResponseData<ReviewParam> {
    return this.reviewParamsValue
  }

  // * Получить параметр отзывов по id
  get reviewParamById () {
    const params = this.reviewParamsValue.data
    return function (id: number): ReviewParam | undefined {
      return params.find(param => param.id === id)
    }
  }

  // * Получить типы отзывов
  get reviewTypes (): ResponseData<ReviewType> {
    return this.reviewTypesValue
  }

  // * Получить тип отзыва по id
  get reviewTypeById () {
    const types = this.reviewTypesValue.data
    return function (id: number): ReviewType | undefined {
      return types.find(type => type.id === id)
    }
  }

  // ? ------ setters ------ //

  // * Установить фильтры
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  // * Сбросить фильтры
  @Mutation
  resetFilters (): void {
    this.filtersValue = {
      typeId: undefined,
      siteId: undefined,
      userId: undefined,
      productId: undefined,
      active: undefined,
      fio: undefined,
      email: undefined,
      response: undefined,
      dateFrom: undefined,
      dateTo: undefined
    }
  }

  // * Установить отзывы
  @Mutation
  setReviews (reviews: ResponseData<Review>) {
    this.reviewsValue = reviews
  }

  // * Установить отзыв
  @Mutation
  setReview (review: Review) {
    this.reviewValue = review
  }

  // * Сбросить отзыв
  @Mutation
  resetReview () {
    this.reviewValue = {
      fio: '',
      email: '',
      typeId: null,
      userId: null,
      rate: 0,
      text: '',
      response: '',
      sort: 0,
      active: true,
      params: [],
      createdAt: null,
      updatedAt: null,
      siteId: 1,
      images: [],
      responseAuthor: null,
      product: {
        id: 0,
        title: ''
      }
    }
  }

  @Mutation
  resetReviews () {
    this.reviewsValue = defaultData
  }

  @Mutation
  resetReviewParams () {
    this.reviewParamsValue = defaultData
  }

  @Mutation
  resetReviewTypes () {
    this.reviewTypesValue = defaultData
  }

  // * Установить параметры отзывов
  @Mutation
  setReviewParams (reviewParams: ResponseData<ReviewParam>) {
    this.reviewParamsValue = reviewParams
  }

  // * Установить типы отзывов
  @Mutation
  setReviewTypes (reviewTypes: ResponseData<ReviewType>) {
    this.reviewTypesValue = reviewTypes
  }

  // ? ------ actions ------ //

  // * Запрос на получение списка отзывов
  @Action({
    rawError: true,
    commit: 'setReviews'
  })
  async getReviews (pageParams: PageParams | null = null): Promise<ResponseData<Review>> {
    try {
      const params = {
        ...pageParams,
        ...this.filters
      }
      const { data } = await $axios.get('/review', { params: { ...params, dateFrom: params.dateFrom ? +params.dateFrom : undefined, dateTo: params.dateTo ? dateOneDayLater(+params.dateTo) : undefined } })
      const response: ResponseData<Review> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  // * Запрос на создание отзыва
  @Action({
    rawError: true
  })
  async createReview (): Promise<Review> {
    try {
      const { product, ...newReview } = this.review
      const { data: { data } } = await $axios.post('/review', {
        ...newReview,
        productId: newReview.productId || product?.id
      })
      const response: Review = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  // * Запрос на получение отзыва по id
  @Action({
    rawError: true,
    commit: 'setReview'
  })
  async getReviewById (id: number): Promise<Review> {
    try {
      const { data: { data } } = await $axios.get(`/review/${id}`)
      const response: Review = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  // * Запрос на изменение отзыва
  @Action({
    rawError: true
  })
  async editReview (): Promise<Review> {
    try {
      const { id, product, ...newReview } = this.review
      const { data: { data } } = await $axios.put(`/review/${id}`, {
        ...newReview,
        productId: newReview.productId || product?.id
      })
      const response: Review = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  // * Запрос на удаление отзыва
  @Action({
    rawError: true
  })
  async removeReview (id: number): Promise<Review> {
    try {
      const { data: { data } } = await $axios.delete(`/review/${id}`)
      const response: Review = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  // * Запрос на получние списка параметров отзывов
  @Action({
    rawError: true,
    commit: 'setReviewParams'
  })
  async getReviewParams (params: Params | null = null): Promise<ResponseData<ReviewParam>> {
    try {
      const { data } = await $axios.get('review/param', { params })
      const response: ResponseData<ReviewParam> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  // * Запрос на создание параметра отзывов
  @Action({
    rawError: true
  })
  async createReviewParam (reviewParam: ReviewParam): Promise<ReviewParam> {
    try {
      const { data: { data } } = await $axios.post('/review/param', reviewParam)
      const response: ReviewParam = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  // * Запрос на удаление параметра отзывов
  @Action({
    rawError: true
  })
  async removeReviewParam (id: number): Promise<ReviewParam> {
    try {
      const { data: { data } } = await $axios.delete(`/review/param/${id}`)
      const response: ReviewParam = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  // * Запрос на получение списка типов отзывов
  @Action({
    rawError: true,
    commit: 'setReviewTypes'
  })
  async getReviewTypes (pageParams: PageParams | null = null): Promise<ResponseData<ReviewType>> {
    try {
      const { data } = await $axios.get('/review/type', { params: pageParams })
      const response: ResponseData<ReviewType> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
