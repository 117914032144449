import { CategoriesPlugin, Category, CategoriesParams, CategoryShort } from './interfaces'
import categoriesTree from '~/utils/categories-tree'
import { ResponseData, PageParams, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (categoriesStore:any) {
  const api: CategoriesPlugin = {
    // ? ________ getters ________
    get categoriesList (): ResponseData<Category> {
      return categoriesStore.categoriesList
    },

    get categoriesTree (): any[] {
      const categories = JSON.parse(JSON.stringify(categoriesStore.categoriesList.data))
      return categoriesTree(categories)
    },

    categoryById (id: number): Category | undefined {
      return categoriesStore.categoryById(id)
    },

    get currentCategory (): Category {
      return categoriesStore.currentCategory
    },

    get validators (): ValidatorParams {
      return categoriesStore.validators
    },

    // ? ________ setters ________

    set categoriesList (categories: ResponseData<Category>) {
      categoriesStore.setCategoriesList(categories)
    },

    resetCurrentCategory () {
      categoriesStore.resetCurrentCategory()
    },

    resetCategories () {
      categoriesStore.resetCategories()
    },

    set currentCategory (category: Category) {
      categoriesStore.setCurrentCategory(category)
    },

    // ? ________ actions ________

    async getCategories (pageParams: CategoriesParams | null = null, save: boolean = true): Promise<ResponseData<Category>> {
      if (save) {
        categoriesStore.setCategoriesList(await categoriesStore.getCategories(pageParams))
        return categoriesStore.categoriesList
      }
      return categoriesStore.getCategories(pageParams)
    },

    getAllCategories (pageParams: PageParams | null = null): Promise<ResponseData<CategoryShort>> {
      return categoriesStore.getAllCategories(pageParams)
    },

    async getCategory (id: number, save: boolean = true): Promise<Category> {
      if (save) {
        return categoriesStore.setCurrentCategory(await categoriesStore.getCategory(id))
      }
      return categoriesStore.getCategory(id)
    },

    removeCategory (id: number): Promise<Category> {
      return categoriesStore.removeCategory(id)
    },

    editCategory (): Promise<Category> {
      return categoriesStore.editCategory()
    },

    createCategory (): Promise<Category> {
      return categoriesStore.createCategory()
    },

    updateCategorySort (id:number): void {
      return categoriesStore.updateCategorySort(id)
    }
  }
  return api
}
