import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ResponseData, PageParams, ValidatorParams } from '../../interfaces'
import { WordAdjective } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'wordAdjectives',
  stateFactory: true,
  namespaced: true
})

export default class WordAdjectivesModule extends VuexModule {
  /**
   * * Массив всех wordAdjective исходя из запроса
   */
  wordAdjectives: ResponseData<WordAdjective> = defaultData

  /**
   * * Текущая wordAdjective
   */
  wordAdjective: WordAdjective = {
    word: undefined,
    man: undefined,
    woman: undefined,
    neuter: undefined
  }

  // ? ______________ getters ______________

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      word: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите слово', trigger: ['blur'] }],
      man: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите слово в мужском роде', trigger: ['blur'] }],
      woman: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите слово в женском роде', trigger: ['blur'] }],
      neuter: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите слово среднем роде', trigger: ['blur'] }]
    }
  }

  /**
   * * Получить массив wordAdjectives и пагинацией
   */
  get wordAdjectivesList (): ResponseData<WordAdjective> {
    return this.wordAdjectives
  }

  /**
   * * Получить wordAdjective из массива wordAdjectives
   */
  get wordAdjectiveById () {
    const wordAdjectives = this.wordAdjectives
    return function (id: number): WordAdjective | undefined {
      return wordAdjectives.data.find(wordAdjective => wordAdjective.id === id)
    }
  }

  /**
   * * Получить текущую wordAdjective для измения или создания wordAdjective
   */
  get currentWordAdjective () {
    return this.wordAdjective
  }

  // ? ______________ setters ______________

  /**
   * * Установить массив WordAdjectives
   * @param wordAdjectives массив WordAdjectives
   */
  @Mutation
  setWordAdjectivesList (wordAdjectives: ResponseData<WordAdjective>) {
    this.wordAdjectives = wordAdjectives
  }

  /**
   * * Установить CurrentWordAdjective для измения или создания wordAdjective
   * @param wordAdjective текущая WordAdjective, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentWordAdjective (wordAdjective: WordAdjective) {
    this.wordAdjective = wordAdjective
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentWordAdjective () {
    this.wordAdjective = {
      word: undefined,
      man: undefined,
      woman: undefined,
      neuter: undefined
    }
  }

  @Mutation
  resetWordAdjectives () {
    this.wordAdjectives = defaultData
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список WordAdjectives
   */
  @Action({
    rawError: true,
    commit: 'setWordAdjectivesList'
  })
  async getWordAdjectives (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/seo/word-adjective', { params: pageParams })
      const response: ResponseData<WordAdjective> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить wordAdjective по id
   * @param id id wordAdjective, который мы хотим получить
   */
  @Action({
    rawError: true, commit: 'setCurrentWordAdjective'
  })
  async getWordAdjective (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/seo/word-adjective/${id}`)
      const response: WordAdjective = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить wordAdjective
   */
  @Action({
    rawError: true
  })
  async editWordAdjective () {
    try {
      const { id, ...wordAdjective } = this.currentWordAdjective
      const { data: { data } } = await $axios.put(`/seo/word-adjective/${id}`, wordAdjective)
      const response: WordAdjective = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать WordAdjective
   */
  @Action({
    rawError: true
  })
  async createWordAdjective () {
    try {
      const { data: { data } } = await $axios.post('/seo/word-adjective', this.currentWordAdjective)
      const response: WordAdjective = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить wordAdjective по id
   * @param id  id wordAdjective, который мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removeWordAdjective (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/seo/word-adjective/${id}`)
      const response: WordAdjective = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
