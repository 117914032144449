import { SitesPlugin, Site } from './interfaces'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (sitesStore:any) {
  const api: SitesPlugin = {
  // ? ________ getters ________

    get sites (): ResponseData<Site> {
      return sitesStore.sites
    },

    get site (): Site {
      return sitesStore.site
    },

    siteById (id: number): Site | undefined {
      return sitesStore.siteById(id)
    },

    get validators (): ValidatorParams {
      return sitesStore.validators
    },

    // ? ________ getters ________

    set sites (sites: ResponseData<Site>) {
      sitesStore.setSites(sites)
    },

    set site (site: Site) {
      sitesStore.setSite(site)
    },

    resetSite () {
      sitesStore.resetSite()
    },

    resetSites () {
      sitesStore.resetSites()
    },
    // ? ________ actions ________

    getSites (params: PageParams | null = null) {
      return sitesStore.getSites(params)
    },

    getSiteById (id: number) {
      return sitesStore.getSiteById(id)
    },

    createSite () {
      return sitesStore.createSite()
    },

    editSite () {
      return sitesStore.editSite()
    },

    removeSite (id: number) {
      return sitesStore.removeSite(id)
    }
  }

  return api
}
