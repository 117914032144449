import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ResponseData, ValidatorParams } from '../../interfaces'
import { Delivery, Filters, Params } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'delivery',
  stateFactory: true,
  namespaced: true
})
export default class DeliveryModule extends VuexModule {
  /**
   * * Значение фильтров
   */
  filtersValue: Filters = {
    name: undefined,
    active: undefined
  }

  /**
   * * Список доставок
   */
  deliveriesValue: ResponseData<Delivery> = defaultData

  /**
   * * Доставка
   */
  deliveryValue: Delivery = {
    code: '',
    name: '',
    url: '',
    groupId: 0,
    meta: [],
    imageId: null,
    types: [],
    active: true
  }

  // ? ______________ getters ______________
  /**
   * * Получить фильтры
   */
  get filters (): Filters {
    return this.filtersValue
  }

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      name: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите название группы доставок',
        trigger: ['blur']
      }]
    }
  }

  /**
   * * Получить список доставок
   */
  get deliveries (): ResponseData<Delivery> {
    return this.deliveriesValue
  }

  /**
   * * Получить доставку
   */
  get delivery (): Delivery {
    return this.deliveryValue
  }

  /**
   * * Получить доставку по id
   */
  get deliveryById () {
    const deliveries = this.deliveriesValue.data
    return function (id: number): Delivery | undefined {
      return deliveries.find(deliverie => deliverie.id === id)
    }
  }

  /**
   * * Валидна ли форма
   */
  get validateData (): boolean {
    return !!this.delivery.name
  }

  // ? ______________ setters ______________
  /**
   * * Установить фильтры
   * @param filters - значения фильтров
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  /**
   * * Сбросить фильтры
   */
  @Mutation
  resetFilters () {
    this.filtersValue = {
      name: undefined,
      active: undefined
    }
  }

  /**
   * * Установить список доставок
   * @param deliveries список доставок
   */
  @Mutation
  setDeliveries (deliveries: ResponseData<Delivery>) {
    this.deliveriesValue = deliveries
  }

  /**
   * * Установить доставку
   * @param delivery доставка
   */
  @Mutation
  setDelivery (delivery: Delivery) {
    this.deliveryValue = delivery
  }

  /**
   * * Сбросить список доставок
   */
  @Mutation
  resetDeliveries () {
    this.deliveriesValue = defaultData
  }

  /**
   * * Сбросить доставку
   */
  @Mutation
  resetDelivery () {
    this.deliveryValue = {
      code: '',
      name: '',
      url: '',
      groupId: 0,
      meta: [],
      imageId: null,
      types: [],
      active: true
    }
  }

  // ? ______________ setters ______________

  /**
   * * Запрос на получение списка доставок
   * @param params
   */
  @Action({
    rawError: true,
    commit: 'setDeliveries'
  })
  async getDeliveries (params: Params | null = null) {
    try {
      const { data } = await $axios.get('/delivery', { params: { ...params, ...this.filters } })
      const response: ResponseData<Delivery> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение доставки по id
   * * @param id id доставки
   */
  @Action({
    rawError: true,
    commit: 'setDelivery'
  })
  async getDeliveryById (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/delivery/${id}`)
      const response: Delivery = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание доставки
   */
  @Action({
    rawError: true
  })
  async createDelivery (groupId: number) {
    try {
      const { ...newDelivery } = this.delivery
      const { data: { data } } = await $axios.post('/delivery', {
        ...newDelivery,
        groupId
      })
      const response: Delivery = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение доставки
   */
  @Action({
    rawError: true
  })
  async editDelivery () {
    try {
      const { id, ...newDelivery } = this.delivery
      // const { id, ...newDelivery } = this.delivery
      const { data: { data } } = await $axios.put(`/delivery/${id}`, {
        ...newDelivery
      })
      const response: Delivery = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на удаление доставки
   * @param id id доставки
   */
  @Action({
    rawError: true
  })
  async removeDelivery (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/delivery/${id}`)
      const response: Delivery = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
