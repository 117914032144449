import { Filters, NewsItem, NewsPlugin } from './interfaces'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (newsStore:any) {
  const api: NewsPlugin = {
    // ? ______ getters ______ //

    get filters (): Filters {
      return newsStore.filters
    },

    get news (): ResponseData<NewsItem> {
      return newsStore.news
    },

    get validators (): ValidatorParams {
      return newsStore.validators
    },

    get buttonValidators (): ValidatorParams {
      return newsStore.buttonValidators
    },

    newsItemById (id: number): NewsItem | undefined {
      return newsStore.newsItemById(id)
    },

    get newsItem (): NewsItem {
      return newsStore.newsItem
    },

    get validateNewsItem (): boolean {
      return newsStore.validateNewsItem
    },

    //* Получить теги
    get tags (): string[] {
      return newsStore.tags
    },

    // ? ______ setters ______ //

    set filters (filters: Filters) {
      newsStore.setFilters(filters)
    },

    resetFilters () {
      newsStore.resetFilters()
    },

    set news (news: ResponseData<NewsItem>) {
      newsStore.setNews(news)
    },

    set newsItem (newsItem: NewsItem) {
      newsStore.setNewsItem(newsItem)
    },

    resetNewsItem () {
      newsStore.resetNewsItem()
    },

    resetNews () {
      newsStore.resetNews()
    },

    // ? ______ actions ______ //

    getNews (pageParams: PageParams | null = null): Promise<ResponseData<NewsItem>> {
      return newsStore.getNews(pageParams)
    },

    createNewsItem (): Promise<NewsItem> {
      return newsStore.createNewsItem()
    },

    getNewsItemById (id: number): Promise<NewsItem> {
      return newsStore.getNewsItemById(id)
    },

    editNewsItem (): Promise<NewsItem> {
      return newsStore.editNewsItem()
    },

    removeNewsItem (id: number): Promise<NewsItem> {
      return newsStore.removeNewsItem(id)
    },

    //* Получить все существующие теги
    async getTags () : Promise<string[]> {
      return await newsStore.getTags()
    }
  }

  return api
}
