/**
 * * Modulo для отрицательных чисел
 */
export function modulo (num: number, mod: number) {
  return ((num % mod) + mod) % mod
}

/**
 * * Range
 * @param start - начало отсчёта
 * @param stop - конец отсчёта
 * @param step - шаг
 */
export function range (start: number, stop: number, step = 1): Array<number> {
  return Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)
}
/**
 * * Рандомное число
 */
export function randomInt () {
  return Math.floor(Math.random() * 9) + 1
}
