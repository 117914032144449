import { AxiosError } from 'axios'
import { CurrencySourcePlugin } from './interface'
import { FormError } from '~/store/interfaces'
import { $axios } from '~/utils/api'

export default function getCurrencySourceApi () {
  const api: CurrencySourcePlugin = {
    async getList (params) {
      try {
        return (await $axios.get('/currency-source', { params }))?.data
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }
  }

  return api
}
