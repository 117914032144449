import { Page, PageKeys, PagesPlugin, StaticPagesParams } from './interfaces'
import PagesModule from './store'
import storePluginApi from '~/types/store/store-plugin-api'

export default function getPluginApi (store: PagesModule): PagesPlugin {
  const api = storePluginApi<Page, 'id', PageKeys, 'title'>(store)

  Object.defineProperty(api, 'removeItemPropByKeys', {
    value: (...keys: PageKeys[]) => {
      store.removeItemPropByKeys(keys)
    }
  })

  Object.defineProperty(api, 'getList', {
    value: async (pageParams: StaticPagesParams, save: Boolean, useFilters: boolean) => {
      const data = await store.getList({ ...pageParams, useFilters })

      if (save) {
        store.setList(data)
      }

      return data
    }
  })

  Object.defineProperty(api, 'updateChildren', {
    value: ({ api, id }: { api: 'sites' | 'roles'; id: number }): Promise<void> => {
      return store.updateChildren({ api, id })
    }
  })

  return api as PagesPlugin
}
