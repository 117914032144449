import { GeneratePlugin, GeneratorParams, GeneratorOutput } from './interfaces'

export default function getPluginApi (generateStore:any) {
  const api: GeneratePlugin = {
    // ? ________ actions ________
    // * Запрос на генерацию seo pages для products
    generateProducts (productId?: number): Promise<GeneratorOutput> {
      return generateStore.generateProducts(productId)
    },
    // * Запрос на генерацию seo pages для filters
    generateFilters (inputParams: GeneratorParams | null): Promise<GeneratorOutput> {
      return generateStore.generateFilters(inputParams)
    }
  }
  return api
}
