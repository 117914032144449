import { PartnersPlugin, Partner, Filters } from './interfaces'
import PartnersStore from './store'
import { PageParams, ValidatorParams, ResponseData } from '~/store/interfaces'

export default function getPluginApi (partnersStore: PartnersStore) {
  const api: PartnersPlugin = {
    // ? ________ getters ________
    get filters (): Filters {
      return partnersStore.filters
    },
    // * Получить список partners и пагинатор
    get partnersList (): ResponseData<Partner> {
      return partnersStore.partnersList
    },
    // * Получить partner по id из массва partners
    partnerById (id: number): Partner | undefined {
      return partnersStore.partnerById(id)
    },
    // * Получить текущий partner
    get currentPartner (): Partner {
      return partnersStore.currentPartner
    },

    get validators (): ValidatorParams {
      return partnersStore.validators
    },

    // ? ________ setters ________
    set filters (filter: Filters) {
      partnersStore.setFilters(filter)
    },
    // * Установить список partners и пагинатор
    set partnersList (partners: ResponseData<Partner>) {
      partnersStore.setPartnersList(partners)
    },
    // * Очистить форму
    resetCurrentPartner () {
      partnersStore.resetCurrentPartner()
    },

    resetFilters () {
      partnersStore.resetFilters()
    },

    resetPartners () {
      partnersStore.resetPartners()
    },
    // * Установить текщий partner
    set currentPartner (partner: Partner) {
      partnersStore.setCurrentPartner(partner)
    },
    // ? ________ actions ________
    // * Запрос на получение списка partners и пагинатор
    getPartners (pageParams?: PageParams | null): Promise<ResponseData<Partner>> {
      return partnersStore.getPartners(pageParams)
    },
    // * Запрос на получение partner по id
    getPartner (id: number): Promise<Partner> {
      return partnersStore.getPartner(id)
    },
    // * Запрос на удаление partner по id
    removePartner (id: number): Promise<Partner> {
      return partnersStore.removePartner(id)
    },
    // * Запрос на изменение partner
    editPartner (): Promise<Partner> {
      return partnersStore.editPartner()
    },
    // * Запрос на создание partner
    createPartner (): Promise<Partner> {
      return partnersStore.createPartner()
    }
  }
  return api
}
