import { Component, Vue } from 'nuxt-property-decorator'
import { Ids, ParamsAll } from './interfaces'
import { PageParams } from '~/store/interfaces'

@Component
export default class OptionsValuesMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$optionsValues.resetCurrentOptionValue()
    this.$optionsValues.resetCurrentOption()
    next()
  }

  // ? ______ Справочники ______ //

  /**
   * * Получение списка справочников
   * @param pageParams - параметры пагинации (необязательные)
   * @returns список справачников
   */
  async getOptions (pageParams: PageParams | null = null) {
    try {
      this.$wait.start('getOptions')
      return await this.$optionsValues.getOptions(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getOptions')
    }
  }

  /**
   * * Получение справочника по id
   * @param id - id справочника
   * @returns справочник
   */
  async getOptionById (id: number) {
    try {
      this.$wait.start('getOptionById')
      return await this.$optionsValues.getOptionById(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getOptionById')
    }
  }

  /**
   * * Получение полного списка словарей
   * @param pageParams - параметры пагинации (необязательный)
   * @returns список справачников
   */
  async getOptionsAll (pageParams: ParamsAll | null = null) {
    try {
      this.$wait.start('getOptionsAll')
      return await this.$optionsValues.getOptionsAll(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getOptionsAll')
    }
  }

  /**
   * * Создание справочника
   * @returns справочник
   */
  async createOption () {
    try {
      this.$wait.start('createOption')
      const data = await this.$optionsValues.createOption()
      this.$notify({
        type: 'success',
        title: 'Добавлено',
        message: `Справочник ${data.title} добавлен`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createOption')
    }
  }

  /**
   * * Изменение справочника
   * @returns справочник
   */
  async editOption () {
    try {
      this.$wait.start('editOption')
      const data = await this.$optionsValues.editOption()
      this.$notify({
        type: 'success',
        title: 'Добавлено',
        message: `Справочник ${data.title} изменён`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editOption')
    }
  }

  /**
   * * Удаление справочника
   * @param id - id справочника
   * @returns справочник
   */
  async removeOption (id: number) {
    try {
      this.$wait.start('removeOption')
      const data = await this.$optionsValues.removeOption(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Справочник ${data.title} удален`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeOption')
    }
  }

  /**
   * * Получение справочника по id фильтра
   * @param id - id фильтра
   * @returns справочник
   */
  async getOptionByValueId (id: number) {
    try {
      this.$wait.start('getOptionByValueId')
      return await this.$optionsValues.getOptionByValueId(id)
    } catch (e: any) {
    } finally {
      this.$wait.end('getOptionByValueId')
    }
  }

  // ? ______ Фильтры ______ //

  /**
   * * Получение списка фильтров справочника
   * @param pageParams - параметры пагинации и categoryId - id категории (необязательные)
   * @return список фильтров справочника
   */
  async getOptionValues (pageParams?: PageParams & {categoryId?: number|null}) {
    try {
      this.$wait.start('getOptionValues')
      return await this.$optionsValues.getOptionValues({
        page: pageParams?.page ?? 1,
        pageSize: pageParams?.pageSize ?? undefined,
        sort: pageParams?.sort || 'sort',
        order: pageParams?.order || 'asc',
        categoryId: pageParams?.categoryId ?? undefined
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getOptionValues')
    }
  }

  /**
   * * Получение фильтра справочника по id`s
   * @param ids - id`s справочника и фильтра
   * @return фильтр справочника
   */
  async getOptionValueById (ids: Ids) {
    try {
      this.$wait.start('getOptionValueById')
      return await this.$optionsValues.getOptionValueById(ids)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getOptionValueById')
    }
  }

  /**
   * * Создание фильтра справочника
   * @return фильтр справочника
   */
  async createOptionValue () {
    try {
      this.$wait.start('createOptionValue')
      const data = await this.$optionsValues.createOptionValue()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Значение ${data.title} успешно добавлено`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createOptionValue')
    }
  }

  /**
   * * Изменение фильтра справочника
   * @return фильтр справочника
   */
  async editOptionValue () {
    try {
      this.$wait.start('createOptionValue')
      const data = await this.$optionsValues.editOptionValue()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Значение ${data.title} успешно изменено`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createOptionValue')
    }
  }

  /**
   * * Удаление фильтра справочника
   * @param id - id фильтра
   * @return фильтр справочника
   */
  async removeOptionValue (id: number) {
    try {
      this.$wait.start('removeOptionValue')
      const data = await this.$optionsValues.removeOptionValue(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Справочник ${data.title} удален`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeOptionValue')
    }
  }

  /**
   * * Запрос на обновление сортировки справочника
   * @param id - id справочника
   */
  async updateOptionSort (id: number) {
    this.$wait.start('updateOptionSort')
    try {
      await this.$optionsValues.updateOptionSort(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Сортировка опции № ${id} обновлена`
      })
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('updateOptionSort') }
  }
}
