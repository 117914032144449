import { AxiosError } from 'axios'
import pageSizes from '~/utils/page-sizes'
/**
 * * Интерфейс ошибок форм
 */
export interface FormErrorInterface extends Error {
  /**
   * * HTTP статус
   */
  error_code?: number
  /**
   * * Ошибки формы
   */
  error_message?: string
  /**
   * * Время ошибки
   */
  error_time?: number
  /**
   * * Адрес запроса
   */
  error_req_url?: string
}

const errors: Record<number, string> = {
  401: 'Пользователь не авторизован',
  403: 'Нет прав для совершения действия',
  413: 'Файл превышает максимальный размер'
}

/**
 * * Ошибка формы
 */
export class FormError extends Error implements FormErrorInterface {
  /**
   * * Статус код
   */
  public error_code: number | undefined
  /**
   * * Ошибки полей формы
   */
  public error_message: string
  /**
   * * Время ошибки
   */
  public error_time: number
  /**
   * * Адрес запроса
   */
  public error_req_url: string

  /**
   * @param error обьект ошибки
   */
  constructor (error: AxiosError<FormError>) { // constructor (status: number | undefined = 500, error: string | undefined = 'Произошла ошибка сервера') {
    super(error.response?.data?.error_message || error.response?.data?.message)
    this.error_time = Date.now()
    this.error_req_url = error.config?.baseURL + '/' + error.config?.url
    if (error.message === 'Network Error') {
      this.error_code = 500
      this.error_message = 'Ошибка соединения с интернетом'
      return
    }
    if (!error.response) {
      this.error_code = 500
      this.error_message = 'Произошла ошибка сервера'
      return
    }
    if (typeof error.response?.data === 'string') {
      this.error_code = error.response.status
      this.error_message = `${error.response.config.method?.toUpperCase()} ${error.response.config.url} ${error.response?.data || errors[error.response.status] || 'Произошла ошибка сервера'}`
      return
    }
    const { error_code, error_message, message } = error.response.data
    this.error_code = error_code
    this.error_message = `${error.response.config.method?.toUpperCase()} ${error.response.config.url} ${!error_message && !message ? 'Ошибка сервера' : typeof error_message === 'string' || typeof message === 'string' ? (error_message || message) : JSON.stringify(error_message || message)}`
  }
}

/**
 * * Обьект правил
 */
export interface ValidatorParams {
  [key: string]: Rules[] | object
}

/**
 * * Параметры Filters для сортировки
 */
export interface Filters {
  address?: string
}
/**
 * * Параметры картинок
 */
export interface Image {
  id: number
  origin: string
  thumb: string
}
/**
 * * Параметры страницы
 */
export interface PageParams {
  page?: number
  pageSize?: number
  sort?: string
  order?: string
}

/**
 * * Пагинатор
 */
export interface Paginator {
  pageSize: number
  currentPage: number
  totalItems: number
  totalPages: number
}
/**
 * * Объект с пагинатором и массивом данных
 */
export interface ResponseData<T> {
  data: T[]
  paginator: Paginator
}
/**
 * * Обьект правил для форм
 */
export interface Rules {
  required?: boolean
  message?: string
  trigger?: string[] | string
  pattern?: RegExp
  min?: number
  max?: number
  len?: number
  type?: string
  enum?: string[]
}

/**
 * * Дефолтное значение пагинатора
 */
export const defaultPaginator = {
  pageSize: pageSizes.default,
  currentPage: 0,
  totalItems: 0,
  totalPages: 0
}

export const defaultData = {
  data: [],
  paginator: defaultPaginator
}

export const defaultDataWithMeta = {
  data: [],
  meta: defaultPaginator
}
