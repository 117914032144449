
import Vue from 'vue'
import { Component, Watch } from 'nuxt-property-decorator'
import { menuItems } from '~/utils/routes'

@Component
export default class SideBar extends Vue {
  /**
   * *________________ Data ______________________
   */
  isCollapse: boolean | null = null
  menuItem = menuItems
  title = this.$config.SITE_NAME

  /**
   * *________________ Watch ______________________
   */
  @Watch('$route.path')
  onChangePage () {
    // @ts-ignore
    this.$refs.menu.openedMenus = []
    if (this.isCollapse) { return }
    menuItems.map(({ item }) => {
      const regexp = new RegExp(`^/${item.link}`)
      if (regexp.test(this.$route.path)) {
        // @ts-ignore
        this.$refs.menu.openedMenus = [item.link]
      }
    })
  }

  /**
   * *________________ Created ______________________
   */
  created () {
    this.isCollapse = this.$cookies.get('menuCollapsed')
  }

  /**
   * *________________ Mounted ______________________
   */
  mounted () {
    // @ts-ignore
    this.$refs.menu.openedMenus = []
    if (this.isCollapse) { return }
    menuItems.map(({ item }) => {
      const regexp = new RegExp(`^/${item.link}`)
      if (regexp.test(this.$route.path)) {
        // @ts-ignore
        this.$refs.menu.openedMenus = [item.link]
      }
    })
  }

  /**
   * *________________ Methods ______________________
   */
  availableModules (items: any) {
    // ! TODO костыль, потом убрать
    const protectedSeoTabs = ['raffles', 'triggers', 'calculators.calculators', 'calculators.types', 'mail_distribution']

    return items.filter((item:any) => this.$auth.role.permissions.some((permission:any) => permission.startsWith(`${item.code}`)) || !item.code ||
    (this.$auth.role.name !== 'seo' && protectedSeoTabs.some((permission:any) => permission.startsWith(`${item.code}`))))
  }

  activeStyle (link: string) {
    const regexp = new RegExp(`^/${link}`)
    if (regexp.test(this.$route.path)) {
      return 'primaryColor'
    }
    return ''
  }

  changeCollapse () {
    this.isCollapse = !this.isCollapse
    this.$cookies.set('menuCollapsed', this.isCollapse)
    this.$emit('changeSideBar', this.isCollapse)
  }
}
