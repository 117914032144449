import { Vue, Component } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class CurrenciesMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$currencies.resetCurrency()
    next()
  }

  /**
   * * Запрос на получение списка валют
   * @param pageParams - параметры пагинатора (необязательные)
   * @returns список валют
   */
  async getCurrencies (pageParams: PageParams | null = null) {
    try {
      this.$wait.start('getCurrencies')
      return await this.$currencies.getCurrencies(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getCurrencies')
    }
  }

  /**
   * * Запрос на удаление валюты
   * @param id - id валюты, которую мы хотим удалить
   * @returns валюта
   */
  async removeCurrency (id: number) {
    try {
      this.$wait.start('removeCurrency')
      const data = await this.$currencies.removeCurrency(id)
      this.$notify({
        type: 'success',
        title: 'Успешно',
        message: `Валюта "${data.name}" удалена`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeCurrency')
    }
  }

  /**
   * * Запрос на создание валюты
   * @returns валюта
   */
  async createCurrency () {
    try {
      this.$wait.start('createCurrency')
      const data = await this.$currencies.createCurrency()
      this.$notify({
        type: 'success',
        title: 'Успешно',
        message: `Валюта "${data.name}" создана`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createCurrency')
    }
  }

  /**
   * * Запрос на изменение валюты
   * @returns валюта
   */
  async editCurrency () {
    try {
      this.$wait.start('editCurrency')
      const data = await this.$currencies.editCurrency()
      this.$notify({
        type: 'success',
        title: 'Успешно',
        message: `Валюта "${data.name}" изменена`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editCurrency')
    }
  }

  /**
   * * Запрос на обновление курса валют по цб
   */
  async updateRate () {
    try {
      this.$wait.start('updateRate')
      await this.$currencies.updateRate()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Курс обновлен'
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('updateRate')
    }
  }
}
