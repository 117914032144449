import { EmailTemplateGroupsPlugin, EmailTemplateGroup } from './interfaces'
import EmailTemplateGroupsModule from './store'
import { PageParams, ResponseData } from '~/store/interfaces'
export default function getPluginApi (store: EmailTemplateGroupsModule) {
  const api: EmailTemplateGroupsPlugin = {
    get emailTemplateGroups (): ResponseData<EmailTemplateGroup> {
      return store.emailTemplateGroups
    },

    get currentEmailTemplateGroup (): EmailTemplateGroup {
      return store.currentEmailTemplateGroup
    },

    groupById (id: number): EmailTemplateGroup| undefined {
      return store.groupById(id)
    },

    set emailTemplateGroups (groups: ResponseData<EmailTemplateGroup>) {
      store.setEmailTemplateGroups(groups)
    },

    set currentEmailTemplateGroup (group: EmailTemplateGroup) {
      store.setCurrentEmailTemplateGroup(group)
    },

    resetCurrentGroup () {
      store.resetCurrentGroup()
    },

    resetEmailTemplateGroups () {
      store.resetEmailTemplateGroups()
    },

    getEmailTemplateGroups (pageParams?: PageParams | null): Promise<ResponseData<EmailTemplateGroup>> {
      return store.getEmailTemplateGroups(pageParams)
    },

    getEmailTemplateGroupById (id: number): Promise<EmailTemplateGroup> {
      return store.getEmailTemplateGroupById(id)
    },

    createEmailTemplateGroup (): Promise<EmailTemplateGroup> {
      return store.createEmailTemplateGroup()
    },

    editEmailTemplateGroup (): Promise<EmailTemplateGroup> {
      return store.editEmailTemplateGroup()
    },

    removeEmailTemplateGroup (id: number): Promise<EmailTemplateGroup> {
      return store.removeEmailTemplateGroup(id)
    }
  }

  return api
}
