import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class RolesMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$roles.resetRole()
    next()
  }

  /**
   * * Запрос на получение списка roles
   * @param pageParams - параметры запрос: page - номер страницы, pageSize: - размер страницы (необязательный)
   * @returns список ролей
   */
  async getRoles (pageParams?: { page?: number, pageSize?: number }) {
    try {
      this.$wait.start('getRoles')
      return await this.$roles.getRoles({
        page: pageParams?.page || 1,
        pageSize: pageParams?.pageSize
      }
      )
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getRoles')
    }
  }

  /**
   * * Запрос на создание роли
   * @returns роль
   */
  async createRole () {
    this.$wait.start('createRole')
    try {
      const data = await this.$roles.createRole()
      this.$notify({
        type: 'success',
        title: 'Добавлено',
        message: `Роль ${data.name} добавлена`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createRole')
    }
  }

  /**
   * *  Запрос на изменение роли
   * @returns роль
   */
  async editRole () {
    this.$wait.start('editRole')
    try {
      const data = await this.$roles.editRole()
      this.$notify({
        type: 'success',
        title: 'Добавлено',
        message: `Роль ${data.name} изменена`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editRole')
    }
  }

  /**
   * * Запрос на уделение роли
   * @param name - код роли
   * @returns роль
   */
  async removeRole (name: string) {
    this.$wait.start('removeRole')
    try {
      const data = await this.$roles.removeRole(name)
      this.$notify({
        type: 'success',
        title: 'Добавлено',
        message: `Роль ${data.name} удалена`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeRole')
    }
  }
}
