import { CollectionsPlugin, Collection } from './interfaces'
import CollectionsModule from './store'
import { ResponseData, PageParams, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (collectionsModule: CollectionsModule) {
  const api: CollectionsPlugin = {
    // ? ____________ getters ____________

    get collections (): ResponseData<Collection> {
      return collectionsModule.collections
    },

    get collection (): Collection {
      return collectionsModule.collection
    },

    collectionById (id: number): Collection | undefined {
      return collectionsModule.collectionById(id)
    },

    get validators (): ValidatorParams {
      return collectionsModule.collectionValidators
    },

    // ? ____________ setters ____________

    set collections (collections: ResponseData<Collection>) {
      collectionsModule.setCollections(collections)
    },

    set collection (collection: Collection) {
      collectionsModule.setCollection(collection)
    },

    resetCollections () {
      collectionsModule.resetCollections()
    },

    resetCollection () {
      collectionsModule.resetCollection()
    },

    // ? ____________ setters ____________

    getCollections (pageParams: PageParams | null = null): Promise<ResponseData<Collection>> {
      return collectionsModule.getCollections(pageParams)
    },

    getCollectionById (id: number): Promise<Collection> {
      return collectionsModule.getCollectionById(id)
    },

    createCollection (): Promise<Collection> {
      return collectionsModule.createCollection()
    },

    editCollection (): Promise<Collection> {
      return collectionsModule.editCollection()
    },

    generateCollection (optionId: number): Promise<Collection> {
      return collectionsModule.generateCollection(optionId)
    },

    removeCollection (id: number): Promise<Collection> {
      return collectionsModule.removeCollection(id)
    }
  }

  return api
}
