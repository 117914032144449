import { Vue, Component } from 'nuxt-property-decorator'

@Component
export default class GenerateMixin extends Vue {
  /**
   * * Генерация шаблонов товаров
   */
  async generateProduct (productId?: number) {
    this.$wait.start('generateProducts')
    try {
      await this.$generate.generateProducts(productId)
      this.$notify({
        title: 'Выполнено',
        message: 'Сгенерировано',
        type: 'success'
      })
    } catch (e: any) {
      this.$notify({
        title: e.error_code,
        message: e.error_message,
        type: 'error'
      })
      console.error(e)
      throw e
    } finally {
      this.$wait.end('generateProducts')
    }
  }

  /**
   * * Генерация шаблона категории
   * @param category - id категории
   * @param depth - default = 3
   */
  async generateCategory (category: number | null = null, depth: number = 3) {
    this.$wait.start('generateCategory')
    try {
      await this.$generate.generateFilters({ category, depth })
      this.$notify({
        title: 'Выполнено',
        message: 'Сгенерировано',
        type: 'success'
      })
    } catch (e: any) {
      this.$notify({
        title: e.error_code,
        message: e.error_message,
        type: 'error'
      })
      console.error(e)
      throw e
    } finally {
      this.$wait.end('generateCategory')
    }
  }
}
