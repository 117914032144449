import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ResponseData, ValidatorParams } from '../../interfaces'
import { Params, OrderStatusGroup, Filters } from './interfaces'
import validatorsPattern from '~/utils/validators'

import { $axios } from '~/utils/api'

@Module({
  name: 'orderStatusGroups',
  stateFactory: true,
  namespaced: true
})

export default class OrderStatusGroupsModule extends VuexModule {
  /**
   * * Значения фильтров групп статусов
   */
  filtersValue: Filters = {
    name: undefined
  }

  /**
   * * Массив групп статусов заказов
   */
  orderStatusGroupsValue: ResponseData<OrderStatusGroup> = defaultData

  /**
   * * Группа статусов заказов
   */
  orderStatusGroupValue: OrderStatusGroup = {
    name: '',
    sort: 0,
    color: null,
    params: [],
    isAdmin: false
  }

  // ? ______________ getters ______________
  /**
   * * Получить фильтры групп статусов
   */
  get filters (): Filters {
    return this.filtersValue
  }

  /**
   * * Получить массив групп статусов заказав с пагинацией
   */
  get orderStatusGroups (): ResponseData<OrderStatusGroup> {
    return this.orderStatusGroupsValue
  }

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      name: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите название', trigger: ['blur'] }],
      sort: [{ pattern: validatorsPattern.wholeNumbers, message: 'Введите целое число', trigger: ['blur'] }]
    }
  }

  /**
   * * Получить группу статуса заказа по id
   */
  get orderStatusGroupById () {
    const orderStatusGroups = this.orderStatusGroupsValue
    return function (id: number): OrderStatusGroup | undefined {
      return orderStatusGroups.data.find(orderStatusGroup => orderStatusGroup.id === id)
    }
  }

  /**
   * * Получить текущую группу статуса заказа
   */
  get orderStatusGroup (): OrderStatusGroup {
    return this.orderStatusGroupValue
  }

  // ? ______________ setters ______________
  /**
   * * Установить значения фильтров
   * @param filters - значения фильтров
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  /**
   * * Сбросить значения фильтров
   */
  @Mutation
  resetFilters () {
    this.filtersValue = {
      name: undefined
    }
  }

  /**
   * * Установить массив статусов групп заказав с пагинацией
   * @param orderStatusGroups массив статусов заказов
   */
  @Mutation
  setOrderStatusGroups (orderStatusGroups: ResponseData<OrderStatusGroup>) {
    this.orderStatusGroupsValue = orderStatusGroups
  }

  /**
   * * Установить группу статусов заказов
   * @param orderStatusGroup группа статусов заказов
   */
  @Mutation
  setOrderStatusGroup (orderStatusGroup: OrderStatusGroup) {
    this.orderStatusGroupValue = orderStatusGroup
  }

  /**
   * * Очистить группу статусов заказов
   */
  @Mutation
  resetOrderStatusGroup () {
    this.orderStatusGroupValue = {
      name: '',
      sort: 0,
      color: null,
      params: [],
      isAdmin: false
    }
  }

  /**
   * * Очистить группы статусов заказов
   */
  @Mutation
  resetOrderStatusGroups () {
    this.orderStatusGroupsValue = defaultData
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список групп статусов заказов
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setOrderStatusGroups'
  })
  async getOrderStatusGroups (params: Params | null = null) {
    try {
      const { data } = await $axios.get('/shop/order-status-groups', { params: { ...params, ...this.filters } })
      const response: ResponseData<OrderStatusGroup> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить группу статусов заказов
   * @param id
   */
  @Action({
    rawError: true,
    commit: 'setOrderStatusGroup'
  })
  async getOrderStatusGroupById (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/shop/order-status-groups/${id}`)
      const response: OrderStatusGroup = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать группу статусов заказов
   */
  @Action({
    rawError: true
  })
  async createOrderStatusGroup () {
    try {
      const { data: { data } } = await $axios.post('/shop/order-status-groups', this.orderStatusGroup)
      const response: OrderStatusGroup = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Редактировать группу статусов заказов
   */
  @Action({
    rawError: true
  })
  async editOrderStatusGroup () {
    try {
      const { id, ...orderStatusGroup } = this.orderStatusGroup
      const { data: { data } } = await $axios.put(`/shop/order-status-groups/${id}`, orderStatusGroup)
      const response: OrderStatusGroup = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * *  Удалить группу статусов заказов
   * @param id
   */
  @Action({
    rawError: true
  })
  async removeOrderStatusGroup (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/shop/order-status-groups/${id}`)
      const response: OrderStatusGroup = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
