import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ResponseData, PageParams, ValidatorParams } from '../../interfaces'
import { SeoText, Filters, SeoTargets } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'seoTexts',
  stateFactory: true,
  namespaced: true
})

export default class SeoTextsModule extends VuexModule {
  /**
   * * Массив всех seoText исходя из запроса
   */
  seoTexts: ResponseData<SeoText> = defaultData

  /**
   * * Текущая seoText
   */
  seoText: SeoText = {
    title: '',
    description: '',
    target: '',
    index: true,
    seoPageId: null,
    siteId: null
  }

  /**
   * * Массив targets
   */
  targetsValue: SeoTargets = {
    data: []
  }

  /**
   * * Текущие фильтры
   */
  filtersValue: Filters = {
    seoPageId: null
  }

  // ? ______________ getters ______________
  /**
   * * получить текущие фильтры
   */
  get filters (): Filters {
    return this.filtersValue
  }

  /**
   * * получить массив targets
   */
  get targets (): SeoTargets {
    return this.targetsValue
  }

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      title: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите название', trigger: ['blur'] }],
      target: [{ required: true, message: 'Выберите ориентацию', trigger: ['blur'] }],
      description: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите текст', trigger: ['blur'] }]
    }
  }

  /**
   * * Получить массив seoTexts и пагинацией
   */
  get seoTextsList (): ResponseData<SeoText> {
    return this.seoTexts
  }

  /**
   * * Получить seoText из массива seoTexts
   */
  get seoTextById () {
    const seoTexts = this.seoTexts
    return function (id: number): SeoText | undefined {
      return seoTexts.data.find(seoText => seoText.id === id)
    }
  }

  /**
   * * Получить текущую seoText для измения или создания seoText
   */
  get currentSeoText () {
    return this.seoText
  }

  // ? ______________ setters ______________
  /**
   * * Установить массив targets
   * @param targets массив targets
   */
  @Mutation
  setTargets (targets: SeoTargets) {
    this.targetsValue = targets
  }

  /**
   * * Установить фильтры
   * @param filter фильтры
   */
  @Mutation
  setFilters (filter: Filters) {
    this.filtersValue = filter
  }

  /**
   * * Установить массив SeoTexts
   * @param seoTexts массив SeoTexts
   */
  @Mutation
  setSeoTextsList (seoTexts: ResponseData<SeoText>) {
    this.seoTexts = seoTexts
  }

  /**
   * * Установить CurrentSeoText для измения или создания seoText
   * @param seoText текущая SeoText, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentSeoText (seoText: SeoText) {
    this.seoText = seoText
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentSeoText () {
    this.seoText = {
      title: '',
      description: '',
      target: '',
      index: true,
      seoPageId: null,
      siteId: null
    }
  }

  @Mutation
  resetSeoTexts () {
    this.seoTexts = defaultData
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список SeoTexts
   */
  @Action({
    rawError: true,
    commit: 'setSeoTextsList'
  })
  async getSeoTexts (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/seo/text', { params: { ...pageParams, ...this.filters } })
      const response: ResponseData<SeoText> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить seoText по id
   * @param id id seoText, который мы хотим получить
   */
  @Action({
    rawError: true, commit: 'setCurrentSeoText'
  })
  async getSeoText (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/seo/text/${id}`)
      const response: SeoText = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить seoText
   */
  @Action({
    rawError: true
  })
  async editSeoText () {
    try {
      const { id, ...seoText } = this.currentSeoText
      const { data: { data } } = await $axios.put(`/seo/text/${id}`, seoText)
      const response: SeoText = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать SeoText
   */
  @Action({
    rawError: true
  })
  async createSeoText () {
    try {
      const { data: { data } } = await $axios.post('/seo/text', this.currentSeoText)
      const response: SeoText = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить seoText по id
   * @param id  id seoText, который мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removeSeoText (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/seo/text/${id}`)
      const response: SeoText = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение массива seoTargets
   */
  @Action({
    rawError: true, commit: 'setTargets'
  })
  async getSeoTextTargets () {
    try {
      const { data } = await $axios.get('/seo/text/targets')
      const response: SeoTargets = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
