import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class OrderStatusesMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$orderStatuses.resetCurrentOrderStatus()
    next()
  }

  /**
   ** Удаление orderStatus по id
   * @param id - id статуса заказа
   * @returns статус заказа
   */
  async removeOrderStatus (id: number) {
    try {
      this.$wait.start('removeOrderStatus')
      const data = await this.$orderStatuses.deleteOrderStatus(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Статус заказа ${data.name} удален`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removeOrderStatus') }
  }

  /**
   ** Создание orderStatus
   * @returns статус заказа
   */
  async createOrderStatus () {
    try {
      this.$wait.start('createOrderStatus')
      const data = await this.$orderStatuses.createOrderStatus()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Статус заказа ${data.name} создан`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('createOrderStatus') }
  }

  /**
   ** Изменение orderStatus
   * @returns статус заказа
   */
  async editOrderStatus () {
    try {
      this.$wait.start('editOrderStatus')
      const data = await this.$orderStatuses.editOrderStatus()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Статус заказа ${data.name} изменен`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('editOrderStatus') }
  }

  /**
   ** Получить список orderStatuses
   * @returns список статусов заказа
   */
  async getOrderStatuses (pageParams?: PageParams) {
    try {
      this.$wait.start('getOrderStatuses')
      return await this.$orderStatuses.getOrderStatuses(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getOrderStatuses') }
  }
}
