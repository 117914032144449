import { Vue, Component } from 'nuxt-property-decorator'
import { Collection } from './interfaces'
import { PageParams, ResponseData } from '~/store/interfaces'

@Component
export default class CollectionsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$collections.resetCollection()
    this.$collections.resetCollections()
    next()
  }

  /**
   * * Получение списка коллекций
   * @param pageParams - параметры запроса
   * @returns массив коллекций и пагинатор
   */
  async getCollections (pageParams: PageParams): Promise<ResponseData<Collection> | undefined> {
    try {
      this.$wait.start('getCollections')
      return await this.$collections.getCollections(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('getCollections')
    }
  }

  async getCollectionById (id: number) {
    try {
      this.$wait.start('getCollectionById')
      return await this.$collections.getCollectionById(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('getCollectionById')
    }
  }

  /**
   * * Создание коллекции
   * @returns объект коллекции
   */
  async createCollection (): Promise<Collection | undefined> {
    try {
      this.$wait.start('createCollection')
      const data = await this.$collections.createCollection()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлена новая коллекция ${data.name}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('createCollection')
    }
  }

  /**
   * * Изменение коллекции
   * @returns объект коллекции
   */
  async editCollection (): Promise<Collection | undefined> {
    try {
      this.$wait.start('editCollection')
      const data = await this.$collections.editCollection()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Коллекция ${data.name} изменена`
      })
      return data
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('editCollection')
    }
  }

  /**
   * * Генерация коллекции по опции
   * @param optionId - id опции
   * @returns объект коллекции
   */
  async generateCollection (optionId: number): Promise<Collection | undefined> {
    try {
      this.$wait.start('generateCollection')
      const data = await this.$collections.generateCollection(optionId)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Сгенерирована коллекция'
      })
      return data
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('generateCollection')
    }
  }

  async removeCollection (id: number): Promise<Collection | undefined> {
    try {
      this.$wait.start('removeCollection')
      const data = await this.$collections.removeCollection(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Коллекция ${data.name} удалена`
      })
      return data
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    }
  }
}
