
import Vue from 'vue'

import { Component, Prop } from 'nuxt-property-decorator'

@Component
export default class UiSkeleton extends Vue {
  /**
   * *________________ Props ______________________
   */
  @Prop({
    type: Number,
    required: false,
    default: () => 6
  })
    rows!: number

  @Prop({
    type: Boolean,
    required: false,
    default: () => false
  })
    animated!: boolean

  @Prop({
    type: String,
    required: false,
    default: () => 'text'
  })
    type!: string
}
