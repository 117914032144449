import { Vue, Component } from 'nuxt-property-decorator'
import { QueryParams } from './interfaces'

@Component
export default class BonusMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$bonus.resetBonus()
    this.$wait.start('leaveRouter')
    next()
  }

  /**
   * * Получение бонусов
   * @param queryParams - параметры пагинации (необязательный)
   * @returns список бонусов
   */
  async getBonuses (queryParams: QueryParams | null = null) {
    try {
      this.$wait.start('getBonuses')
      return await this.$bonus.getBonuses(queryParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getBonuses')
    }
  }

  /**
   * * Создание бонуса
   * @returns бонус
   */
  async createBonus () {
    try {
      this.$wait.start('createBonus')
      const data = await this.$bonus.createBonus()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлен бонус ${data.name}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createBonus')
    }
  }

  /**
   * * Получение бонуса по id
   * @param id - id бонуса
   * @returns бонус
   */
  async getBonusById (id: number) {
    try {
      this.$wait.start('getBonusById')
      return await this.$bonus.getBonusById(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getBonusById')
    }
  }

  /**
   * * Изменение бонуса
   * @returns бонус
   */
  async editBonus () {
    try {
      this.$wait.start('editBonus')
      const data = await this.$bonus.editBonus()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Бонус ${data.name} изменён`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editBonus')
    }
  }

  /**
   * * Удаление бонуса
   * @param id - id бонуса
   * @returns бонус
   */
  async removeBonus (id: number) {
    try {
      this.$wait.start('removeBonus')
      const data = await this.$bonus.removeBonus(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Бонус ${data.name} удалён`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeBonus')
    }
  }
}
