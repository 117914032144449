import { Context } from '@nuxt/types'
import CalculatorTypesModule from './store'
import storePluginApi from '~/types/store/store-plugin-api'
import { NCalculator } from '~/types/store/calculator-micro'

export default function getPluginApi (store: CalculatorTypesModule) {
  const ctx: Context = this

  const api: any = storePluginApi<
    NCalculator.ICalculatorType,
    'id',
    keyof NCalculator.ICalculatorType
  >(store, ctx.$config.SITE_API)

  return api
}
