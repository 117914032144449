import { SubscribersPlugin, Subscriber, FiltersParams } from './interfaces'
import { PageParams, ResponseData } from '~/store/interfaces'

export default function getPluginApi (subscribersStore:any) {
  const api: SubscribersPlugin = {
    // ? ________ getters ________

    get filters (): FiltersParams {
      return subscribersStore.filters
    },

    get subscribers (): ResponseData<Subscriber> {
      return subscribersStore.subscribers
    },

    // ? ________ setters ________

    set subscribers (subscribers: ResponseData<Subscriber>) {
      subscribersStore.setSubscribers(subscribers)
    },

    set filters (filters: FiltersParams) {
      subscribersStore.setFilters(filters)
    },

    resetFilters () {
      subscribersStore.resetFilters()
    },

    resetSubscribers () {
      subscribersStore.resetSubscribers()
    },

    // ? ________ actions ________
    getSubscribers (pageParams: PageParams | null = null): Promise<ResponseData<Subscriber>> {
      return subscribersStore.getSubscribers(pageParams)
    },

    /**
     * * Запрос на получение участников розыгрыша с помощью excel файла
     */
    downloadSubscribers (): Promise<Blob> {
      return subscribersStore.downloadSubscribers()
    }
  }
  return api
}
