import { Sticker, StickersPlugin } from './interfaces'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (stickersStore:any) {
  const api: StickersPlugin = {
    // ? ________ getters ________

    // * Получить список стикеров и пагинатор
    get stickerList (): ResponseData<Sticker> {
      return stickersStore.stickerList
    },
    // * Получить текущий стикер
    get currentSticker (): Sticker {
      return stickersStore.currentSticker
    },
    get validators (): ValidatorParams {
      return stickersStore.validators
    },
    // * Получить стикер по id
    getStickerById (id: number): Sticker | undefined {
      return stickersStore.getStickerById(id)
    },
    // ? ________ setters ________

    // * Установить список стикеров
    set stickerList (stickerList: ResponseData<Sticker>) {
      stickersStore.setStickerList(stickerList)
    },
    // * Установить текущий стикер
    set currentSticker (currentSticker: Sticker) {
      stickersStore.setCurrentSticker(currentSticker)
    },
    // * Очистить текущий стикер
    resetCurrentSticker () {
      stickersStore.resetCurrentSticker()
    },

    resetStickers () {
      stickersStore.resetStickers()
    },

    // ? ________ actions ________
    // * Запрос на получение всех стикеров и пагинатор
    getStickers (pageParams: PageParams | null = null): Promise<ResponseData<Sticker>> {
      return stickersStore.getStickers(pageParams)
    },
    // * Запрос на получение стикера по id
    getSticker (id: number): Promise<Sticker> {
      return stickersStore.getSticker(id)
    },
    // * Запрос на создание стикера
    createSticker (): Promise<Sticker> {
      return stickersStore.createSticker()
    },
    // * Запрос на изменение стикера
    editSticker (): Promise<Sticker> {
      return stickersStore.editSticker()
    },
    // * Запрос на удаление стикера по id
    removeSticker (id: number): Promise<Sticker> {
      return stickersStore.removeSticker(id)
    }
  }
  return api
}
