// ________________________ COMMON ________________________ //

import { ValidatorParams } from '~/store/interfaces'

/**
 * * Псевдонимы для обращения к апи розыгрышей/участников
 */
export enum API {
  RAFFLE = 'raffle',
  PARTICIPANT = 'participant'
}

/**
 * * Псевдонимы для обращения к методам апи розыгрышей/участников
 */
export enum MODE {
  ACTIVE = 'active',
  CREATE = 'create',
  EDIT = 'edit',
  REMOVE = 'remove',
  REMOVE_ALL = 'remove_all'
}

/**
 * * Типы розыгрышей
 */
export enum RaffleTypes {
  barcode = 'barcode',
  orderId = 'orderId'
}
/**
 * * Методы загрузки участников из excel файла
 */
export enum UploadParticipantsModes {
  update = 0, // с обновлением
  noupdate = 1, // без обновления
  delete = 2, // удаление затем загрузка
}

/**
 * * Перечисление значений параметра порядки сортировки
 */
export enum OrderQuery {
  ASC = 'ASC',
  DESC = 'DESC',
}

/**
 * * Параметры сортировки и пагинации
 */
export interface RequestParams<T> {
  // * Номер страницы
  page?: number;
  // * Кол-во элементов на страницу
  limit?: number;
  // * Название поля сортировки
  sort?: T;
  // * Порядок сортировки
  order?: OrderQuery;
}

/**
 * * Данные пагинации
 */
export interface ResponseMeta {
  // * Номер текущей страницы
  currentPage: number;
  // unknown
  offset: number;
  // * Общей кол-во страницы
  pageCount: number;
  // * Кол-во розыгрышей на страницу
  perPage: number;
  // * Общее кол-во на страницу
  totalCount: number;
}

/**
 * * Данные ответа
 */

// ________________________ RAFFLES ________________________ //

/**
 * * Объект розыгрыша
 */
export interface Raffle {
  // * Флаг активности розыгрыша
  active: boolean;
  // * Код розыгрыша
  code: string;
  // * Описание розыгрыша
  description: string;
  // * Id розыгрыша
  id?: number;
  // * Название розыгрыша
  name: string;
  // * Кол-во участников розыгрыша
  numberParticipants?: number;
  // * Тип розыгрыша
  type: RaffleTypes;
  // * Ссылка на видео
  videoLink: string
  // * Id видео
  videoId: number | null
}

/**
 * * Объект параметров валидации
 */
export interface ValidationParams {
  // * Момент до которого проверяются условия для участия в розыгрыше
  endTime: number; // min - 0
  // * Момент с которого проверяются условия для участия в розыгрыше
  startTime: number; // min - 0
  // * Список статусов заказов для участия в розыгрыше
  statusIds: number[];
}

/**
 * * Интерфейс объекта ответа запрос на список розыгрышей
 */
export interface RafflesResponse {
  // * Данные пагинации ответа
  meta: ResponseMeta;
  // * Список розыгрышей
  raffle: Raffle[];
}

/**
 * * Параметры фильтров запроса на список розыгрышей
 */
export interface RafflesRequestFilters {
  // * Фильтр по полю имя
  name?: string;
  // * Фильтр по полю код
  code?: string;
  // * Фильтр по полю тип
  type?: string;
  // * Фильтр по полю описание
  description?: string;
}

// ________________________ PARTICIPANTS ________________________ //

/**
 * * Объект участника розыгрыша
 * @property active - Флаг активности на участие в розыгрыше
 */
export interface Participant {
  // * Флаг активности на участие в розыгрыше
  active: boolean;
  // * Отображаемое имя участника розыгрыша
  displayName: string;
  // * Адрес электронной почты участника розыгрыша
  email?: string;
  // * Фамилия, имя, отчество участника розыгрыша
  fio: string;
  // * Id участника
  id?: number;
  // * Номер телефона участника розыгрыша
  phone: string;
  // // * Код розыгрыша
  // raffleCode: string;
  // * "Билет" участника (штрихкод товара/номер оформленного заказа)
  ticket: string;
}

/**
 * * Интерфейс объекта ответа запрос на список участников розыгрыша
 */
export interface ParticipantsResponse {
  // * Данные пагинации ответа
  meta: ResponseMeta;
  // * Список участников розыгрыша
  participants: Participant[];
}

/**
 * * Параметры фильтров запроса на список участников розыгрыша
 */
export interface ParticipantsRequestFilters {
  fio?: string;
  email?: string;
  displayName?: string;
  ticket?: string;
}

// ________________________ PLUGIN ________________________ //

/**
 * * Интерфейс объекта плагина
 */
export interface RafflesPlugin {
  // * Фильтры списка розыгрышей
  rafflesFilters: RafflesRequestFilters;

  // * Сброс фильтров списка розыгрышей
  reset_rafflesFilters: () => void;

  // * Список розыгрышей
  raffles: RafflesResponse;

  // * Сброс списка розыгрышей
  reset_raffles: () => void;

  // * Розыгрыш по id
  raffleById: (id: number) => Raffle | undefined

  // * Розыгрыш
  raffle: Raffle;

  // * Валидаторы розыгрыша
  raffleValidators: ValidatorParams

  // * Сброс розыгрыша
  reset_raffle: () => void;

  // --- --- //

  // * Фильтры списка участников розыгрыша
  participantsFilters: ParticipantsRequestFilters;

  // * Сброс фильтров участников розыгрыша
  reset_participantsFilters: () => void;

  // * Список участников розыгрыша
  participants: ParticipantsResponse;

  // * Сброс списка участников розыгрыша
  reset_participants: () => void;

  // * Участник розыгрыша по id
  participantById: (id: number) => Participant | undefined

  // * Участник розыгрыша
  participant: Participant;

  // * Валидаторы участника
  participantValidators: ValidatorParams

  // * Сброс участника розыгрыша
  reset_participant: () => void;

  // --- --- //

  // * Получение списка розыгрышей
  get_raffles: (
    requestParams?: RequestParams<keyof Raffle>
  ) => Promise<RafflesResponse>;

  // * Создание розыгрыша
  create_raffle: () => Promise<Raffle>;

  // * Изменение розыгрыша
  edit_raffle: () => Promise<Raffle>;

  // * Удаление розыгрыша
  remove_raffle: ({ code } : { code: string }) => Promise<Raffle>;

  // --- --- //

  // * Получение списка участников розыгрыша
  get_participants: (
    requestParams?: RequestParams<keyof Participant>
  ) => Promise<ParticipantsResponse>

  // * Добавление участника розыгрыша
  create_participant: () => Promise<Participant>

  // * Изменение участника розыгрыша
  edit_participant: () => Promise<Participant>

  // * Удаление участника розыгрыша
  remove_participant: ({ id } : { id: number }) => Promise<Participant>

  // * Удаление всех участников розыгрыша
  remove_participants: (code: string) => Promise<void>

  // * Изменение участников розыгрыша с помощью excel файла
  upload_participants: ({ file, updateExists } : { file: FormData; updateExists: UploadParticipantsModes}) => Promise<void>
}
