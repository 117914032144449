import {
  OptionsValuesPlugin, Option, OptionValue, Ids, ParamsAll, Filters
} from './interfaces'
import OptionsValuesStore from './store'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (optionsValuesStore: OptionsValuesStore) {
  const api: OptionsValuesPlugin = {
    // ? ------ getters ------ //

    get options (): ResponseData<Option> {
      return optionsValuesStore.options
    },

    get filters (): Filters {
      return optionsValuesStore.filters
    },

    get filtersOptionValues (): Filters {
      return optionsValuesStore.filtersOptionValues
    },

    get option (): Option {
      return optionsValuesStore.option
    },

    optionById (id: number): Option | undefined {
      return optionsValuesStore.optionById(id)
    },

    get validatorsOption (): ValidatorParams {
      return optionsValuesStore.validatorsOption
    },

    get optionValues (): ResponseData<OptionValue> {
      return optionsValuesStore.optionValues
    },

    get optionValue (): OptionValue {
      return optionsValuesStore.optionValue
    },

    optionValueById (id: number): OptionValue | undefined {
      return optionsValuesStore.optionValueById(id)
    },

    get validatorsOptionValues (): ValidatorParams {
      return optionsValuesStore.validatorsOptionValues
    },

    // ? ------ setters ------ //

    set options (options: ResponseData<Option>) {
      optionsValuesStore.setOptions(options)
    },

    set filters (filter: Filters) {
      optionsValuesStore.setFilters(filter)
    },

    set filtersOptionValues (filter: Filters) {
      optionsValuesStore.setFiltersOptionValues(filter)
    },
    resetFiltersOptionValues () {
      optionsValuesStore.resetFiltersOptionValues()
    },
    resetFilters () {
      optionsValuesStore.resetFilters()
    },

    set option (option: Option) {
      optionsValuesStore.setOption(option)
    },

    resetCurrentOption () {
      optionsValuesStore.resetCurrentOption()
    },

    resetOptions () {
      optionsValuesStore.resetOptions()
    },

    set optionValues (optionValues: ResponseData<OptionValue>) {
      optionsValuesStore.setOptionValues(optionValues)
    },

    resetOptionValues () {
      optionsValuesStore.resetOptionValues()
    },

    set optionValue (optionValue: OptionValue) {
      optionsValuesStore.setOptionValue(optionValue)
    },

    resetCurrentOptionValue () {
      optionsValuesStore.resetCurrentOptionValue()
    },

    // ? ------ actions ------ //

    getOptions (pageParams: PageParams | null = null): Promise<ResponseData<Option>> {
      return optionsValuesStore.getOptions(pageParams)
    },

    getOptionsAll (pageParams: ParamsAll | null = null): Promise<ResponseData<Option>> {
      return optionsValuesStore.getOptionsAll(pageParams)
    },

    createOption (): Promise<Option> {
      return optionsValuesStore.createOption()
    },

    getOptionById (id: number): Promise<Option> {
      return optionsValuesStore.getOptionById(id)
    },

    editOption (): Promise<Option> {
      return optionsValuesStore.editOption()
    },

    removeOption (id: number): Promise<Option> {
      return optionsValuesStore.removeOption(id)
    },

    async getOptionValues (params: PageParams & {categoryId?: number} & { optionId?: number } | null = null, save: boolean = true): Promise<ResponseData<OptionValue>> {
      const data = await optionsValuesStore.getOptionValues(params)
      if (save) {
        optionsValuesStore.setOptionValues(data)
      }
      return data
    },

    createOptionValue (): Promise<OptionValue> {
      return optionsValuesStore.createOptionValue()
    },

    getOptionValueById (ids: Ids): Promise<OptionValue> {
      return optionsValuesStore.getOptionValueById(ids)
    },

    editOptionValue (): Promise<OptionValue> {
      return optionsValuesStore.editOptionValue()
    },

    removeOptionValue (id: number): Promise<OptionValue> {
      return optionsValuesStore.removeOptionValue(id)
    },

    getOptionByValueId (id: number): Promise<Option> {
      return optionsValuesStore.getOptionByValueId(id)
    },

    updateOptionSort (id:number) {
      return optionsValuesStore.updateOptionSort(id)
    }
  }

  return api
}
