import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class PermissionsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    next()
  }

  /**
   * * Запрос на получение разрешений
   * @returns разрешения
   */
  async getPermissions () {
    try {
      this.$wait.start('getPermissions')
      return await this.$permissions.getPermissions()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getPermissions')
    }
  }
}
