import BaseModule from './store-base'
import { IFilters, PageParams } from './store-base-type'

/**
 * Фабрика плагина стора
 * @template T - Тип сущности
 * @template I - Ключ идентификатора сущности
 * @template KEYS - Ключи свойств сущности (включая вложенные)
 * @template FILTERS_KEYS - Ключи свойств сущности используемые в качестве фильтров
 *
 * @param store Инстанс стора
 * @param apiUrlPrefix Префикс адреса, если используется внешнее апи
 * @returns Инстанс плагина
 */
export default function storePluginApi<
  T,
  I extends keyof T,
  KEYS,
  FILTERS_KEYS extends keyof Partial<T> = keyof Partial<T>
> (store: BaseModule<T, I, KEYS, FILTERS_KEYS>, apiUrlPrefix?: string) {
  return {
    get filters () {
      return store.filters
    },

    get list () {
      return store.list
    },

    get item () {
      return store.item
    },

    itemByIdentifier (identifier: T[I]) {
      return store.itemByIdentifier(identifier)
    },

    set filters (data) {
      store.setFilters(data)
    },

    set list (data) {
      store.setList(data)
    },

    set item (data) {
      store.setItem(data)
    },

    setFilterByKey<K extends FILTERS_KEYS> (key: K, value: IFilters<T, FILTERS_KEYS>[K]) {
      store.setFilterByKey({ key, value })
    },

    setItemProp<K extends keyof T> (key: K, value: T[K]) {
      store.setItemProp({ key, value })
    },

    setItemPropByKeys (value: any, ...keys: (KEYS | number)[]) {
      store.setItemPropByKeys({
        keys,
        value
      })
    },

    resetFilters () {
      store.resetFilters()
    },

    resetList () {
      store.resetList()
    },

    resetItem () {
      store.resetItem()
    },

    async getList (pageParams: PageParams<T>, save = true) {
      const list = await store.getList({ ...pageParams, apiUrlPrefix })
      if (save) {
        store.setList(list)
      }
      return list
    },

    async getItem (identifier: T[I], save = true) {
      const item = await store.getItem({ identifier, apiUrlPrefix })
      if (save) {
        store.setItem(item)
      }
      return item
    },

    createItem () {
      return store.createItem(apiUrlPrefix)
    },

    editItem (identifier: T[I], item?: T) {
      return store.editItem({ identifier, apiUrlPrefix, item })
    },

    removeItem (identifier: T[I]) {
      return store.removeItem({ identifier, apiUrlPrefix })
    }
  }
}
