import { Context } from '@nuxt/types'
import jwt_decode from 'jwt-decode'
import { AuthPlugin, Profile } from './interfaces'
import { $axios } from '~/utils/api'
import { Role } from '~/store/modules/roles/interfaces'
import errorLogHandler from '~/utils/error-log-handler'

enum MAX_COOKIE_AGE {
  auth = 60 * 60 * 24 * 30,
  refresh = 60 * 60 * 24 * 30,
}

export default function getPluginApi (authStore: any) {
  const ctx: Context = this
  const AUTH_TOKEN_NAME = `auth_token_admin_${ctx.app.$config.SITE_CODE}`
  const REFRESH_TOKEN_NAME = `refresh_token_admin_${ctx.app.$config.SITE_CODE}`

  const api: AuthPlugin = {
  // ? ________ getters ________

    get token (): string {
      let token = ctx.app.$cookies.get(AUTH_TOKEN_NAME)
      if (token !== authStore.token && authStore.token) { token = authStore.token }
      return token
    },

    get authToken (): string {
      const token = this.token
      return token ? `Bearer ${token}` : ''
    },

    get refreshToken (): string {
      return ctx.app.$cookies.get(REFRESH_TOKEN_NAME)
    },

    get isAuth (): boolean {
      return !!(ctx.app.$cookies.get(AUTH_TOKEN_NAME))
    },
    get profile (): Profile {
      return authStore.profile
    },
    get role (): Role {
      return authStore.role
    },

    // ? ________ setters ________

    set token (value: string) {
      authStore.setToken(value)
      ctx.app.$cookies.set(AUTH_TOKEN_NAME, value, { maxAge: MAX_COOKIE_AGE.auth, path: '/' })
    },

    set refreshToken (value: string) {
      ctx.app.$cookies.set(REFRESH_TOKEN_NAME, value, { maxAge: MAX_COOKIE_AGE.refresh, path: '/' })
    },

    set profile (profile: Profile) {
      authStore.setProfile(profile)
    },

    // ? ________ actions ________
    async signin ({ login, password }: { login: string, password: string }) {
      const { token, refreshToken } = await authStore.signin({ login, password })
      ctx.app.$auth.token = token
      ctx.app.$auth.refreshToken = refreshToken
      return token
    },

    async getRefreshToken () {
      const { token, refreshToken } = await authStore.getRefreshToken(ctx.app.$auth.refreshToken)
      ctx.app.$auth.token = token
      ctx.app.$auth.refreshToken = refreshToken
      return token
    },

    getProfile () {
      return authStore.getProfile()
    },

    logout () {
      try {
        return authStore.logout()
      } finally {
        ctx.app.$cookies.remove(AUTH_TOKEN_NAME)
        ctx.app.$cookies.remove(REFRESH_TOKEN_NAME)
        if (ctx.route.fullPath !== '/signin') { ctx.app.$cookies.set('prevLink', ctx.route.fullPath) }
        ctx.redirect('/signin')
      }
    }
  }

  /**
   * * Переменная промиса запроса на обновления токена для избежания гонки
   */
  let refreshPromise: Promise<string> | null = null

  /**
  * * Токен авторизации
  */

  /**
  * * Callback добавление к axios config нового header авторизации
  */

  const axiosAuthInterceptor = async function authConfigInterceptor (config: any) {
    if (config.url !== '/refresh-token' && config.url !== '/login') {
      try {
        const decode: any = jwt_decode(api.token)
        if (decode.exp < Date.now() / 1000) {
          // * Проверка наличия промиса запроса обновления токна
          if (!refreshPromise) {
            refreshPromise = api.getRefreshToken()
          }
          // * Разрешение промиса
          await refreshPromise

          // * Сброс переменной промиса
          refreshPromise = null
        }
      } catch (e) {
        await api.logout()
        return
      }
    }

    const token = api.authToken

    try {
      if (token) {
        config.headers.common.Authorization = token
        config.headers.common.Control = 'no-cache'
        config.headers.common.Pragma = 'no-cache'
        config.headers.common.Expires = '0'
      } else if (config.headers.common.Authorization) {
        delete config.headers.common.Authorization
      }
      if (token) {
        config.headers.common.Authorization = token
      }
      return config
    } catch (e) {
      console.error('req error --->', e)
    }
  }

  const axiosErrorLogsInterceptor = function (err: any) {
    if (process.client) {
      ctx.app.$errorLogs.errorLogs = errorLogHandler({
        error_code: err?.response?.data?.error_code,
        error_message: `${err?.response?.config?.method?.toUpperCase()} ${err?.response?.config?.url} ${!err?.response?.data?.error_message ? 'Ошибка сервера' : typeof err?.response?.data?.error_message === 'string' ? err?.response?.data?.error_message : JSON.stringify(err?.response?.data?.error_message)}`,
        error_req_url: `${err?.response?.config?.baseURL}${err?.response?.config?.url}`
      })
    }
    return Promise.reject(err)
  }

  /**
  * * Перехватываем запрос и вызываем callback
  */
  $axios.interceptors.request.use(axiosAuthInterceptor)
  $axios.onError(axiosErrorLogsInterceptor)

  return api
}
