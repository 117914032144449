import { PriceRoleAssignment, PriceRoleAssignmentPlugin } from './interfaces'
import { PageParams, ResponseData } from '~/store/interfaces'

export default function getPluginApi (priceRoleAssignmentStore:any) {
  const api: PriceRoleAssignmentPlugin = {
    // ? ________ getters ________
    get priceRoleAssignments () {
      return priceRoleAssignmentStore.priceRoleAssignments
    },

    get priceRoleAssignment () {
      return priceRoleAssignmentStore.priceRoleAssignment
    },

    priceRoleAssignmentById (id: number): PriceRoleAssignment | undefined {
      return priceRoleAssignmentStore.priceRoleAssignmentById(id)
    },

    // ? ________ setters ________

    set priceRoleAssignments (priceRoleAssignments: ResponseData<PriceRoleAssignment>) {
      priceRoleAssignmentStore.setPriceRoleAssignments(priceRoleAssignments)
    },

    set priceRoleAssignment (priceRoleAssignment: PriceRoleAssignment) {
      priceRoleAssignmentStore.setPriceRoleAssignment(priceRoleAssignment)
    },

    resetPriceRoleAssignment () {
      priceRoleAssignmentStore.resetPriceRoleAssignment()
    },

    resetPriceRoleAssignments () {
      priceRoleAssignmentStore.resetPriceRoleAssignments()
    },

    // ? ________ actions ________

    getPriceRoleAssignments (pageParams: PageParams | null = null): Promise<ResponseData<PriceRoleAssignment>> {
      return priceRoleAssignmentStore.getPriceRoleAssignments(pageParams)
    },

    getPriceRoleAssignmentById (id: number): Promise<PriceRoleAssignment> {
      return priceRoleAssignmentStore.getPriceRoleAssignmentById(id)
    },

    createPriceRoleAssignment (): Promise<PriceRoleAssignment> {
      return priceRoleAssignmentStore.createPriceRoleAssignment()
    },

    editPriceRoleAssignment (): Promise<PriceRoleAssignment> {
      return priceRoleAssignmentStore.editPriceRoleAssignment()
    },

    removePriceRoleAssignment (id: number): Promise<PriceRoleAssignment> {
      return priceRoleAssignmentStore.removePriceRoleAssignment(id)
    }
  }

  return api
}
