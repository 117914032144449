import { FeedsPlugin, Feeds } from './interfaces'
import FeedsStore from './store'
import { ValidatorParams } from '~/store/interfaces'
export default function getPluginApi (store: FeedsStore) {
  const api: FeedsPlugin = {

    get currentFeed (): Feeds {
      return store.currentFeed
    },

    get validators (): ValidatorParams {
      return store.validators
    },

    get feeds (): Feeds[] {
      return store.feeds
    },

    // ? ________ setters ________

    set feeds (feeds: Feeds[]) {
      store.setFeeds(feeds)
    },

    set currentFeed (feed: Feeds) {
      store.setCurrentFeed(feed)
    },

    // ? ________ actions ________

    async getFeeds (): Promise<Feeds[]> {
      return await store.getFeeds()
    },

    async updateFeed (): Promise<Feeds> {
      return await store.updateFeed()
    },

    async updateFeedsAll (): Promise<void> {
      return await store.updateFeedsAll()
    }
  }

  return api
}
