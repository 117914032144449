import { Component } from 'nuxt-property-decorator'
import { PageTemplate } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { PageParams } from '~/types/store/store-base-type'
import validatorsPattern from '~/utils/validators'
import { ValidatorParams } from '~/store/interfaces'

@Component({
  name: 'PageTemplatesMixin'
})
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    // Запуск лоадера перехода по страницам
    this.$wait.start('leaveRouter')
    // Сброс данных в сторе
    this.$pageTemplates.resetItem()
    this.$pageTemplates.resetList()
    this.$pageTemplates.resetFilters()
    next()
  }

  /**
   * * Запрос на список шаблонов
   * @param pageParams параметры пагинации
   * @param save статус сохранения значения в стор
   * @returns список шаблонов
   */
  async getPageTemplatesList (pageParams?: PageParams<PageTemplate>, save = true) {
    return await this.asyncRequestHandler(
      'getPageTemplatesList',
      this.$pageTemplates.getList,
      pageParams,
      save
    )
  }

  /**
   * Запрос на шаблон по идентификатору
   * @param identifier идентификатор шаблона
   * @param save статус сохранения значения в стор
   * @returns шаблон
   */
  async getPageTemplateItem (identifier: PageTemplate['id'], save = true) {
    return await this.asyncRequestHandler(
      'getPageTemplateItem',
      this.$pageTemplates.getItem,
      identifier,
      save
    )
  }

  /**
   * Запрос на создание шаблона
   * @returns шаблон
   */
  async createPageTemplateItem () {
    return await this.asyncRequestHandler(
      'createPageTemplateItem',
      this.$pageTemplates.createItem
    )
  }

  /**
   * Запрос на изменение шаблона
   * @param identifier идентификатор шаблона
   * @returns шаблон
   */
  async editPageTemplateItem (identifier: PageTemplate['id']) {
    return await this.asyncRequestHandler(
      'editPageTemplateItem',
      this.$pageTemplates.editItem,
      identifier
    )
  }

  /**
   * Запрос на удаление шаблона
   * @param identifier идентификатор шаблона
   * @returns шаблон
   */
  async removePageTemplateItem (identifier: PageTemplate['id']) {
    return await this.asyncRequestHandler(
      'removePageTemplateItem',
      this.$pageTemplates.removeItem,
      identifier
    )
  }

  /**
   * Валидаторы шаблона
   */
  pageTemplateItemValidators: ValidatorParams = {
    code: [{
      required: true,
      pattern: validatorsPattern.stringEmpty,
      message: 'Введите код',
      trigger: ['blur']
    }],
    name: [{
      required: true,
      pattern: validatorsPattern.stringEmpty,
      message: 'Введите название',
      trigger: ['blur']
    }]
  }
}
