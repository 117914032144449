import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class MainPageMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    next()
  }

  /**
   * * Запрос на заказы по дате (данные пользователей)
   * @param pageParams - dateRange c dateFrom до dateTo (необязательные)
   * @returns данные пользователя
   */
  async getMainPageUsersData (pageParams?: {dateFrom?: number, dateTo?: number}) {
    try {
      this.$wait.start('getMainPageUsersData')
      return await this.$mainPage.getOrderUser({
        dateFrom: pageParams?.dateFrom,
        dateTo: pageParams?.dateTo
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getMainPageUsersData') }
  }

  /**
   * * Запрос на список форм по дате
   * @param pageParams - dateRange c dateFrom до dateTo (необязательные)
   * @returns список форм
   */
  async getMainPageSiteForms (pageParams?: {dateFrom?: number, dateTo?: number}) {
    try {
      this.$wait.start('getMainPageSiteForms')
      return await this.$mainPage.getForms({
        dateFrom: pageParams?.dateFrom,
        dateTo: pageParams?.dateTo
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getMainPageSiteForms') }
  }

  /**
   * * Запрос на заказы по дате и статуса заказа
   * @param pageParams - dateRange c dateFrom до dateTo (необязательные), statuses - id статусов заказов
   * @returns список статусов заказа
   */
  async getMainPageOrderStatuses (pageParams?: {dateFrom?: number|undefined, dateTo?: number|undefined, statuses: number[]}) {
    try {
      this.$wait.start('getMainPageOrderStatuses')
      return await this.$mainPage.getStatuses({
        dateFrom: pageParams?.dateFrom,
        dateTo: pageParams?.dateTo,
        statuses: pageParams?.statuses
      })
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      console.error(e)
      throw e
    } finally { this.$wait.end('getMainPageOrderStatuses') }
  }

  /**
   * * Запрос на заказы по городам
   * @param pageParams - dateRange c dateFrom до dateTo (необязательные)
   * @returns список заказов по городам
   */
  async getMainPageGeoData (pageParams?: {dateFrom?: number|undefined, dateTo?: number|undefined}) {
    try {
      this.$wait.start('getMainPageGeoData')
      return await this.$mainPage.getOrdersGeography({
        dateFrom: pageParams?.dateFrom,
        dateTo: pageParams?.dateTo
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getMainPageGeoData') }
  }

  /**
   * * Запрос по дате на доход по месяцам
   * @param pageParams - dateRange c dateFrom до dateTo (необязательные), statuses - id статусов зазаов которые нужно исключить [1, 2, 3]
   * @returns доход по месяцам
   */
  async getMainPageMonthData (pageParams?: {dateFrom?: number|undefined, dateTo?: number|undefined, statuses?: number[]}) {
    try {
      return await this.$mainPage.getMonths({
        dateFrom: pageParams?.dateFrom,
        dateTo: pageParams?.dateTo,
        statuses: pageParams?.statuses
      })
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      console.error(e)
      throw e
    }
  }

  /**
   * * Запрос по дате на доход по дням
   * @param dateFrom - начиная с даты (необязательные)
   * @param dateTo - до даты (необязательные)
   * @returns доход по дням
   */
  async getMainPageDaysData (dateFrom?: number|undefined, dateTo?: number|undefined) {
    try {
      this.$wait.start('getMainPageDaysData')
      return await this.$mainPage.getDays({
        dateFrom,
        dateTo
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getMainPageDaysData')
    }
  }

  async getStatusesByDay (params?: {dateFrom?: number|undefined, dateTo?: number|undefined, statuses?: number[]}) {
    try {
      this.$wait.start('getStatusesByDay')
      return await this.$mainPage.getStatusesByDay(params)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getStatusesByDay')
    }
  }

  async getStatusesByDayExcel (params?: {dateFrom?: number|undefined, dateTo?: number|undefined, statuses?: number[]}) {
    try {
      this.$wait.start('getStatusesByDayExcel')
      return await this.$mainPage.getStatusesByDayExcel(params)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getStatusesByDayExcel')
    }
  }
}
