import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError } from '../../interfaces'
import { Profile, SignIn } from './interfaces'
import { $axios } from '~/utils/api'
import { Role } from '~/store/modules/roles/interfaces'

/**
 * TODO  Добавить сслыки на хранилища
*/

@Module({
  name: 'auth',
  stateFactory: true,
  namespaced: true
})
export default class AuthModule extends VuexModule {
  currentProfile: Profile = {
    role: {
      name: '',
      description: '',
      permissions: []
    }
  }

  tokenValue: string = ''

  /**
  * * Получить объект пользователя
  */
  get profile (): Profile {
    return this.currentProfile
  }

  /**
  * * Получить объект роли
  */
  get role (): Role | any {
    return this.currentProfile.role
  }

  /**
  * * Получить token
  */
  get token (): Role | any {
    return this.tokenValue
  }

  /**
  * * Установить профиль пользователя
  * @param profile
  */
  @Mutation
  setProfile (profile: Profile): void {
    this.currentProfile = profile
  }

  /**
  * * Установить token
  * @param token
  */
  @Mutation
  setToken (token: string): void {
    this.tokenValue = token
  }

  // ? ______________ actions ______________

  /**
  * * Авторизация пользователя
  * @param userdata - объект авторизации
  */
  @Action({ rawError: true })
  async signin (userdata: SignIn) {
    try {
      const { data: { data: { token, refreshToken } } } = await $axios.post('/login', { ...userdata })
      return { token, refreshToken }
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
  * * Получение данных пользователя
  */
  @Action({ rawError: true, commit: 'setProfile' })
  async getProfile () {
    try {
      const { data: { data } } = await $axios.get('/get-user-info')
      const response: Profile = data
      return response
    } catch (error: any) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
  * * Получение токена по refresh
  */
  @Action({ rawError: true })
  async getRefreshToken (refreshToken: string) {
    try {
      const { data } = await $axios.post('/refresh-token', { refreshToken })
      return data
    } catch (error: any) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
  * * Выход из пользователя
  */
  @Action({ rawError: true })
  logout () {
    this.setProfile({
      role: {
        name: '',
        description: '',
        permissions: []
      }
    })
    this.setToken('')
  }
}
