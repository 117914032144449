import { Filters, ProductGroupsPlugin, ProductGroup, ProductGroupInput } from './interfaces'
import ProductGroupsModule from './store'
import { ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (store: ProductGroupsModule) {
  const api: ProductGroupsPlugin = {
    // ? ________ getters ________
    // * Получить фильтры
    get filters (): Filters {
      return store.filters
    },

    // * Получить файлы
    get files (): File[] {
      return store.files
    },

    // * Получение списка productGroups и пагинатор
    get productGroupsList (): ResponseData<ProductGroup> {
      return store.productGroupsList
    },

    // * Получение productGroup по id
    productGroupById (id: number): ProductGroup | undefined {
      return store.productGroupById(id)
    },

    // * Получение текущего productGroup
    get currentProductGroup (): ProductGroup {
      return store.currentProductGroup
    },

    get validators (): ValidatorParams {
      return store.validators
    },

    // ? ________ setters ________

    // * Установить фильтры
    set filters (filters: Filters) {
      store.setFilters(filters)
    },

    // * Установить файлы
    set files (files: File[]) {
      store.setFiles(files)
    },

    // * Сбросить файлы
    resetFiles () {
      store.resetFiles()
    },

    // * Установить список productGroups
    set productGroupsList (productGroups: ResponseData<ProductGroup>) {
      store.setProductGroupsList(productGroups)
    },

    // * Очистить форму
    resetCurrentProductGroup () {
      store.resetCurrentProductGroup()
    },

    // * Установить текущий productGroup
    set currentProductGroup (productGroup: ProductGroup) {
      store.setCurrentProductGroup(productGroup)
    },

    resetFilters () {
      store.resetFilters()
    },

    resetProductGroups () {
      store.resetProductGroups()
    },

    // ? ________ actions ________

    // * Запрос на получение списка productGroups
    getProductGroups (pageParams: ProductGroupInput | null = null): Promise<ResponseData<ProductGroup>> {
      return store.getProductGroups(pageParams)
    },

    // * Запрос на получение productGroup по id
    getProductGroupById (id: number): Promise<ProductGroup> {
      return store.getProductGroupById(id)
    },

    // * Запрос на удаление productGroup по id
    removeProductGroup (id: number): Promise<ProductGroup> {
      return store.removeProductGroup(id)
    },

    // * Запрос на изменение productGroup
    editProductGroup (): Promise<ProductGroup> {
      return store.editProductGroup()
    },

    // * Запрос на создание productGroup
    createProductGroup (): Promise<ProductGroup> {
      return store.createProductGroup()
    },

    // * Запрос на создание всех productGroups
    updateAll () {
      return store.updateAll()
    },

    // * Запрос на изменение группы товаров с помощью файлов
    loadFromFile (): Promise<ProductGroup> {
      return store.loadFromFile()
    },

    getProductGroupExcel (id: number): Promise<Blob> {
      return store.getProductGroupExcel(id)
    }
  }
  return api
}
