import { SeoPagesPlugin, SeoPage, Filters, LinkTypes } from './interfaces'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (seoPagesStore:any) {
  const api: SeoPagesPlugin = {
    // ? ________ getters ________

    get filters (): Filters {
      return seoPagesStore.filters
    },

    get linkTypes (): LinkTypes {
      return seoPagesStore.linkTypes
    },

    get seoPagesList (): ResponseData<SeoPage> {
      return seoPagesStore.seoPagesList
    },

    get validators (): ValidatorParams {
      return seoPagesStore.validators
    },

    seoPageById (id: number): SeoPage | undefined {
      return seoPagesStore.seoPageById(id)
    },

    get currentSeoPage (): SeoPage {
      return seoPagesStore.currentSeoPage
    },

    // ? ________ setters ________

    set filters (filters: Filters) {
      seoPagesStore.setFilters(filters)
    },

    resetFilters (): void {
      seoPagesStore.resetFilters()
    },

    set seoPagesList (seoPages: ResponseData<SeoPage>) {
      seoPagesStore.setSeoPagesList(seoPages)
    },

    resetCurrentSeoPage () {
      seoPagesStore.resetCurrentSeoPage()
    },

    resetSeoPages () {
      seoPagesStore.resetSeoPages()
    },

    set currentSeoPage (seoPage: SeoPage) {
      seoPagesStore.setCurrentSeoPage(seoPage)
    },

    set linkTypes (linkTypes:LinkTypes) {
      seoPagesStore.setLinkTypes(linkTypes)
    },

    resetLinkTypes () {
      seoPagesStore.resetLinkTypes()
    },

    // ? ________ actions ________

    getSeoPages (pageParams: PageParams): Promise<ResponseData<SeoPage>> {
      return seoPagesStore.getSeoPages(pageParams)
    },

    getLinksTypes (): Promise<LinkTypes> {
      return seoPagesStore.getLinksTypes()
    },

    async updateCategories () {
      await seoPagesStore.updateCategories()
    },

    async generateSiteMap () {
      await seoPagesStore.generateSiteMap()
    },

    getSeoPage (id: number): Promise<SeoPage> {
      return seoPagesStore.getSeoPage(id)
    },

    removeSeoPage (id: number): Promise<SeoPage> {
      return seoPagesStore.removeSeoPage(id)
    },

    editSeoPage (): Promise<SeoPage> {
      return seoPagesStore.editSeoPage()
    },

    createSeoPage (): Promise<SeoPage> {
      return seoPagesStore.createSeoPage()
    },

    exportExcel (pageParams: PageParams | null = null) {
      return seoPagesStore.exportExcel(pageParams)
    },

    updateContent (type: string) {
      return seoPagesStore.updateContent(type)
    }
  }
  return api
}
