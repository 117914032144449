
import Vue from 'vue'
import { Component, Prop } from 'nuxt-property-decorator'
import { randomInt } from '@/utils/numbers'
import errorLogHandler from '@/utils/error-log-handler'

@Component({
  middleware: ['auth'],
  layout: 'default'
})

export default class ErrorLayout extends Vue {
  public head () {
    return {
      link: [{ rel: 'icon', type: 'image/x-icon', href: this.$config.S3_DOMAIN + '/icons/favicon.ico' }]
    }
  }

  /**
   * *________________ Props ______________________
   */
  @Prop({
    type: Object,
    required: true
  })
    error!: any

  /**
   * *________________ Data ______________________
   */
  code = this.error.statusCode
  message = this.error.message
  digit1:number| string = 0
  digit2:number| string = 0
  digit3:number| string = 0

  /**
   * *________________ Created ______________________
   */
  created () {
    this.$wait.end('leaveRouter')
    if (this.code === 401) {
      this.$auth.logout()
      // this.$router.push('/signin')
    }
  }

  /**
   * *________________ Mounted ______________________
   */
  mounted () {
    errorLogHandler({
      error_code: this.code,
      error_message: this.message,
      error_req_url: this.error.path
    })
    const [digit1, digit2, digit3] = `${this.code}`.split('')
    const time = 30
    let i = 0
    const loop3 = setInterval(() => {
      if (i > 100) {
        clearInterval(loop3)
        this.digit3 = digit3
      } else {
        this.digit3 = randomInt()
        i++
      }
    }, time)
    const loop2 = setInterval(() => {
      if (i > 80) {
        clearInterval(loop2)
        this.digit2 = digit2
      } else {
        this.digit2 = randomInt()
        i++
      }
    }, time)
    const loop1 = setInterval(() => {
      if (i > 40) {
        clearInterval(loop1)
        this.digit1 = digit1
      } else {
        this.digit1 = randomInt()
        i++
      }
    }, time)
  }
}
