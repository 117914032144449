import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class SearchLogsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$searchLogs.resetSearchLog()
    this.$searchLogs.resetFilters()
    next()
  }

  /**
   * * Запрос на получение списка поисковых логов
   * @param pageParams - параметры запроса
   * @returns поисковые логи
   */
  async getSearchLogs (pageParams?: PageParams) {
    try {
      this.$wait.start('getSearchLogs')
      return await this.$searchLogs.getSearchLogs(pageParams)
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getSearchLogs')
    }
  }

  /**
   * * Запрос на получение списка поисковых логов
   * @param query - запрос
   * @returns поисковые логи
   */
  async getSearchLogByQuery (query: string) {
    try {
      this.$wait.start('getSearchLogByQuery')
      return await this.$searchLogs.getSearchLogByQuery(query)
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getSearchLogByQuery')
    }
  }

  /**
   * * Запрос на создание поискового лога
   * @returns поисковой лог
   */
  async createSearchLog () {
    try {
      this.$wait.start('createSearchLog')
      const data = await this.$searchLogs.createSearchLog()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Поисковой запрос ${data.query} добавлен`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createSearchLog')
    }
  }

  /**
   * * Запрос на изменение поискового лога
   * @returns поисковой лог
   */
  async editSearchLog () {
    try {
      this.$wait.start('editSearchLog')
      const data = await this.$searchLogs.editSearchLog()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Поисковой запрос ${data.query} изменён`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editSearchLog')
    }
  }

  /**
   * * Запрос на поискового лога
   * @param query строка
   * @returns сайт
   */
  async removeSearchLog (query: string) {
    try {
      this.$wait.start('removeSearchLog')
      const data = await this.$searchLogs.removeSearchLog(query)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Сайт ${data.query} удалён`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeSearchLog')
    }
  }
}
