import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class TemplatesMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$templates.resetCurrentTemplate()
    this.$templates.resetDropDowns()
    next()
  }

  /**
   * * Получение списка templateDropDown
   * @returns список templateDropDown
   */
  async getSeoTemplateDropdown () {
    try {
      this.$wait.start('getSeoTemplateDropdown')
      return await this.$templates.getDropDowns()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getSeoTemplateDropdown')
    }
  }

  /**
   * * Получение списка шаблонов
   * @returns список шаблонов
   */
  async getTemplates () {
    try {
      this.$wait.start('getTemplates')
      return await this.$templates.getTemplates()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getTemplates') }
  }

  /**
   * * Удаление seo/template по id
   * @returns шаблон
   */
  async removeTemplate () {
    try {
      this.$wait.start('removeTemplate')
      const data = await this.$templates.removeTemplate()
      this.$notify({
        title: 'Выполнено',
        message: `Шаблон категории №${data.categoryId} (${this.$templates.currentTemplate.typeId === 2 ? 'фильтры' : 'товары'}) успешно удален`,
        type: 'success'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removeTemplate') }
  }

  /**
   * * Создание seo/template
   * @returns шаблон
   */
  async createTemplate () {
    try {
      this.$wait.start('createTemplate')
      const data = await this.$templates.createTemplate()
      this.$notify({
        title: 'Выполнено',
        message: `Шаблон категории №${data.categoryId} (${this.$templates.currentTemplate.typeId === 2 ? 'фильтры' : 'товары'}) успешно создан`,
        type: 'success'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('createTemplate') }
  }

  /**
   * * Изменение seo/template
   * @returns шаблон
   */
  async editTemplate () {
    try {
      this.$wait.start('editTemplate')
      const data = await this.$templates.editTemplate()
      this.$notify({
        title: 'Выполнено',
        message: `Шаблон категории №${data.categoryId} (${this.$templates.currentTemplate.typeId === 2 ? 'фильтры' : 'товары'}) успешно изменен`,
        type: 'success'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('editTemplate') }
  }

  /**
   * * Синхронизация шаблонов
   */
  async syncTemplates () {
    try {
      this.$wait.start('syncTemplates')
      await this.$templates.syncTemplates()
      this.$notify({
        title: 'Выполнено',
        message: 'Шаблоны синхронизированы',
        type: 'success'
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('syncTemplates') }
  }
}
