import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError } from '../../interfaces'
import { ConfigIds, ConfigItem } from './interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'configsList',
  stateFactory: true,
  namespaced: true
})
export default class ConfigsModule extends VuexModule {
  /**
   * * шаблон configs
   */
  configsValue: ConfigItem[] = []

  /**
   * * Объект настройки
   */
  currentConfig: any = {}
  // ? ______________ getters ______________

  /**
   * * Получить config
   */
  get configs (): ConfigItem[] {
    return this.configsValue
  }

  /**
   * * Получить объект настройки
   */
  get config (): any {
    return this.currentConfig
  }

  // ? ______________ setters ______________

  /**
   * * Установить configs
   * @param configs - значение конфига
   */
  @Mutation
  setConfigs (configs: ConfigItem[]) {
    this.configsValue = configs
  }

  /**
   * * Установить объект настройки
   * @param config - значение конфига
   */
  @Mutation
  setConfig (config: any) {
    this.currentConfig = config
  }

  /**
   * * Очистить объект настройки
   */
  @Mutation
  resetConfig () {
    this.currentConfig = {}
  }

  /**
   * * Очистить объект настройки
   */
  @Mutation
  resetConfigs () {
    this.configsValue = []
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить configs
   */
  @Action({
    rawError: true,
    commit: 'setConfigs'
  })
  async getConfigs () {
    try {
      const { data: { data: config } } = await $axios.get('/config')
      const response: ConfigItem[] = config
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить config
   */
  @Action({
    rawError: true
  })
  async editConfigs (configs: ConfigItem[]) {
    try {
      const { data: { data: config } } = await $axios.put('/config', { config: configs })
      const response: ConfigItem[] = config
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить список настроек по id
   * @param id id конфига
   */
  @Action({
    rawError: true
  })
  async getConfigById (id: ConfigIds) {
    try {
      const { data: { data } } = await $axios.get('/config', { params: { name: id } })
      return data?.[0]
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить список настроек по id
   */
  @Action({
    rawError: true
  })
  async editConfig () {
    const { id, ...config } = this.currentConfig
    try {
      const { data: { data } } = await $axios.put(`/config/${id}`, config)
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
