import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import {
  CalculationResponse,
  CalculateRequestParams,
  RecalculateDeliveryRequestParams,
  StaticCalculateRequestParams,
  RecalculateRequestParams
} from './interfaces'
import { FormError } from '~/store/interfaces'
import { $axios } from '~/utils/api'
@Module({
  name: 'calculations',
  stateFactory: true,
  namespaced: true
})
export default class CalculationsModule extends VuexModule {
  calculationValue: CalculationResponse = {
    calculations: [],
    id: null
  }

  get calculation (): CalculationResponse {
    return this.calculationValue
  }

  @Mutation
  setCalculation (calculationValue: CalculationResponse) {
    this.calculationValue = calculationValue
  }

  @Mutation
  resetCalculation () {
    this.calculationValue = {
      calculations: [],
      id: null
    }
  }

  @Action({
    rawError: true,
    commit: 'setCalculation'
  })
  async calculateByAddress ({
    siteApiUrl,
    requestParams
  }: {
    siteApiUrl: string;
    requestParams: CalculateRequestParams;
  }): Promise<CalculationResponse> {
    try {
      const { data } = await $axios.post(
        `${siteApiUrl}/delivery/v1/calculate/order/address`,
        requestParams,
        {
          headers: {
            Authorization: ''
          }
        }
      )
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true,
    commit: 'setCalculation'
  })
  async calculateByDeliveryPoint ({
    siteApiUrl,
    requestParams
  }: {
    siteApiUrl: string;
    requestParams: CalculateRequestParams;
  }): Promise<CalculationResponse> {
    try {
      const { data } = await $axios.post(
        `${siteApiUrl}/delivery/v1/calculate/order/pickupPoint`,
        requestParams,
        {
          headers: {
            Authorization: ''
          }
        }
      )
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true,
    commit: 'setCalculation'
  })
  async calculateByStatic ({
    siteApiUrl,
    requestParams
  } : {
    siteApiUrl: string,
    requestParams: StaticCalculateRequestParams
  }): Promise<CalculationResponse> {
    try {
      const { data } = await $axios.post(
        `${siteApiUrl}/delivery/v1/calculate/static`,
        requestParams,
        {
          headers: {
            Authorization: ''
          }
        }
      )
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async recalculateDelivery ({
    siteApiUrl,
    requestParams
  }: {
    siteApiUrl: string;
    requestParams: RecalculateDeliveryRequestParams;
  }): Promise<void> {
    try {
      return await $axios.patch(
        `${siteApiUrl}/orders/v1/recalculate/delivery`,
        requestParams,
        {
          headers: {
            Authorization: ''
          }
        }
      )
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на пересчёт заказа
   * * requestParams - id заказа и кол-во бонусов
   * @returns
   */
  @Action({
    rawError: true
  })
  async recalculate ({ siteApiUrl, requestParams } : { siteApiUrl: string, requestParams: RecalculateRequestParams}): Promise<void> {
    try {
      return await $axios.patch(
        `${siteApiUrl}/orders/v1/recalculate`,
        {},
        {
          headers: {
            Authorization: ''
          },
          params: { ...requestParams }
        }
      )
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
