import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import Vue from 'vue'
import { RequestActionParams, Trigger, TriggerKeys, TriggerType } from './interfaces'
import { FormError } from '~/store/interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'eventTriggers',
  stateFactory: true,
  namespaced: true
})
export default class EventTriggersModule extends VuexModule {
  /**
   * Стейт список триггеров
   */
  private listData: Trigger[] = []

  /**
   * Стейт триггера
   */
  private itemData: Trigger = {
    code: '',
    config: {
      name: '',
      emailTemplate: {
        id: null,
        promocodePlacehodler: ''
      },
      minOrderIntervalDays: 0,
      promocode: '',
      schedule: {}
    },
    id: null,
    name: '',
    active: false
  }

  /**
   * Геттер списка триггеров
   */
  get list () {
    return this.listData
  }

  /**
   * Геттер триггера
   */
  get item () {
    return this.itemData
  }

  /**
   * Геттер получения триггера из списка по идентификатору
   */
  get itemByIdentifier () {
    return function (identifier: number): Trigger | undefined {
      return this.listData?.slice()?.find((item: Trigger) => item.id === identifier)
    }
  }

  /**
   * Сеттер списка триггеров
   * @param data список триггеров
   */
  @Mutation
  setList (data: Trigger[]) {
    this.listData = data
  }

  /**
   * * Ресеттер списка триггеров
   */
  @Mutation
  resetList () {
    this.listData = []
  }

  /**
   * Сеттер триггера
   * @param data данные триггера
   */
  @Mutation
  setItem (data: Trigger) {
    this.itemData = data
  }

  /**
   * Сеттер свойства триггера неопределенной вложенности по набору ключей
   * @param keys список ключей свойства в порядке вложенности
   * @param value значение свойства
   */
  @Mutation
  setItemPropByKeys<K extends TriggerKeys> ({
    keys,
    value
  } : {
    keys: K[],
    value: TriggerType<K>
  }) {
    if (!keys || !keys.length) { return }
    let stateInstance = this.itemData
    const currentKey = keys.splice(keys.length - 1, 1)
    for (const key of keys) {
      // @ts-ignore
      if (!stateInstance[key]) {
        // @ts-ignore
        // stateInstance[key] = {}
        Vue.set(stateInstance, key, {})
      }
      // @ts-ignore
      stateInstance = stateInstance[key] ?? {}
    }
    // @ts-ignore
    // stateInstance[currentKey[0]] = value
    Vue.set(stateInstance, currentKey[0], value)
  }

  /**
   * Рессетер триггера
   */
  @Mutation
  resetItem () {
    this.itemData = {
      code: '',
      config: {
        name: '',
        emailTemplate: {
          id: null,
          promocodePlacehodler: ''
        },
        minOrderIntervalDays: 0,
        promocode: '',
        schedule: {}
      },
      id: null,
      name: '',
      active: false
    }
  }

  /**
   * Запрос на получение списка триггеров
   * @param param0 параметры action`а запроса
   * @returns список триггеров
   */
  @Action({
    rawError: true
  })
  async getList ({
    siteApiUrl
  }: RequestActionParams) {
    try {
      const { data } = await $axios.get<Trigger[]>(
        `${siteApiUrl}/event-triggers/v1`
      )
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * Запрос на обновление promo-sender`а
   * @param param0 параметры action`а запроса
   */
  @Action({
    rawError: true
  })
  async updatePromoSender ({
    siteApiUrL
  }: {
    siteApiUrL: string
  }) {
    try {
      const { data } = await $axios.put<void>(
        `${siteApiUrL}/event-triggers/v1/promocode-sender`,
        {
          active: this.itemData.active,
          emailTemplate: this.itemData.config.emailTemplate,
          minOrderIntervalDays: this.itemData.config.minOrderIntervalDays,
          name: this.itemData.config.name,
          promocode: this.itemData.config.promocode,
          schedule: this.itemData.config.schedule
        },
        {
          headers: {
            common: {
              Authorization: ''
            }
          }
        }
      )
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
