export default function (err: { error_code?: number, error_message?: string, error_req_url?: string}) {
  const errorLogs_inStorage = localStorage.getItem('errorLogs')

  const errorLogs = !errorLogs_inStorage ? [] : JSON.parse(errorLogs_inStorage)

  const errorLog = {
    error_code: err?.error_code || 500,
    error_message: err?.error_message || 'Something went wrong',
    error_time: Date.now(),
    error_req_url: err?.error_req_url || ''
  }

  errorLogs.unshift(errorLog)
  localStorage.setItem('errorLogs', JSON.stringify(errorLogs.slice(0, 50)))

  return errorLogs.slice(0, 50)
}
