/*
* * Привести дату к формату yyyy-mm-dd
*/
export function isoString (date: any) {
  return new Date(date).toISOString().split('T')[0]
}
/**
* * если month не пришел то возвращать дату с первого число до сегоднященго дня текущего месяца если нет то дата с 1 числа выбраного месяца до начала следуещего месяца
* @param month - дата месяц и год
*/
export function dateRangeMonth (month: any | null = null) {
  const dateRange = []
  if (!month) {
    const firstDayMonthDate = new Date().setDate(1)
    dateRange[0] = Math.round(new Date(firstDayMonthDate).setHours(0, 0, 0, 0))
    const currentDay = new Date().getDate()
    dateRange[1] = Math.round(new Date().setDate(currentDay))
    return dateRange
  } else {
    let firstDayMonthDate = new Date(month).setDate(1)
    dateRange[0] = Math.round(new Date(firstDayMonthDate).setHours(0, 0, 0, 0))
    firstDayMonthDate = new Date(month).setDate(1)
    dateRange[1] = Math.round(new Date(new Date(firstDayMonthDate).setMonth(new Date(month).getMonth() + 1)).setDate(0))
    return dateRange
  }
}
/**
* * если year не пришел то возвращать дату с первого числа текущего года до первого числа следующего года, если нет то дата с 1 числа выбраного года до начала следуещего года
* @param year - год
*/
export function dateRangeYear (year: any | null) {
  const dateRange = []
  if (!year) {
    dateRange[0] = new Date(new Date(new Date(0).setFullYear(new Date().getFullYear())).setDate(1)).setHours(0, 0, 0, 0)
    dateRange[1] = new Date(new Date(new Date(0).setFullYear(new Date().getFullYear() + 1)).setHours(0, 0, 0, 0)).setDate(0)
    return dateRange
  } else {
    dateRange[0] = new Date(new Date(new Date(0).setFullYear(+year)).setDate(1)).setHours(0, 0, 0, 0)
    dateRange[1] = new Date(new Date(new Date(0).setFullYear(+year + 1)).setHours(0, 0, 0, 0)).setDate(0)
    return dateRange
  }
}
/*
* * Дата на год раньше
*/
export function dateYearAgo (date: any) {
  return new Date(date).setFullYear(new Date(date).getFullYear() - 1)
}
/*
* * Привести дату к формату dd.mm.yyyy в зависимотсит от options
*/
export function dateTimeFormat (date: any, options: Intl.DateTimeFormatOptions) {
  return Intl.DateTimeFormat('ru-RU', options).format(new Date(date ?? 0).valueOf())
}
/*
* * Дата на день позже
* @param date timestamp
*/
export function dateOneDayLater (date: number) {
  return (+new Date(+date * 1000) + 86400000) / 1000
}
/*
* * Дата на день раньше
* @param date timestamp
*/
export function dateOneDayEarly (date: number) {
  return (+new Date(+date * 1000) - 86400000) / 1000
}
