import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { defaultData, FormError, ResponseData, ValidatorParams } from '../../interfaces'
import { WidgetParams } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

/**
 * TODO  Добавить сслыки на хранилища
*/

@Module({
  name: 'widgets',
  stateFactory: true,
  namespaced: true
})
export default class WidgetsModule extends VuexModule {
  /**
  * * Список виджетов и пагинатор
  */
  widgetsList: ResponseData<WidgetParams> = defaultData

  /**
   * * Текущий виджет
   */
  currentWidgetValue: WidgetParams = {
    name: '',
    priceRole: null,
    ids: [],
    siteId: 1,
    articles: [],
    active: false,
    manual: false
  }

  // ? ______________ getters ______________
  get validators (): ValidatorParams {
    return {
      name: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите название виджета', trigger: 'blur' }],
      ids: [{ pattern: validatorsPattern.idArrayInput, message: 'Введите id в столбик, без запятых и пробелов', trigger: ['blur', 'change'] },
        { type: 'array', len: (this.currentWidgetValue?.ids.join()).split(',').length, message: 'Введите id в столбик, без запятых и пробелов', trigger: ['blur', 'change'] }],
      articles: [{ pattern: /^((?:[A-Za-z0-9а-яА-Я- _/]+,)*[A-Za-z0-9а-яА-Я- /]+|)$/, message: 'Введите артикулы в столбик, без запятых и пробелов', trigger: ['blur', 'change'] }]
    }
  }

  /**
  * * Получить данные всех виджетов
  */
  get widgets (): ResponseData<WidgetParams> | null {
    return this.widgetsList
  }

  /**
  * * Поиск виджета по id из всех виджетов
  */
  get getWidgetById () {
    return function (id: number): WidgetParams | undefined {
      return {
        ...this.widgets.data.find((data: WidgetParams) => {
          return data.id === id
        }),
        articles: []
      }
    }
  }

  /**
  * * Получение параметров для редактирования или создания виджета
  */
  get currentWidget (): WidgetParams {
    return this.currentWidgetValue
  }

  // ? ______________ setters ______________

  /**
  * * Установить данные
  * @param widgets - все виджеты
  */
  @Mutation
  setWidgets (widgets: ResponseData<WidgetParams>): void {
    this.widgetsList = widgets
  }

  /**
  * * Установить данные
  * @param widgetCreated - параметры для создания виджета или редактирования
  */
  @Mutation
  setCurrentWidget (widgetCreated: WidgetParams) {
    this.currentWidgetValue = widgetCreated
  }

  /**
   * * Сброс текущего виджета
   */
  @Mutation
  resetCurrentWidget () {
    this.currentWidgetValue = {
      name: undefined,
      priceRole: null,
      ids: [],
      siteId: 1,
      articles: [],
      active: false,
      manual: false
    }
  }

  @Mutation
  resetWidgets () {
    this.widgetsList = defaultData
  }
  // ? ______________ actions ______________

  /**
  * * Запрос на получение всех widgets
  */
  @Action({ rawError: true, commit: 'setWidgets' })
  async getWidgets () {
    try {
      const { data } = await $axios.get('/shop/product-widgets')
      const response: ResponseData<WidgetParams> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
  * * Запрос на получение widget по id
  * @param id - id виджета, которого мы хотим получить
  *
  */
  @Action({ rawError: true, commit: 'setCurrentWidget' })
  async getWidget (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/shop/product-widgets/${id}`)
      const response: WidgetParams = { ...data, articles: [] }
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
  * * Запрос на создание widget
  */
  @Action({ rawError: true })
  async createWidget () {
    try {
      const { name, priceRole, articles, ids, productGroupId, siteId } = this.currentWidget
      let idsFilters: number[] = []
      if (ids) {
        idsFilters = ids.filter(i => Number.isInteger(+i) ? +i : null).map(i => +i)
      }
      const { data: { data } } = await $axios.post('/shop/product-widgets', { name, priceRole, articles, ids: idsFilters, productGroupId, siteId })
      const response: WidgetParams[] = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
  * * Запрос на изменение widget по id
  */
  @Action({ rawError: true })
  async editWidget () {
    try {
      const { id, ...widgetParams } = this.currentWidget
      let idsFilters: number[] = []
      if (widgetParams.ids) {
        idsFilters = widgetParams.ids.filter(i => Number.isInteger(+i) ? +i : null).map(i => +i)
      }
      const { data: { data } } = await $axios.put(`/shop/product-widgets/${id}`, { ...widgetParams, ids: idsFilters })
      const response: WidgetParams = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
  * * Запрос на удаление widget по id
  * @param id - id виджета, который мы хотим удалить
  */
  @Action({ rawError: true })
  async removeWidget (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/shop/product-widgets/${id}`)
      const response: WidgetParams = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
