import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ResponseData, ValidatorParams } from '../../interfaces'
import { Filters, ProductGroupInput, ProductGroup } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'productGroups',
  stateFactory: true,
  namespaced: true
})

export default class ProductGroupsModule extends VuexModule {
  /**
   * * Фильтры
   */
  filtersValue: Filters = {
    name: undefined,
    active: undefined,
    manual: undefined
  }

  /**
   * * Файлы
   */
  filesValue: File[] = []

  /**
  * * Массив всех productGroup исходя из запроса
  */
  productGroups: ResponseData<ProductGroup> = defaultData

  /**
   * * Текущий productGroup
   */
  productGroup: ProductGroup = {
    title: undefined,
    active: true,
    manual: 0,
    products: [],
    stickers: [],
    rules: [],
    markup: null,
    priority: 1,
    description: null,
    params: {
      alwaysAddImages: true
    }
  }

  // ? ______________ getters ______________
  /**
   * * Получить фильтры
   */
  get filters (): Filters {
    return this.filtersValue
  }

  /**
   * * Получить файлы
   */
  get files (): File[] {
    return this.filesValue
  }

  /**
   * * Получить массив productGroups и пагинацией
   */
  get productGroupsList (): ResponseData<ProductGroup> {
    return this.productGroups
  }

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      title: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите название группы товаров',
        trigger: ['blur']
      }],
      // markup: [{
      //   // pattern: this.productGroup.markup === null ? '' : validatorsPattern.floatFrom1To99,
      //   type: 'number',
      //   message: 'Введите наценку',
      //   trigger: ['blur']
      // }],
      priority: [{
        required: false,
        pattern: validatorsPattern.intFrom1,
        message: 'Введите приоритет(от 1)',
        trigger: ['blur']
      }]
    }
  }

  /**
   * * Получить productGroup из массива productGroups
   */
  get productGroupById () {
    const productGroups = this.productGroups
    return function (id: number): ProductGroup | undefined {
      return productGroups.data.find(productGroup => productGroup.id === id)
    }
  }

  /**
   * * Получить текущую productGroup для измения или создания productGroup
   */
  get currentProductGroup () {
    return this.productGroup
  }

  // ? ______________ setters ______________
  /**
   * * Установить фильтры
   * @param filters фильтры
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  /**
   * * Установить файлы
   */
  @Mutation
  setFiles (files: File[]) {
    this.filesValue = files
  }

  /**
   * * Сбросить файлы
   */
  @Mutation
  resetFiles () {
    this.filesValue = []
  }

  /**
   * * Установить массив productGroups
   * @param productGroups массив productGroups
   */
  @Mutation
  setProductGroupsList (productGroups: ResponseData<ProductGroup>) {
    this.productGroups = productGroups
  }

  /**
   * * Установить currentProductGroup для измения или создания productGroup
   * @param productGroup текущая productGroup, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentProductGroup (productGroup: ProductGroup) {
    this.productGroup = productGroup
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentProductGroup () {
    this.productGroup = {
      title: undefined,
      active: true,
      manual: 0,
      products: [],
      stickers: [],
      rules: [],
      markup: null,
      priority: 1,
      description: null,
      params: {
        alwaysAddImages: true
      }
    }
  }

  @Mutation
  resetProductGroups () {
    this.productGroups = defaultData
  }

  @Mutation
  resetFilters () {
    this.filtersValue = {
      name: undefined,
      active: undefined,
      manual: undefined
    }
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список productGroups по параметрам запроса
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setProductGroupsList'
  })
  async getProductGroups (pageParams: ProductGroupInput | null): Promise<ResponseData<ProductGroup>> {
    try {
      const params = { ...pageParams, ...this.filters }
      const { data } = await $axios.get('/shop/product-groups', { params })
      const response: ResponseData<ProductGroup> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить productGroup по id
   * @param id id productGroup, которую мы хотим получить
   */
  @Action({
    rawError: true,
    commit: 'setCurrentProductGroup'
  })
  async getProductGroupById (id: number): Promise<ProductGroup> {
    try {
      const { data: { data } } = await $axios.get(`/shop/product-groups/${id}`)
      const response: ProductGroup = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить productGroup
   */
  @Action({
    rawError: true
  })
  async editProductGroup (): Promise<ProductGroup> {
    try {
      const { id, ...newProductGroup } = this.currentProductGroup
      const { data: { data } } = await $axios.put(`/shop/product-groups/${id}`, newProductGroup)
      const response: ProductGroup = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать productGroup
   */
  @Action({
    rawError: true
  })
  async createProductGroup (): Promise<ProductGroup> {
    try {
      const { data: { data } } = await $axios.post('/shop/product-groups', this.currentProductGroup)
      const response: ProductGroup = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить productGroup по id
   * @param id  id productGroup, которую мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removeProductGroup (id: number): Promise<ProductGroup> {
    try {
      const { data: { data } } = await $axios.delete(`/shop/product-groups/${id}`)
      const response: ProductGroup = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Обновить все productGroups
   */
  @Action({
    rawError: true
  })
  async updateAll () {
    try {
      return await $axios.post('shop/product-groups/update-all')
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение группы товаров с помощью файлов
   */
  @Action({
    rawError: true
  })
  async loadFromFile (): Promise<ProductGroup> {
    try {
      const formData = new FormData()
      this.files.map((file: File) => {
        formData.append('files[]', file)
      })
      const { data: { data } } = await $axios.post(`/shop/product-groups/${this.currentProductGroup.id}/load-from-file` /* '/api/files' */, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      const response: ProductGroup = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на эксель excel файл группы товаров
   * @param id - группы товаров
   * @returns - blob excel файл
   */
  @Action({
    rawError: true
  })
  async getProductGroupExcel (id: number): Promise<Blob> {
    try {
      const { data } = await $axios.get(`/shop/product-groups/${id}/excel`, {
        responseType: 'blob'
      })
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
