import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'
import defaultPageSize from '~/utils/page-sizes'

@Component
export default class WordAdjectivesMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$wordAdjectives.resetCurrentWordAdjective()
    next()
  }

  /**
   * * Запрос на wordAdjectives
   * @param pageParams параметры пагинатора (необязательный)
   * @returns список wordAdjectives
   */
  async getWordAdjectives (pageParams?: PageParams) {
    try {
      this.$wait.start('getWordAdjectives')
      return await this.$wordAdjectives.getWordAdjectives({
        page: +(pageParams?.page ?? 1),
        pageSize: +(pageParams?.pageSize ?? defaultPageSize.default),
        sort: pageParams?.sort,
        order: pageParams?.order
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getWordAdjectives') }
  }

  /**
   * * Удаление seo/wordAdjective по id
   * @param id - id wordAdjective
   * @returns wordAdjective
   */
  async removeWordAdjective (id: number) {
    try {
      this.$wait.start('removeWordAdjective')
      const data = await this.$wordAdjectives.removeWordAdjective(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Склонение слова ${data.word} удалено`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removeWordAdjective') }
  }

  /**
   * * Создание seo/wordAdjective
   * @returns wordAdjective
   */
  async createWordAdjective () {
    try {
      this.$wait.start('createWordAdjective')
      const data = await this.$wordAdjectives.createWordAdjective()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Склонение слова ${data.word} создано`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('createWordAdjective') }
  }

  /**
   * * Изменение seo/wordAdjective
   * @returns wordAdjective
   */
  async editWordAdjective () {
    try {
      this.$wait.start('editWordAdjective')
      const data = await this.$wordAdjectives.editWordAdjective()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Склонение слова ${data.word} изменено`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('editWordAdjective') }
  }
}
