import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class PointsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$points.resetCurrentPoint()
    this.$points.resetFilters()
    next()
  }

  /**
   * * Запрос на получение списка магазинов
   * @param pageParams параметры пагинатора (необязательные)
   * @returns список магазинов
   */
  async getPoints (pageParams?: PageParams) {
    try {
      this.$wait.start('getPoints')
      return await this.$points.getPoints({
        ...pageParams,
        page: +(pageParams?.page ?? 1),
        pageSize: pageParams?.pageSize ? +pageParams?.pageSize : undefined
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({ type: 'error', title: e.error_code, message: e.error_message })
      throw e
    } finally { this.$wait.end('getPoints') }
  }

  /**
   * * Запрос на удаление магазина
   * @param id - id магазина
   * @returns магазин
   */
  async removePoint (id: number) {
    try {
      this.$wait.start('removePoint')
      const data = await this.$points.removePoint(id)
      this.$notify({ type: 'success', title: 'Выполнено', message: `Удален магазин ${data?.name}` })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({ type: 'error', title: e.error_code, message: e.error_message })
      throw e
    } finally { this.$wait.end('removePoint') }
  }

  /**
   * * Запрос на изменение магазина
   * @returns магазин
   */
  async editPointRequest () {
    try {
      this.$wait.start('changePoint')
      const data = await this.$points.editPoint()
      this.$notify({ type: 'success', title: 'Выполнено', message: `Изменен магазин ${data?.name}` })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('changePoint') }
  }

  /**
   * * Запрос на создание магазина
   * @returns магазин
   */
  async createPoint () {
    try {
      this.$wait.start('changePoint')
      const data = await this.$points.createPoint()
      this.$notify({ type: 'success', title: 'Выполнено', message: `Создан магазин ${data?.name}` })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('changePoint') }
  }
}
