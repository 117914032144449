import { Vue, Component } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class UsersMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$users.resetCurrentUser()
    this.$users.resetFilters()
    this.$users.resetUserPassword()
    next()
  }

  /**
   * * Получение списка пользователей
   * @param pageParams - параметры пагинации
   * @returns список пользователей
   */
  async getUsers (pageParams: PageParams) {
    try {
      this.$wait.start('getUsers')
      return await this.$users.getUsers(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getUsers')
    }
  }

  /**
   * * Получение пользователя по id
   * @param id - id пользователя
   * @returns пользователь
   */
  async getUser (id: number) {
    try {
      this.$wait.start('getUser')
      return await this.$users.getUser(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getUser')
    }
  }

  /**
   * * Изменение данных пользователя
   * @returns пользователь
   */
  async editUser () {
    try {
      this.$wait.start('editUser')
      const data = await this.$users.editUser()
      this.$notify({
        title: 'Выполнено',
        message: `Пользователь ${data.role === 'org_user' ? data.orgName : data.fio} изменен`,
        type: 'success'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editUser')
    }
  }

  /**
  * * Создание пользователя
   * @returns пользователь
  */
  async createUser () {
    try {
      this.$wait.start('createUser')
      const data = await this.$users.createUser()
      this.$notify({
        title: 'Выполнено',
        message: `Пользователь ${data.role === 'org_user' ? data.orgName : data.fio} создан`,
        type: 'success'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createUser')
    }
  }

  /**
   * * Получение данных пользователя до изменения
   * @returns data до редактирования
   */
  async recoverData () {
    try {
      this.$wait.start('recoverData')
      return await this.$users.recoverData()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('recoverData')
    }
  }

  /**
   * * Изменение пароля пользователя
   * @returns пользователь
   */
  async changePassword () {
    try {
      this.$wait.start('changePassword')
      const data = await this.$users.changePassword()
      this.$notify({
        title: 'Выполнено',
        message: `Пароль пользователя ${data.role === 'org_user' ? data.orgName : data.fio} изменен`,
        type: 'success'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('changePassword')
    }
  }

  /**
   * * Удаление пользователя
   * @param id - id пользователя
   * @returns пользователь
   */
  async removeUser (id: number) {
    try {
      this.$wait.start('removeUser')
      const data = await this.$users.removeUser(id)
      this.$notify({
        title: 'Выполнено',
        message: `Пользователь ${data.role === 'org_user' ? data.orgName : data.fio} удален`,
        type: 'success'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeUser')
    }
  }

  /**
   * * Получить токены пользователя
   * @param id  id user
   * @returns токены
   */
  async getUserTokens (id: number) {
    try {
      this.$wait.start('getUserTokens')
      return await this.$users.getUserTokens(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getUserTokens')
    }
  }

  /**
     * * Выгрузить в excel
     */
  async exportExcelUsers (pageParams: PageParams) {
    try {
      this.$wait.start('exportExcel')
      return await this.$users.exportExcel(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('exportExcel')
    }
  }
}
