import { Vue, Component } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'
@Component
export default class BasketsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$baskets.resetFilters()
    next()
  }

  /**
   * * Получение списка корзин и пагинатора
   * @param pageParams - pageParams - параметры пагинации
   * @returns список корзин и пагинатор
   */
  async getBaskets (pageParams: PageParams) {
    try {
      this.$wait.start('getBaskets')
      return await this.$baskets.getBaskets(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getBaskets')
    }
  }
}
