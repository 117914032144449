import { Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import Vue from 'vue'
import { DEFAULT_FORM_STATE, DEFAULT_TABLE_STATE } from './constants'
import { Newsletter } from './interfaces'
import { FormError, ResponseData } from '~/store/interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'newsletters',
  stateFactory: true,
  namespaced: true
})
export default class NewslettersModule extends VuexModule {
  //* все типы рассылки
  newslettersState: ResponseData<Newsletter[]> = JSON.parse(JSON.stringify(DEFAULT_TABLE_STATE))

  //* форма типа рассылки
  formNewslettersState: Newsletter = JSON.parse(JSON.stringify(DEFAULT_FORM_STATE))

  //* список получателей
  recipientSourcesState = [] as { id: number, title: string, code: string }[]

  //* снэпшоты типов рассылки
  snapshotsOfNewslettersState = {} as any

  //* стэйт блэклиста
  blacklistState: ResponseData<{ id: number, value: string }[]> = JSON.parse(JSON.stringify(DEFAULT_TABLE_STATE))

  //* стэйт вайтлиста
  whitelistState: ResponseData<string[]> = JSON.parse(JSON.stringify(DEFAULT_TABLE_STATE))

  //* стэйт ввода почты
  newEmailState: string = ''

  //* стэйт добавления файла
  newFileState: number | undefined = undefined

  //* статистика по типам рассылки
  statsOfNewslettersState: Record<number, any> = {}

  //* айди текущего типа рассылки
  currentStatsOfNewslettersStateId: number | undefined = undefined

  //* геттер типов рассылки
  get newsletters (): ResponseData<Newsletter[]> {
    return this.newslettersState
  }

  //* геттер блэклиста
  get blacklist (): ResponseData<{ id: number, value: string }[]> {
    return this.blacklistState
  }

  //* геттер вайтлиста
  get whitelist (): ResponseData<string[]> {
    return this.whitelistState
  }

  //* геттер списка пользователя
  get recipientsSource (): { id: number, code: string, title: string }[] {
    return this.recipientSourcesState
  }

  //* геттер снепшотов
  get snapshots () {
    return this.snapshotsOfNewslettersState
  }

  //* геттер формы типа рассылки
  get formNewsletters () {
    return this.formNewslettersState
  }

  //* геттер новой почты
  get newEmail () {
    return this.newEmailState
  }

  //* геттер нового файла
  get newFile () {
    return this.newFileState
  }

  //* геттер статистики типов рассылки
  get statsOfNewsletters () {
    return this.statsOfNewslettersState
  }

  //* геттер выбранного типа рассылки по айди
  get currentStatsOfNewslettersId () {
    return this.currentStatsOfNewslettersStateId
  }

  //* записать типы рассылок
  @Mutation
  setNewsletters (newsletters: ResponseData<Newsletter[]>) {
    if (this.newslettersState.data.length) { this.newslettersState.data.length = 0 }
    this.newslettersState.data.push(...newsletters.data)
    this.newslettersState.paginator = newsletters.paginator
  }

  //* записать блэклист
  @Mutation
  setBlacklist (blacklist: ResponseData<{ id: number, value: string }[]>) {
    if (this.blacklistState.data.length) { this.blacklistState.data.length = 0 }
    this.blacklistState.data.push(...blacklist.data)
    this.blacklistState.paginator = blacklist.paginator
  }

  //* записать вайтлист
  @Mutation
  setWhitelist (whitelist: ResponseData<string[]>) {
    if (this.whitelistState.data.length) { this.whitelistState.data.length = 0 }
    this.whitelistState.data.push(...whitelist.data)
    this.whitelistState.paginator = whitelist.paginator
  }

  //* записать список пользователей для рассылки
  @Mutation
  setRecipientsSource (recipientsSource: { id: number, code: string, title: string }[]) {
    if (this.recipientSourcesState.length) { this.recipientSourcesState.length = 0 }
    this.recipientSourcesState.push(...recipientsSource)
  }

  //* записать снепшоты
  @Mutation
  setSnapshots (id: number, snapshots: any[]) {
    Vue.set(this.snapshotsOfNewslettersState, id, snapshots)
  }

  //* записать данные в форму
  @Mutation
  setFormNewslettersState ({ key, value } : { key: string, value: any }) {
    Vue.set(this.formNewslettersState, key, value)
  }

  //* записать новую почту
  @Mutation
  setNewEmail (value: string) {
    this.newEmailState = value
  }

  //* добавить новый файл
  @Mutation
  setNewFile (value: number | undefined) {
    this.newFileState = value
  }

  //* записать данные статистики типа рассылки
  @Mutation
  setStatsOfNewsletters ({ key, value } : { key: number, value: any }) {
    Vue.set(this.statsOfNewslettersState, key, value)
  }

  //* записать айди текущего типа рассылки
  @Mutation
  setCurrentStatsOfNewslettersId (value: number | undefined) {
    this.currentStatsOfNewslettersStateId = value
  }

  //* получить типы рассылки
  @Action({
    rawError: true,
    commit: 'setNewsletters'
  })
  async getNewsletters ({
    siteApiUrl,
    page,
    pageSize
  } : {
      siteApiUrl: string
      page?: number
      pageSize?: number
    }): Promise<ResponseData<Newsletter[]>> {
    try {
      const { data } = await $axios.get(`${siteApiUrl}/mail-distribution/v1/types?sort=id&order=DESC&page=${page || 1}${pageSize ? `&pageSize=${pageSize}` : ''}`)
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  //* получить тип рассылки по айди
  @Action({
    rawError: true
  })
  async getNewsletterById ({ siteApiUrl, id } : { siteApiUrl: string, id: number }): Promise<Newsletter> {
    try {
      const { data } = await $axios.get(`${siteApiUrl}/mail-distribution/v1/types/${id}`)

      for (const field of Object.keys(data)) {
        this.setFormNewslettersState({ key: field, value: data[field] })
      }

      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  //* получить блэклист
  @Action({
    rawError: true,
    commit: 'setBlacklist'
  })
  async getBlacklist ({
    siteApiUrl,
    page,
    pageSize
  } : {
    siteApiUrl: string
    page?: number
    pageSize?: number
  }): Promise<ResponseData<{ id: number, value: string }[]>> {
    try {
      const { data } = await $axios.get(`${siteApiUrl}/mail-distribution/v1/banlist?order=DESC&sort=id&page=${page || 1}${pageSize ? `&pageSize=${pageSize}` : ''}`)
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  //* получить вайтлист
  @Action({
    rawError: true,
    commit: 'setWhitelist'
  })
  async getWhitelist ({
    siteApiUrl,
    page,
    pageSize
  } : {
    siteApiUrl: string
    page?: number
    pageSize?: number
  }): Promise<ResponseData<string[]>> {
    try {
      const { data } = await $axios.get(`${siteApiUrl}/mail-distribution/v1/recipients?order=DESC&sort=id&status=test&page=${page || 1}${pageSize ? `&pageSize=${pageSize}` : ''}`)
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  //* получить список пользователей
  @Action({
    rawError: true,
    commit: 'setRecipientsSource'
  })
  async getRecipientSources ({ siteApiUrl } : { siteApiUrl: string }): Promise<{ id: number, code: string, title: string }[]> {
    try {
      const { data } = await $axios.get(`${siteApiUrl}/mail-distribution/v1/recipient-sources`)
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  //* получить снэпшоты по айди
  @Action({
    rawError: true
  })
  async getSnapshotsById ({ id, siteApiUrl } : { id: number, siteApiUrl: string }): Promise<any[]> {
    try {
      const { data } = await $axios.get(`${siteApiUrl}/v1/types/${id}/distributions`)
      this.setSnapshots(id, data)
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  //* создать тип рассылки
  @Action({
    rawError: true
  })
  async createNewsletter ({ siteApiUrl } : { siteApiUrl: string }) {
    try {
      const { data } = await $axios.post(
        `${siteApiUrl}/mail-distribution/v1/types`,
        this.formNewsletters
      )

      for (const field in this.formNewsletters) {
        const defaultState: Record<string, string | undefined> = {
          authorName: '',
          config: undefined,
          createdAt: undefined,
          id: undefined,
          recipientSourceParams: undefined,
          recipientSource: '',
          senderEmail: '',
          templateID: '',
          title: '',
          updatedAt: undefined
        }

        this.setFormNewslettersState({
          key: field,
          value: defaultState[field]
        })
      }

      const response: any = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  //* изменить тип рассылки
  @Action({
    rawError: true
  })
  async editNewsletter ({ siteApiUrl } : { siteApiUrl: string }) {
    try {
      await $axios.patch(
        `${siteApiUrl}/mail-distribution/v1/types/${this.formNewsletters.id}`,
        this.formNewsletters
      )

      for (const field in this.formNewsletters) {
        const defaultState: Record<string, string | undefined> = {
          authorName: '',
          config: undefined,
          createdAt: undefined,
          id: undefined,
          recipientSourceParams: undefined,
          recipientSource: '',
          senderEmail: '',
          templateID: '',
          title: '',
          updatedAt: undefined
        }

        this.setFormNewslettersState({
          key: field,
          value: defaultState[field]
        })
      }
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  //* удалить почту из списка (вайт/блэк листа)
  @Action({
    rawError: true
  })
  async removeEmail ({
    siteApiUrl,
    listName,
    email,
    distributionTypeId,
    status
  } : {
    siteApiUrl: string,
    listName: string,
    email: string,
    distributionTypeId?: number,
    status?: string
  }) {
    try {
      await $axios.delete(
        `${siteApiUrl}/mail-distribution/v1/${listName}${email ? `?email=${email}` : ''}${distributionTypeId ? `&distributionTypeId=${+distributionTypeId}` : ''}${status ? `&status=${status}` : ''}`
      )
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  //* добавить почту в список (вайт/блэк лист)
  @Action({
    rawError: true
  })
  async loadNewEmail ({
    siteApiUrl,
    listName,
    status
  } : {
    siteApiUrl: string,
    listName: string,
    status?: string
  }) {
    try {
      await $axios.post(
        `${siteApiUrl}/mail-distribution/v1/${listName}`,
        { emails: [this.newEmail], status }
      )

      this.setNewEmail('')
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  //* сохранить блэклист
  @Action({
    rawError: true
  })
  async saveBlacklist ({ siteApiUrl } : { siteApiUrl: string }) {
    try {
      await $axios.post(
        `${siteApiUrl}/mail-distribution/v1/banlist/file`,
        { fileId: this.newFile }
      )

      this.setNewFile(undefined)
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  //* запустить рассылку
  @Action({
    rawError: true
  })
  async sendNewsletter ({ status, id, siteApiUrl } : { status?: string, id: number, siteApiUrl: string }) {
    try {
      await $axios.post(
        `${siteApiUrl}/mail-distribution/v1/types/${id}/distributions`,
        status === 'test'
          ? { recipientSource: 'test' }
          : {}
      )
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  //* отправить рассылку по неразосланным почтам
  @Action({
    rawError: true
  })
  async sendUnsentNewsletter ({ id, siteApiUrl } : { id: number, siteApiUrl: string }) {
    try {
      await $axios.post(`${siteApiUrl}/mail-distribution/v1/distributions/${id}/restart`)
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  //* получить статистику по айди
  @Action({
    rawError: true
  })
  async viewStatsNewsletter ({ id, siteApiUrl } : { id: number, siteApiUrl: string }) {
    try {
      if (this.statsOfNewslettersState?.[id]) { return }

      const { data } = await $axios.get(`${siteApiUrl}/mail-distribution/v1/types/${id}/distributions`)

      this.setStatsOfNewsletters({ key: id, value: data })
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  //* скачать статистику в файл эксель
  @Action({
    rawError: true
  })
  async downloadStatsOfNewsletter ({ siteApiUrl } : { siteApiUrl: string }) {
    try {
      const { data } = await $axios.get(
        `${siteApiUrl}/mail-distribution/v1/statistics/${this.statsOfNewsletters?.[this.currentStatsOfNewslettersId as number]?.[0]?.id}/file`,
        { responseType: 'blob' }
      )
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
