import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, ResponseData, defaultData, PageParams, ValidatorParams } from '../../interfaces'
import { PromoCode, Filters } from './interfaces'
import { $axios } from '~/utils/api'
import validators from '~/utils/validators'

@Module({
  name: 'promoCodes',
  stateFactory: true,
  namespaced: true
})

export default class PromoCodesModule extends VuexModule {
  /**
   * * Массив всех promoCode исходя из запроса
   */
  promoCodes: ResponseData<PromoCode> = defaultData

  filtersValues: Filters = {
    productGroup: undefined,
    typeId: undefined,
    percent: undefined,
    isActive: undefined,
    isSet: undefined,
    manual: undefined,
    reusable: undefined,
    start_date_from: undefined,
    start_date_to: undefined,
    code: undefined
  }

  /**
   * * Текущая promoCode
   */
  promoCode: PromoCode = {
    code: '',
    description: '',
    typeId: 1,
    productGroupId: null,
    discount: 1,
    isActive: true,
    limit: 1,
    minProducts: 1,
    manual: true,
    isSet: true,
    reusable: true,
    startDate: null,
    endDate: null,
    onlySets: false
  }

  // ? ______________ getters ______________

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      code: [{ required: true, message: 'Введите код', trigger: 'blur' }],
      discount: [this.currentPromoCode.typeId === 2 ? { required: true, type: 'number', min: 1, message: 'Введите значение скидки', trigger: ['blur'] } : { required: true, type: 'number', min: 1, max: 95, message: 'Введите значение скидки(макс: 95)', trigger: ['blur'] }, { pattern: validators.naturalNumbers, message: 'Введите целое число' }],
      limit: [{ type: 'number', message: 'Введите значение порога', trigger: ['blur'] }, { pattern: validators.naturalNumbers, message: 'Введите целое число' }, { type: 'number', min: 1, message: 'Минимальное значение 1' }]
    }
  }

  /**
   * * Получить массив promoCodes и пагинацией
   */
  get promoCodesList (): ResponseData<PromoCode> {
    return this.promoCodes
  }

  /**
   * * Получить filters
   */
  get filters (): Filters {
    return this.filtersValues
  }

  /**
   * * Получить promoCode из массива promoCodes
   */
  get promoCodeById () {
    const promoCodes = this.promoCodes
    return function (id: number): PromoCode | undefined {
      return promoCodes.data.find(promoCode => promoCode.id === id)
    }
  }

  /**
   * * Получить текущую promoCode для измения или создания promoCode
   */
  get currentPromoCode () {
    return this.promoCode
  }

  // ? ______________ setters ______________

  // * Сбросить фильтры
  @Mutation
  resetFilters (): void {
    this.filtersValues = {
      productGroup: undefined,
      typeId: undefined,
      percent: undefined,
      isActive: undefined,
      isSet: undefined,
      manual: undefined,
      reusable: undefined,
      start_date_from: undefined,
      start_date_to: undefined,
      code: undefined
    }
  }

  /**
   * * Установить массив PromoCodes
   * @param promoCodes массив PromoCodes
   */
  @Mutation
  setPromoCodesList (promoCodes: ResponseData<PromoCode>) {
    this.promoCodes = promoCodes
  }

  /**
   * * Установить filters
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValues = filters
  }

  /**
   * * Установить CurrentPromoCode для измения или создания promoCode
   * @param promoCode текущая PromoCode, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentPromoCode (promoCode: PromoCode) {
    this.promoCode = promoCode
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentPromoCode () {
    this.promoCode = {
      code: '',
      description: '',
      typeId: 1,
      productGroupId: null,
      discount: 1,
      isActive: true,
      limit: 1,
      minProducts: 1,
      manual: true,
      isSet: true,
      reusable: true,
      startDate: null,
      endDate: null,
      onlySets: false
    }
  }

  @Mutation
  resetPromoCodes () {
    this.promoCodes = defaultData
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список PromoCodes по параметрам запроса
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setPromoCodesList'
  })
  async getPromoCodes (pageParams: PageParams) {
    try {
      const { data } = await $axios.get('/shop/promo-codes', { params: { ...pageParams, ...this.filtersValues } })
      const response: ResponseData<PromoCode> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить promoCode по id
   * @param id id promoCode которую мы хотим получить
   */
  @Action({
    rawError: true,
    commit: 'setCurrentPromoCode'
  })
  async getPromoCode (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/shop/promo-codes/${id}`)
      const response: PromoCode = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить promoCode
   */
  @Action({
    rawError: true
  })
  async editPromoCode () {
    try {
      const { id, ...promoCode } = this.currentPromoCode
      const { data: { data } } = await $axios.put(`/shop/promo-codes/${id}`, promoCode)
      const response: PromoCode = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать PromoCode
   */
  @Action({
    rawError: true
  })
  async createPromoCode () {
    try {
      const { data: { data } } = await $axios.post('/shop/promo-codes', this.currentPromoCode)
      const response: PromoCode = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить promoCode по id
   * @param id  id promoCode которую мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removePromoCode (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/shop/promo-codes/${id}`)
      const response: PromoCode = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
