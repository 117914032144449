import Vue from 'vue'
import { Component } from 'nuxt-property-decorator'
import { MessageType } from 'element-ui/types/message'
import { ValidationObject, ValidationResult } from './interfaces'

/**
 * Миксин валидации форм
 */
@Component
export default class FormValidationMixin extends Vue {
  // ____________ Methods ____________
  /**
   * Метод валидации формы
   * @param vObjs массив объектов валидации
   * @param isAlert флаг отображения уведомления
   * @returns Состояние валидации формы
   */
  validateForms (vObjs: ValidationObject[], isAlert: boolean = true): ValidationResult | undefined {
    try {
      // Возврат положительного результата валидации при отсутствии объектов валидации
      if (!vObjs.length) { return { isValid: true, idx: -1 } }
      // Переменная результата валидации
      let isValid: boolean
      // Поиск индекса объект валидации с ошибкой
      const vObjIdx = vObjs?.findIndex((vObj: ValidationObject) => {
        // Проверка наличия рефа
        if (!vObj?.ref) {
          // Если реф отсутствует возврат положительного результата
          isValid = true
          return !isValid
        }
        vObj?.ref?.validate((valid: any) => {
          isValid = !!valid
        })
        return !isValid
      })
      // При отсутствии индекса возврат положительного результата валидации
      if (vObjIdx === -1) { return { isValid: true, idx: -1 } }
      // Проверка отображения сообщения
      if (isAlert) {
        // Отображения сообщения об ошибке
        this.$notify({
          type: vObjs?.[vObjIdx]?.alert?.type as MessageType || 'warning',
          title: vObjs?.[vObjIdx]?.alert?.title || 'Ошибка валидации',
          message: vObjs?.[vObjIdx]?.alert?.message || 'Заполните выделенные поля'
        })
      }
      // Возврат результата валидации
      return { isValid: false, idx: vObjIdx }
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Метод очистки состояний валидации форм
   * @param refs массив рефов форм
   * @returns
   */
  clearFormValidation (refs: any[]): undefined {
    // Прерывание при отсутствии рефов
    if (!refs.length) { return }
    // Обход форм
    refs.forEach((ref: any) => {
      // Очистка состояний валидации
      ref?.clearValidate()
    })
  }
}
