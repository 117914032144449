import { Context } from '@nuxt/types'
import CalculatorsModule from './store'
import storePluginApi from '~/types/store/store-plugin-api'
import { NCalculator } from '~/types/store/calculator-micro'

export default function getPluginApi (store: CalculatorsModule) {
  const ctx: Context = this

  const api: any = storePluginApi<
    NCalculator.ICalculator,
    'id',
    keyof NCalculator.ICalculator
  >(store, ctx.$config.SITE_API)

  return api
}
