import { Param, PriceRole, PriceRoleParam, PriceRolesPlugin } from './interfaces'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (priceRolesStore:any) {
  const api: PriceRolesPlugin = {
    // ? ________ getters ________
    get priceRoles (): ResponseData<PriceRole> {
      return priceRolesStore.priceRoles
    },

    get priceRolesParams (): Param[] {
      return priceRolesStore.priceRolesParams
    },

    get currentParams (): PriceRoleParam[] {
      return priceRolesStore.currentParams
    },

    get priceRole (): PriceRole {
      return priceRolesStore.priceRole
    },

    priceRoleById (id: number): PriceRole | undefined {
      return priceRolesStore.priceRoleById(id)
    },

    priceRoleByName (name: string): PriceRole | undefined {
      return priceRolesStore.priceRoleByName(name)
    },

    get validators (): ValidatorParams {
      return priceRolesStore.validators
    },

    // ? ______________ setters ______________

    set priceRoles (priceRoles: ResponseData<PriceRole>) {
      priceRolesStore.setPriceRoles(priceRoles)
    },

    set priceRole (priceRole: PriceRole) {
      priceRolesStore.setPriceRole(priceRole)
    },

    set priceRolesParams (priceRolesParams: Param[]) {
      priceRolesStore.setPriceRolesParams(priceRolesParams)
    },

    set currentParams (currentParams: PriceRoleParam[]) {
      priceRolesStore.setCurrentParams(currentParams)
    },

    resetPriceRole () {
      priceRolesStore.resetPriceRole()
    },

    resetPriceRoles () {
      priceRolesStore.resetPriceRoles()
    },

    // ? ________ actions ________

    getPriceRoles (pageParams: PageParams | null = null): Promise<ResponseData<PriceRole>> {
      return priceRolesStore.getPriceRoles(pageParams)
    },

    getPriceRoleById (id: number) {
      return priceRolesStore.getPriceRoleById(id)
    },

    createPriceRole (params: any) {
      return priceRolesStore.createPriceRole(params)
    },

    editPriceRole () {
      return priceRolesStore.editPriceRole()
    },

    removePriceRole (id: number) {
      return priceRolesStore.removePriceRole(id)
    },

    getPriceRolesParams () {
      return priceRolesStore.getPriceRolesParams()
    },

    getCurrentParams () {
      return priceRolesStore.getCurrentParams()
    },

    createPriceRoleParam (param: PriceRoleParam) {
      return priceRolesStore.createPriceRoleParam(param)
    },

    editPriceRoleParam (param: PriceRoleParam) {
      return priceRolesStore.editPriceRoleParam(param)
    },

    deletePriceRoleParam (id: number) {
      return priceRolesStore.deletePriceRoleParam(id)
    }
  }

  return api
}
