import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, PageParams, defaultData, ResponseData, ValidatorParams } from '../../interfaces'
import { DeliveryGroup, Filters } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'deliveryGroups',
  stateFactory: true,
  namespaced: true
})
export default class DeliveryGroupsModule extends VuexModule {
  /**
   * * Значения фильтров
   */
  filtersValue: Filters = {
    name: undefined,
    code: undefined
  }

  /**
   * * Список групп доставки
   */
  deliveryGroupsValue: ResponseData<DeliveryGroup> = defaultData

  /**
   * * Группа доставки
   */
  deliveryGroupValue: DeliveryGroup = {
    code: '',
    name: '',
    defaultPrice: 0,
    description: '',
    optionsTitle: '',
    typeTitle: 0,
    isDefault: false,
    imageId: null,
    options: []
  }

  // ? ______________ getters ______________

  /**
   * * Получить фильтры
   */
  get filters (): Filters {
    return this.filtersValue
  }

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      name: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите название группы доставок',
        trigger: ['blur']
      }],
      defaultPrice: [{
        required: true,
        message: 'Введите цену по умолчанию',
        trigger: ['blur']
      },
      {
        pattern: validatorsPattern.prices,
        message: 'Введите корректную цену умолчанию',
        trigger: ['change', 'blur']
      }
      ]
    }
  }

  /**
   * * Получить список групп доставки
   */
  get deliveryGroups (): ResponseData<DeliveryGroup> {
    return this.deliveryGroupsValue
  }

  /**
   * * Получить группу доставки
   */
  get deliveryGroup (): DeliveryGroup {
    return this.deliveryGroupValue
  }

  /**
   * * Получить группу доставок по id
   */
  get deliveryGroupById () {
    const deliveryGroups = this.deliveryGroupsValue.data
    return function (id: number): DeliveryGroup | undefined {
      return deliveryGroups.find(deliveryGroup => deliveryGroup.id === id)
    }
  }

  // ? ______________ setters ______________
  /**
   * * Установить фильтры
   * @param filters - значения фильтров
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  /**
   * * Сбросить фильтры
   */
  @Mutation
  resetFilters () {
    this.filtersValue = {
      name: undefined,
      code: undefined
    }
  }

  /**
   * * Установить список групп доставок
   */
  @Mutation
  setDeliveryGroups (deliveryGroups: ResponseData<DeliveryGroup>) {
    this.deliveryGroupsValue = deliveryGroups
  }

  /**
   * * Установить группу доставок
   */
  @Mutation
  setDeliveryGroup (deliveryGroup: DeliveryGroup) {
    this.deliveryGroupValue = deliveryGroup
  }

  /**
   * * Сбросить группу доставок
   */
  @Mutation
  resetDeliveryGroup () {
    this.deliveryGroupValue = {
      code: '',
      name: '',
      defaultPrice: 0,
      description: '',
      optionsTitle: '',
      isDefault: false,
      typeTitle: 0,
      imageId: null,
      options: []
    }
  }

  /**
   * * Сбросить группы доставок
   */
  @Mutation
  resetDeliveryGroups () {
    this.deliveryGroupsValue = defaultData
  }

  // ? ______________ actions ______________

  /**
   * * Запрос на получение списка групп доставок
   * @param pageParams параметры пагинцаии
   */
  @Action({
    rawError: true,
    commit: 'setDeliveryGroups'
  })
  async getDeliveryGroups (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/delivery/groups', { params: { ...pageParams, ...this.filters } })
      const response: ResponseData<DeliveryGroup> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение полного списка групп доставок
   * @param pageParams параметры пагинцаии
   */
  @Action({
    rawError: true,
    commit: 'setDeliveryGroups'
  })
  async getDeliveryGroupsAll (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/delivery/groups/all', { params: pageParams })
      const response: ResponseData<DeliveryGroup> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение группы доставок по id
   * @param id id группы доставок
   */
  @Action({
    rawError: true,
    commit: 'setDeliveryGroup'
  })
  async getDeliveryGroupById (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/delivery/groups/${id}`)
      const response: DeliveryGroup = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание группы доставок
   */
  @Action({
    rawError: true
  })
  async createDeliveryGroup () {
    try {
      const { ...newDeliveryGroup } = this.deliveryGroup
      const { data: { data } } = await $axios.post('/delivery/groups', { ...newDeliveryGroup })
      const response: DeliveryGroup = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение группы доставок
   */
  @Action({
    rawError: true
  })
  async editDeliveryGroup () {
    try {
      const { id, ...newDeliveryGroup } = this.deliveryGroup
      const { data: { data } } = await $axios.put(`/delivery/groups/${id}`, { ...newDeliveryGroup })
      const response: DeliveryGroup = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async removeDeliveryGroup (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/delivery/groups/${id}`)
      const response: DeliveryGroup = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
