import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class ProductFixedMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$fixedProducts.resetCurrentFixedProduct()
    next()
  }

  /**
   * * Получение списка фиксированных полей
   * @param pageParams - параметры пагинатора
   * @returns список фиксированных полей
   */
  async getFixedProducts (pageParams : {page: number, pageSize: number}) {
    try {
      this.$wait.start('getFixedProducts')
      await this.$fixedProducts.getFixedProducts({ page: pageParams.page, pageSize: pageParams.pageSize })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getFixedProducts') }
  }

  /**
   * * Удаление фиксированного поля
   * @param {id, title} - id фиксированного поля, title - название товара фиксированного поля
   * @returns фиксированное поле
   */
  async removeFixedProduct ({ id, title } : {id: number, title: string}) {
    try {
      this.$wait.start('removeFixedProducts')
      const data = await this.$fixedProducts.removeFixedProduct(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Фиксированные поля товара ${title} удалены`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeFixedProducts')
    }
  }
}
