import { Filters, Params, Review, ReviewParam, ReviewType, ReviewsPlugin } from './interfaces'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (reviewsStore:any) {
  const api: ReviewsPlugin = {
    // ? ________ getters ________
    get filters (): Filters {
      return reviewsStore.filters
    },

    get reviews (): ResponseData<Review> {
      return reviewsStore.reviews
    },

    get review (): Review {
      return reviewsStore.review
    },

    get validators (): ValidatorParams {
      return reviewsStore.validators
    },

    reviewById (id: number): Review | undefined {
      return reviewsStore.reviewById(id)
    },

    get reviewParams (): ResponseData<ReviewParam> {
      return reviewsStore.reviewParams
    },

    reviewParamById (id: number): ReviewParam | undefined {
      return reviewsStore.reviewParamById(id)
    },

    get reviewTypes (): ResponseData<ReviewType> {
      return reviewsStore.reviewTypes
    },

    reviewTypeById (id: number): ReviewType | undefined {
      return reviewsStore.reviewTypeById(id)
    },

    // ? ________ setters ________

    set filters (filters: Filters) {
      reviewsStore.setFilters(filters)
    },

    resetFilters (): void {
      reviewsStore.resetFilters()
    },

    set reviews (reviews: ResponseData<Review>) {
      reviewsStore.setReviews(reviews)
    },

    set review (review: Review) {
      reviewsStore.setReview(review)
    },

    resetReview (): void {
      reviewsStore.resetReview()
    },

    set reviewParams (reviewParams: ResponseData<ReviewParam>) {
      reviewsStore.setReviewParams(reviewParams)
    },

    resetReviews (): void {
      reviewsStore.resetReviews()
    },

    resetReviewTypes (): void {
      reviewsStore.resetReviewTypes()
    },

    resetReviewParams (): void {
      reviewsStore.resetReviewParams()
    },
    // ? ________ actions ________

    getReviews (pageParams: PageParams | null = null): Promise<ResponseData<Review>> {
      return reviewsStore.getReviews(pageParams)
    },

    createReview (): Promise<Review> {
      return reviewsStore.createReview()
    },

    getReviewById (id: number): Promise<Review> {
      return reviewsStore.getReviewById(id)
    },

    editReview (): Promise<Review> {
      return reviewsStore.editReview()
    },

    removeReview (id: number): Promise<Review> {
      return reviewsStore.removeReview(id)
    },

    getReviewParams (params: Params | null = null): Promise<ResponseData<ReviewParam>> {
      return reviewsStore.getReviewParams(params)
    },

    createReviewParam (reviewParam: ReviewParam): Promise<ReviewParam> {
      return reviewsStore.createReviewParam(reviewParam)
    },

    removeReviewParam (id: number): Promise<ReviewParam> {
      return reviewsStore.removeReviewParam(id)
    },

    getReviewTypes (pageParams?: PageParams | null): Promise<ResponseData<ReviewType>> {
      return reviewsStore.getReviewTypes(pageParams)
    }
  }

  return api
}
