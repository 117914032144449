import { ProductOffersPlugin, ProductOffer, Params, Ids, Filters } from './interfaces'
import ProductOffersModule from './store'
import { ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (store: ProductOffersModule) {
  const api: ProductOffersPlugin = {
    // ? ________ getters ________
    get filters (): Filters {
      return store.filters
    },

    get productOffers (): ResponseData<ProductOffer> {
      return store.productOffers
    },

    get productOffer (): ProductOffer {
      return store.productOffer
    },

    productOfferById (id: number): ProductOffer | undefined {
      return store.productOfferById(id)
    },

    get validators (): ValidatorParams {
      return store.validators
    },

    // ? ______________ setters ______________

    set filters (filters: Filters) {
      store.setFilters(filters)
    },

    resetFilters () {
      store.resetFilters()
    },

    set productOffers (productOffers: ResponseData<ProductOffer>) {
      store.setProductOffers(productOffers)
    },

    resetProductOffers () {
      store.resetProductOffers()
    },

    set productOffer (productOffer: ProductOffer) {
      store.setProductOffer(productOffer)
    },

    resetProductOffer () {
      store.resetProductOffer()
    },

    // ? ________ actions ________

    getProductOffers (params: Params): Promise<ResponseData<ProductOffer>> {
      return store.getProductOffers(params)
    },

    createProductOffer (productId: number): Promise<ProductOffer> {
      return store.createProductOffer(productId)
    },

    getProductOfferById (ids: Ids): Promise<ProductOffer> {
      return store.getProductOfferById(ids)
    },

    editProductOffer (productId: number): Promise<ProductOffer> {
      return store.editProductOffer(productId)
    },

    removeProductOffer (ids: Ids): Promise<ProductOffer> {
      return store.removeProductOffer(ids)
    }
  }

  return api
}
