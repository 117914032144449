import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, PageParams, defaultData, ResponseData } from '../../interfaces'
import { Subscriber, FiltersParams } from './interfaces'
import { dateOneDayLater } from '~/utils/date'
import { $axios } from '~/utils/api'

@Module({
  name: 'subscribers',
  stateFactory: true,
  namespaced: true
})
export default class EmailTemplatesModule extends VuexModule {
  filtersValues: FiltersParams = {
    email: undefined,
    created_at_from: undefined,
    created_at_to: undefined,
    active: undefined
  }

  /**
   * * Массив подписчиков
   */
  subscribersList: ResponseData<Subscriber> = defaultData

  // ? ______________ getters ______________

  /**
   * * Получить массив подписчиков
   */
  get subscribers (): ResponseData<Subscriber> {
    return this.subscribersList
  }

  /**
   * * Получить filters
   */
  get filters (): FiltersParams {
    return this.filtersValues
  }

  // ? ______________ setters ______________

  /**
   * * Установить массив подписчиков
   * @param subscribers массив подписчиков
   */
  @Mutation
  setSubscribers (subscribers: ResponseData<Subscriber>) {
    this.subscribersList = subscribers
  }

  @Mutation
  resetSubscribers () {
    this.subscribersList = defaultData
  }

  /**
   * * Установить filters
   */
  @Mutation
  setFilters (filters: FiltersParams) {
    this.filtersValues = filters
  }

  /**
   * * Обнулить фильтры
   */
  @Mutation
  resetFilters () {
    this.filtersValues = {
      email: undefined,
      created_at_from: undefined,
      created_at_to: undefined,
      active: undefined
    }
  }
  // ? ______________________________________actions______________________________________

  /**
   * * Получить список подписчиков
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setSubscribers'
  })
  async getSubscribers (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/mass-mailing/subscribes', {
        params: {
          ...pageParams,
          ...this.filtersValues,
          created_at_from: this.filtersValues.created_at_from ? +this.filtersValues.created_at_from : undefined,
          created_at_to: this.filtersValues.created_at_to ? dateOneDayLater(+this.filtersValues.created_at_to) : undefined
        }
      })
      const response: ResponseData<Subscriber> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение подписчиков с помощью excel файла
   */
  @Action({
    rawError: true
  })
  async downloadSubscribers (): Promise<Blob> {
    try {
      const { data } = await $axios.get('/mass-mailing/subscribes/excel', {
        responseType: 'blob'
      })
      return data as Blob
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
