import { Vue, Component } from 'nuxt-property-decorator'
import { QueryParams } from './interfaces'

@Component
export default class BonusAccountMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$bonusAccount.resetBonusAccount()
    next()
  }

  /**
   * * Получение списка аккаунтов бонусов
   * @param queryParams - параметры пагинации (необязательный)
   * @param save - если true, то сохраняет в стор и не возвращает,
   *  если false, не сохраняет в стор но возварщает (необязательный default=true)
   * @returns список аккаунтов бонусов
   */
  async getBonusAccounts (queryParams: QueryParams | null = null, save: boolean = true) {
    try {
      this.$wait.start('getBonusAccounts')
      const data = await this.$bonusAccount.getBonusAccounts(queryParams, save)
      if (!save) {
        return data
      }
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getBonusAccounts')
    }
  }

  /**
   * * Создание аккаунта бонусов
   * @returns аккаунт бонусов
   */
  async createBonusAccount () {
    try {
      this.$wait.start('createBonusAccount')
      const data = await this.$bonusAccount.createBonusAccount()
      this.$notify({
        type: 'success',
        title: 'Выполнение',
        message: `Добавлен аккаунт бонусов ${data.name}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createBonusAccount')
    }
  }

  /**
   * * Получение аккаунта бонусов по id
   * @param id - id аккаунта бонусов
   * @returns аккаунт бонусов
   */
  async getBonusAccountById (id: number) {
    try {
      this.$wait.start('getBonusAccountById')
      return await this.$bonusAccount.getBonusAccountById(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getBonusAccountById')
    }
  }

  /**
   * * Изменение аккаунта бонусов
   * @returns аккаунт бонусов
   */
  async editBonusAccount () {
    try {
      this.$wait.start('editBonusAccount')
      const data = await this.$bonusAccount.editBonusAccount()
      this.$notify({
        type: 'success',
        title: 'Выполнение',
        message: `Аккаунт бонусов ${data.name} изменён`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editBonusAccount')
    }
  }

  /**
   * * Удаление аккаунта бонусов
   * @param id - id аккаунта бонусов
   * @returns аккаунт бонусов
   */
  async removeBonusAccount (id: number) {
    try {
      this.$wait.start('removeBonusAccount')
      const data = await this.$bonusAccount.removeBonusAccount(id)
      this.$notify({
        type: 'success',
        title: 'Выполнение',
        message: `Аккаунт бонусов ${data.name} удалён`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeBonusAccount')
    }
  }
}
