import { PointsPlugin, Point, DillerData } from './interfaces'
import { PageParams, ResponseData, ValidatorParams, Filters } from '~/store/interfaces'

export default function getPluginApi (store:any) {
  const api: PointsPlugin = {
    // ? ________ getters ________

    get filters (): Filters & DillerData {
      return store.filters
    },

    get pointsList (): ResponseData<Point> {
      return store.pointsList
    },

    get validators (): ValidatorParams {
      return store.validators
    },

    pointById (id: number): Point | undefined {
      return store.pointById(id)
    },

    get currentPoint (): Point {
      return store.currentPoint
    },

    // ? ________ setters ________

    set filters (filter: Filters & DillerData) {
      store.setFilters(filter)
    },

    set pointsList (points: ResponseData<Point>) {
      store.setPointsList(points)
    },

    resetCurrentPoint () {
      store.resetCurrentPoint()
    },

    resetFilters () {
      store.resetFilters()
    },

    resetPointsList () {
      store.resetPointsList()
    },

    set currentPoint (point: Point) {
      store.setCurrentPoint(point)
    },

    setItemPropByKeys (value, ...keys) {
      store.setItemPropByKeys({
        keys,
        value
      })
    },

    // ? ________ actions ________

    getPoints (pageParams: PageParams | null = null): Promise<ResponseData<Point>> {
      return store.getPoints(pageParams)
    },

    getPoint (id: number): Promise<Point> {
      return store.getPoint(id)
    },

    removePoint (id: number): Promise<Point> {
      return store.removePoint(id)
    },

    editPoint (): Promise<Point> {
      return store.editPoint()
    },

    createPoint (): Promise<Point> {
      return store.createPoint()
    }
  }
  return api
}
