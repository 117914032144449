import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ResponseData, PageParams, ValidatorParams } from '../../interfaces'
import { SearchTextReplace, FiltersParams } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'
@Module({
  name: 'searchTextReplace',
  stateFactory: true,
  namespaced: true
})

export default class SearchTextReplacesModule extends VuexModule {
  /**
   * * Массив всех searchTextReplace исходя из запроса
   */
  searchTextReplaces: ResponseData<SearchTextReplace> = defaultData
  /**
   * * Текущая searchTextReplace
   */
  searchTextReplace: SearchTextReplace = {
    word: undefined,
    replace: undefined,
    active: true
  }

  filtersValues: FiltersParams = {
    word: undefined,
    replace: undefined
  }

  // ? ______________ getters ______________

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      word: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите исходное слово', trigger: ['blur'] }],
      replace: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите синоним слова', trigger: ['blur'] }]
    }
  }

  /**
   * * Получить filters
   */
  get filters (): FiltersParams {
    return this.filtersValues
  }

  /**
   * * Получить массив searchTextReplaces и пагинацией
   */
  get searchTextReplacesList (): ResponseData<SearchTextReplace> {
    return this.searchTextReplaces
  }

  /**
   * * Получить searchTextReplace из массива searchTextReplaces
   */
  get searchTextReplaceById () {
    const searchTextReplaces = this.searchTextReplaces
    return function (id: number): SearchTextReplace | undefined {
      return searchTextReplaces.data.find(searchTextReplace => searchTextReplace.id === id)
    }
  }

  /**
   * * Получить текущую searchTextReplace для измения или создания searchTextReplace
   */
  get currentSearchTextReplace () {
    return this.searchTextReplace
  }

  // ? ______________ setters ______________

  /**
   * * Установить массив SearchTextReplaces
   * @param searchTextReplaces массив SearchTextReplaces
   */
  @Mutation
  setSearchTextReplacesList (searchTextReplaces: ResponseData<SearchTextReplace>) {
    this.searchTextReplaces = searchTextReplaces
  }

  /**
   * * Установить CurrentSearchTextReplace для измения или создания searchTextReplace
   * @param searchTextReplace текущая SearchTextReplace, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentSearchTextReplace (searchTextReplace: SearchTextReplace) {
    this.searchTextReplace = searchTextReplace
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentSearchTextReplace () {
    this.searchTextReplace = {
      word: undefined,
      replace: undefined,
      active: true
    }
  }

  /**
   * * Установить filters
   */
  @Mutation
  setFilters (filters: FiltersParams) {
    this.filtersValues = filters
  }

  @Mutation
  resetSearchTextReplaces () {
    this.searchTextReplaces = defaultData
  }

  /**
   * * Обнулить фильтры
   */
  @Mutation
  resetFilters () {
    this.filtersValues = {
      word: undefined,
      replace: undefined
    }
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список SearchTextReplaces
   */
  @Action({
    rawError: true,
    commit: 'setSearchTextReplacesList'
  })
  async getSearchTextReplaces (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/search-text-replace', { params: { ...pageParams, ...this.filters } })
      const response: ResponseData<SearchTextReplace> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить searchTextReplace по id
   * @param id id searchTextReplace, который мы хотим получить
   */
  @Action({
    rawError: true, commit: 'setCurrentSearchTextReplace'
  })
  async getSearchTextReplace (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/search-text-replace/${id}`)
      const response: SearchTextReplace = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить searchTextReplace
   */
  @Action({
    rawError: true
  })
  async editSearchTextReplace () {
    try {
      const { id, ...searchTextReplace } = this.currentSearchTextReplace
      const { data: { data } } = await $axios.put(`/search-text-replace/${id}`, searchTextReplace)
      const response: SearchTextReplace = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать SearchTextReplace
   */
  @Action({
    rawError: true
  })
  async createSearchTextReplace () {
    try {
      const { data: { data } } = await $axios.post('/search-text-replace', this.currentSearchTextReplace)
      const response: SearchTextReplace = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить searchTextReplace по id
   * @param id  id searchTextReplace, который мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removeSearchTextReplace (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/search-text-replace/${id}`)
      const response: SearchTextReplace = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async generateSTR (optionIds: number[]) {
    try {
      const { data } = await $axios.post('/search-text-replace/fill', {
        optionIds
      })
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async generateSTRById (id: number) {
    try {
      const { data } = await $axios.post(`/search-text-replace/${id}/generate`)
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
