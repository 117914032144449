
import draggable from 'vuedraggable'
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import Skeleton from '~/components/ui/skeleton/index.vue'

/**
 * Компонент списка с возможностью сортировки элементов перетаскиванием
 * TODO: переработать, добавить слот для использования кастомных элементов списка
 */
@Component({
  name: 'UiList',
  components: {
    draggable,
    Skeleton
  }
})
export default class extends Vue {
  /**
   * Массив элементов
   */
  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
    items!: Array<any>

  /**
   * Ключ свойства лейбла элемента
   */
  @Prop({
    type: Array,
    required: true,
    default: () => ''
  })
    labelKeys!: string[]

  /**
   * Ключ ререндеринга
   */
  @Prop({
    type: [Number, String],
    required: false,
    default: undefined
  })
    sync!: number | String

  /**
   * Перетаскивать кнопкой
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
    useHandle!: string

  /**
   * Имя класса стилей для перетаскивания кнопкой элемента списка
   */
  @Prop({
    type: String,
    required: false,
    default: 'handle'
  })
    handleClass!: string

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
    noDrag!: boolean

  /**
   * Список элементов
   */
  get itemsLocal () {
    return this.items?.slice() || []
  }

  /**
   * Сеттер спика элементов
   */
  set itemsLocal (event: any) {
    this.$emit('change', event)
  }
}
