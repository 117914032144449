import { Action, Module, VuexModule } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError } from '~/store/interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'reindexer',
  stateFactory: true,
  namespaced: true
})
export default class ReindexerModule extends VuexModule {
  // ? ______________________________________actions______________________________________

  /**
   * * Запустить выгрузку
   */
  @Action({
    rawError: true
  })
  async export (url: string = '/reindexer/export') {
    try {
      const { data: { status } } = await $axios.post(url)
      const response: boolean = status
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
