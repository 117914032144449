import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { QueryParams, BonusAccount } from './interfaces'
import { defaultData, FormError, ResponseData, ValidatorParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'bonusAccount',
  stateFactory: true,
  namespaced: true
})
export default class BonusAccountModule extends VuexModule {
  /**
   * * Массив аккаунтов бонусов и пагинатор
   */
  bonusAccountsValue: ResponseData<BonusAccount> = defaultData

  /**
   * * Аккаунт бонусов
   */
  bonusAccountValue: BonusAccount = {
    name: '',
    percent: 0
  }

  // ? ______________ getters ______________

  /**
   * * Получить массив аккаунтов бонусов и пагинатор
   */
  get bonusAccounts (): ResponseData<BonusAccount> {
    return this.bonusAccountsValue
  }

  /**
   * * Получить аккаунт бонусов по id
   */
  get bonusAccountById () {
    const bonusAccounts = this.bonusAccountsValue.data
    return function (id: number): BonusAccount | undefined {
      return bonusAccounts.find(bonusAccount => bonusAccount.id === id)
    }
  }

  /**
   * * Получить аккаунт бонусов
   */
  get bonusAccount (): BonusAccount {
    return this.bonusAccountValue
  }

  get bonusAccountValidators (): ValidatorParams {
    return {
      name: [{
        required: true,
        message: 'Введите название бонусного аккаунта',
        trigger: ['change', 'blur']
      }],
      percent: [{
        required: true,
        type: 'number',
        min: 0,
        max: 100,
        message: 'Введите число от 0 до 100',
        trigger: ['change', 'blur']
      }]
    }
  }

  // ? ______________ setters ______________

  /**
   * * Установить массив бонусов и пагинатор
   * @param bonusAccounts - массив бонусов и пагинатор
   */
  @Mutation
  setBonusAccounts (bonusAccounts: ResponseData<BonusAccount>) {
    this.bonusAccountsValue = bonusAccounts
  }

  /**
   * * Сбросить массив бонусов и пагинатор
   */
  @Mutation
  resetBonusAccounts (): void {
    this.bonusAccountsValue = defaultData
  }

  /**
   * * Установить аккаунт бонусов
   * @param bonusAccount - аккаунт бонусов
   */
  @Mutation
  setBonusAccount (bonusAccount: BonusAccount) {
    this.bonusAccountValue = bonusAccount
  }

  /**
   * * Сбросить аккаунт бонусов
   */
  @Mutation
  resetBonusAccount (): void {
    this.bonusAccountValue = {
      name: '',
      percent: 0
    }
  }

  // ? ______________ actions ______________

  /**
   * * Запрос на получение списка аккаунтов бонусов
   * @param queryParams - параметры пагинации
   * @returns массив аккаунтов бонусов и пагинатор
   */
  @Action({
    rawError: true
  })
  async getBonusAccounts (queryParams: QueryParams | null = null): Promise<ResponseData<BonusAccount>> {
    try {
      const { data } = await $axios.get('/bonus/accounts', { params: { ...queryParams } })
      const response: ResponseData<BonusAccount> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать аккаунт бонусов
   * @returns аккаунт бонусов
   */
  @Action({
    rawError: true
  })
  async createBonusAccount (): Promise<BonusAccount> {
    try {
      const { data: { data } } = await $axios.post('/bonus/accounts', this.bonusAccount)
      const response: BonusAccount = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение аккаунта бонусов по id
   * @param id - id аккаунта бонусов
   * @returns аккаунт бонусов
   */
  @Action({
    rawError: true,
    commit: 'setBonusAccount'
  })
  async getBonusAccountById (id: number): Promise<BonusAccount> {
    try {
      const { data: { data } } = await $axios.get(`/bonus/accounts/${id}`)
      const response: BonusAccount = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменение аккаунта бонусов
   * @returns аккаунт бонусов
   */
  @Action({
    rawError: true
  })
  async editBonusAccount (): Promise<BonusAccount> {
    try {
      const { id, ...bonusAccount } = this.bonusAccount
      const { data: { data } } = await $axios.put(`/bonus/accounts/${id}`, bonusAccount)
      const response: BonusAccount = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удаление аккаунта бонусов
   * @param id - id аккаунта бонусов
   * @returns аккаунт бонусов
   */
  @Action({
    rawError: true
  })
  async removeBonusAccount (id: number): Promise<BonusAccount> {
    try {
      const { data: { data } } = await $axios.delete(`/bonus/accounts/${id}`)
      const response: BonusAccount = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
