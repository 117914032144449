import { WordReplacesPlugin, FiltersParams, WordReplace } from './interfaces'
import WordReplacesStore from './store'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'
export default function getPluginApi (wordReplacesStore: WordReplacesStore) {
  const api: WordReplacesPlugin = {
    // ? ________ getters ________

    // * Получить список wordReplaces и пагинатор
    get wordReplacesList (): ResponseData<WordReplace> {
      return wordReplacesStore.wordReplacesList
    },

    get filters (): FiltersParams {
      return wordReplacesStore.filters
    },

    get validators (): ValidatorParams {
      return wordReplacesStore.validators
    },

    // * Получить wordReplace по id из массва wordReplaces
    wordReplaceById (id: number): WordReplace | undefined {
      return wordReplacesStore.wordReplaceById(id)
    },

    // * Получить текущий wordReplace
    get currentWordReplace (): WordReplace {
      return wordReplacesStore.currentWordReplace
    },

    // ? ________ setters ________

    // * Установить список wordReplaces и пагинатор
    set wordReplacesList (wordReplaces: ResponseData<WordReplace>) {
      wordReplacesStore.setWordReplacesList(wordReplaces)
    },

    set filters (filters: FiltersParams) {
      wordReplacesStore.setFilters(filters)
    },

    resetFilters () {
      wordReplacesStore.resetFilters()
    },

    // * Очистить форму
    resetCurrentWordReplace () {
      wordReplacesStore.resetCurrentWordReplace()
    },

    resetWordReplaces () {
      wordReplacesStore.resetWordReplaces()
    },

    // * Установить текщий wordReplace
    set currentWordReplace (wordReplace: WordReplace) {
      wordReplacesStore.setCurrentWordReplace(wordReplace)
    },

    // ? ________ actions ________

    // * Запрос на получение списка wordReplaces и пагинатор
    getWordReplaces (pageParams: PageParams | null = null): Promise<ResponseData<WordReplace>> {
      return wordReplacesStore.getWordReplaces(pageParams)
    },

    // * Запрос на получение wordReplace по id
    getWordReplace (id: number): Promise<WordReplace> {
      return wordReplacesStore.getWordReplace(id)
    },

    // * Запрос на удаление wordReplace по id
    removeWordReplace (id: number): Promise<WordReplace> {
      return wordReplacesStore.removeWordReplace(id)
    },

    // * Запрос на изменение wordReplace
    editWordReplace (): Promise<WordReplace> {
      return wordReplacesStore.editWordReplace()
    },

    // * Запрос на создание wordReplace
    createWordReplace (): Promise<WordReplace> {
      return wordReplacesStore.createWordReplace()
    }
  }
  return api
}
