
import Vue from 'vue'
import { Component, Prop, Watch } from 'nuxt-property-decorator'
import pageSizes from '~/utils/page-sizes'

@Component
export default class Paginator extends Vue {
  /**
   * *________________ Props ______________________
   */
  @Prop({
    required: true,
    type: Object
  })
    paginationData!: any

  @Prop({
    required: false,
    type: Boolean,
    default: () => false
  })
    smallPaginator!: boolean

  @Prop({
    required: false,
    type: Boolean,
    default: () => false
  })
    hideOnSinglePage!: boolean

  /**
   * *________________ Data ______________________
   */
  paginator = { ...this.paginationData }
  pageSizes = pageSizes

  /**
   * *________________ Watch ______________________
   */
  @Watch('paginationData')
  onchangePaginationData () {
    this.paginator = { ...this.paginationData }
  }
}
