import { Filters, ListWaitingPlugin, WaitingItem } from './interfaces'
import { ResponseData, PageParams } from '~/store/interfaces'

export default function getPluginApi (listWaitingStore:any) {
  const api: ListWaitingPlugin = {
    // ? ______ getters ______ //

    /**
     * * Получить фильтры
     */
    get filters () {
      return listWaitingStore.filters
    },

    /**
     * * Получить лист ожидания
     */
    get waitingList () : ResponseData<WaitingItem> {
      return listWaitingStore.waitingList
    },

    // ? ______ setters ______ //

    /**
     * * Установить фильтры
     */
    set filters (filters: Filters) {
      listWaitingStore.setFilters(filters)
    },

    /**
     * * Сбросить фильтры
     */
    resetFilters () {
      listWaitingStore.resetFilters()
    },

    resetWaitingLists () {
      listWaitingStore.resetWaitingLists()
    },

    // ? ______ actions ______ //

    /**
     * * Запрос на получение
     * @param pageParams - номер страницы, размер страницы
     * @returns - лист ожидания
     */
    getWaitingList (pageParams: PageParams | null = null): Promise<ResponseData<WaitingItem>> {
      return listWaitingStore.getWaitingList(pageParams)
    }
  }

  return api
}
