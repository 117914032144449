import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { AxiosError } from 'axios'
import { FormError } from '~/store/interfaces'
import { MimeTypes } from '~/types/types'

/**
 * * Объект плагина Download (скачивания)
 */
export interface DownloadPlugin {
  /**
   * * Метод скачивания файла
   * @param data файл
   * @param options опции (название файла тип файла)
   * @returns void
   */
  downloadFile: (
    data: Blob,
    options: { name: string; type: keyof typeof MimeTypes }
  ) => void;

  downloadFileByLink: (link: string, name?: string) => Promise<void>;
}

export default (ctx: Context, inject: Inject) => {
  inject('download', {
    downloadFile: (
      data: Blob,
      options: { name: string; type: keyof typeof MimeTypes }
    ): void => {
      try {
        // * Создание элемента ссылки
        const linkElement = document.createElement('a')
        linkElement.download = options.name
        // * Создание объекта файла
        const file = new File([data], options.name, {
          type: MimeTypes[options.type]
        })
        // * Установка пути ссылки на объект ссылки
        linkElement.href = URL.createObjectURL(file)
        // * Внедрение элемента ссылки в body
        document.body.appendChild(linkElement)
        // * Вызов события клик ссылки
        linkElement.click()
        // * Удаление объекта ссылки
        URL.revokeObjectURL(linkElement.href)
        // * Удаление элемента ссылки
        document.body.removeChild(linkElement)
      } catch (e) {
        console.error(e)
      }
    },

    /**
     * * Метод скачивания файла по ссылке
     * @param link ссылка на файл
     * @returns void
     */
    downloadFileByLink: async (link: string, name?: string): Promise<void> => {
      try {
        // * Загрузка файла фида с помощью axios
        const file = (await ctx.$axios.get(link, { responseType: 'blob' }))
          ?.data as Blob
        // * Создание элемента ссылки
        const linkElement = document.createElement('a')
        // * Установка пути ссылки на объект ссылки
        linkElement.href = URL.createObjectURL(file)
        // * Установка названия файла
        linkElement.download = name || link
        // * Внедрение элемента ссылки в body
        document.body.appendChild(linkElement)
        // * Вызов события клик ссылки
        linkElement.click()
        // * Удаление объекта ссылки
        URL.revokeObjectURL(linkElement.href)
        // * Удаление элемента ссылки
        document.body.removeChild(linkElement)
      } catch (e) {
        console.error(e)
        throw new FormError(e as AxiosError<FormError>)
      }
    }
  })
}
