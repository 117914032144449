import { Vue, Component } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class ListWaitingMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$listWaiting.resetFilters()
    next()
  }

  /**
   * * Получить список листов ожиданий
   * @param pageParams - параметры пагинатора
   * @returns список листов ожидания
   */
  async getWaitingList (pageParams: PageParams) {
    try {
      this.$wait.start('getWaitingList')
      return await this.$listWaiting.getWaitingList(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getWaitingList')
    }
  }
}
