import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'
import defaultPageSize from '~/utils/page-sizes'

@Component
export default class SearchTextReplacesMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    next()
  }

  /**
   * * Запрос на searchTextReplace
   * @param pageParams параметры пагинатора (необязательный)
   * @returns список searchTextReplace
   */
  async getSearchTextReplaces (pageParams?: PageParams) {
    try {
      this.$wait.start('getSearchTextReplaces')
      return await this.$searchTextReplace.getSearchTextReplaces({
        page: +(pageParams?.page ?? 1),
        pageSize: +(pageParams?.pageSize ?? defaultPageSize.default),
        sort: pageParams?.sort,
        order: pageParams?.order
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getSearchTextReplaces') }
  }

  /**
   * * Удаление seo/searchTextReplace по id
   * @param id - id searchTextReplace
   * @returns searchTextReplace
   */
  async removeSearchTextReplace (id: number) {
    try {
      this.$wait.start('removeSearchTextReplace')
      const data = await this.$searchTextReplace.removeSearchTextReplace(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Склонение слова ${data.word} удалено`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removeSearchTextReplace') }
  }

  /**
   * * Создание seo/searchTextReplace
   * @returns searchTextReplace
   */
  async createSearchTextReplace () {
    try {
      this.$wait.start('createSearchTextReplace')
      const data = await this.$searchTextReplace.createSearchTextReplace()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Склонение слова ${data.word} создано`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('createSearchTextReplace') }
  }

  /**
   * * Изменение seo/searchTextReplace
   * @returns searchTextReplace
   */
  async editSearchTextReplace () {
    try {
      this.$wait.start('editSearchTextReplace')
      const data = await this.$searchTextReplace.editSearchTextReplace()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Склонение слова ${data.word} изменено`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('editSearchTextReplace') }
  }

  async generateSTR (optionIds: number[]) {
    try {
      this.$wait.start('generateSTR')
      const data = await this.$searchTextReplace.generateSTR(optionIds)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Синонимы сгенерированы'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('generateSTR')
    }
  }

  async generateSTRById (id: number) {
    try {
      this.$wait.start('generateSTRById')
      const data = await this.$searchTextReplace.generateSTRById(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Синонимы сгенерированы'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('generateSTRById')
    }
  }
}
