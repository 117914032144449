import { WordCasesPlugin, WordCase, Filters } from './interfaces'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (wordCasesStore:any) {
  const api: WordCasesPlugin = {
    // ? ________ getters ________
    // * Получить список wordCases и пагинатор
    get wordCasesList (): ResponseData<WordCase> {
      return wordCasesStore.wordCasesList
    },

    // * Получить wordCase по id из массва wordCases
    wordCaseById (id: number): WordCase | undefined {
      return wordCasesStore.wordCaseById(id)
    },

    // * Получить текущий wordCase
    get filters (): Filters {
      return wordCasesStore.filters
    },

    // * Получить текущий wordCase
    get currentWordCase (): WordCase {
      return wordCasesStore.currentWordCase
    },

    get validators (): ValidatorParams {
      return wordCasesStore.validators
    },

    // ? ________ setters ________

    // * Установить список wordCases и пагинатор
    set wordCasesList (wordCases: ResponseData<WordCase>) {
      wordCasesStore.setWordCasesList(wordCases)
    },

    // * Получить текущий wordCase
    set filters (filters: Filters) {
      wordCasesStore.setFilters(filters)
    },

    // * Очистить форму
    resetCurrentWordCase () {
      wordCasesStore.resetCurrentWordCase()
    },

    resetWordCases () {
      wordCasesStore.resetWordCases()
    },

    // * Установить текщий wordCase
    set currentWordCase (wordCase: WordCase) {
      wordCasesStore.setCurrentWordCase(wordCase)
    },

    // ? ________ actions ________

    // * Запрос на получение списка wordCases и пагинатор
    getWordCases (pageParams: PageParams | null = null): Promise<ResponseData<WordCase>> {
      return wordCasesStore.getWordCases(pageParams)
    },

    // * Запрос на получение wordCase по id
    getWordCase (id: number): Promise<WordCase> {
      return wordCasesStore.getWordCase(id)
    },

    // * Запрос на удаление wordCase по id
    removeWordCase (id: number): Promise<WordCase> {
      return wordCasesStore.removeWordCase(id)
    },

    // * Запрос на изменение wordCase
    editWordCase (): Promise<WordCase> {
      return wordCasesStore.editWordCase()
    },

    // * Запрос на создание wordCase
    createWordCase (): Promise<WordCase> {
      return wordCasesStore.createWordCase()
    }
  }
  return api
}
