import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'
import defaultPageSize from '~/utils/page-sizes'

@Component
export default class WordCasesMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$wordCases.resetCurrentWordCase()
    next()
  }

  /**
   * * Запрос на wordCases
   * @param pageParams параметры пагинатора (необязательный)
   * @returns список wordCases
   */
  async getWordCases (pageParams?: PageParams) {
    try {
      this.$wait.start('getWordCases')
      return await this.$wordCases.getWordCases({
        page: +(pageParams?.page ?? 1),
        pageSize: +(pageParams?.pageSize ?? defaultPageSize.default),
        sort: pageParams?.sort,
        order: pageParams?.order
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getWordCases') }
  }

  /**
   * * Удаление seo/wordCase по id
   * @param id - id wordCase
   * @returns wordCase
   */
  async removeWordCase (id: number) {
    try {
      this.$wait.start('removeWordCase')
      const data = await this.$wordCases.removeWordCase(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Склонение слова ${data.word} удалено`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removeWordCase') }
  }

  /**
   * * Создание seo/wordCase
   * @returns wordCase
   */
  async createWordCase () {
    try {
      this.$wait.start('createWordCase')
      const data = await this.$wordCases.createWordCase()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Склонение слова ${data.word} создано`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('createWordCase') }
  }

  /**
   * * Изменение seo/wordCase
   * @returns wordCase
   */
  async editWordCase () {
    try {
      this.$wait.start('editWordCase')
      const data = await this.$wordCases.editWordCase()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Склонение слова ${data.word} изменено`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('editWordCase') }
  }
}
