import { DeliveryGroup, DeliveryGroupsPlugin, Filters } from './interfaces'
import DeliveryGroupsModule from './store'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (store: DeliveryGroupsModule) {
  const api: DeliveryGroupsPlugin = {

    get filters (): Filters {
      return store.filters
    },

    get deliveryGroups (): ResponseData<DeliveryGroup> {
      return store.deliveryGroups
    },

    get deliveryGroup (): DeliveryGroup {
      return store.deliveryGroup
    },

    deliveryGroupById (id: number): DeliveryGroup | undefined {
      return store.deliveryGroupById(id)
    },

    get validators (): ValidatorParams {
      return store.validators
    },

    // ? ________ setters ________

    set filters (filters: Filters) {
      store.setFilters(filters)
    },

    resetFilters () {
      store.resetFilters()
    },

    set deliveryGroups (deliveryGroups: ResponseData<DeliveryGroup>) {
      store.setDeliveryGroups(deliveryGroups)
    },

    set deliveryGroup (deliveryGroup: DeliveryGroup) {
      store.setDeliveryGroup(deliveryGroup)
    },

    resetDeliveryGroup () {
      store.resetDeliveryGroup()
    },

    resetDeliveryGroups () {
      store.resetDeliveryGroups()
    },

    // ? ________ actions ________

    async getDeliveryGroups (pageParams: PageParams | null = null): Promise<ResponseData<DeliveryGroup>> {
      return await store.getDeliveryGroups(pageParams)
    },

    async getDeliveryGroupsAll (pageParams: PageParams | null = null): Promise<ResponseData<DeliveryGroup>> {
      return await store.getDeliveryGroupsAll(pageParams)
    },

    async getDeliveryGroupById (id: number) {
      return await store.getDeliveryGroupById(id)
    },

    createDeliveryGroup () {
      return store.createDeliveryGroup()
    },

    editDeliveryGroup () {
      return store.editDeliveryGroup()
    },

    removeDeliveryGroup (id: number) {
      return store.removeDeliveryGroup(id)
    }
  }

  return api
}
