import { Vue, Component } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class EmailTemplateGroupsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$emailTemplateGroups.resetCurrentGroup()
    next()
  }

  async getEmailTemplateGroups (pageParams?: PageParams) {
    this.$wait.start('getEmailTemplateGroups')
    try {
      return await this.$emailTemplateGroups.getEmailTemplateGroups(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getEmailTemplateGroups')
    }
  }

  async getEmailTemplateGroupById (id: number) {
    this.$wait.start('getEmailTemplateGroupById')
    try {
      return await this.$emailTemplateGroups.getEmailTemplateGroupById(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getEmailTemplateGroupById')
    }
  }

  async createEmailTemplateGroup () {
    this.$wait.start('createEmailTemplateGroup')
    try {
      const data = await this.$emailTemplateGroups.createEmailTemplateGroup()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Группа шаблонов ${data.name} создана`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createEmailTemplateGroup')
    }
  }

  async editEmailTemplateGroup () {
    this.$wait.start('editEmailTemplateGroup')
    try {
      const data = await this.$emailTemplateGroups.editEmailTemplateGroup()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Группа шаблонов ${data.name} изменена`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editEmailTemplateGroup')
    }
  }

  async removeEmailTemplateGroup (id: number) {
    this.$wait.start('removeEmailTemplateGroup')
    try {
      const data = await this.$emailTemplateGroups.removeEmailTemplateGroup(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Группа шаблонов ${data.name} удалена`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeEmailTemplateGroup')
    }
  }
}
