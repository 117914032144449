import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { Param, PriceRole, PriceRoleParam } from './interfaces'
import { FormError, PageParams, ResponseData, defaultData, ValidatorParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'priceRoles',
  stateFactory: true,
  namespaced: true
})
export default class PriceRolesModule extends VuexModule {
  /**
   * * Ценовые роли
   */
  priceRolesValue: ResponseData<PriceRole> = defaultData

  /**
   * * Ценовая роль
   */
  priceRoleValue: PriceRole = {
    name: '',
    title: ''
  }

  /**
   * * Значения параметров ценовых ролей
   */
  priceRolesParamsValue: Param[] = []

  /**
   * * Параметры текущей роли
   */
  currentParamsValue: PriceRoleParam[] = []

  // ? ______________ getters ______________

  /**
   * * Получить список ценовых ролей
   */
  get priceRoles (): ResponseData<PriceRole> {
    return this.priceRolesValue
  }

  /**
   * * Получить ценовую роль
   */
  get priceRole (): PriceRole {
    return this.priceRoleValue
  }

  /**
   * * Получить значения параметров ценовых ролей
   */
  get priceRolesParams (): Param[] {
    return this.priceRolesParamsValue
  }

  /**
   * * Получить параметры текущей роли
   */
  get currentParams (): PriceRoleParam[] {
    return this.currentParamsValue
  }

  /**
   * * Получить ценовую роль по id
   */
  get priceRoleById () {
    const priceRoles = this.priceRoles
    return function (id: number) {
      return priceRoles.data.find(priceRole => priceRole.id === id)
    }
  }

  get priceRoleByName () {
    const priceRoles = this.priceRoles
    return function (name: string) {
      return priceRoles.data.find(priceRole => priceRole.name === name)
    }
  }

  /**
   * * валидна ли форма
   */
  get validators (): ValidatorParams {
    return {
      title: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите название ценовой роли', trigger: ['blur'] }],
      name: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите код ценовой роли', trigger: ['blur'] }]
    }
  }

  // ? ______________ setters ______________

  /**
   * * Установить список ценовых ролей
   * @param priceRoles - список ценовых ролей
   */
  @Mutation
  setPriceRoles (priceRoles: ResponseData<PriceRole>) {
    this.priceRolesValue = priceRoles
  }

  /**
   * * Установить ценовую роль
   * @param priceRole - ценовая роль
   */
  @Mutation
  setPriceRole (priceRole: PriceRole) {
    this.priceRoleValue = priceRole
  }

  /**
   * * Установить значения параметров ценовых ролей
   * @param priceRolesParams - значения параметров ценовых ролей
   */
  @Mutation
  setPriceRolesParams (priceRolesParams: Param[]) {
    this.priceRolesParamsValue = priceRolesParams
  }

  /**
   * * Установить параметры текущей роли
   * @param currentParams - параметры текущей роли
   */
  @Mutation
  setCurrentParams (currentParams: PriceRoleParam[]) {
    this.currentParamsValue = currentParams
  }

  /**
   * * Сбросить ценовую роль
   */
  @Mutation
  resetPriceRole () {
    this.priceRoleValue = {
      name: '',
      title: ''
    }
    this.currentParamsValue = []
  }

  /**
   * * Сбросить список ролей
   */
  @Mutation
  resetPriceRoles () {
    this.priceRolesValue = defaultData
    this.priceRolesParamsValue = []
  }

  // ? ______________ setters ______________

  /**
   * * Запрос на получение списка ценовых ролей
   * @param pageParams - параметры пагинации
   */
  @Action({
    rawError: true,
    commit: 'setPriceRoles'
  })
  async getPriceRoles (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/shop/price-roles', { params: pageParams })
      const response: ResponseData<PriceRole> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение ценовой роли по id
   * @param id id ценовой роли
   */
  @Action({
    rawError: true,
    commit: 'setPriceRole'
  })
  async getPriceRoleById (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/shop/price-roles/${id}`)
      const response: PriceRole = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание ценовой роли
   */
  @Action({
    rawError: true
  })
  async createPriceRole (params: any) {
    try {
      const { data: { data } } = await $axios.post('/shop/price-roles', params)
      const response: PriceRole = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение ценовой роли
   */
  @Action({
    rawError: true
  })
  async editPriceRole () {
    try {
      const { id, ...priceRole } = this.priceRoleValue
      const updatedParams = {
        ...priceRole,
        params: priceRole.params.map(({ paramName, paramValue }: any) => {
          return { paramName, paramValue }
        })
      }
      const { data: { data } } = await $axios.put(`/shop/price-roles/${id}`, updatedParams)
      const response: PriceRole = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на удаление ценовой роли
   * @param id id ценовой роли
   */
  @Action({
    rawError: true
  })
  async removePriceRole (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/shop/price-roles/${id}`)
      const response: PriceRole = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на параметры ценовых ролей
   */
  @Action({
    rawError: true,
    commit: 'setPriceRolesParams'
  })
  async getPriceRolesParams () {
    try {
      const { data: { data } } = await $axios.get('/shop/price-roles/params')
      const response: Param[] = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на параметры текущей роли
   */
  @Action({
    rawError: true,
    commit: 'setCurrentParams'
  })
  async getCurrentParams () {
    try {
      const { data: { data } } = await $axios.get(`/shop/price-roles/${this.priceRole.id}/params`)
      const response: PriceRoleParam[] = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание параметра текущей роли
   * @param newParam новый параметр
   */
  @Action({
    rawError: true
  })
  async createPriceRoleParam (newParam: PriceRoleParam) {
    try {
      const { data: { data } } = await $axios.post(`/shop/price-roles/${this.priceRole.id}/params`, newParam)
      const response: PriceRoleParam = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение параметра текущей роли
   * @param param новый параметр
   */
  @Action({
    rawError: true
  })
  async editPriceRoleParam (param: PriceRoleParam) {
    const { id, ...newParam } = param
    try {
      const { data: { data } } = await $axios.put(`/shop/price-roles/${this.priceRole.id}/params/${id}`, newParam)
      const response: PriceRoleParam = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на удаление параметра текущей роли
   * @param id id параметра
   */
  @Action({
    rawError: true
  })
  async deletePriceRoleParam (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/shop/price-roles/${this.priceRole.id}/params/${id}`)
      const response: PriceRoleParam = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
