import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ResponseData, PageParams, ValidatorParams } from '../../interfaces'
import { Filters, Region } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'regions',
  stateFactory: true,
  namespaced: true
})
export default class RegionsModule extends VuexModule {
  /**
   * * Значения фильтров регионов
   */
  filtersValue: Filters = {
    name: undefined
  }

  /**
   * * Массив всех region исходя из запроса
   */
  regions: ResponseData<Region> = defaultData

  /**
   * * Текущая region
   */
  region: Region = {
    name: '',
    x: 55,
    y: 63,
    zoom: 5,
    shops: []
  }

  // ? ______________ getters ______________
  /**
   * * Получить фильтры регионов
   */
  get filters (): Filters {
    return this.filtersValue
  }

  /**
   * * Получить массив regions и пагинацией
   */
  get regionsList (): ResponseData<Region> {
    return this.regions
  }

  /**
   * * валидна ли форма
   */
  get validators (): ValidatorParams {
    return {
      name: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите название региона', trigger: ['blur'] }]
    }
  }

  /**
   * * Получить region из массива regions
   */
  get regionById () {
    const regions = this.regions
    return function (id: number): Region | undefined {
      return regions.data.find(region => region.id === id)
    }
  }

  /**
   * * Получить текущую region для измения или создания region
   */
  get currentRegion () {
    return this.region
  }

  // ? ______________ setters ______________
  /**
   * * Установить фильтры регионов
   * @param filters - значения фильтров
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  /**
   * * Сбросить фильтры регионов
   */
  @Mutation
  resetFilters () {
    this.filtersValue = {
      name: undefined
    }
  }

  /**
   * * Установить массив Regions
   * @param regions массив Regions
   */
  @Mutation
  setRegionsList (regions: ResponseData<Region>) {
    this.regions = regions
  }

  /**
   * * Установить CurrentRegion для измения или создания region
   * @param region текущая Region, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentRegion (region: Region) {
    this.region = region
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentRegion () {
    this.region = {
      name: '',
      x: 55,
      y: 63,
      zoom: 5,
      shops: []
    }
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetRegionsList () {
    this.regions = defaultData
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список Regions по параметрам запроса
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setRegionsList'
  })
  async getRegions (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/points/regions', { params: { ...pageParams, ...this.filters } })
      const response: ResponseData<Region> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить region по id
   * @param id id region которую мы хотим получить
   */
  @Action({
    rawError: true,
    commit: 'setCurrentRegion'
  })
  async getRegion (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/points/regions/${id}`)
      const response: Region = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить region
   */
  @Action({
    rawError: true
  })
  async editRegion () {
    try {
      const { id } = this.currentRegion
      const params = { name: this.currentRegion.name, x: this.currentRegion.x, y: this.currentRegion.y, zoom: this.currentRegion.zoom }
      const { data: { data } } = await $axios.put(`/points/regions/${id}`, params)
      const response: Region = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать Region
   */
  @Action({
    rawError: true
  })
  async createRegion () {
    try {
      const params = { name: this.currentRegion.name, x: this.currentRegion.x, y: this.currentRegion.y, zoom: this.currentRegion.zoom }
      const { data: { data } } = await $axios.post('/points/regions', params)
      const response: Region = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить region по id
   * @param id  id region которую мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removeRegion (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/points/regions/${id}`)
      const response: Region = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
