import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, PageParams, ResponseData } from '../../interfaces'
import { Basket, Filters } from './interfaces'
import { $axios } from '~/utils/api'
import { dateOneDayLater } from '~/utils/date'
@Module({
  name: 'baskets',
  stateFactory: true,
  namespaced: true
})

export default class BasketsModule extends VuexModule {
  /**
   * * Массив всех basket исходя из запроса
   */
  baskets: ResponseData<Basket> = defaultData

  filtersValues: Filters = {
    name: undefined,
    email: undefined,
    phone: undefined,
    createdAtFrom: undefined,
    createdAtTo: undefined,
    updatedAtFrom: undefined,
    updatedAtTo: undefined
  }

  // ? ______________ getters ______________
  /**
   * * Получить массив baskets и пагинацией
   */
  get basketsList (): ResponseData<Basket> {
    return this.baskets
  }

  /**
   * * Получить filters
   */
  get filters (): Filters {
    return this.filtersValues
  }

  // ? ______________ setters ______________
  /**
   * * Установить массив Baskets
   * @param baskets массив Baskets
   */
  @Mutation
  setBasketsList (baskets: ResponseData<Basket>) {
    this.baskets = baskets
  }

  /**
   * * Обнулить список корзин
   */
  @Mutation
  resetBaskets () {
    this.baskets = defaultData
  }

  /**
    * * Обнулить фильтры
    */
  @Mutation
  resetFilters () {
    this.filtersValues = {
      name: undefined,
      email: undefined,
      phone: undefined,
      createdAtFrom: undefined,
      createdAtTo: undefined,
      updatedAtFrom: undefined,
      updatedAtTo: undefined
    }
  }

  /**
   * * Установить filters
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValues = filters
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список Baskets по параметрам запроса
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setBasketsList'
  })
  async getBaskets (pageParams: PageParams) {
    try {
      const { data } = await $axios.get('/shop/baskets', {
        params: {
          ...pageParams,
          ...this.filtersValues,
          updatedAtFrom: this.filtersValues.updatedAtFrom ? this.filtersValues.updatedAtFrom : undefined,
          updatedAtTo: this.filtersValues.updatedAtTo ? dateOneDayLater(this.filtersValues.updatedAtTo) : undefined,
          createdAtFrom: this.filtersValues.createdAtFrom ? this.filtersValues.createdAtFrom : undefined,
          createdAtTo: this.filtersValues.createdAtTo ? dateOneDayLater(this.filtersValues.createdAtTo) : undefined
        }
      })
      const response: ResponseData<Basket> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
