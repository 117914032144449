import { Component, Vue } from 'nuxt-property-decorator'
import { BaseStoreModules } from '~/store'

/**
 * Миксин для компонентов с запросами. Содержит обработчик вызова метода запроса.
 */
@Component({
  name: 'PageTemplatesMixin'
})
export default class StoreMixin extends Vue {
  protected async asyncRequestHandler<T> (loader: string, cb: (...args: any[]) => Promise<T>, ...args: any[]) {
    try {
      // Запуск лоадера
      this.$wait.start(loader)
      // Вызов метода запроса
      return await cb.apply(this, args)
    } catch (e: any) {
      console.error(e)
      // Вывод ноитифая об ошибке
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      // Остановка лоадера
      this.$wait.end(loader)
    }
  }

  protected statesReset (pluginKey: keyof BaseStoreModules) {
    this[pluginKey].resetItem()
    this[pluginKey].resetList()
    this[pluginKey].resetFilters()
  }
}
