import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { PriceRoleAssignment } from './interfaces'
import { defaultData, FormError, PageParams, ResponseData } from '~/store/interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'priceRoleAssignment',
  stateFactory: true,
  namespaced: true
})
export default class PriceRoleAssignmentModule extends VuexModule {
  /**
   * * Список связей ценовых ролей
   */
  priceRoleAssignmentsValue: ResponseData<PriceRoleAssignment> = defaultData

  /**
   * * Связь ценовых ролей
   */
  priceRoleAssignmentValue: PriceRoleAssignment = {
    userRole: '',
    priceRole: ''
  }

  // ? ______________ getters ______________

  /**
   * * Получить список связей ценовых ролей
   */
  get priceRoleAssignments (): ResponseData<PriceRoleAssignment> {
    return this.priceRoleAssignmentsValue
  }

  /**
   * * Получить свядь ценовых ролей
   */
  get priceRoleAssignment (): PriceRoleAssignment {
    return this.priceRoleAssignmentValue
  }

  /**
   * * Получить связь ценовых ролей по id
   */
  get priceRoleAssignmentById () {
    const priceRoleAssignments = this.priceRoleAssignments
    return function (id: number): PriceRoleAssignment | undefined {
      return priceRoleAssignments.data.find(priceRoleAssignment => priceRoleAssignment.id === id)
    }
  }

  // ? ______________ setters ______________

  /**
   * * Установить список связей ценовых ролей
   */
  @Mutation
  setPriceRoleAssignments (priceRoleAssignments: ResponseData<PriceRoleAssignment>) {
    this.priceRoleAssignmentsValue = priceRoleAssignments
  }

  /**
   * * Уставноить связь ценовых ролей
   */
  @Mutation
  setPriceRoleAssignment (priceRoleAssignment: PriceRoleAssignment) {
    this.priceRoleAssignmentValue = priceRoleAssignment
  }

  /**
   * * Сбросить связь ценовых ролей
   */
  @Mutation
  resetPriceRoleAssignment () {
    this.priceRoleAssignmentValue = {
      userRole: '',
      priceRole: ''
    }
  }

  @Mutation
  resetPriceRoleAssignments () {
    this.priceRoleAssignmentsValue = defaultData
  }

  // ? ______________ actions ______________

  /**
   * * Запрос на получение списка связей ценовых ролей
   * @param pageParams параметры пагинации
   */
  @Action({
    rawError: true,
    commit: 'setPriceRoleAssignments'
  })
  async getPriceRoleAssignments (pageParams: PageParams | null = null): Promise<ResponseData<PriceRoleAssignment>> {
    try {
      const { data } = await $axios.get('/shop/price-role-assignment', { params: pageParams })
      const response: ResponseData<PriceRoleAssignment> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение связи ценовых ролей по id
   */
  @Action({
    rawError: true,
    commit: 'setPriceRoleAssignment'
  })
  async getPriceRoleAssignmentById (id: number): Promise<PriceRoleAssignment> {
    try {
      const { data: { data } } = await $axios.get(`/shop/price-role-assignment/${id}`)
      const response: PriceRoleAssignment = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание связи ценовых ролей
   */
  @Action({
    rawError: true
  })
  async createPriceRoleAssignment (): Promise<PriceRoleAssignment> {
    try {
      const { data: { data } } = await $axios.post('/shop/price-role-assignment', this.priceRoleAssignmentValue)
      const response: PriceRoleAssignment = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение связи ценовых ролей
   * @param id id связи ценовых ролей
   */
  @Action({
    rawError: true
  })
  async editPriceRoleAssignment (): Promise<PriceRoleAssignment> {
    try {
      const { id, ...priceRoleAssignment } = this.priceRoleAssignment
      const { data: { data } } = await $axios.put(`/shop/price-role-assignment/${id}`, priceRoleAssignment)
      const response: PriceRoleAssignment = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на удаление связи ценовых ролей
   * @param id id связи ценовых ролей
   */
  @Action({
    rawError: true
  })
  async removePriceRoleAssignment (id: number): Promise<PriceRoleAssignment> {
    try {
      const { data: { data } } = await $axios.delete(`/shop/price-role-assignment/${id}`)
      const response: PriceRoleAssignment = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
