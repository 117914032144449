
import { Component, mixins, Watch } from 'nuxt-property-decorator'
import { menuItems } from '~/utils/routes'

import Mixins from '~/store/mixins'
import { ConfigIds } from '~/store/modules/config/interfaces'

@Component({
  mixins: [Mixins.ReindexerMixin]
})
export default class HeaderBlock extends mixins(Mixins.ReindexerMixin) {
  /**
   * *________________ Data ______________________
   */
  dialogVisible = false
  showBreadcrumbs = true
  exportButtons = []

  /**
   * *________________ Computed ______________________
   */
  get breadcrumbs () {
    return this.$store.getters['breadcrumbs/breadcrumbs']
  }

  get notViewedErrors () {
    return this.$errorLogs.errorLogs.filter(error => !error.viewed)
  }

  /**
   * *________________ Watch ______________________
   */
  @Watch('$route.path')
  onChangePage () {
    this.createBreadcrumbs()
  }

  /**
   * *________________ Created ______________________
   */
  created () {
    this.createBreadcrumbs()
  }

  /**
   * *________________ Mounted ______________________
   */
  async mounted () {
    try {
      this.exportButtons = (await this.$configsList.getConfig(ConfigIds['admin-menu-buttons'], false))?.value
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * *________________ Methods ______________________
   */
  handleCommand (e: string) {
    if (e.startsWith('exportReindexer')) {
      // @ts-ignore
      return this.exportReindexer(e.replace('exportReindexer|', ''))
    }
    // @ts-ignore
    this[e]()
  }

  openProfile () {
    this.$router.push('/profile')
  }

  exportReindexer (url: string) {
    if (this.$wait.is(url)) {
      this.$alert('Это может занять некоторое время', 'Выгрузка в reindexer уже идет')
      return
    }
    this.$confirm('Вы уверены, что хотите запустить выгрузку? Она может занять продолжительное время.', 'Предупреждение', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Отмена',
      type: 'warning'
    }).then((_) => {
      this.reindexerExport(url)
    }).catch((_) => {})
  }

  createBreadcrumbs () {
    if (this.$route.path.length > 1) {
      let links: any[] = this.$route.path.split('/') // разделяем строку на /
      let headerItem: {item?: { link: string, value: string},
        subItems: {link:string, value: string}[] } = { subItems: [] }
      links = links.map((link) => {
        if (!link) {
          return {
            link: '',
            title: 'Главная'
          }
          // если link пустой значит главная '/''
        } else {
          // проверка сначала в обьект items если есть записываем его и записываем в headerItem его
          // для поиска уже из него
          return menuItems.map((linkItem) => {
            // проверяем есть ли headerItem.item если его нет,
            // то проходимся по item
            if (linkItem.item.link === link && !headerItem.item) {
              headerItem = { item: linkItem.item, subItems: linkItem.subItems ?? [] }
              return {
                link: linkItem?.item?.link,
                title: linkItem?.item?.value
              }
            } else if (headerItem.subItems.length) { // иначе проходимся по subItems в headerItem
              const linkType = headerItem.subItems?.find(linkSubItems =>
                linkSubItems?.link === link)
              if (linkType) {
                // если ссылка находиться вставляем его, если появитьсы саб меню у саб меню,
                // то присваивать этот обьект к headerItem
                headerItem.subItems = []
                return {
                  link: linkType.link,
                  title: linkType.value
                }
              }
            }
            // фильтруем значения если данного значения нет то тайтл делаем путем
          }).filter(el =>
            el?.title)[0] ?? { link, title: link === 'profile' ? 'Профиль' : link }
        }
      })
      if (/\/seo\/search-logs\/.+/.test(this.$route.path)) {
        links.pop()
      }
      for (const i in links) {
        if (+i > 0) {
          // генерируем ссылки
          links[+i].link = links[+i - 1]?.link + '/' + links[+i].link
        }
      }
      links[0].link = '/'
      this.$store.commit('breadcrumbs/setBreadcrumbs', links) // запись в стор
    } else {
      this.$store.commit('breadcrumbs/setBreadcrumbs', [{
        link: '/',
        title: 'Главная'
      }]) // запись в стор
    }
  }

  async logout () {
    await this.$auth.logout()
    this.$cookies.remove('prevLink')
    // this.$router.push('/signin')
  }
}
