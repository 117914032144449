

import Vue from 'vue'
import { Component } from 'nuxt-property-decorator'
import UiTable from '~/components/ui/ui-table/index.vue'
import FormatterDate from '~/utils/formatter-date'
@Component({
  components: {
    UiTable
  }
})
export default class ErrorLog extends Vue {
  /**
   * *________________ Methods ______________________
   */
  copy (row: any) {
    const value = `Код ошибки: ${row.error_code}\nВремя ошибки: ${this.formattedDate(row.error_time)}\nURL: ${row.error_req_url}\nСообщение: ${row.error_message}`
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(value)
    } else {
      const dummy = document.createElement('textarea')
      document.body.appendChild(dummy)
      dummy.value = value
      dummy.select()
      document.execCommand('copy')
      document.body.removeChild(dummy)
    }
    this.$message('Скопировано в буфер обмена')
  }

  formattedDate (date: number) {
    return FormatterDate(date / 1000)
  }
}
