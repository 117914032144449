import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { QueryParams, BonusType } from './interfaces'
import { defaultData, FormError, ResponseData, ValidatorParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'bonusType',
  stateFactory: true,
  namespaced: true
})
export default class BonusTypeModule extends VuexModule {
  /**
   * * Массив типов бонусов и пагинатор
   */
  bonusTypesValue: ResponseData<BonusType> = defaultData

  /**
   * * Тип бонусов
   */
  bonusTypeValue: BonusType = {
    name: '',
    code: '',
    params: []
  }

  // ? ______________ getters ______________

  /**
   * * Получить массив типов бонусов и пагинатор
   */
  get bonusTypes (): ResponseData<BonusType> {
    return this.bonusTypesValue
  }

  /**
   * * Получить тип бонусов
   */
  get bonusType (): BonusType {
    return this.bonusTypeValue
  }

  /**
   * * Получить тип бонусов по id
   */
  get bonusTypeById () {
    const bonusTypes = this.bonusTypesValue.data
    return function (id: number): BonusType | undefined {
      return bonusTypes.find(bonusType => bonusType.id === id)
    }
  }

  get bonusTypeValidators (): ValidatorParams {
    return {
      name: [{
        required: true,
        message: 'Введите название типа бонусов',
        trigger: ['change', 'blur']
      }],
      code: [{
        required: true,
        message: 'Введите код типа бонусов',
        trigger: ['change', 'blur']
      }]
    }
  }

  // ? ______________ setters ______________

  /**
   * * Установить список типов бонусов
   * @param bonusTypes - массив типов бонусов и пагинатор
   */
  @Mutation
  setBonusTypes (bonusTypes: ResponseData<BonusType>) {
    this.bonusTypesValue = bonusTypes
  }

  /**
   * * Сбросить список типов
   */
  @Mutation
  resetBonusTypes (): void {
    this.bonusTypesValue = defaultData
  }

  /**
   * * Установить тип бонусов
   * @param bonusType - тип бонусов
   */
  @Mutation
  setBonusType (bonusType: BonusType) {
    this.bonusTypeValue = bonusType
  }

  /**
   * * Сбросить тип бонусов
   */
  @Mutation
  resetBonusType (): void {
    this.bonusTypeValue = {
      name: '',
      code: '',
      params: []
    }
  }

  // ? ______________ actions ______________

  /**
   * * Запрос на получение списка типов бонусов
   * @param queryParams - параметры пагинации
   * @returns - массив типов бонусов и пагинатор
   */
  @Action({
    rawError: true
  })
  async getBonusTypes (queryParams: QueryParams | null = null): Promise<ResponseData<BonusType>> {
    try {
      const { data } = await $axios.get('/bonus/types', { params: queryParams })
      const response: ResponseData<BonusType> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание типа бонусов
   * @returns тип бонусов
   */
  @Action({
    rawError: true
  })
  async createBonusType (): Promise<BonusType> {
    try {
      const { data: { data } } = await $axios.post('/bonus/types', this.bonusType)
      const response: BonusType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение типа бонусов по id
   * @param id - id типа бонусов
   * @returns тип бонусов
   */
  @Action({
    rawError: true,
    commit: 'setBonusType'
  })
  async getBonusTypeById (id: number): Promise<BonusType> {
    try {
      const { data: { data } } = await $axios.get(`/bonus/types/${id}`)
      const response: BonusType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение типа бонусов
   * @returns тип бонусов
   */
  @Action({
    rawError: true
  })
  async editBonusType (): Promise<BonusType> {
    try {
      const { id, ...bonusType } = this.bonusType
      const { data: { data } } = await $axios.put(`/bonus/types/${id}`, bonusType)
      const response: BonusType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на удаление типа бонусов
   * @param id - id типа бонусов
   * @returns тип бонусов
   */
  @Action({
    rawError: true
  })
  async removeBonusType (id: number): Promise<BonusType> {
    try {
      const { data: { data } } = await $axios.delete(`/bonus/types/${id}`)
      const response: BonusType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
