import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { ErrorLog } from './interfaces'

@Module({
  name: 'errorLogs',
  stateFactory: true,
  namespaced: true
})
export default class ErrorLogsModule extends VuexModule {
  errorLogsValue: ErrorLog[] = []

  // ? ______________ getters ______________
  get errorLogs (): ErrorLog[] {
    return this.errorLogsValue
  }

  // ? ______________ setters ______________

  @Mutation
  setErrorLogs (errorLogs: ErrorLog[]) {
    this.errorLogsValue = errorLogs
  }

  @Mutation
  resetErrorLogs (): void {
    this.errorLogsValue = []
    localStorage.removeItem('errorLogs')
  }
}
