import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { CategoriesParams, Category } from './interfaces'
import { defaultData, FormError, ResponseData, PageParams, ValidatorParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'
@Module({
  name: 'categories',
  stateFactory: true,
  namespaced: true
})

export default class CategoriesModule extends VuexModule {
  /**
   * * Массив всех category
   */
  categories: ResponseData<Category> = defaultData

  /**
   * * Текущая category
   */
  category: Category = {
    title: '',
    code: null,
    sort: 0,
    parentId: null,
    active: true,
    imageUrl: null,
    imageId: null,
    childrenIds: [],
    productCount: 0,
    mainPageImageId: null
  }

  // ? ______________ getters ______________
  /**
   * * Получить массив categories
   */
  get categoriesList (): ResponseData<Category> {
    return this.categories
  }

  /**
   * * Получить category из массива categories
   */
  get categoryById () {
    const categories: ResponseData<Category> = this.categoriesList
    return function (id: number): Category | undefined {
      return categories.data.find(category => category.id === id)
    }
  }

  /**
   * * Получить текущую category для измения или создания category
   */
  get currentCategory () {
    return this.category
  }

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      title: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите название категории',
        trigger: ['blur']
      }],
      code: [{
        required: false,
        pattern: validatorsPattern.emptyStringEmpty,
        message: 'Введите код категории',
        trigger: ['blur']
      }],
      markup: [{ pattern: validatorsPattern.notWholeNumbers, message: 'Введите число', trigger: ['blur'] }],
      sort: [{ required: true, pattern: validatorsPattern.wholeNumbers, message: 'Введите целое число', trigger: ['blur', 'change'] }]
    }
  }

  // ? ______________ setters ______________
  /**
   * * Установить массив categories
   * @param categories массив categories
   */

  @Mutation
  setCategoriesList (categories: ResponseData<Category>) {
    this.categories = categories
  }

  /**
   * * Установить currentCategory для измения или создания category
   * @param category текущая category, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentCategory (category: Category) {
    this.category = category
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentCategory () {
    this.category = {
      title: '',
      code: null,
      sort: 0,
      parentId: null,
      active: true,
      imageUrl: null,
      imageId: null,
      childrenIds: [],
      productCount: 0,
      mainPageImageId: null
    }
  }

  /**
   * * Обнулить categoriesList
   */
  @Mutation
  resetCategories () {
    this.categories = defaultData
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список categories
   */
  @Action({
    rawError: true
  })
  async getCategories (pageParams: CategoriesParams | null) {
    try {
      const { data } = await $axios.get('/shop/categories', { params: pageParams })
      const response: ResponseData<Category> = { ...data, data: data.data.map((category: Category) => ({ ...category, hasChildren: category.childrenIds?.length, hasNotChildren: !category.childrenIds.length })) }
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить список всех categories
   */
  @Action({
    rawError: true,
    commit: 'setCategoriesList'
  })
  async getAllCategories (pageParams: PageParams | null) {
    try {
      const { data } = await $axios.get('/shop/categories/all', { params: pageParams })
      const response: ResponseData<Category> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить category по id
   * @param id id category, который мы хотим получить
   */
  @Action({
    rawError: true
  })
  async getCategory (id: number): Promise<Category> {
    try {
      const { data: { data } } = await $axios.get(`/shop/categories/${id}`)
      const response: Category = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить category
   */
  @Action({
    rawError: true
  })
  async editCategory (): Promise<Category> {
    try {
      const { id, ...category } = this.currentCategory
      const { data: { data } } = await $axios.put(`/shop/categories/${id}`, {
        active: category.active,
        code: category.code || null,
        title: category.title,
        sort: category.sort,
        parentId: category.parentId,
        imageId: category.imageId,
        imageUrl: category.imageUrl,
        params: category.params,
        mainPageImageId: category.mainPageImageId
      })
      const response: Category = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать category
   */
  @Action({
    rawError: true
  })
  async createCategory (): Promise<Category> {
    try {
      const { data: { data } } = await $axios.post('/shop/categories', {
        active: this.currentCategory.active,
        code: this.currentCategory.code || null,
        title: this.currentCategory.title,
        sort: this.currentCategory.sort,
        parentId: this.currentCategory.parentId,
        imageId: this.currentCategory.imageId,
        imageUrl: this.currentCategory.imageUrl,
        params: this.category.params,
        mainPageImageId: this.category.mainPageImageId
      }
      )
      const response: Category = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить category по id
   * @param id  id category, который мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removeCategory (id: number): Promise<Category> {
    try {
      const { data: { data } } = await $axios.delete(`/shop/categories/${id}`)
      const response: Category = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на обновление сортировки категории
   * @param id  id category, сортировку которой мы хотим обновить
   */
  @Action({
    rawError: true
  })
  async updateCategorySort (id: number) {
    try {
      return await $axios.post(`/shop/categories/${id}/update-sort`)
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
