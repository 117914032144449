import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { BonusLog, Filters, ValidatorsParams } from './interfaces'
import { FormError, defaultDataWithMeta, Paginator } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'
@Module({
  name: 'bonusLog',
  stateFactory: true,
  namespaced: true
})
export default class BonusLogModule extends VuexModule {
  /**
   * * Массив логов бонусов и пагинатор
   */
  bonusLogsValue: { data: BonusLog[], meta: Paginator } = defaultDataWithMeta

  /**
   * ! Необходимо уточнить дефолтные значения
   */
  /**
   * * Лог бонуса
   */
  bonusLogValue: BonusLog = {
    operationType: 1,
    bonusAccountId: 1,
    userId: 0,
    value: 1,
    activationDate: 0,
    endDate: null,
    bonusId: 1,
    entityId: 1,
    transactionCode: '',
    active: false
  }

  /**
   * * Значения фильтров запроса на список логов бонусов
   */
  filtersValue: Filters = {}

  // ? ______________ getters ______________

  /**
   * * Валидаторы формы бонусов
   */
  get validators (): ValidatorsParams {
    return {
      operationType: [{ required: true, pattern: validatorsPattern.naturalNumbers, message: 'Выберите тип операции', trigger: ['blur'] }],
      bonusAccountId: [{ required: true, pattern: validatorsPattern.naturalNumbers, message: 'Введите бонусный счёт', trigger: ['blur'] }],
      userId: [{ required: true, pattern: validatorsPattern.naturalNumbers, message: 'Введите ID пользователя', trigger: ['blur'] }],
      value: [{ required: true, pattern: validatorsPattern.naturalNumbers, message: 'Введите корректное количество бонусов', trigger: ['blur'] }],
      activationDate: [{ pattern: validatorsPattern.naturalNumbers, message: 'Введите корректную дату', trigger: ['blur'] }],
      endDate: [{ pattern: validatorsPattern.naturalNumbers, message: 'Введите корректную дату', trigger: ['blur'] }],
      bonusId: [{ required: true, pattern: validatorsPattern.naturalNumbers, message: 'Введите условие начисления бонуса', trigger: ['blur'] }]
    }
  }

  /**
   * * Получить массив логов бонусов и пагинатор
   */
  get bonusLogs (): { data: BonusLog[], meta: Paginator } {
    return this.bonusLogsValue
  }

  /**
   * * Получить лог бонуса
   */
  get bonusLog (): BonusLog {
    return this.bonusLogValue
  }

  /**
   * * Получить значения фильтров
   */
  get filters (): Filters {
    return this.filtersValue
  }

  // ? ______________ setters ______________

  /**
   * * Установить массив логов бонусов
   * @param bonusLogs - массив логов бонусов и пагинатор
   */
  @Mutation
  setBonusLogs (bonusLogs: { data: BonusLog[], meta: Paginator }) {
    this.bonusLogsValue = bonusLogs
  }

  /**
   * * Сбросить массив логов и пагинатор
   */
  @Mutation
  resetBonusLogs () {
    this.bonusLogsValue = defaultDataWithMeta
  }

  /**
   * * Установить лог бонуса
   * @param bonusLog - лог бонуса
   */
  @Mutation
  setBonusLog (bonusLog: BonusLog) {
    this.bonusLogValue = bonusLog
  }

  /**
   * * Сбросить лог бонуса
   */
  @Mutation
  resetBonusLog () {
    this.bonusLogValue = {
      operationType: 1,
      bonusAccountId: 1,
      userId: 0,
      value: 1,
      activationDate: 0,
      endDate: null,
      bonusId: 1,
      entityId: 1,
      transactionCode: '',
      active: false
    }
  }

  /**
   * * Установить фильтры
   * @param filters - значения фильтров
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  /**
   * * Сбросить значения фильтров
   */
  @Mutation
  resetFilters () {
    this.filtersValue = {}
  }

  // ? ______________ actions ______________

  /**
   * * Запрос на получение логов бонусов
   * @param pageParams - параметры пагинации
   * @returns - массив логов бонусов и пагинатор
   */
  @Action({
    rawError: true,
    commit: 'setBonusLogs'
  })
  async getBonusLogs (bonusLogParams: any): Promise<{ data: BonusLog[], meta: Paginator }> {
    try {
      const response = (await $axios.get(`${bonusLogParams.siteApiUrl}/bonuses/v1/log`, {
        headers: {
          Authorization: '',
          common: {
            Authorization: ''
          }
        },
        params: {
          ...bonusLogParams.pageParams,
          ...this.filters
        }
      }))?.data as { data: BonusLog[], meta: Paginator }

      return {
        ...response,
        meta: {
          currentPage: response.meta.currentPage,
          pageSize: (response.meta as any).perPage,
          totalItems: (response.meta as any).totalCount,
          totalPages: (response.meta as any).pageCount
        }
      }
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание лога бонуса
   * @returns - лог бонуса
   */
  @Action({
    rawError: true
  })
  async createBonusLog (): Promise<BonusLog> {
    try {
      const {
        operationType,
        bonusAccountId,
        userId,
        value,
        activationDate,
        endDate,
        bonusId,
        entityId,
        transactionCode
      } = this.bonusLog
      const { data: { data } } = await $axios.post('/bonus/log', {
        operationType,
        bonusAccountId,
        userId,
        value,
        activationDate,
        endDate,
        bonusId,
        entityId,
        transactionCode
      })
      const response: BonusLog = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение лога бонуса
   * @param id - id лога бонуса
   * @returns лог бонуса
   */
  @Action({
    rawError: true,
    commit: 'setBonusLog'
  })
  async getBonusLogById (id: number): Promise<BonusLog> {
    try {
      const { data: { data } } = await $axios.get(`/bonus/log/${id}`)
      const response: BonusLog = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
