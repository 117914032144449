import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { Option, OptionValue, Ids, ParamsAll, Filters } from './interfaces'
import { defaultData, PageParams, FormError, ResponseData, ValidatorParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'optionsValues',
  stateFactory: true,
  namespaced: true
})
export default class OptionsValuesModule extends VuexModule {
  filtersValue: Filters = {
    title: undefined,
    nameFrom1s: undefined,
    type: undefined
  }

  filtersOptionValuesState: Filters = {
    title: undefined,
    nameFrom1s: undefined,
    id: undefined,
    active: undefined
  }

  /**
   * * Список словарей
   */
  optionsList: ResponseData<Option> = defaultData

  /**
   * * Словарь
   */
  currentOption: Option = {
    title: '',
    code: '',
    counterSort: false,
    type: 1,
    basket: false,
    filter: false,
    order: false,
    search: false,
    viewType: 1,
    sort: 0,
    active: true,
    defaultOpen: false,
    measure: '',
    isMultiFilter: 0,
    seo: 0,
    position: 0,
    categories: [],
    onlyAllCatalog: false,
    showInCard: false,
    priorityId: null,
    searchPriority: null,
    productCard: false,
    showInAllCatalog: false,
    values: [],
    categoriesWithoutSeo: []
  }

  /**
   * * Фильтры словаря
   */
  optionValuesList: ResponseData<OptionValue> = defaultData

  /**
   * * Фильтр словаря
   */
  currentOptionValue: OptionValue = {
    title: '',
    code: '',
    sort: 0,
    active: true,
    tags: [],
    descr: '',
    seo: false,
    roles: [],
    count: 0,
    params: {
      view: {}
    },
    categoriesWithoutSeo: []
  }

  // ? ------ getters ------ //
  // ? --- Словари --- //

  /**
   * * Получить список словарей
   */
  get options (): ResponseData<Option> {
    return this.optionsList
  }

  /**
   * * Получить фильтры
   */
  get filters (): Filters {
    return this.filtersValue
  }

  get filtersOptionValues (): Filters {
    return this.filtersOptionValuesState
  }

  /**
   * * Получить словарь
   */
  get option (): Option {
    return this.currentOption
  }

  /**
   * * Получить словарь по id
   */
  get optionById () {
    const optionsList = this.optionsList.data
    return function (id: number): Option | undefined {
      return optionsList.find(option => option.id === id)
    }
  }

  /**
   * *  Шаблон валидатора для формы
   */
  get validatorsOption (): ValidatorParams {
    return {
      title: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите название справочника', trigger: ['blur'] }],
      code: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите код справочника', trigger: ['blur'] }],
      sort: [{ required: true, pattern: validatorsPattern.wholeNumbers, message: 'Введите целое число', trigger: ['blur'] }],
      categories: [{ required: true, type: 'array', min: 1, message: 'Выберите роль', trigger: ['blur'] }]
    }
  }

  // ? --- Фильтры словарей --- //

  /**
   * * Получить список фильтров словаря
   */
  get optionValues (): ResponseData<OptionValue> {
    return this.optionValuesList
  }

  /**
   * * Получить фильтр словаря
   */
  get optionValue (): OptionValue {
    return this.currentOptionValue
  }

  /**
   * * Получить фильтр словаря по id
   */
  get optionValueById () {
    const optionValuesList = this.optionValuesList.data
    return function (id: number): OptionValue | undefined {
      return optionValuesList.find(optionValue => optionValue.id === id)
    }
  }

  /**
   * *  Шаблон валидатора для формы
   */
  get validatorsOptionValues (): ValidatorParams {
    return {
      title: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите название справочника', trigger: ['blur'] }],
      code: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите код справочника', trigger: ['blur'] }],
      sort: [{ required: true, pattern: validatorsPattern.wholeNumbers, message: 'Введите целое число', trigger: ['blur'] }],
      roles: [{ required: true, type: 'array', min: 1, message: 'Выберите категории', trigger: ['blur'] }]
    }
  }

  // ? ------ setters ------ //

  // ? --- Словари --- //

  /**
   * * Установить фильтры
   * @param filter - фильтры
   */
  @Mutation
  setFilters (filter: Filters) {
    this.filtersValue = filter
  }

  /**
   * * Установить фильтры optionValues
   * @param filter - фильтры
   */
  @Mutation
  setFiltersOptionValues (filter: Filters) {
    this.filtersOptionValuesState = filter
  }

  /**
   * * Установить список словарей
   * @param options - список словарей
   */
  @Mutation
  setOptions (options: ResponseData<Option>) {
    this.optionsList = options
  }

  //* Сбросить фильтры
  @Mutation
  resetFilters () {
    this.filtersValue = {
      title: undefined,
      nameFrom1s: undefined
    }
  }

  //* Сбросить фильтры
  @Mutation
  resetFiltersOptionValues () {
    this.filtersOptionValuesState = {
      title: undefined,
      nameFrom1s: undefined,
      id: undefined
    }
  }

  /**
   * * Установить словарь
   * @param option - словарь
   */
  @Mutation
  setOption (option: Option) {
    this.currentOption = option
  }

  /**
   * * Сбросить словарь
   */
  @Mutation
  resetCurrentOption () {
    this.currentOption = {
      title: '',
      code: '',
      counterSort: false,
      type: 1,
      basket: false,
      filter: false,
      order: false,
      search: false,
      viewType: 1,
      sort: 0,
      active: true,
      defaultOpen: false,
      measure: '',
      isMultiFilter: 0,
      seo: 0,
      position: 0,
      categories: [],
      onlyAllCatalog: false,
      showInCard: false,
      priorityId: null,
      searchPriority: null,
      productCard: false,
      showInAllCatalog: false,
      values: [],
      categoriesWithoutSeo: []
    }
  }

  // ? --- Фильтры словарей --- //

  /**
   * * Установить список фильтров словаря
   * @param optionValues - список фильтров словаря
   */
  @Mutation
  setOptionValues (optionValues: ResponseData<OptionValue>) {
    this.optionValuesList = optionValues
  }

  /**
   * * Сбросить список фильтров словаря
   */
  @Mutation
  resetOptionValues () {
    this.optionValuesList = defaultData
  }

  /**
   * * Установить фильтр словаря
   * @param optionValue - фильтр словаря
   */
  @Mutation
  setOptionValue (optionValue: OptionValue) {
    this.currentOptionValue = optionValue
  }

  /**
   * * Сбросить фильтр словаря
   */
  @Mutation
  resetCurrentOptionValue () {
    this.currentOptionValue = {
      title: '',
      code: '',
      tags: [],
      sort: 0,
      active: true,
      descr: '',
      roles: [],
      seo: false,
      count: 0,
      params: {
        view: {}
      },
      categoriesWithoutSeo: []
    }
  }

  /**
   * * Сбросить Options
   */
  @Mutation
  resetOptions () {
    this.optionsList = defaultData
  }

  // ? ------ actions ------ //

  // ? --- Словари --- //

  /**
   * * Запрос на получение списка словарей
   * @param pageParams - параметры пагинации
   */
  @Action({
    rawError: true,
    commit: 'setOptions'
  })
  async getOptions (pageParams: PageParams | null = null): Promise<ResponseData<Option>> {
    try {
      const { data } = await $axios.get('/shop/options', { params: { ...pageParams, ...this.filters } })
      const response: ResponseData<Option> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение полного списка словарей
   * @param pageParams - параметры пагинации
   */
  @Action({
    rawError: true,
    commit: 'setOptions'
  })
  async getOptionsAll (pageParams: ParamsAll| null = null): Promise<ResponseData<Option>> {
    try {
      const { data } = await $axios.get('/shop/options/all', { params: pageParams })
      const response: ResponseData<Option> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание словаря
   */
  @Action({
    rawError: true
  })
  async createOption (): Promise<Option> {
    try {
      const { data: { data } } = await $axios.post('/shop/options', this.option)
      const response: Option = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение словаря по id
   * @param id id словаря
   */
  @Action({
    rawError: true,
    commit: 'setOption'
  })
  async getOptionById (id: number): Promise<Option> {
    try {
      const { data: { data } } = await $axios.get(`/shop/options/${id}`)
      const response: Option = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение словаря
   */
  @Action({
    rawError: true
  })
  async editOption (): Promise<Option> {
    try {
      const { id, ...newOption } = this.option
      const { data: { data } } = await $axios.put(`/shop/options/${id}`, newOption)
      const response: Option = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на удаление словаря
   * @param id id словаря
   */
  @Action({
    rawError: true
  })
  async removeOption (id: number): Promise<Option> {
    try {
      const { data: { data } } = await $axios.delete(`/shop/options/${id}`)
      const response: Option = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  // ? --- Фильтры словарей --- //

  /**
   * * Запрос на получение списка фильтров словаря
   * @param params - параметры пагинации
   */
  @Action({
    rawError: true
  })
  async getOptionValues (params: (PageParams & {categoryId?: number} & {optionId?: number} | null) = null): Promise<ResponseData<OptionValue>> {
    try {
      const { data } = await $axios.get(`/shop/options/${params?.optionId || this.option.id}/values`, { params: { ...params, ...this.filtersOptionValuesState } })
      const response: ResponseData<OptionValue> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание фильтра словаря
   */
  @Action({
    rawError: true
  })
  async createOptionValue (): Promise<OptionValue> {
    try {
      const { data: { data } } = await $axios.post(`/shop/options/${this.option.id}/values`, this.optionValue)
      const response: OptionValue = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение фильтра словаря по id словаря и id фильтра
   * @param ids id словаря и id фильтра
   */
  @Action({
    rawError: true,
    commit: 'setOptionValue'
  })
  async getOptionValueById (ids: Ids): Promise<OptionValue> {
    try {
      const { data: { data } } = await $axios.get(`/shop/options/${ids.optionId}/values/${ids.optionValueId}`)
      const response: OptionValue = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение фильтра словаря
   */
  @Action({
    rawError: true
  })
  async editOptionValue (): Promise<OptionValue> {
    try {
      const { id, ...newOptionValue } = this.optionValue
      const { data: { data } } = await $axios.put(`/shop/options/${this.option.id}/values/${id}`, newOptionValue)
      const response: OptionValue = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на удаление фильтра словаря
   * @param id id фильтра словаря
   */
  @Action({
    rawError: true
  })
  async removeOptionValue (id: number): Promise<OptionValue> {
    try {
      const { data: { data } } = await $axios.delete(`/shop/options/${this.option.id}/values/${id}`)
      const response: OptionValue = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение справочника по id фильтра
   * @param id id фильтра
   */
  @Action({
    rawError: true,
    commit: 'setOption'
  })
  async getOptionByValueId (id: number): Promise<Option> {
    try {
      const { data: { data } } = await $axios.get(`/shop/options/values/${id}`)
      const response: Option = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на обновление сортировки опции
   * @param id  id опции, сортировку которой мы хотим обновить
   */
  @Action({
    rawError: true
  })
  async updateOptionSort (id: number) {
    try {
      return await $axios.post(`/shop/options/${id}/update-sort`)
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
