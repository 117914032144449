import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { Params, FormParam, FormParamType } from './interfaces'
import { FormError, defaultData, ResponseData, ValidatorParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'formParams',
  stateFactory: true,
  namespaced: true
})
export default class FormParamsModule extends VuexModule {
  /**
   * * Список параметров формы
   */
  formParamsValue: ResponseData<FormParam> = defaultData

  /**
   * * Параметр формы
   */
  formParamValue: FormParam = {
    typeId: 0,
    formParamTypeId: 0,
    sort: 100
  }

  /**
   * * Список типов параметров форм
   */
  formParamTypesValue: ResponseData<FormParamType> = defaultData

  /**
   * * Тип параметра формы
   */
  formParamTypeValue: FormParamType = {
    name: '',
    code: '',
    type: '',
    params: {
      required: true,
      type: 'input',
      options: []
    }
  }

  // ? ______________ getters ______________

  /**
   * * Получить список параметров формы
   */
  get formParams (): ResponseData<FormParam> {
    return this.formParamsValue
  }

  /**
   * * Получить параметр формы
   */
  get formParam (): FormParam {
    return this.formParamValue
  }

  /**
   * * Получить параметр формы по id
   */
  get formParamById () {
    const formParams = this.formParamsValue.data
    return function (id: number): FormParam | undefined {
      return formParams.find(formParam => formParam.id === id)
    }
  }

  /**
   * * Валидатор для форма paramType
   */
  get paramTypeParamsValidators (): ValidatorParams {
    return {
      name: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите название', trigger: ['blur', 'change'] }],
      code: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите код', trigger: ['blur', 'change'] }]
    }
  }

  /**
   * * Получить типы параметра формы
   */
  get formParamTypes (): ResponseData<FormParamType> {
    return this.formParamTypesValue
  }

  /**
   * * Получить тип параметра формы
   */
  get formParamType (): FormParamType {
    return this.formParamTypeValue
  }

  /**
   * * Получить тип параметра формы по id
   */
  get formParamTypeById () {
    const formParamTypes = this.formParamTypesValue.data
    return function (id: number): FormParamType | undefined {
      return formParamTypes.find(formParamType => formParamType.id === id)
    }
  }

  // ? ______________ setters ______________

  /**
   * * Установить список параметров формы
   * @param formParams список параметров формы
   */
  @Mutation
  setFormParams (formParams: ResponseData<FormParam>) {
    this.formParamsValue = formParams
  }

  /**
   * * Установить параметр формы
   * @param formParam парметр формы
   */
  @Mutation
  setFormParam (formParam: FormParam) {
    this.formParamValue = formParam
  }

  /**
   * * Сбросить параметр формы
   */
  @Mutation
  resetFormParam () {
    this.formParamValue = {
      typeId: 0,
      formParamTypeId: 0
    }
  }

  /**
   * * Сбросить список параметров формы
   */
  @Mutation
  resetFormParams () {
    this.formParamsValue = defaultData
  }

  /**
   * * Установить список типов параметров форм
   * @param formParamTypes типы параметров форм
   */
  @Mutation
  setFormParamTypes (formParamTypes: ResponseData<FormParamType>) {
    this.formParamTypesValue = formParamTypes
  }

  /**
   * * Установить тип параметров формы
   * @param formParamType тип параметров формы
   */
  @Mutation
  setFormParamType (formParamType: FormParamType) {
    this.formParamTypeValue = {
      ...formParamType,
      params: {
        ...formParamType.params,
        options: formParamType.params.options || []
      }
    }
  }

  /**
   * * Сбросить тип параметров формы
   */
  @Mutation
  resetFormParamType () {
    this.formParamTypeValue = {
      name: '',
      code: '',
      type: '',
      params: {
        required: true,
        type: 'input',
        options: []
      }
    }
  }

  /**
   * * Сбросить список типов параметров формы
   */
  @Mutation
  resetFormParamTypes () {
    this.formParamTypesValue = defaultData
  }

  // ? ______________ actions ______________

  /**
   * * Запрос на получение параметров форм
   * @param params - параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setFormParams'
  })
  async getFormParams (params: Params | null = null): Promise<ResponseData<FormParam>> {
    try {
      const query = {
        page: params?.page || null,
        pageSize: params?.pageSize || null,
        typeId: params?.typeId || null
      }
      const { data } = await $axios.get('/form/param', { params: query })
      const response: ResponseData<FormParam> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение параметра форм по id
   * @param id id параметра формы
   */
  @Action({
    rawError: true,
    commit: 'setFormParam'
  })
  async getFormParamById (id: number): Promise<FormParam> {
    try {
      const { data: { data } } = await $axios.get(`/form/param/${id}`)
      const response: FormParam = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание параметра форм
   */
  @Action({
    rawError: true
  })
  async createFormParam (): Promise<FormParam> {
    try {
      const { data: { data } } = await $axios.post('/form/param', this.formParam)
      const response: FormParam = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение параметра формы
   */
  @Action({
    rawError: true
  })
  async editFormParam (): Promise<FormParam> {
    try {
      const { id, ...newFormParam } = this.formParam
      const { data: { data } } = await $axios.put(`/form/param/${id}`, newFormParam)
      const response: FormParam = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на удаление параметра формы по id
   * @param id id параметра
   */
  @Action({
    rawError: true
  })
  async removeFormParam (id: number): Promise<FormParam> {
    try {
      const { data: { data } } = await $axios.delete(`/form/param/${id}`)
      const response: FormParam = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение списка типов параметров форм
   * @param params параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setFormParamTypes'
  })
  async getFormParamTypes (params: Params | null = null): Promise<ResponseData<FormParamType>> {
    try {
      const query = {
        page: params?.page || null,
        pageSize: params?.pageSize || null,
        type: params?.type || null
      }
      const { data } = await $axios.get('/form/param-type', { params: query })
      const response: ResponseData<FormParamType> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение типа параметров форм по id
   * @param id id типа параметров форм
   */
  @Action({
    rawError: true,
    commit: 'setFormParamType'
  })
  async getFormParamTypeById (id: number): Promise<FormParamType> {
    try {
      const { data: { data } } = await $axios.get(`/form/param-type/${id}`)
      const response: FormParamType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание типа параметров форм
   */
  @Action({
    rawError: true
  })
  async createFormParamType (): Promise<FormParamType> {
    try {
      const { data: { data } } = await $axios.post('/form/param-type', this.formParamType)
      const response: FormParamType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение типа параметров форм
   */
  @Action({
    rawError: true
  })
  async editFormParamType (): Promise<FormParamType> {
    try {
      const { id, ...newFormParamType } = this.formParamType
      const { data: { data } } = await $axios.put(`/form/param-type/${id}`, newFormParamType)
      const response: FormParamType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удаление типа параметров формы по id
   * @param id id типа параметров формы
   */
  @Action({
    rawError: true
  })
  async removeFormParamType (id: number): Promise<FormParamType> {
    try {
      const { data: { data } } = await $axios.delete(`/form/param-type/${id}`)
      const response: FormParamType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
