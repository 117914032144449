import { Context } from '@nuxt/types'
import NewslettersModule from './store'

export default function getPluginApi (store: NewslettersModule): NewslettersModule {
  const ctx: Context = this

  const api: any = {
    get newsletters () {
      return store.newsletters
    },

    get blacklist () {
      return store.blacklist
    },

    get whitelist () {
      return store.whitelist
    },

    get recipientsSource () {
      return store.recipientsSource
    },

    get snapshots () {
      return store.snapshots
    },

    get formNewsletters () {
      return store.formNewsletters
    },

    get newEmail () {
      return store.newEmail
    },

    get newFile () {
      return store.newFile
    },

    get statsOfNewsletters () {
      return store.statsOfNewsletters
    },

    get currentStatsOfNewslettersId () {
      return store.currentStatsOfNewslettersId
    },

    setFormNewslettersState ({ key, value } : { key: string, value: any }) {
      store.setFormNewslettersState({ key, value })
    },

    setNewEmail (value: string) {
      store.setNewEmail(value)
    },

    setNewFile (value: number | undefined) {
      store.setNewFile(value)
    },

    setCurrentStatsOfNewslettersId (value: number | undefined) {
      store.setCurrentStatsOfNewslettersId(value)
    },

    async getNewsletters ({ page, pageSize } : { page?: number, pageSize?: number }) {
      return await store.getNewsletters({
        page,
        pageSize,
        siteApiUrl: ctx.$config.SITE_API
      })
    },

    async getNewsletterById (id: number) {
      return await store.getNewsletterById({
        siteApiUrl: ctx.$config.SITE_API,
        id
      })
    },

    async getBlacklist ({ page, pageSize } : { page?: number, pageSize?: number }) {
      return await store.getBlacklist({
        siteApiUrl: ctx.$config.SITE_API,
        page,
        pageSize
      })
    },

    async getWhitelist ({ page, pageSize } : { page?: number, pageSize?: number }) {
      return await store.getWhitelist({
        siteApiUrl: ctx.$config.SITE_API,
        page,
        pageSize
      })
    },

    async getRecipientSources () {
      return await store.getRecipientSources({
        siteApiUrl: ctx.$config.SITE_API
      })
    },

    async createNewsletter () {
      return await store.createNewsletter({
        siteApiUrl: ctx.$config.SITE_API
      })
    },

    async editNewsletter () {
      return await store.editNewsletter({
        siteApiUrl: ctx.$config.SITE_API
      })
    },

    async getSnapshotsById (id: number) {
      return await store.getSnapshotsById({
        id,
        siteApiUrl: ctx.$config.SITE_API
      })
    },

    async removeEmail (
      listName: string,
      email: string,
      distributionTypeId?: number,
      status?: string
    ) {
      return await store.removeEmail({
        listName,
        email,
        distributionTypeId,
        status,
        siteApiUrl: ctx.$config.SITE_API
      })
    },

    async loadNewEmail (listName: string, status?: string) {
      return await store.loadNewEmail({
        listName,
        status,
        siteApiUrl: ctx.$config.SITE_API
      })
    },

    async saveBlacklist () {
      return await store.saveBlacklist({
        siteApiUrl: ctx.$config.SITE_API
      })
    },

    async sendNewsletter (id: number, status?: string) {
      return await store.sendNewsletter({
        status,
        id,
        siteApiUrl: ctx.$config.SITE_API
      })
    },

    async sendUnsentNewsletter (id: number) {
      return await store.sendUnsentNewsletter({
        id,
        siteApiUrl: ctx.$config.SITE_API
      })
    },

    async viewStatsNewsletter (id: number) {
      return await store.viewStatsNewsletter({
        id,
        siteApiUrl: ctx.$config.SITE_API
      })
    },

    async downloadStatsOfNewsletter () {
      return await store.downloadStatsOfNewsletter({
        siteApiUrl: ctx.$config.SITE_API
      })
    }
  }

  return api
}
