import { Context } from '@nuxt/types'
import { QueryParams, Bonus, AvailableBonusResp, BonusPlugin } from './interfaces'
import { ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (store: any) {
  const ctx: Context = this

  const api: BonusPlugin = {
    // ? ______________ getters ______________

    get bonuses (): ResponseData<Bonus> {
      return store.bonuses
    },

    get bonus (): Bonus {
      return store.bonus
    },

    bonusById (id: number): Bonus | undefined {
      return store.bonusById(id)
    },

    get bonusValidators (): ValidatorParams {
      return store.bonusValidators
    },

    // ? ______________ setters ______________

    set bonuses (bonuses: ResponseData<Bonus>) {
      store.setBonuses(bonuses)
    },

    set bonus (bonus: Bonus) {
      store.setBonus(bonus)
    },

    resetBonuses (): void {
      store.resetBonuses()
    },

    resetBonus (): void {
      store.resetBonus()
    },

    // ? ______________ actions ______________

    getBonuses (queryParams: QueryParams | null = null): Promise<ResponseData<Bonus>> {
      return store.getBonuses(queryParams)
    },

    createBonus (): Promise<Bonus> {
      return store.createBonus()
    },

    getBonusById (id: number): Promise<Bonus> {
      return store.getBonusById(id)
    },

    editBonus (): Promise<Bonus> {
      return store.editBonus()
    },

    removeBonus (id: number): Promise<Bonus> {
      return store.removeBonus(id)
    },

    getAvailableForOrder (orderId: number): Promise<AvailableBonusResp> {
      return store.getAvailableForOrder({ siteApiUrl: ctx.$config.SITE_API, orderId })
    }

  }

  return api
}
