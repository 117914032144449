import { Context } from '@nuxt/types'
import CalculatorElementsModule from './store'
import storePluginApi from '~/types/store/store-plugin-api'
import { NCalculator } from '~/types/store/calculator-micro'

export default function getPluginApi (store: CalculatorElementsModule) {
  const ctx: Context = this

  const api: any = storePluginApi<
    NCalculator.IElement,
    'id',
    keyof NCalculator.IElement
  >(store as any, ctx.$config.SITE_API)

  return api
}
