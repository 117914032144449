import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { Currency, GeneratorOutput, Filters } from './interfaces'
import { defaultData, FormError, PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'currencies',
  stateFactory: true,
  namespaced: true
})
export default class CurrenciesModule extends VuexModule {
  /**
   * * Значения фильтров
   */
  filtersValue: Filters = {
    sourceId: undefined
  }

  /**
   * * Список валют
   */
  currenciesValue: ResponseData<Currency> = defaultData

  /**
   * * Валюта
   */
  currencyValue: Currency = {
    type: 1,
    sign: '',
    mark: '',
    name: '',
    rate: 1
  }

  // ? ______________ getters ______________
  /**
   * * Получить значения фильтров
   */
  get filters (): Filters {
    return this.filtersValue
  }

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      sign: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите код валюты',
        trigger: ['blur']
      }],
      mark: [{
        required: true,
        message: 'Введите знак',
        trigger: ['blur']
      }],
      name: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите название',
        trigger: ['blur']
      }],
      rate: [{
        pattern: validatorsPattern.notWholeNumbers,
        message: 'Введите цисло',
        trigger: ['blur']
      }],
      sourceId: [{
        required: true,
        message: 'Выберите источник',
        trigger: ['blur']
      }],
      type: [{
        required: true,
        message: 'Выберите тип',
        trigger: ['blur']
      }]
    }
  }

  /**
   * * Получить список валют
   */
  get currencies (): ResponseData<Currency> {
    return this.currenciesValue
  }

  /**
   * * Получить валюту
   */
  get currency (): Currency {
    return this.currencyValue
  }

  /**
   * * Получить валюту по id
   */
  get currencyById () {
    const currencies = this.currenciesValue
    return function (id: number): Currency | undefined {
      return currencies.data.find((currency) => {
        return currency.id === id
      })
    }
  }

  // ? ______________ setters ______________
  /**
   * * Установить значения фильтров
   * @param filters - значения фильтров
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  /**
   * * Сбросить значения фильтров
   */
  @Mutation
  resetFilters (): void {
    this.filtersValue = {
      sourceId: undefined
    }
  }

  /**
   * * Установить валюты
   * @param currencies
   */
  @Mutation
  setCurrencies (currencies: ResponseData<Currency>) {
    this.currenciesValue = currencies
  }

  /**
   * * Установить валюту
   * @param currency
   */
  @Mutation
  setCurrency (currency: Currency) {
    this.currencyValue = currency
  }

  /**
   * * Очистить объект настройки
   */
  @Mutation
  resetCurrency () {
    this.currencyValue = {
      type: 1,
      sign: '',
      mark: '',
      name: '',
      rate: 1
    }
  }

  /**
    * * Очистить объект настройки
    */
  @Mutation
  resetCurrencies () {
    this.currenciesValue = defaultData
  }

  // ? ______________ actions ______________
  /**
   * * Загрузка валют
   * @param pageParams - параметры страниц
   */
  @Action({
    rawError: true,
    commit: 'setCurrencies'
  })
  async getCurrencies (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/currencies', { params: { ...pageParams, ...this.filters } })
      const response: ResponseData<Currency> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Загрузка валют
   * @param pageParams - параметры страниц
   */
  @Action({
    rawError: true,
    commit: 'setCurrency'
  })
  async getCurrency (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/currencies/${id}`)
      const response: Currency = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать валюту
   */
  @Action({
    rawError: true
  })
  async createCurrency () {
    try {
      const { data: { data } } = await $axios.post('/currencies', this.currency)
      const response: Currency = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить валюту
   */
  @Action({
    rawError: true
  })
  async editCurrency () {
    try {
      const { id, ...currency } = this.currency
      const { data: { data } } = await $axios.put(`/currencies/${id}`, currency)
      const response: Currency = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить валюту
   * @param id - id валюты
   */
  @Action({
    rawError: true
  })
  async removeCurrency (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/currencies/${id}`)
      const response: Currency = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Обновить курс по ЦБ
   */
   @Action({
     rawError: true
   })
  async updateRate () {
    try {
      const { data } = await $axios.post('/currencies/update-rates')
      const response: GeneratorOutput = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
