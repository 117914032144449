import { FilesPlugin, Files } from './interfaces'

export default function getPluginApi (filesStore:any) {
  const api: FilesPlugin = {
    // ? ________ actions ________

    createFiles (files: Files): Promise<any[]> {
      return filesStore.createFiles(files)
    },

    uploadFiles (files: FormData, onUploadProgress: any): Promise<any[]> {
      return filesStore.uploadFiles({ files, onUploadProgress })
    },

    uploadVideos (files: FormData, onUploadProgress: any): Promise<any[]> {
      return filesStore.uploadVideos({ files, onUploadProgress })
    }
  }
  return api
}
