import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { defaultPaginator, FormError, ResponseData, ValidatorParams } from '../../interfaces'
import { Template, DropDown, InputParams } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'templates',
  stateFactory: true,
  namespaced: true
})

export default class TemplatesModule extends VuexModule {
  /**
   * * Массив всех dropDowns
   */
  dropDownValues: DropDown[] = []

  /**
   * * Массив всех template исходя из запроса
   */
  templates: ResponseData<Template> = {
    data: [],
    paginator: defaultPaginator
  }

  /**
   * * Текущая template
   */
  template: Template = {
    title: undefined,
    h1: '',
    description: '',
    keywords: '',
    typeId: null,
    categoryId: null,
    additionalFields: null
  }

  // ? ______________ getters ______________

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      title: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите название шаблона', trigger: ['blur'] }],
      h1: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите h1', trigger: ['blur'] }],
      additionalFields: [{ required: true, message: 'Введите название поля' }]
    }
  }

  /**
   * * Получить массив dropDowns
   */
  get dropDowns (): DropDown[] {
    return this.dropDownValues
  }

  /**
   * * Получить массив templates
   */
  get templatesList (): ResponseData<Template> {
    return this.templates
  }

  /**
   * * Получить template из массива templates
   */
  get templateById () {
    const templates = this.templates
    return function (id: number): Template | undefined {
      return templates.data.find(template => template.id === id)
    }
  }

  /**
   * * Получить текущую template для измения или создания template
   */
  get currentTemplate () {
    return this.template
  }

  // ? ______________ setters ______________
  /**
   * * Установить массив dropDowns
   * @param dropDowns массив dropDowns
   */
  @Mutation
  setDropDowns (dropDowns: DropDown[]) {
    this.dropDownValues = dropDowns
  }

  /**
   * * Установить массив Templates
   * @param templates массив Templates
   */
  @Mutation
  setTemplatesList (templates: ResponseData<Template>) {
    this.templates = templates
  }

  /**
   * * Установить CurrentTemplate для измения или создания template
   * @param template текущая Template, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentTemplate (template: Template) {
    this.template = template
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentTemplate () {
    this.template = {
      title: undefined,
      h1: '',
      description: '',
      keywords: '',
      typeId: null,
      categoryId: null,
      additionalFields: null
    }
  }

  @Mutation
  resetTemplates () {
    this.templates = {
      data: [],
      paginator: defaultPaginator
    }
  }

  @Mutation
  resetDropDowns () {
    this.dropDownValues = []
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список Templates
   */
  @Action({
    rawError: true,
    commit: 'setTemplatesList'
  })
  async getTemplates (inputParams: InputParams | null = null) {
    try {
      const { data } = await $axios.get('/seo/template', { params: inputParams })
      const response: ResponseData<Template> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить template по id
   * @param id id template, который мы хотим получить
   */
  @Action({
    rawError: true, commit: 'setCurrentTemplate'
  })
  async getTemplate (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/seo/template/${id}`)
      const response: Template = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить template
   */
  @Action({
    rawError: true
  })
  async editTemplate () {
    try {
      const { id, ...template } = this.currentTemplate
      const { data: { data } } = await $axios.put(`/seo/template/${id}`, template)
      const response: Template = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать Template
   */
  @Action({
    rawError: true
  })
  async createTemplate () {
    try {
      const { data: { data } } = await $axios.post('/seo/template', this.currentTemplate)
      const response: Template = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить template по id
   * @param id  id template, который мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removeTemplate () {
    try {
      const { data: { data } } = await $axios.delete(`/seo/template/${this.currentTemplate.id}`)
      const response: Template = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
  * * Запрос на список drop-down в templates
   */

  @Action({
    rawError: true,
    commit: 'setDropDowns'
  })
  async getDropDowns () {
    try {
      const { data: { data } } = await $axios.get('/seo/template/drop-down')
      const response: DropDown[] = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
  * * Запрос на список синхронизацию
   */
  @Action({
    rawError: true
  })
  async syncTemplates () {
    try {
      await $axios.post('/seo/template/sync')
      return true
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
