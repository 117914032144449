
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'UiTags'
})
export default class UiTags extends Vue {
  @Prop({
    type: String,
    required: true,
    default: ''
  })
    tags!: string

  /**
   * Модель редактируемого тега
   */
  newTagValue = ''
  /**
   * Состояние видимости формы ввода тега
   */
  newTagInputVisibility = false

  get tagList () {
    return this.tags?.length ? this.tags?.split(',') : []
  }

  /**
   * Метод открытия формы нового тега
   */
  showNewTagInput () {
    this.newTagInputVisibility = true
  }

  /**
   * Метод закрытия формы нового тега
   */
  hideNewTagInput () {
    this.newTagInputVisibility = false
    this.newTagValue = ''
  }

  /**
   * Метод добавления тега
   */
  handleInputConfirm () {
    if (!this.newTagValue) {
      this.hideNewTagInput()
      return
    }
    this.$emit('update', [...(this.tagList || []), this.newTagValue]?.join(','))
    this.hideNewTagInput()
  }

  /**
   * Метод удаления тега
   * @param index индекс тега
   */
  removeTag (index: number) {
    const copy = [...this.tagList]
    copy.splice(index, 1)
    this.$emit('update', copy.join(','))
  }
}
