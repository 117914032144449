// import { NuxtAppOptions } from '@nuxt/types/app'
import { PaymentsPlugin, PaymentType } from './interfaces'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (paymentsStore:any) {
  const api: PaymentsPlugin = {
  // ? ________ getters ________
    /**
     * * Получить способы оплаты
     */
    get paymentTypes (): ResponseData<PaymentType> {
      return paymentsStore.paymentTypes
    },

    /**
     * * Получить способ оплаты
     */
    get paymentType (): PaymentType {
      return paymentsStore.paymentType
    },

    get validators (): ValidatorParams {
      return paymentsStore.validators
    },

    paymentTypeById (id: number): PaymentType | undefined {
      return paymentsStore.paymentTypeById(id)
    },

    // ? ________ setters ________

    /**
     * * Установить способы оплаты
     */
    set paymentTypes (value: ResponseData<PaymentType>) {
      paymentsStore.setPaymentTypes(value)
    },

    /**
     * * Установить способ оплаты
     */
    set paymentType (value: PaymentType) {
      paymentsStore.setPaymentType(value)
    },

    resetCurrentPaymentType (): void {
      paymentsStore.resetCurrentPaymentType()
    },

    resetPaymentTypes (): void {
      paymentsStore.resetPaymentTypes()
    },

    // ? ________ actions ________

    /**
     * * Запрос на загрузку способов оплаты
     * @param pageParams - параметры страниц
     */
    async getPaymentTypes (pageParams?: PageParams): Promise<ResponseData<PaymentType>> {
      const data = await paymentsStore.getPaymentTypes(pageParams)
      return data
    },

    /**
     * * Запрос на загрузку способа оплаты по id
     * @param id - id способа оплаты
     */
    getPaymentTypeById (id: number): Promise<PaymentType> {
      return paymentsStore.getPaymentTypeById(id)
    },

    /**
     * * Запрос на редактирование способа оплаты
     * @param editPaymentTypeById - объект редактирования способа оплаты
     */
    editPaymentTypeById (): Promise<PaymentType> {
      return paymentsStore.editPaymentTypeById()
    }
  }

  return api
}
