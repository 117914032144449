import { Component, Vue } from 'nuxt-property-decorator'
import {
  Participant,
  ParticipantsResponse,
  Raffle,
  RafflesResponse,
  RequestParams,
  UploadParticipantsModes
} from './interface'

@Component
export default class RafflesMixin extends Vue {
  /**
   * * Очищение данных модулю стора при уходе со страницы
   */
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    // --- PARTICIPANTS --- //
    this.$raffles.reset_participant()
    this.$raffles.reset_participants()
    this.$raffles.reset_participantsFilters()
    // --- RAFFLES --- //
    this.$raffles.reset_raffle()
    this.$raffles.reset_raffles()
    this.$raffles.reset_rafflesFilters()
    next()
  }

  // ------------------------ DATA ------------------------ //

  // ------------------------ METHODS ------------------------ //

  // ------------ RAFFLES ------------ //

  /**
   * * Обработчик получения списка розыгрышей
   * @param requestParams - параметры пагинации и сортировки
   */
  async get_raffles (
    requestParams?: RequestParams<keyof Raffle>,
    throwError?: boolean
  ): Promise<RafflesResponse | undefined> {
    try {
      this.$wait.start('get_raffles')
      return await this.$raffles.get_raffles(requestParams)
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      if (throwError) {
        throw e
      }
    } finally {
      this.$wait.end('get_raffles')
    }
  }

  /**
   * * Обработчик добавления розыгрыша
   * @returns
   */
  async create_raffle (throwError?: boolean): Promise<Raffle | undefined> {
    try {
      this.$wait.start('create_raffles')
      return await this.$raffles.create_raffle()
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      if (throwError) {
        throw e
      }
    } finally {
      this.$wait.end('create_raffles')
    }
  }

  /**
   * * Обработчик изменения розыгрыша
   * @returns
   */
  async edit_raffle (throwError?: boolean): Promise<Raffle | undefined> {
    try {
      this.$wait.start('edit_raffles')
      return await this.$raffles.edit_raffle()
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      if (throwError) {
        throw e
      }
    } finally {
      this.$wait.end('edit_raffles')
    }
  }

  /**
   * * Обработчик удаления розыгрыша
   * @returns
   */
  async remove_raffle (code: string, throwError?: boolean): Promise<Raffle | undefined> {
    try {
      this.$wait.start('remove_raffles')
      return await this.$raffles.remove_raffle({ code })
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      if (throwError) {
        throw e
      }
    } finally {
      this.$wait.end('remove_raffles')
    }
  }

  // ------------ PARTICIPANTS ------------ //

  /**
   * * Обработчик получения списка участников розыгрыша
   * @param requestParams - параметры пагинации и сортировки
   */
  async get_participants (
    requestParams?: RequestParams<keyof Participant>,
    throwError?: boolean
  ): Promise<ParticipantsResponse | undefined> {
    try {
      this.$wait.start('get_participants')
      return await this.$raffles.get_participants(requestParams)
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      if (throwError) {
        throw e
      }
    } finally {
      this.$wait.end('get_participants')
    }
  }

  /**
   * * Обработчик добавления участника розыгрыша
   */
  async create_participant (throwError?: boolean): Promise<Participant | undefined> {
    try {
      this.$wait.start('create_participant')
      return await this.$raffles.create_participant()
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      if (throwError) {
        throw e
      }
    } finally {
      this.$wait.end('create_participant')
    }
  }

  /**
   * * Обработчик изменения участника розыгрыша
   */
  async edit_participant (throwError?: boolean): Promise<Participant | undefined> {
    try {
      this.$wait.start('edit_participant')
      return await this.$raffles.edit_participant()
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      if (throwError) {
        throw e
      }
    } finally {
      this.$wait.end('edit_participant')
    }
  }

  /**
   * * Обработчик удаления участника розыгрыша
   * @param id - id участника
   * @returns - участник розыгрыша
   */
  async remove_participant (id: number, throwError?: boolean): Promise<Participant | undefined> {
    try {
      this.$wait.start('remove_participant')
      return await this.$raffles.remove_participant({ id })
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      if (throwError) {
        throw e
      }
    } finally {
      this.$wait.end('remove_participant')
    }
  }

  /**
   * * Обработчик удаления всех участников розыгрыша
   * @param code - code розыгрыша
   * @returns
   */
  async remove_participants (code: string, throwError?: boolean): Promise<void> {
    try {
      this.$wait.start('remove_participants')
      await this.$raffles.remove_participants(code)
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      if (throwError) {
        throw e
      }
    } finally {
      this.$wait.end('remove_participants')
    }
  }

  /**
   * * Обработчик добавления участников розыгрыша с помощью excel файла
   * @param param0 - - { files, updateExists} - файл для загрузки, режим обновления участников
   * @returns
   */
  async upload_participants ({
    file,
    updateExists,
    throwError
  }: {
    file: FormData,
    updateExists: UploadParticipantsModes,
    throwError?: boolean
  }): Promise<void> {
    try {
      this.$wait.start('upload_participants')
      return await this.$raffles.upload_participants({ file, updateExists })
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      if (throwError) {
        throw e
      }
    } finally {
      this.$wait.end('upload_participants')
    }
  }
}
