import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class ReviewsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$reviews.resetFilters()
    this.$reviews.resetReview()
    this.$reviews.resetReviewParams()
    this.$reviews.resetReviewTypes()
    next()
  }

  // ? ___________ ReviewsTypes ______________

  /**
   * * Получение списка типов отзывов
   * @returns список типов отзывов
   */
  async getReviewTypes () {
    try {
      this.$wait.start('getReviewTypes')
      return await this.$reviews.getReviewTypes()
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getReviewTypes')
    }
  }

  // ? ___________  ReviewsParams ______________

  /**
   * * Получение параметров
   * @returns список параметров
   */
  async getReviewParams () {
    try {
      this.$wait.start('getReviewParams')
      return await this.$reviews.getReviewParams()
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getReviewParams')
    }
  }

  /**
   * * Добавление параметра
   * @param typeId - id типа
   * @param formParamTypeId - id formParamType
   * @returns параметр
   */
  async createReviewParam (typeId: number, formParamTypeId: number) {
    try {
      this.$wait.start('createReviewParam')
      const data = await this.$reviews.createReviewParam({ typeId, formParamTypeId })
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Добавлен новый параметр'
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createReviewParam')
    }
  }

  /**
   * * Удаление параметра
   * @param ids - id параметра
   * @returns параметр
   */
  async removeReviewParam (ids: number[]) {
    try {
      this.$wait.start('removeReviewParam')
      const data = await this.$reviews.removeReviewParam(ids[0])
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Параметр удален'
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeReviewParam')
    }
  }

  // ? ___________  Reviews ______________

  /**
   * * Запрос на получение списка отзывов
   * @param pageParams - параметры пагинатора
   * @returns список отзывов
   */
  async getReviews (pageParams: PageParams | null = null) {
    try {
      this.$wait.start('getReviews')
      return await this.$reviews.getReviews(pageParams)
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getReviews')
    }
  }

  /**
   * * Запрос на создание отзыва
   * @returns отзыв
   */
  async createReview () {
    try {
      this.$wait.start('createReview')
      const data = await this.$reviews.createReview()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлен отзыв ${data.id}`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createReview')
    }
  }

  /**
   * * Запрос на изменение отзыва
   * @returns отзыв
   */
  async editReview () {
    try {
      this.$wait.start('editReview')
      const data = await this.$reviews.editReview()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Отзыв изменён ${data.id}`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editReview')
    }
  }

  /**
   * * Запрос на удаление отзыва
   * @returns отзыв
   */
  async removeReview (id: number) {
    try {
      this.$wait.start('removeReview')
      const data = await this.$reviews.removeReview(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Отзыв ${data.id} удален`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeReview')
    }
  }
}
