import { Vue, Component, Watch } from 'nuxt-property-decorator'
import defaultPageSize from '~/utils/page-sizes'

@Component
export default class PageMixin extends Vue {
  /**
   * * Сортировка
   **/
  @Watch('$route.query.sort')
  onChangeSort (newQuery?: string, oldQuery?: string) { // убрать иконку активной сортировки при изменение столбца сортировки
    if (newQuery !== oldQuery) {
      this.mountedSort()
      if (oldQuery) {
        // @ts-ignore
        this.$refs[oldQuery].columnConfig.order = ''
      }
    }
  }

  @Watch('$route.query.order')
  onChangeOrder (newQuery?: string, oldQuery?: string) {
    if (newQuery !== oldQuery) {
      this.mountedSort()
    }
  }

  mounted () {
    this.$wait.end('leaveRouter')
  }

  /**
   * * Сортировка
   */
  changeSort (data: any) {
    this.$router.push({
      query: {
        ...this.$route.query,
        page: '1',
        pageSize: this.$route.query.pageSize ?? defaultPageSize.default,
        sort: !data.order ? undefined : data.prop,
        order: data.order === 'ascending' ? 'ASC' : data.order === 'descending' ? 'DESC' : undefined
      }
    })
  }

  /**
   * * Подсвечивание icon sort в таблице по route.query
   */
  mountedSort () {
    if (this.$route.query.sort && this.$route.query.order) { // в таблице отобразить сортировку при обновлении страницы
      // @ts-ignore
      this.$refs[this.$route.query.sort as string].columnConfig.order = (this.$route.query.order === 'ASC' ? 'ascending' : this.$route.query.order === 'DESC' ? 'descending' : '')
    }
  }

  /**
   ** Смена страницы
   */
  changePage (page: string) {
    this.$router.push({
      query: {
        ...this.$route.query,
        page
      }
    })
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  /**
   ** Смена кол-ва элементов на страницу
   */
  changeSize (size: string) {
    this.$router.push({
      query: {
        ...this.$route.query,
        pageSize: size,
        page: '1'
      }
    })
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
