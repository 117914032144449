import { Vue, Component } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'
@Component
export default class StickerMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$stickers.resetCurrentSticker()
    next()
  }

  /**
   * * Получение списка стикеров
   * @param pageParams - параметры пагинации
   * @returns список стикеров
   */
  async getStickers (pageParams: PageParams | null = null) {
    try {
      this.$wait.start('getStickers')
      return await this.$stickers.getStickers(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getStickers')
    }
  }

  /**
   * * Получение стикера по id
   * @param id - id стикера
   * @returns стикер
   */
  async getSticker (id: number) {
    try {
      this.$wait.start('getSticker')
      return await this.$stickers.getSticker(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getSticker')
    }
  }

  /**
   * * Создание стикера
   * @returns стикер
   */
  async createSticker () {
    try {
      this.$wait.start('createSticker')
      const data = await this.$stickers.createSticker()
      this.$notify({
        type: 'success',
        title: 'Создано',
        message: `Стикер ${data.name} создан`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createSticker')
    }
  }

  /**
   * * Изменение стикера
   * @returns стикер
   */
  async editSticker () {
    try {
      this.$wait.start('editSticker')
      const data = await this.$stickers.editSticker()
      this.$notify({
        type: 'success',
        title: 'Изменен',
        message: `Стикер ${data.name} изменен`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editSticker')
    }
  }

  /**
   * * Удаление стикера
   * @param id - id стикера
   * @returns стикер
   */
  async removeSticker (id: number) {
    try {
      this.$wait.start('removeSticker')
      const data = await this.$stickers.removeSticker(id)
      this.$notify({
        type: 'success',
        title: 'Выполено',
        message: `Стикер ${data.name} удален`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeSticker')
    }
  }
}
