import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { Permission } from './interfaces'
import { $axios } from '~/utils/api'
import { FormError } from '~/store/interfaces'

@Module({
  name: 'permissions',
  stateFactory: true,
  namespaced: true
})
export default class PermissionsModule extends VuexModule {
  /**
   * * Список разрешений
   */
  permissionsValue: Permission[] = []

  // ? ------ getters ------ //

  /**
   * * Получить список разрешений
   */
  get permissions (): Permission[] {
    return this.permissionsValue
  }

  // ? ------ setters ------ //

  /**
   * * Установить список разрешений
   * @param permissions список разрешений
   */
  @Mutation
  setPermissions (permissions: Permission[]) {
    this.permissionsValue = permissions
  }

  @Mutation
  resetPermissions () {
    this.permissionsValue = []
  }

  // ? ------ actions ------ //

  /**
   * * Запрос на получение списка разрешений
   */
  @Action({
    rawError: true,
    commit: 'setPermissions'
  })
  async getPermissions (): Promise<Permission[]> {
    try {
      const { data } = await $axios.get('/permissions')
      const response: Permission[] = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
