import { ReindexerPlugin } from '~/store/modules/reindexer/interfaces'

export default function getPluginApi (reindexerStore:any) {
  const api: ReindexerPlugin = {
    export (url: string): Promise<boolean> {
      return reindexerStore.export(url)
    }
  }
  return api
}
