import { FormParamsPlugin, FormParam, FormParamType, Params } from './interfaces'
import FormParamsModule from './store'
import { ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (store: FormParamsModule) {
  const api: FormParamsPlugin = {
    // ? ________ getters ________
    get formParams (): ResponseData<FormParam> {
      return store.formParams
    },

    get formParam (): FormParam {
      return store.formParam
    },
    get paramTypeParamsValidators (): ValidatorParams {
      return store.paramTypeParamsValidators
    },

    formParamById (id: number): FormParam | undefined {
      return store.formParamById(id)
    },

    get formParamTypes (): ResponseData<FormParamType> {
      return store.formParamTypes
    },

    get formParamType (): FormParamType {
      return store.formParamType
    },

    formParamTypeById (id: number): FormParamType | undefined {
      return store.formParamTypeById(id)
    },

    // ? ________ setters ________

    set formParams (formParams: ResponseData<FormParam>) {
      store.setFormParams(formParams)
    },

    set formParam (formParam: FormParam) {
      store.setFormParam(formParam)
    },

    resetFormParam () {
      store.resetFormParam()
    },

    resetFormParams () {
      store.resetFormParams()
    },

    set formParamTypes (formParamTypes: ResponseData<FormParamType>) {
      store.setFormParamTypes(formParamTypes)
    },

    set formParamType (formParamType: FormParamType) {
      store.setFormParamType(formParamType)
    },

    resetFormParamType () {
      store.resetFormParamType()
    },

    resetFormParamTypes () {
      store.resetFormParamTypes()
    },

    // ? ________ actions ________

    async getFormParams (params: Params | null = null, save = true): Promise<ResponseData<FormParam>> {
      const data = await store.getFormParams(params)
      if (save) {
        store.setFormParams(data)
      }
      return data
    },

    getFormParamById (id: number): Promise<FormParam> {
      return store.getFormParamById(id)
    },

    createFormParam (): Promise<FormParam> {
      return store.createFormParam()
    },

    editFormParam (): Promise<FormParam> {
      return store.editFormParam()
    },

    removeFormParam (id: number): Promise<FormParam> {
      return store.removeFormParam(id)
    },

    getFormParamTypes (params: Params | null = null): Promise<ResponseData<FormParamType>> {
      return store.getFormParamTypes(params)
    },

    getFormParamTypeById (id: number): Promise<FormParamType> {
      return store.getFormParamTypeById(id)
    },

    createFormParamType (): Promise<FormParamType> {
      return store.createFormParamType()
    },

    editFormParamType (): Promise<FormParamType> {
      return store.editFormParamType()
    },

    removeFormParamType (id: number): Promise<FormParamType> {
      return store.removeFormParamType(id)
    }
  }

  return api
}
