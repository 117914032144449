import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class OfferMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$offers.resetCurrentOffer()
    next()
  }

  /**
   * * Получение списка предложений
   * @param pageParams - параметры пагинации
   * @returns список предложений
   */
  async getOffers (pageParams?: PageParams) {
    try {
      this.$wait.start('getOffers')
      return await this.$offers.getOffers(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getOffers')
    }
  }

  /**
   * * Получение предложения по id
   * @param id - id предложения
   * @returns предложение
   */
  async getOffer (id: number) {
    try {
      return await this.$offers.getOffer(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    }
  }

  /**
   * * Получение предложения по id
   * @param id - id предложения
   * @returns предложение
   */
  async getRequestOfferStatuses () {
    try {
      this.$wait.start('getRequestOfferStatuses')
      return await this.$offers.getRequestOfferStatuses()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getRequestOfferStatuses')
    }
  }

  /**
   * * Изменения статуса заявки по id
   * @param id - id заявки
   * @param status - status заявки
   */
  async changeRequestOfferStatus (id: number, status: number) {
    try {
      this.$wait.start('changeRequestOfferStatus')
      const data = await this.$offers.changeRequestOfferStatus(id, status)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Статус заявки ${id} изменен`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('changeRequestOfferStatus')
    }
  }

  /**
   * * Создание заказа по id торгового предложения
   * @param id - id предложения
   */
  async createOrderByOffer (id: number) {
    try {
      this.$wait.start('createOrderByOffer')
      const data = await this.$offers.createOrderByOffer(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Заказ по тп ${id} создан`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createOrderByOffer')
    }
  }
}
