import { ErrorLog, ErrorLogsPlugin } from './interfaces'

export default function getPluginApi (errorLogsStore:any) {
  const api: ErrorLogsPlugin = {
  // ? ______________ getters ______________

    get errorLogs () {
      return errorLogsStore.errorLogs
    },

    // ? ______________ setters ______________

    set errorLogs (errorLogs: ErrorLog[]) {
      errorLogsStore.setErrorLogs(errorLogs)
    },

    resetErrorLogs (): void {
      errorLogsStore.resetErrorLogs()
    }
  }

  return api
}
