import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, PageParams, defaultData, ResponseData } from '../../interfaces'
import { ArchiveGroup } from './interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'archive',
  stateFactory: true,
  namespaced: true
})
export default class ArchiveModule extends VuexModule {
  /**
   * * Список групп доставки
   */
  archiveValue: ResponseData<ArchiveGroup> = defaultData

  // ? ______________ getters ______________
  /**
   * * Получить список групп доставки
   */
  get archive (): ResponseData<ArchiveGroup> {
    return this.archiveValue
  }

  // ? ______________ setters ______________
  /**
   * * Установить список групп доставок
   */
  @Mutation
  setArchive (archive: ResponseData<ArchiveGroup>) {
    this.archiveValue = archive
  }

  // ? ______________ actions ______________

  /**
   * * Запрос на получение списка групп доставок
   * @param pageParams параметры пагинцаии
   */
  @Action({
    rawError: true,
    commit: 'setArchive'
  })
  async getArchive (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/one-s-archive', { params: { ...pageParams } })
      const response: ResponseData<ArchiveGroup> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
