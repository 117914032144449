import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ResponseData, PageParams, ValidatorParams } from '../../interfaces'
import { CustomFilters } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'customFilters',
  stateFactory: true,
  namespaced: true
})

export default class CustomFiltersModule extends VuexModule {
  /**
   * * Массив всех customFilter исходя из запроса
   */
  customFilters: ResponseData<CustomFilters> = defaultData

  /**
   * * Текущая customFilter
   */
  customFilter: CustomFilters = {
    code: '',
    name: '',
    groupCode: '',
    groupName: ''
  }

  // ? ______________ getters ______________

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      word: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите слово', trigger: ['blur'] }],
      man: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите слово в мужском роде', trigger: ['blur'] }],
      woman: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите слово в женском роде', trigger: ['blur'] }],
      neuter: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите слово среднем роде', trigger: ['blur'] }]
    }
  }

  /**
   * * Получить массив customFilters и пагинацией
   */
  get customFiltersList (): ResponseData<CustomFilters> {
    return this.customFilters
  }

  /**
   * * Получить customFilter из массива customFilters
   */
  get customFilterById () {
    const customFilters = this.customFilters
    return function (id: number): CustomFilters | undefined {
      return customFilters.data.find(customFilter => customFilter.id === id)
    }
  }

  /**
   * * Получить текущую customFilter для измения или создания customFilter
   */
  get currentCustomFilter () {
    return this.customFilter
  }

  // ? ______________ setters ______________

  /**
   * * Установить массив CustomFilters
   * @param customFilters массив CustomFilters
   */
  @Mutation
  setCustomFiltersList (customFilters: ResponseData<CustomFilters>) {
    this.customFilters = customFilters
  }

  /**
   * * Установить CurrentCustomFilter для измения или создания customFilter
   * @param customFilter текущая CustomFilter, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentCustomFilter (customFilter: CustomFilters) {
    this.customFilter = customFilter
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentCustomFilter () {
    this.customFilter = {
      code: '',
      name: '',
      groupCode: '',
      groupName: ''
    }
  }

  @Mutation
  resetCustomFilters () {
    this.customFilters = defaultData
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список CustomFilters
   */
  @Action({
    rawError: true,
    commit: 'setCustomFiltersList'
  })
  async getCustomFilters (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/shop/custom-filters', { params: pageParams })
      const response: ResponseData<CustomFilters> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить customFilter по id
   * @param id id customFilter, который мы хотим получить
   */
  @Action({
    rawError: true, commit: 'setCurrentCustomFilter'
  })
  async getCustomFilter (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/shop/custom-filters/${id}`)
      const response: CustomFilters = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить customFilter
   */
  @Action({
    rawError: true
  })
  async editCustomFilter () {
    try {
      const { id, ...customFilter } = this.currentCustomFilter
      const { data: { data } } = await $axios.put(`/shop/custom-filters/${id}`, customFilter)
      const response: CustomFilters = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать CustomFilter
   */
  @Action({
    rawError: true
  })
  async createCustomFilter () {
    try {
      const { data: { data } } = await $axios.post('/shop/custom-filters', this.currentCustomFilter)
      const response: CustomFilters = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить customFilter по id
   * @param id  id customFilter, который мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removeCustomFilter (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/shop/custom-filters/${id}`)
      const response: CustomFilters = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
