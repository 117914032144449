import { Component, Vue } from 'nuxt-property-decorator'
import { BillRequestData, Params } from './interfaces'

@Component
export default class OrderBillsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$orderBills.resetFilters()
    this.$orderBills.resetOrderBill()
    next()
  }

  /**
   * * Получение списка счетов заказа
   * @param params - параметры пагинации и id заказа
   * @returns список счетов
   */
  async getOrderBills (params: Params) {
    try {
      this.$wait.start('getOrderBills')
      return await this.$orderBills.getOrderBills(params)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getOrderBills')
    }
  }

  /**
   * * Создание счёта заказа
   * @param orderId - id заказа
   * @param billRequestData
   * @returns счёт
   */
  async createOrderBill (orderId: number, billRequestData: BillRequestData | undefined = undefined) {
    try {
      this.$wait.start('createOrderBill')
      const data = await this.$orderBills.createOrderBill(orderId, billRequestData)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Счёт добавлен'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createOrderBill')
    }
  }
}
