
import Vue from 'vue'
import { Component } from 'nuxt-property-decorator'
import SidebarMenu from '@/components/common/sidebar/index.vue'
import HeaderComponent from '@/components/common/header/index.vue'
import ErrorLog from '@/components/common/error-log/index.vue'
import TagsView from '@/components/common/tags-view/index.vue'

@Component({
  components: { SidebarMenu, HeaderComponent, TagsView, ErrorLog },
  middleware: ['auth']
})
export default class DefaultLayout extends Vue {
  public head () {
    return {
      link: [{ rel: 'icon', type: 'image/x-icon', href: this.$config.S3_DOMAIN + '/icons/favicon.ico' }]
    }
  }

  /**
   * *________________ Data ______________________
   */
  sideBar = false
  errorDialogVisible = false

  /**
   * *________________ Mounted ______________________
   */
  mounted () {
    // @ts-ignore
    this.sideBar = !!this.$refs.sidebar?.isCollapse
    const errorLogs_inStorage = localStorage.getItem('errorLogs')
    const arrErrorLogs_inStorage = errorLogs_inStorage ? JSON.parse(errorLogs_inStorage) : []
    if (arrErrorLogs_inStorage && arrErrorLogs_inStorage.length > 50) {
      const itemsToDel = arrErrorLogs_inStorage.length - 50
      arrErrorLogs_inStorage.splice(0, itemsToDel)
    }
    this.$errorLogs.errorLogs = arrErrorLogs_inStorage
    localStorage.setItem('errorLogs', JSON.stringify(this.$errorLogs.errorLogs))
  }

  /**
   * *________________ Methods ______________________
   */
  changeSideBar (status: boolean) {
    this.sideBar = !!status
  }

  openErrorLog () {
    this.errorDialogVisible = true
    const errorLogs_inStorage = localStorage.getItem('errorLogs')
    this.$errorLogs.errorLogs = errorLogs_inStorage ? JSON.parse(errorLogs_inStorage).map((errorLog:any) => { return { ...errorLog, viewed: true } }) : []
    localStorage.setItem('errorLogs', JSON.stringify(this.$errorLogs.errorLogs))
  }

  resetErrorLogs () {
    this.$errorLogs.resetErrorLogs()
    this.errorDialogVisible = false
  }
}
