import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import Vue from 'vue'
import { FormError, defaultData, ResponseData, PageParams, ValidatorParams, Filters } from '../../interfaces'
import { Point, DillerData } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'points',
  stateFactory: true,
  namespaced: true
})

export default class PointsModule extends VuexModule {
  /**
   * * Filters
   */
  filtersValue: Filters = {}

  /**
   * * Массив всех point исходя из запроса
   */
  points: ResponseData<Point> = defaultData

  /**
   * * Текущая point
   */
  point: Point = {
    name: '',
    zoom: 5,
    meta: {},
    address: '',
    images: [],
    tags: [],
    regionsId: 0
  }

  // ? ______________ getters ______________

  /**
   * * Получить массив filters
   */
  get filters (): Filters & DillerData {
    return this.filtersValue
  }

  /**
   * * Получить массив points и пагинацией
   */
  get pointsList (): ResponseData<Point> {
    return this.points
  }

  /**
   * * валидна ли форма
   */
  get validators (): ValidatorParams {
    return {
      name: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите название магазина', trigger: ['blur'] }],
      regionsId: [{ required: true, type: 'number', min: 1, message: 'Выберите регион', trigger: ['blur', 'change'] }],
      email: [{ type: 'email', message: 'Введите корректный email', trigger: ['blur'] }],
      address: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите адрес магазина', trigger: ['blur'] }]
    }
  }

  /**
   * * Получить point из массива points
   */
  get pointById () {
    const points = this.points
    return function (id: number): Point | undefined {
      const point = points.data.find(point => point.id === id)
      // @ts-ignore
      return {
        ...point,
        meta: {
          ...point?.meta,
          // @ts-ignore
          businessHours: point?.meta?.businessHours ? JSON?.parse(point?.meta?.businessHours) : {}
        }
      }
    }
  }

  /**
   * * Получить текущую point для измения или создания point
   */
  get currentPoint () {
    return this.point
  }

  // ? ______________ setters ______________

  /**
   * * Установить Filters
   * @param filters обьект Filters
   */
  @Mutation
  setFilters (filters: Filters & DillerData) {
    this.filtersValue = filters
  }

  /**
   * * Установить массив Points
   * @param points массив Points
   */
  @Mutation
  setPointsList (points: ResponseData<Point>) {
    this.points = points
  }

  /**
   * * Установить CurrentPoint для измения или создания point
   * @param point текущая Point, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentPoint (point: Point) {
    this.point = point
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentPoint () {
    this.point = {
      name: '',
      zoom: 5,
      meta: {},
      address: '',
      images: [],
      tags: []
    }
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetPointsList () {
    this.points = defaultData
  }

  /**
   * * Обнулить фильтры
   */
  @Mutation
  resetFilters () {
    this.filtersValue = {}
  }

  /**
   * Изменение свойства по ключу
   * @param param - { keys, value } ключи и значение свойства
   */
  @Mutation
  setItemPropByKeys ({
    keys,
    value
  } : {
    keys: (string | number)[],
    value: any
  }) {
    // * Прерывание операции, при отсутствии ключей
    if (!keys || !keys.length) { return }
    // * Создание инстанса сущности
    let stateInstance = this.point
    // * Сохранение индекса изменяемого свойства
    const setPropIndex = (keys.length - 1)
    // * Переменная сигнатуры отсутствующего параметра
    let missingParam
    // * Обход ключей родительских свойств сущности
    for (let keyIndex = 0; keyIndex < setPropIndex; keyIndex++) {
      // * Проверка наличия свойства по текущему ключу
      // @ts-ignore
      if (!stateInstance[keys[keyIndex]]) {
        // * Определение сигнатуры свойства по типу следующего ключа
        missingParam = typeof keys[keyIndex + 1] === 'number' ? [] : {}
        // * Если отсутствует, то реактивное добавление его сигнатуры, в сущность
        // @ts-ignore
        Vue.set(stateInstance, keys[keyIndex], missingParam)
      }
      // * Замена текущего инстанса, инстансом вложенного свойства
      // @ts-ignore
      stateInstance = stateInstance[keys[keyIndex]]
    }
    // * Реактивное изменение значения устанавливаемого свойства
    // @ts-ignore
    Vue.set(stateInstance, keys[setPropIndex], value)
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список Points по параметрам запроса
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setPointsList'
  })
  async getPoints (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/points/points', { params: { ...pageParams, ...this.filters } })
      const response: ResponseData<Point> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить point по id
   * @param id id point которую мы хотим получить
   */
  @Action({
    rawError: true,
    commit: 'setCurrentPoint'
  })
  async getPoint (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/points/points/${id}`)
      const response: Point = data
      return {
        ...response,
        meta: {
          ...response.meta,
          // @ts-ignore
          businessHours: response?.meta?.businessHours ? JSON?.parse(response.meta.businessHours) : {}
        }
      }
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить point
   */
  @Action({
    rawError: true
  })
  async editPoint () {
    try {
      const { id, ...point } = this.currentPoint
      const { data: { data } } = await $axios.put(`/points/points/${id}`, {
        ...point,
        meta: {
          ...point.meta,
          businessHours: JSON.stringify(point.meta.businessHours)
        }
      })
      const response: Point = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать Point
   */
  @Action({
    rawError: true
  })
  async createPoint () {
    try {
      const { data: { data } } = await $axios.post('/points/points', {
        ...this.currentPoint,
        meta: {
          ...this.currentPoint.meta,
          businessHours: JSON?.stringify(this.currentPoint?.meta?.businessHours)
        }
      })
      const response: Point = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить point по id
   * @param id  id point которую мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removePoint (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/points/points/${id}`)
      const response: Point = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
