import { EmailTemplate, EmailTemplatesPlugin, Filters } from './interfaces'
import EmailTemplatesModule from './store'
import { PageParams, ValidatorParams, ResponseData } from '~/store/interfaces'

export default function getPluginApi (store: EmailTemplatesModule) {
  const api: EmailTemplatesPlugin = {
    // ? ________ getters ________
    get filters (): Filters {
      return store.filters
    },

    get emailTemplates (): ResponseData<EmailTemplate> {
      return store.emailTemplates
    },

    get currentTemplate (): EmailTemplate {
      return store.currentTemplate
    },

    get validators (): ValidatorParams {
      return store.validators
    },

    get validateData (): boolean {
      return store.validateData
    },

    templateById (id: number): EmailTemplate | undefined {
      return store.templateById(id)
    },

    // ? ________ setters ________
    set filters (filters: Filters) {
      store.setFilters(filters)
    },

    set emailTemplates (templates: ResponseData<EmailTemplate>) {
      store.setEmailTemplates(templates)
    },

    set currentTemplate (template: EmailTemplate) {
      store.setCurrentTemplate(template)
    },

    resetCurrentTemplate () {
      store.resetCurrentTemplate()
    },

    resetEmailTemplates () {
      store.resetEmailTemplates()
    },

    // ? ________ actions ________
    async getEmailTemplates (pageParams: PageParams | null = null, save: boolean = true): Promise<ResponseData<EmailTemplate>> {
      const templates = await store.getEmailTemplates(pageParams)
      if (save) { store.setEmailTemplates(templates) }
      return templates
    },

    createEmailTemplate () {
      return store.createEmailTemplate()
    },

    getEmailTemplateById (id) {
      return store.getEmailTemplateById(id)
    },

    editEmailTemplate () {
      return store.editEmailTemplate()
    },

    removeEmailTemplate (id: number) {
      return store.removeEmailTemplate(id)
    },

    sendEmail (id: number) {
      return store.sendEmail(id)
    }
  }
  return api
}
