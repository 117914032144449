import { Delivery, DeliveryPlugin, Filters, Params } from './interfaces'
import DeliveryModule from './store'
import { ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (store: DeliveryModule) {
  const api: DeliveryPlugin = {
    // ? ________ getters ________

    get filters (): Filters {
      return store.filters
    },

    get deliveries (): ResponseData<Delivery> {
      return store.deliveries
    },

    get delivery (): Delivery {
      return store.delivery
    },

    deliveryById (id: number): Delivery | undefined {
      return store.deliveryById(id)
    },

    get validators (): ValidatorParams {
      return store.validators
    },

    // ? ________ getters ________

    set filters (filters: Filters) {
      store.setFilters(filters)
    },

    resetFilters () {
      store.resetFilters()
    },

    set deliveries (deliveries: ResponseData<Delivery>) {
      store.setDeliveries(deliveries)
    },

    set delivery (delivery: Delivery) {
      store.setDelivery(delivery)
    },

    resetDeliveries () {
      store.resetDeliveries()
    },

    resetDelivery () {
      store.resetDelivery()
    },

    // ? ________ actions ________

    getDeliveries (params: Params | null = null): Promise<ResponseData<Delivery>> {
      return store.getDeliveries(params)
    },

    getDeliveryById (id: number) {
      return store.getDeliveryById(id)
    },

    createDelivery (groupId: number) {
      return store.createDelivery(groupId)
    },

    editDelivery () {
      return store.editDelivery()
    },

    removeDelivery (id: number) {
      return store.removeDelivery(id)
    }
  }

  return api
}
