export default (value?: number | null) => {
  if (value === null || value === undefined) { return '' }
  const date = new Date(value * 1000)
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }
  return new Intl.DateTimeFormat('ru-RU', options).format(date)
}
