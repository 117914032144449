import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class PromoCodesMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$promoCodes.resetFilters()
    this.$promoCodes.resetCurrentPromoCode()
    next()
  }

  /**
   * * Запрос на список промокодов
   * @param pageParams - обьект пагинатора
   * @returns список промокодов
   */
  async getPromoCodes (pageParams?: PageParams) {
    try {
      this.$wait.start('getPromoCodes')
      return await this.$promoCodes.getPromoCodes({
        page: +(pageParams?.page ?? 1),
        pageSize: pageParams?.pageSize,
        sort: pageParams?.sort,
        order: pageParams?.order
      })
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getPromoCodes') }
  }

  /**
   * * Удаление промокода по id
   * @param id - id промокода
   * @returns промокод
   */
  async removePromoCode (id: number) {
    try {
      this.$wait.start('removePromoCode')
      const data = await this.$promoCodes.removePromoCode(id)
      this.$notify({
        type: 'success',
        title: 'Удалено',
        message: `Промокод №${data.id} удален`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removePromoCode') }
  }

  /**
   * * Создание промокода
   * @returns промокод
   */
  async createPromoCode () {
    try {
      this.$wait.start('createPromoCode')
      const data = await this.$promoCodes.createPromoCode()
      this.$notify({
        title: 'Успешно',
        message: `Промокод №${data.id} создан`,
        type: 'success'
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createPromoCode')
    }
  }

  /**
   * * Изменение промокода
   * @returns промокод
   */
  async editPromoCode () {
    try {
      this.$wait.start('editPromoCode')
      const data = await this.$promoCodes.editPromoCode()
      this.$notify({
        title: 'Успешно',
        message: `Промокод №${data.id} изменен`,
        type: 'success'
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editPromoCode')
    }
  }
}
