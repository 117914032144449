import { SearchTextReplacesPlugin, FiltersParams, SearchTextReplace } from './interfaces'
import SearchTextReplacesStore from './store'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'
export default function getPluginApi (searchTextReplacesStore: SearchTextReplacesStore) {
  const api: SearchTextReplacesPlugin = {
    // ? ________ getters ________

    // * Получить список searchTextReplaces и пагинатор
    get searchTextReplacesList (): ResponseData<SearchTextReplace> {
      return searchTextReplacesStore.searchTextReplacesList
    },

    get filters (): FiltersParams {
      return searchTextReplacesStore.filters
    },

    get validators (): ValidatorParams {
      return searchTextReplacesStore.validators
    },

    // * Получить searchTextReplace по id из массва searchTextReplaces
    searchTextReplaceById (id: number): SearchTextReplace | undefined {
      return searchTextReplacesStore.searchTextReplaceById(id)
    },

    // * Получить текущий searchTextReplace
    get currentSearchTextReplace (): SearchTextReplace {
      return searchTextReplacesStore.currentSearchTextReplace
    },

    // ? ________ setters ________

    // * Установить список searchTextReplaces и пагинатор
    set searchTextReplacesList (searchTextReplaces: ResponseData<SearchTextReplace>) {
      searchTextReplacesStore.setSearchTextReplacesList(searchTextReplaces)
    },

    set filters (filters: FiltersParams) {
      searchTextReplacesStore.setFilters(filters)
    },

    resetFilters () {
      searchTextReplacesStore.resetFilters()
    },

    // * Очистить форму
    resetCurrentSearchTextReplace () {
      searchTextReplacesStore.resetCurrentSearchTextReplace()
    },

    resetSearchTextReplaces () {
      searchTextReplacesStore.resetSearchTextReplaces()
    },

    // * Установить текщий searchTextReplace
    set currentSearchTextReplace (searchTextReplace: SearchTextReplace) {
      searchTextReplacesStore.setCurrentSearchTextReplace(searchTextReplace)
    },

    // ? ________ actions ________

    // * Запрос на получение списка searchTextReplaces и пагинатор
    getSearchTextReplaces (pageParams: PageParams | null = null): Promise<ResponseData<SearchTextReplace>> {
      return searchTextReplacesStore.getSearchTextReplaces(pageParams)
    },

    // * Запрос на получение searchTextReplace по id
    getSearchTextReplace (id: number): Promise<SearchTextReplace> {
      return searchTextReplacesStore.getSearchTextReplace(id)
    },

    // * Запрос на удаление searchTextReplace по id
    removeSearchTextReplace (id: number): Promise<SearchTextReplace> {
      return searchTextReplacesStore.removeSearchTextReplace(id)
    },

    // * Запрос на изменение searchTextReplace
    editSearchTextReplace (): Promise<SearchTextReplace> {
      return searchTextReplacesStore.editSearchTextReplace()
    },

    // * Запрос на создание searchTextReplace
    createSearchTextReplace (): Promise<SearchTextReplace> {
      return searchTextReplacesStore.createSearchTextReplace()
    },

    generateSTR (optionIds: number[]): Promise<any> {
      return searchTextReplacesStore.generateSTR(optionIds)
    },

    generateSTRById (id: number): Promise<any> {
      return searchTextReplacesStore.generateSTRById(id)
    }

  }
  return api
}
