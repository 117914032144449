import { Vue, Component } from 'nuxt-property-decorator'

@Component
export default class FeedsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    next()
  }

  // ?____________Группы Скидок_______________

  /**
   * * Получение списка фидов
   * @returns cписок фидов
   */
  async getFeeds () {
    this.$wait.start('getFeeds')
    try {
      return await this.$feeds.getFeeds()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getFeeds')
    }
  }

  /**
   * * Изменение комментария фида
   */
  async updateFeed () {
    try {
      this.$wait.start('updateFeed')
      await this.$feeds.updateFeed()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Фид ${this.$feeds.currentFeed.name} изменен`
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('updateFeed')
    }
  }

  /**
   * * Обновление фидов
   */
  async updateFeedsAll () {
    try {
      // Запуск лоадера
      this.$wait.start('updateFeedsAll')
      // Оправка запроса на обновление фидов
      await this.$feeds.updateFeedsAll()
      // Вызов нотифая при успешном завершении запроса
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Фиды обновлены'
      })
    } catch (e: any) {
      console.error(e)
      // Вызов нотифая при ошибке
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      // Остановка лоадера
      this.$wait.end('updateFeedsAll')
    }
  }
}
