import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'
import defaultPageSize from '~/utils/page-sizes'

@Component
export default class WordPluralsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$wordPlurals.resetCurrentWordPlural()
    next()
  }

  /**
   * * Запрос на wordPlural
   * @param pageParams параметры пагинатора (необязательный)
   * @returns список wordPlural
   */
  async getWordPlurals (pageParams?: PageParams) {
    try {
      this.$wait.start('getWordPlurals')
      return await this.$wordPlurals.getWordPlurals({
        page: +(pageParams?.page ?? 1),
        pageSize: +(pageParams?.pageSize ?? defaultPageSize.default),
        sort: pageParams?.sort,
        order: pageParams?.order
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getWordPlurals') }
  }

  /**
   * * Удаление seo/wordPlural по id
   * @param id - id wordPlural
   * @returns wordPlural
   */
  async removeWordPlural (id: number) {
    try {
      this.$wait.start('removeWordPlural')
      const data = await this.$wordPlurals.removeWordPlural(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Склонение слова ${data.single} удалено`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removeWordPlural') }
  }

  /**
   * * Создание seo/wordPlural
   * @returns wordPlural
   */
  async createWordPlural () {
    try {
      this.$wait.start('createWordPlural')
      const data = await this.$wordPlurals.createWordPlural()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Склонение слова ${data.single} создано`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('createWordPlural') }
  }

  /**
   * * Изменение seo/wordPlural
   * @returns wordPlural
   */
  async editWordPlural () {
    try {
      this.$wait.start('editWordPlural')
      const data = await this.$wordPlurals.editWordPlural()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Склонение слова ${data.single} изменено`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('editWordPlural') }
  }
}
