import { Vue, Component } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class DeliveryGroupsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$deliveryGroups.resetDeliveryGroup()
    next()
  }

  /**
   * * Получение списка групп доставок
   * @param pageParams - параметры пагинации
   * @returns список групп доставок
   */
  async getDeliveryGroups (pageParams: PageParams) {
    try {
      this.$wait.start('getDeliveryGroups')
      return await this.$deliveryGroups.getDeliveryGroups(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getDeliveryGroups')
    }
  }

  /**
   * * Получение группы доставок по id
   * @param id - id группы доставок
   * @returns группу доставки
   */
  async getDeliveryGroupById (id: number) {
    try {
      this.$wait.start('getDeliveryGroupById')
      return await this.$deliveryGroups.getDeliveryGroupById(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getDeliveryGroupById')
    }
  }

  /**
   * * Создание группы доставок
   * @returns группу доставки
   */
  async createDeliveryGroup () {
    try {
      this.$wait.start('createDeliveryGroup')
      const data = await this.$deliveryGroups.createDeliveryGroup()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлена группа доставок ${data.name}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createDeliveryGroup')
    }
  }

  /**
   * * Изменение группы доставок
   * @returns группу доставки
   */
  async editDeliveryGroup () {
    try {
      this.$wait.start('editDeliveryGroup')
      const data = await this.$deliveryGroups.editDeliveryGroup()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Группа доставок ${data.name} изменена`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editDeliveryGroup')
    }
  }

  /**
   * * Удаление группы доставок
   * @param id - id группы доставок
   * @returns группу доставки
   */
  async removeDeliveryGroup (id: number) {
    try {
      this.$wait.start('removeDeliveryGroup')
      const data = await this.$deliveryGroups.removeDeliveryGroup(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Группа доставок ${data.name} удалена`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeDeliveryGroup')
    }
  }

  /**
   * * Запрос на полный список групп доставок
   * @returns список групп доставок
   */
  async getDeliveryGroupsAll () {
    try {
      this.$wait.start('getDeliveryGroupsAll')
      return await this.$deliveryGroups.getDeliveryGroupsAll()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getDeliveryGroupsAll')
    }
  }
}
