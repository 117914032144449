const baseValidators = {
  authorName: [{
    required: true,
    label: 'Имя создателя',
    message: 'Введите отображаемое имя создателя',
    trigger: 'blur'
  }],

  recipientSource: [{
    required: true,
    type: 'string',
    label: 'Тип пользователей',
    message: 'Выберите тип пользователей для рассылки',
    trigger: 'blur'
  }],

  senderEmail: [{
    required: true,
    label: 'Email отправителя',
    message: 'Выберите email отправителя рассылки',
    trigger: 'blur'
  }],

  templateID: [{
    required: true,
    type: 'number',
    label: 'Шаблон письма',
    message: 'Выберите шаблон письма для рассылки',
    trigger: 'blur'
  }],

  title: [{
    required: true,
    label: 'Название рассылки',
    message: 'Введите название рассылки',
    trigger: 'blur'
  }],

  delayAt: [{
    required: false,
    label: 'Дата отложенной рассылки',
    message: 'Введите дату отложенной рассылки',
    trigger: 'blur'
  }]
}

//* Валдиация формы
const DIALOGS_VALIDATORS = {
  create: {
    ...baseValidators
  },

  edit: {
    ...baseValidators
  }
}

//* Поля модалок типов рассылки
const DEFAULT_MODAL_FIELDS = {
  title: { type: 'input' },
  authorName: { type: 'input' },
  senderEmail: {
    type: 'select',
    options: 'senderEmails'
  },
  recipientSource: {
    type: 'select',
    options: 'recipientsSource',
    optionLabel: 'title',
    optionValue: 'code'
  },
  templateID: {
    type: 'select',
    options: 'templateIDs',
    optionLabel: 'name',
    optionValue: 'id'
  },
  delayAt: { type: 'date' }
}

//* Поля форм по умолчанию
const DEFAULT_FORM_STATE = {
  authorName: '',
  config: undefined,
  createdAt: undefined,
  id: undefined,
  recipientSourceParams: undefined,
  recipientSource: '',
  senderEmail: '',
  templateID: '',
  title: '',
  updatedAt: undefined
}

//* Дефолтные значения пагинации
const PAGINATION_DEFAULT_STATE = {
  page: 1,
  pageSize: 20
}

//* Дефолтное значение для таблицы
const DEFAULT_TABLE_STATE = {
  data: [],
  paginator: {
    pageSize: 20,
    currentPage: 1,
    totalItems: 0,
    totalPages: 0
  }
}

export {
  DIALOGS_VALIDATORS,
  DEFAULT_MODAL_FIELDS,
  PAGINATION_DEFAULT_STATE,
  DEFAULT_FORM_STATE,
  DEFAULT_TABLE_STATE
}
