import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { Files } from './interfaces'
import { $axios } from '~/utils/api'
import { FormError } from '~/store/interfaces'

@Module({
  name: 'files',
  stateFactory: true,
  namespaced: true
})
export default class FilesModule extends VuexModule {
  videoFileValue: any = {}

  // ? ______________ getters ______________
  get videoFile (): any {
    return this.videoFileValue
  }

  // ? ______________ setters ______________
  @Mutation
  setVideoFile (videoFile: any) {
    this.videoFileValue = videoFile
  }
  // ? ______________ actions ______________

  /**
   * * Создать файл
   */
  @Action({
    rawError: true
  })
  async createFiles (files: Files) {
    try {
      const formData = new FormData()
      files.files.map((file: File) => {
        formData.append('files[]', file)
      })
      formData.append('type', files.type)
      formData.append('target', files.target)
      const { data } = await $axios.post('/files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      const response: any[] = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать видео файл
   */
  @Action({
    rawError: true
  })
  uploadFiles (data:{files: FormData, onUploadProgress: any}) {
    try {
      return $axios.request({
        url: '/files',
        method: 'POST',
        data: data.files,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: data.onUploadProgress
      })
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  uploadVideos (data:{files: FormData, onUploadProgress: any}) {
    try {
      return $axios.post('/files/video', data.files, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: data.onUploadProgress
      })
    } catch (error: any) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
