import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, PageParams, defaultData, ResponseData, ValidatorParams } from '../../interfaces'
import { PaymentType } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'payments',
  stateFactory: true,
  namespaced: true
})
export default class PaymentsModule extends VuexModule {
  /**
   * * Способы оплаты
   */
  paymentTypesValue: ResponseData<PaymentType> = defaultData

  /**
   * * Способ оплаты
   */
  paymentTypeValue: PaymentType = {
    id: 0,
    code: '',
    name: '',
    active: true,
    multiply: 0,
    description: '',
    permissions: [],
    isDefault: false,
    params: {}
  }

  // ? ______________ getters ______________

  /**
   * * валидна ли форма
   */
  get validators (): ValidatorParams {
    return {
      name: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите название способа', trigger: ['blur'] }]
    }
  }

  /**
   * * Получить способы оплаты
   */
  get paymentTypes (): ResponseData<PaymentType> {
    return this.paymentTypesValue
  }

  /**
   * * Получить способ оплаты
   */
  get paymentType (): PaymentType {
    return this.paymentTypeValue
  }

  get paymentTypeById () {
    const paymentTypes = this.paymentTypes
    return function (id: number): PaymentType | undefined {
      return paymentTypes.data.find((paymentType) => {
        return paymentType.id === id
      })
    }
  }

  // ? ______________ setters ______________

  /**
   * * Установить способы оплаты
   * @param paymentTypes - способы оплаты
   */
  @Mutation
  setPaymentTypes (paymentTypes: ResponseData<PaymentType>): void {
    this.paymentTypesValue = paymentTypes
  }

  /**
   * * Установить способ оплаты
   * @param paymentType
   */
  @Mutation
  setPaymentType (paymentType: PaymentType): void {
    this.paymentTypeValue = paymentType
  }

  /**
   * * Обнулить форму
   */
  @Mutation
  resetCurrentPaymentType () {
    this.paymentTypeValue = {
      id: 0,
      code: '',
      name: '',
      active: true,
      multiply: 0,
      description: '',
      permissions: [],
      isDefault: false,
      params: {}
    }
  }

  /**
   * * Обнулить форму
   */
  @Mutation
  resetPaymentTypes () {
    this.paymentTypesValue = defaultData
  }

  // ? ______________ actions ______________

  /**
   * * Загрузка способов оплаты
   * @param pageParams - параметры страниц
   */
  @Action({ rawError: true, commit: 'setPaymentTypes' })
  async getPaymentTypes (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('payments/payment-types', { params: pageParams })
      const response: ResponseData<PaymentType> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Загрузка способа оплаты по id
   * @param id - id способа оплаты
   */
  @Action({ rawError: true, commit: 'setPaymentType' })
  async getPaymentTypeById (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/payments/payment-types/${id}`)
      const response: PaymentType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Редактирование способа оплаты
   */
  @Action({ rawError: true })
  async editPaymentTypeById () {
    try {
      const { id, ...paymentType } = this.paymentType
      const { data: { data } } = await $axios.put(`/payments/payment-types/${id}`, paymentType)
      const response: PaymentType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
