import { Context } from '@nuxt/types'
import { BonusLogPlugin, Filters, BonusLog, ValidatorsParams, BonusLogParams } from './interfaces'
import { Paginator } from '~/store/interfaces'

export default function getPluginApi (bonusLogStore:any) {
  const ctx: Context = this

  const api: BonusLogPlugin = {
    // ? ______________ getters ______________

    get bonusLogs (): { data: BonusLog[], meta: Paginator } {
      return bonusLogStore.bonusLogs
    },

    get bonusLog (): BonusLog {
      return bonusLogStore.bonusLog
    },

    get filters (): Filters {
      return bonusLogStore.filters
    },
    get validators (): ValidatorsParams {
      return bonusLogStore.validators
    },
    // ? ______________ getters ______________

    set bonusLogs (bonusLogs: { data: BonusLog[], meta: Paginator }) {
      bonusLogStore.setBonusLogs(bonusLogs)
    },

    set bonusLog (bonusLog: BonusLog) {
      bonusLogStore.setBonusLog(bonusLog)
    },

    set filters (filters: Filters) {
      bonusLogStore.setFilters(filters)
    },

    resetBonusLogs (): void {
      bonusLogStore.resetBonusLogs()
    },

    resetBonusLog (): void {
      bonusLogStore.resetBonusLog()
    },

    resetFilters (): void {
      bonusLogStore.resetFilters()
    },

    // ? ______________ actions ______________

    getBonusLogs ({ pageParams = null, userId }: BonusLogParams): Promise<{ data: BonusLog[], meta: Paginator }> {
      return bonusLogStore.getBonusLogs({ pageParams, siteApiUrl: ctx.$config.SITE_API, userId })
    },

    createBonusLog (): Promise<BonusLog> {
      return bonusLogStore.createBonusLog()
    },

    getBonusLogById (id: number): Promise<BonusLog> {
      return bonusLogStore.getBonusLogById(id)
    }
  }

  return api
}
