import { UserTypesPlugin, UserType, UserTypeParams } from './interfaces'
import UserTypesStore from './store'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'
export default function getPluginApi (userTypesStore:UserTypesStore) {
  const api: UserTypesPlugin = {
    // ? ________ getters ________

    // * Получить список userTypes и пагинатор
    get userTypesList (): ResponseData<UserType> {
      return userTypesStore.userTypesList
    },

    get validators (): ValidatorParams {
      return userTypesStore.validators
    },

    // * Получить userType по id из массва userTypes
    userTypeById (id: number): UserType | undefined {
      return userTypesStore.userTypeById(id)
    },

    // * Получить текущий userType
    get currentUserType (): UserType {
      return userTypesStore.currentUserType
    },

    // * Получить текущий список userTypeParams
    get userTypeParamsList (): ResponseData<UserTypeParams> {
      return userTypesStore.userTypeParamsList
    },

    // ? ________ setters ________

    // * Установить список userTypes и пагинатор
    set userTypesList (userTypes: ResponseData<UserType>) {
      userTypesStore.setUserTypesList(userTypes)
    },

    // * Установить список userTypeParams и пагинатор
    set userTypeParamsList (userTypeParamsList: ResponseData<UserTypeParams>) {
      userTypesStore.setUserTypeParamsList(userTypeParamsList)
    },

    // * Очистить форму
    resetCurrentUserType () {
      userTypesStore.resetCurrentUserType()
    },

    resetUserTypes () {
      userTypesStore.resetUserTypes()
    },

    // * Установить текщий userType
    set currentUserType (userType: UserType) {
      userTypesStore.setCurrentUserType(userType)
    },

    // ? ________ actions ________

    // * Запрос на получение списка userTypes и пагинатор
    getUserTypes (pageParams: PageParams | null = null): Promise<ResponseData<UserType>> {
      return userTypesStore.getUserTypes(pageParams)
    },

    // * Запрос на получение userType по id
    getUserType (id: number): Promise<UserType> {
      return userTypesStore.getUserType(id)
    },

    getUserTypeParams (): Promise<ResponseData<UserTypeParams>> {
      return userTypesStore.getUserTypeParams()
    },

    // * Запрос на удаление userType по id
    removeUserType (id: number): Promise<UserType> {
      return userTypesStore.removeUserType(id)
    },

    // * Запрос на изменение userType
    editUserType (): Promise<UserType> {
      return userTypesStore.editUserType()
    },

    // * Запрос на создание userType
    createUserType (): Promise<UserType> {
      return userTypesStore.createUserType()
    }
  }
  return api
}
