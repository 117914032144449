import { Vue, Component } from 'nuxt-property-decorator'
import { Files } from './interfaces'
@Component
export default class FilesMixin extends Vue {
  /**
   * * Загрузить файл на сервер
   * @param fileParams Параметры загрузки файла
   * @param options - {additionalLoadName - дополнительное название загрузки, notifyMessage - текст уведомления или показывать показывать нотифай} (необязательный)
   * @returns Загруженный файл
   */
  async uploadFile (fileParams: Files, options?: {additionalLoadName?: string, notifyMessage?: string | boolean}) {
    try {
      this.$wait.start(`uploadFile${options?.additionalLoadName ? `-${options?.additionalLoadName}` : ''}`)
      const uploadedFiles = await this.$files.createFiles(fileParams)
      if (options?.notifyMessage) {
        this.$notify({
          type: 'success',
          title: 'Выполнено',
          message: `${typeof options.notifyMessage === 'string' ? options.notifyMessage : 'Файл загружен'}`
        })
      }
      return uploadedFiles
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      console.error(e)
      throw e
    } finally {
      this.$wait.end(`uploadFile${options?.additionalLoadName ? `-${options?.additionalLoadName}` : ''}`)
    }
  }

  /**
   * * Загрузить файл на сервер
   * @param fileParams Параметры загрузки файла
   * @param options - {additionalLoadName - дополнительное название загрузки, notifyMessage - текст уведомления или показывать показывать нотифай} (необязательный)
   * @returns Загруженный файл
   */
  async uploadVideos (fileParams: FormData, options?: {additionalLoadName?: string, notifyMessage?: string | boolean}) {
    try {
      const loaderVideoName = `uploadVideo${options?.additionalLoadName ? `-${options?.additionalLoadName}` : ''}`
      this.$wait.start(loaderVideoName)
      this.$wait.progress(loaderVideoName, 0)
      const uploadProgressEvent = (progressEvent: { loaded: number; total: number }) => {
        this.$wait.progress(loaderVideoName, Math.floor((progressEvent.loaded * 100) / progressEvent.total))
      }
      const uploadedFiles = await this.$files.uploadVideos(fileParams, uploadProgressEvent)
      if (options?.notifyMessage) {
        this.$notify({
          type: 'success',
          title: 'Выполнено',
          message: `${typeof options.notifyMessage === 'string' ? options.notifyMessage : 'Видео загружено'}`
        })
      }
      return uploadedFiles
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      console.error(e)
      throw e
    } finally {
      const loaderVideoName = `uploadVideo${options?.additionalLoadName ? `-${options?.additionalLoadName}` : ''}`
      this.$wait.progress(loaderVideoName, 0)
      this.$wait.end(loaderVideoName)
    }
  }

  /**
     * * Загрузить файл на сервер
     * @param fileParams Параметры загрузки файла
     * @param options - {additionalLoadName - дополнительное название загрузки, notifyMessage - текст уведомления или показывать показывать нотифай} (необязательный)
     * @returns Загруженный файл
     */
  async uploadFiles (fileParams: FormData, options?: {additionalLoadName?: string, notifyMessage?: string | boolean}) {
    try {
      const loaderImageName = `uploadImage${options?.additionalLoadName ? `-${options?.additionalLoadName}` : ''}`
      this.$wait.start(loaderImageName)
      this.$wait.progress(loaderImageName, 0)
      const uploadProgressEvent = (progressEvent: { loaded: number; total: number }) => {
        this.$wait.progress(loaderImageName, Math.floor((progressEvent.loaded * 100) / progressEvent.total))
      }
      const uploadedFiles = await this.$files.uploadFiles(fileParams, uploadProgressEvent)
      if (options?.notifyMessage) {
        this.$notify({
          type: 'success',
          title: 'Выполнено',
          message: `${typeof options.notifyMessage === 'string' ? options.notifyMessage : 'Файл загружен'}`
        })
      }
      return uploadedFiles
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      console.error(e)
      throw e
    } finally {
      const loaderImageName = `uploadImage${options?.additionalLoadName ? `-${options?.additionalLoadName}` : ''}`
      this.$wait.progress(loaderImageName, 0)
      this.$wait.end(loaderImageName)
    }
  }
  //   /**
  //  * * Загрузить файл на сервер
  //  * @param fileParams Параметры загрузки файла
  //  * @param options - {additionalLoadName - дополнительное название загрузки, notifyMessage - текст уведомления или показывать показывать нотифай} (необязательный)
  //  * @returns Загруженный файл
  //  */
  //   async uploadFile (fileParams: Files, options?: {additionalLoadName?: string, notifyMessage?: string | boolean}) {
  //     try {
  //       this.$wait.start(`uploadFile${options?.additionalLoadName ? `-${options?.additionalLoadName}` : ''}`)
  //       const uploadedFiles = await this.$files.createFiles(fileParams)
  //       if (options?.notifyMessage) {
  //         this.$notify({
  //           type: 'success',
  //           title: 'Выполнено',
  //           message: `${typeof options.notifyMessage === 'string' ? options.notifyMessage : 'Файл загружен'}`
  //         })
  //       }
  //       return uploadedFiles
  //     } catch (e: any) {
  //       this.$notify({
  //         type: 'error',
  //         title: e.error_code,
  //         message: e.error_message
  //       })
  //       console.error(e)
  //       throw e
  //     } finally {
  //       this.$wait.end(`uploadFile${options?.additionalLoadName ? `-${options?.additionalLoadName}` : ''}`)
  //     }
  //   }
}
