import { Vue, Component } from 'nuxt-property-decorator'
import { Ids } from './interfaces'
import { PageParams } from '~/store/interfaces'

@Component
export default class DiscountGroupsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$discountGroups.resetDiscountGroup()
    this.$discountGroups.resetDiscounts()
    this.$discountGroups.resetDiscount()
    next()
  }

  // ?____________Группы Скидок_______________

  /**
   * * Получение списка групп скидок
   * @param pageParams - параметры пагинации (необязательные)
   * @returns cписок групп скидок
   */
  async getDiscountGroups (pageParams: PageParams | null = null) {
    this.$wait.start('getDiscountGroups')
    try {
      return await this.$discountGroups.getDiscountGroups(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getDiscountGroups')
    }
  }

  /**
   * * Получение группы скидок по id
   * @param id - id группы скидок
   * @returns группа скидок
   */
  async getDiscountGroupById (id: number) {
    try {
      this.$wait.start('getDiscountGroupById')
      return await this.$discountGroups.getDiscountGroupById(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getDiscountGroupById')
    }
  }

  /**
   * * Создание группы скидок
   * @returns группа скидок
   */
  async createDiscountGroup () {
    try {
      this.$wait.start('createDiscountGroup')
      const data = await this.$discountGroups.createDiscountGroup()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлена новая группа ${data.title}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      if (e.message.slice(5, 13) === 'priority') {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          message: 'Ошибка добавления группы: приоритет не уникален'
        })
      } else {
        this.$notify({
          type: 'error',
          title: e.error_code,
          message: e.error_message
        })
      }
      throw e
    } finally {
      this.$wait.end('createDiscountGroup')
    }
  }

  /**
   * * Изменение группы скидок
   * @returns группа скидок
   */
  async editDiscountGroup () {
    this.$wait.start('editDiscountGroup')
    try {
      const data = await this.$discountGroups.editDiscountGroup()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Группа скидок ${data.title} изменена`
      })
      return data
    } catch (e: any) {
      console.error(e)
      if (e.message.slice(5, 13) === 'priority') {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          message: 'Ошибка изменения группы: приоритет не уникален'
        })
      } else {
        this.$notify({
          type: 'error',
          title: e.error_code,
          message: e.error_message
        })
      }
      throw e
    } finally {
      this.$wait.end('editDiscountGroup')
    }
  }

  /**
   * * Удаление группы скидок
   * @param id - id группы скидок
   * @returns группа скидок
   */
  async removeDiscountGroup (id: number) {
    try {
      this.$wait.start('removeDiscountGroup')
      const data = await this.$discountGroups.removeDiscountGroup(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Группа скидок ${data.title} удалена`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeDiscountGroup')
    }
  }

  // ?____________Скидки_______________

  /**
   * * Получение списка скидок
   * @param pageParams - параметры пагинации
   * @returns список скидок
   */
  async getDiscounts (pageParams: PageParams) {
    try {
      this.$wait.start('getDiscounts')
      return await this.$discountGroups.getDiscounts(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getDiscounts')
    }
  }

  /**
   * * Получение группы скидки по id`s
   * @param ids - id`s группы скидок и скидки
   * @returns скидку
   */
  async getDiscountById (ids: Ids) {
    try {
      this.$wait.start('getDiscountById')
      return await this.$discountGroups.getDiscountById(ids)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getDiscountById')
    }
  }

  /**
   * * Создание скидки
   * @returns скидку
   */
  async createDiscount () {
    this.$wait.start('createDiscount')
    try {
      const data = await this.$discountGroups.createDiscount()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлена новая скидка ${data.id}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createDiscount')
    }
  }

  /**
   * * Изменение скидки
   * @returns скидку
   */
  async editDiscount () {
    try {
      this.$wait.start('editDiscount')
      const data = await this.$discountGroups.editDiscount()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Скидка ${data.id} изменена`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editDiscount')
    }
  }

  /**
   * * Удаление скидки
   * @param id - id скидки
   * @returns скидку
   */
  async removeDiscount (id: number) {
    try {
      this.$wait.start('removeDiscount')
      const data = await this.$discountGroups.removeDiscount(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Скидка ${data.id} удалена`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeDiscount')
    }
  }
}
