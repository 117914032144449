import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class RobotsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$robots.resetCurrentRobot()
    next()
  }

  /**
   * * Запрос на получение всех версий роботов
   * @returns список роботов
   */
  async getRobotList () {
    try {
      this.$wait.start('getRobotList')
      return await this.$robots.getRobots()
    } catch (e:any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getRobotList') }
  }

  /**
   * * Запрос на создание робота
   * @returns текущий робот
   */
  async createRobot () {
    try {
      this.$wait.start('createRobot')
      const data = await this.$robots.createRobot()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Файл сохранен ${data.id}`
      })
      return data
    } catch (e:any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('createRobot') }
  }
}
