import { Action, Module, Mutation } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { ElementsPageParams } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { FormError } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import { ModuleConfig, ResponseData } from '~/types/store/store-base-type'
import { NCalculator } from '~/types/store/calculator-micro'

@Module({
  name: 'calculatorElements',
  stateFactory: true,
  namespaced: true
})
export default class CalculatorElementsModule extends BaseModule<
  NCalculator.IElement,
  'id',
  keyof NCalculator.IElement
> {
  protected config: ModuleConfig<NCalculator.IElement, 'id'> = {
    apiUrl: 'calculator/v1/elements',
    identifier: 'id'
  }

  protected itemData: NCalculator.IElement = {
    active: false,
    calculatorId: null,
    condition: '',
    conditions: [],
    name: ''
  }

  @Mutation
  resetItem (): void {
    this.itemData = {
      active: false,
      calculatorId: null,
      condition: '',
      conditions: [],
      name: ''
    }
  }

  @Action({
    rawError: true
  })
  async getList (pageParams: ElementsPageParams<NCalculator.IElement>) {
    try {
      const { apiUrlPrefix, code, ...params } = pageParams
      const { data } = await $axios.get<ResponseData<NCalculator.IElement>>(
        `${apiUrlPrefix}/calculator/v1/calculators/${code}/elements`,
        {
          params: { ...params, ...this.listFilters }
        }
      )
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
