import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, PageParams, ResponseData, ValidatorParams } from '../../interfaces'
import { Filters, WordCase } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'wordCases',
  stateFactory: true,
  namespaced: true
})

export default class WordCasesModule extends VuexModule {
  /**
   * * Массив всех wordCase исходя из запроса
   */
  wordCases: ResponseData<WordCase> = defaultData

  filtersValue: Filters = {
    searchString: undefined
  }

  /**
   * * Текущая wordCase
   */
  wordCase: WordCase = {
    word: undefined,
    rd: undefined,
    dt: undefined,
    vn: undefined,
    tv: undefined,
    pr: undefined
  }

  // ? ______________ getters ______________

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      word: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите слово в именительном падеже', trigger: ['blur'] }],
      rd: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите слово в родительном падеже', trigger: ['blur'] }],
      dt: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите слово в дательном падеже', trigger: ['blur'] }],
      vn: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите слово в винительном падеже', trigger: ['blur'] }],
      tv: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите слово в творительном падеже', trigger: ['blur'] }],
      pr: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите слово в предложном падеже', trigger: ['blur'] }]
    }
  }

  get filters (): Filters {
    return this.filtersValue
  }

  /**
   * * Получить массив wordCases и пагинацией
   */
  get wordCasesList (): ResponseData<WordCase> {
    return this.wordCases
  }

  /**
   * * Получить wordCase из массива wordCases
   */
  get wordCaseById () {
    const wordCases = this.wordCases
    return function (id: number): WordCase | undefined {
      return wordCases.data.find(wordCase => wordCase.id === id)
    }
  }

  /**
   * * Получить текущую wordCase для измения или создания wordCase
   */
  get currentWordCase () {
    return this.wordCase
  }

  // ? ______________ setters ______________

  /**
   * * Установить массив WordCases
   * @param wordCases массив WordCases
   */
  @Mutation
  setWordCasesList (wordCases: ResponseData<WordCase>) {
    this.wordCases = wordCases
  }

  /**
   * * Установить CurrentWordCase для измения или создания wordCase
   * @param wordCase текущая WordCase, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentWordCase (wordCase: WordCase) {
    this.wordCase = wordCase
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentWordCase () {
    this.wordCase = {
      word: undefined,
      rd: undefined,
      dt: undefined,
      vn: undefined,
      tv: undefined,
      pr: undefined
    }
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  @Mutation
  resetWordCases () {
    this.wordCases = defaultData
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список WordCases
   */
  @Action({
    rawError: true,
    commit: 'setWordCasesList'
  })
  async getWordCases (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/seo/word-case', { params: { ...pageParams, ...this.filters } })
      const response: ResponseData<WordCase> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить wordCase по id
   * @param id id wordCase, который мы хотим получить
   */
  @Action({
    rawError: true, commit: 'setCurrentWordCase'
  })
  async getWordCase (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/seo/word-case/${id}`)
      const response: WordCase = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить wordCase
   */
  @Action({
    rawError: true
  })
  async editWordCase () {
    try {
      const { id, ...wordCase } = this.currentWordCase
      const { data: { data } } = await $axios.put(`/seo/word-case/${id}`, wordCase)
      const response: WordCase = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать WordCase
   */
  @Action({
    rawError: true
  })
  async createWordCase () {
    try {
      const { data: { data } } = await $axios.post('/seo/word-case', this.currentWordCase)
      const response: WordCase = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить wordCase по id
   * @param id  id wordCase, который мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removeWordCase (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/seo/word-case/${id}`)
      const response: WordCase = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
