import { QueryParams, BonusAccount, BonusAccountPlugin } from './interfaces'
import { ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (bonusAccountStore:any) {
  const api: BonusAccountPlugin = {
    // ? ______________ getters _____________

    get bonusAccounts (): ResponseData<BonusAccount> {
      return bonusAccountStore.bonusAccounts
    },

    get bonusAccount (): BonusAccount {
      return bonusAccountStore.bonusAccount
    },

    bonusAccountById (id: number): BonusAccount | undefined {
      return bonusAccountStore.bonusAccountById(id)
    },

    get bonusAccountValidators (): ValidatorParams {
      return bonusAccountStore.bonusAccountValidators
    },

    // ? ______________ setters _____________

    set bonusAccounts (bonusAccounts: ResponseData<BonusAccount>) {
      bonusAccountStore.setBonusAccounts(bonusAccounts)
    },

    set bonusAccount (bonusAccount: BonusAccount) {
      bonusAccountStore.setBonusAccount(bonusAccount)
    },

    resetBonusAccounts (): void {
      bonusAccountStore.resetBonusAccounts()
    },

    resetBonusAccount (): void {
      bonusAccountStore.resetBonusAccount()
    },

    // ? ______________ actions _____________

    async getBonusAccounts (queryParams: QueryParams | null = null, save: boolean = true): Promise<ResponseData<BonusAccount>> {
      if (save) {
        return bonusAccountStore.setBonusAccounts(await bonusAccountStore.getBonusAccounts(queryParams))
      }
      return bonusAccountStore.getBonusAccounts(queryParams)
    },

    createBonusAccount (): Promise<BonusAccount> {
      return bonusAccountStore.createBonusAccount()
    },

    getBonusAccountById (id: number): Promise<BonusAccount> {
      return bonusAccountStore.getBonusAccountById(id)
    },

    editBonusAccount (): Promise<BonusAccount> {
      return bonusAccountStore.editBonusAccount()
    },

    removeBonusAccount (id: number): Promise<BonusAccount> {
      return bonusAccountStore.removeBonusAccount(id)
    }
  }

  return api
}
