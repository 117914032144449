import { RegionsPlugin, Region, Filters } from './interfaces'
import RegionsModule from './store'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (store: RegionsModule) {
  const api: RegionsPlugin = {
    // ? ________ getters ________

    get filters (): Filters {
      return store.filters
    },

    get regionsList (): ResponseData<Region> {
      return store.regionsList
    },

    get validators (): ValidatorParams {
      return store.validators
    },

    regionById (id: number): Region | undefined {
      return store.regionById(id)
    },

    get currentRegion (): Region {
      return store.currentRegion
    },

    // ? ________ setters ________

    set filters (filters: Filters) {
      store.setFilters(filters)
    },

    resetFilters () {
      store.resetFilters()
    },

    set regionsList (regions: ResponseData<Region>) {
      store.setRegionsList(regions)
    },
    resetCurrentRegion () {
      store.resetCurrentRegion()
    },

    resetRegionsList () {
      store.resetRegionsList()
    },

    set currentRegion (region: Region) {
      store.setCurrentRegion(region)
    },
    // ? ________ actions ________
    getRegions (pageParams: PageParams | null = null): Promise<ResponseData<Region>> {
      return store.getRegions(pageParams)
    },
    getRegion (id: number): Promise<Region> {
      return store.getRegion(id)
    },
    removeRegion (id: number): Promise<Region> {
      return store.removeRegion(id)
    },
    editRegion (): Promise<Region> {
      return store.editRegion()
    },
    createRegion (): Promise<Region> {
      return store.createRegion()
    }
  }
  return api
}
