import { Context } from '@nuxt/types'
import EventTriggersPlugin from './interfaces'
import EventTriggersModule from './store'

export default function getPluginApi (store: EventTriggersModule) {
  const ctx: Context = this

  const api: EventTriggersPlugin = {
    get list () {
      return store.list
    },

    set list (data) {
      store.setList(data)
    },

    get item () {
      return store.item
    },

    set item (data) {
      store.setItem(data)
    },

    setItemPropByKeys (value, ...keys) {
      store.setItemPropByKeys({
        keys,
        value
      })
    },

    itemByIdentifier (identifier) {
      return store.itemByIdentifier(identifier)
    },

    resetList () {
      store.resetList()
    },

    resetItem () {
      store.resetItem()
    },

    async getList (save = true) {
      const list = await store.getList({
        siteApiUrl: ctx.$config.SITE_API
      })
      if (save) {
        store.setList(list)
      }
      return list
    },

    updatePromoSender () {
      return store.updatePromoSender({
        siteApiUrL: ctx.$config.SITE_API
      })
    }
  }

  return api
}
