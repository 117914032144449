
import Vue from 'vue'
import { Component } from 'nuxt-property-decorator'

import {
  menuItems
} from '~/utils/routes'

@Component
export default class SiteSearch extends Vue {
  /**
   * *________________ Data ______________________
   */
  searchQuery = ''
  show = false
  // SITE_MAP: []

  /**
   * *________________ Computed ______________________
   */
  get SITE_MAP () {
    return menuItems.map((menu_item) => {
      if (!menu_item?.subItems) { return menu_item.item }
      return [
        {
          value: menu_item.item.value,
          link: menu_item.item.link
        },
        ...menu_item.subItems.map((subItem) => {
          return {
            value: subItem.value,
            link: menu_item.item.link + '/' + subItem.link
          }
        })
      ]
    }).flat()
  }

  /**
   * *________________ Methods ______________________
   */
  click () {
    this.show = !this.show
    if (this.show) {
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs.headerSearchSelect.focus()
      })
    } else {
      this.searchQuery = ''
    }
  }

  /**
     * * Поиск совпадений среди названий страниц
     * @param queryString - строка запроса
     * @param cb - callback возврата найденных совпадений
     */
  fetchSuggestions (queryString: any, cb: any) {
    const suggesstions = this.SITE_MAP.filter(
      routeItem =>
        new RegExp(queryString, 'i').test(routeItem.value) ||
          new RegExp(queryString, 'i').test(routeItem.link)
    )
    cb(suggesstions)
  }

  redirectTo ({ link }: { link: string }) {
    this.show = false
    this.$router.replace({ path: `/${link}` })
    this.searchQuery = ''
  }
}
