import { OrdersParams, InputParams, FormsParams, StatusesParams, StatusesByDayResp, MainPagePlugin } from './interfaces'

export default function getPluginApi (store:any) {
  const api: MainPagePlugin = {
    // ? ______ getters ______ //
    get orders () {
      return store.orders
    },
    get forms () {
      return store.forms
    },
    get statuses () {
      return store.statuses
    },
    get days () {
      return store.days
    },
    get months () {
      return store.months
    },
    get statusesByDay () {
      return store.statusesByDay
    },

    // ? ______ setters ______ //

    resetMainPage () {
      store.resetMainPage()
    },

    // ? ______ actions ______ //
    getOrdersGeography (params: InputParams | null = null): Promise<{orderCountByCity: Record<string, number>}> {
      return store.getOrdersGeography(params)
    },
    getOrderUser (params: InputParams | null = null): Promise<OrdersParams> {
      return store.getOrderUser(params)
    },
    getForms (params: InputParams | null = null): Promise<FormsParams> {
      return store.getForms(params)
    },
    getStatuses (params: InputParams | null = null): Promise<StatusesParams> {
      return store.getStatuses(params)
    },
    getDays (params: InputParams | null = null): Promise<StatusesParams> {
      return store.getDays(params)
    },
    getMonths (params: InputParams | null = null): Promise<StatusesParams> {
      return store.getMonths(params)
    },
    getStatusesByDay (inputParams: InputParams | null = null): Promise<StatusesByDayResp> {
      return store.getStatusesByDay(inputParams)
    },
    getStatusesByDayExcel (inputParams: InputParams | null = null): Promise<Blob> {
      return store.getStatusesByDayExcel(inputParams)
    }
  }

  return api
}
