import { Vue, Component } from 'nuxt-property-decorator'
import { Ids } from './interface'
import { PageParams } from '~/store/interfaces'

function isIdErrors (message: string) {
  return !!message.startsWith('body.products: ') && !!message.slice(15).split(',').map((number: string) => parseInt(number, 10)).length
}

@Component
export default class BannerBlocksMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$bannerBlocks.resetBanners()
    this.$bannerBlocks.resetCurrentBlock()
    this.$bannerBlocks.resetCurrentBanner()
    this.$bannerBlocks.resetBannerParams()
    next()
  }

  /**
   * * Получение списка блоков баннеров
   * @param pageParams - параметры пагинации
   * @returns список блоков баннеров с пагинатором
   */
  async getBannerBlocks (pageParams: PageParams) {
    try {
      this.$wait.start('getBannerBlocks')
      return await this.$bannerBlocks.getBannerBlocks(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getBannerBlocks')
    }
  }

  /**
   * * Получение блока баннеров по id
   * @param id - id блока баннеров
   * @returns блок баннера
   */
  async getBannerBlockById (id: number) {
    try {
      this.$wait.start('getBannerBlockById')
      return await this.$bannerBlocks.getBannerBlockById(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getBannerBlockById')
    }
  }

  /**
   * * Получение списка параметров баннеров
   * @returns список параметров баннера
   */
  async getBannerParams () {
    try {
      this.$wait.start('getBannerParams')
      return await this.$bannerBlocks.getBannerParams()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getBannerParams')
    }
  }

  /**
   * * Создание блока баннеров
   * @returns блок баннера
   */
  async createBannerBlock () {
    try {
      this.$wait.start('createBannerBlock')
      const data = await this.$bannerBlocks.createBannerBlock()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлен новый блок ${data.name}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createBannerBlock')
    }
  }

  /**
   * * Изменение блока баннеров
   * @returns блок баннера
   */
  async editBannerBlock () {
    try {
      this.$wait.start('editBannerBlock')
      const data = await this.$bannerBlocks.editBannerBlock()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Блок ${data.name} изменен`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editBannerBlock')
    }
  }

  /**
   * * Удаление блока баннеров
   * @param id - id блока баннеров
   * @returns блок баннера
   */
  async removeBannerBlock (id: number) {
    try {
      this.$wait.start('removeBannerBlock')
      const data = await this.$bannerBlocks.removeBannerBlock(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Блок ${data.name} удален`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeBannerBlock')
    }
  }

  /**
   * * Получение списка баннеров
   * @param pageParams - параметры пагинации (необязательный)
   * @returns список баннеров с пагинатором
   */
  async getBanners (pageParams: PageParams | null = null) {
    try {
      this.$wait.start('getBanners')
      return await this.$bannerBlocks.getBanners(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getBanners')
    }
  }

  /**
   * * Получение баннера по id`s
   * @param ids - id`s блока баннеров и баннера
   * @returns баннер
   */
  async getBannerById (ids: Ids) {
    try {
      this.$wait.start('getBannerById')
      return await this.$bannerBlocks.getBannerById(ids)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getBannerById')
    }
  }

  /**
   * * Создание баннера
   * @returns баннер
   */
  async createBanner () {
    try {
      this.$wait.start('createBanner')
      const data = await this.$bannerBlocks.createBanner()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлен новый баннер ${data.name}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      if (isIdErrors(e.message)) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          message: 'Неправильные ID товаров'
        })
        return
      }
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createBanner')
    }
  }

  /**
   * * Изменение баннера
   * @returns баннер
   */
  async editBanner () {
    try {
      this.$wait.start('editBanner')
      const data = await this.$bannerBlocks.editBanner()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Баннер ${data.name} изменён`
      })
      return data
    } catch (e: any) {
      console.error(e)
      if (isIdErrors(e.message)) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          message: 'Неправильные ID товаров'
        })
        return
      }
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editBanner')
    }
  }

  /**
   * * Удаление баннера
   * @param id - id баннера
   * @returns баннер
   */
  async removeBanner (id: number) {
    try {
      this.$wait.start('removeBanner')
      const data = await this.$bannerBlocks.removeBanner(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Баннер ${data.name} удален`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeBanner')
    }
  }
}
