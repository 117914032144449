import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class SubscribersMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    next()
  }

  /**
   * * Запрос на получение списка подписчиков
   * @param pageParams - обьект пагинатора
   * @returns список подписчиков
   */
  async getSubscribers (pageParams: PageParams) {
    try {
      this.$wait.start('getSubscribers')
      return await this.$subscribers.getSubscribers(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getSubscribers')
    }
  }

  /**
   * * Запрос на получение подписчиков с помощью excel файла
   */
  async downloadSubscribers (
    throwError?: boolean
  ): Promise<void> {
    try {
      this.$wait.start('downloadSubscribers')
      const data = await this.$subscribers.downloadSubscribers() as Blob
      if (data === undefined) { return }
      // * Загрузка файла
      this.$download.downloadFile(data, { name: 'subscribers.xlsx', type: 'xlsx' })
    } catch (e: any) {
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      if (throwError) {
        throw e
      }
    } finally {
      this.$wait.end('downloadSubscribers')
    }
  }
}
