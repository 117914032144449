import { BonusType, BonusTypePlugin, QueryParams } from './interfaces'
import { ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (bonusTypeStore:any) {
  const api: BonusTypePlugin = {
    // ? ______________ getters ______________

    get bonusTypes (): ResponseData<BonusType> {
      return bonusTypeStore.bonusTypes
    },

    get bonusType () {
      return bonusTypeStore.bonusType
    },

    bonusTypeById (id: number) {
      return bonusTypeStore.bonusTypeById(id)
    },

    get bonusTypeValidators (): ValidatorParams {
      return bonusTypeStore.bonusTypeValidators
    },

    // ? ______________ setters ______________

    set bonusTypes (bonusTypes: ResponseData<BonusType>) {
      bonusTypeStore.setBonusTypes(bonusTypes)
    },

    set bonusType (bonusType: BonusType) {
      bonusTypeStore.setBonusType(bonusType)
    },

    resetBonusTypes (): void {
      bonusTypeStore.resetBonusTypes()
    },

    resetBonusType (): void {
      bonusTypeStore.resetBonusType()
    },

    // ? ______________ actions ______________

    async getBonusTypes (queryParams: QueryParams | null = null, save: boolean = true): Promise<ResponseData<BonusType>> {
      if (save) {
        return bonusTypeStore.setBonusTypes(await bonusTypeStore.getBonusTypes(queryParams))
      }
      return bonusTypeStore.getBonusTypes(queryParams)
    },

    createBonusType (): Promise<BonusType> {
      return bonusTypeStore.createBonusType()
    },

    getBonusTypeById (id: number): Promise<BonusType> {
      return bonusTypeStore.getBonusTypeById(id)
    },

    editBonusType (): Promise<BonusType> {
      return bonusTypeStore.editBonusType()
    },

    removeBonusType (id: number): Promise<BonusType> {
      return bonusTypeStore.removeBonusType(id)
    }
  }

  return api
}
