import { SeoTextsPlugin, SeoText, Filters, SeoTargets } from './interfaces'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

// @ts-ignore
export default function getPluginApi (seoTextsStore) {
  const api: SeoTextsPlugin = {
    // ? ________ getters ________
    get filters (): Filters {
      return seoTextsStore.filters
    },
    // * Получить список seoTexts и пагинатор
    get seoTextsList (): ResponseData<SeoText> {
      return seoTextsStore.seoTextsList
    },

    get validators (): ValidatorParams {
      return seoTextsStore.validators
    },

    // * Получить seoText по id из массва seoTexts
    seoTextById (id: number): SeoText | undefined {
      return seoTextsStore.seoTextById(id)
    },

    // * Получить текущий seoText
    get currentSeoText (): SeoText {
      return seoTextsStore.currentSeoText
    },

    // * Получить seoTargets
    get targets (): SeoTargets {
      return seoTextsStore.targets
    },

    // ? ________ setters ________
    set filters (filterValue: Filters) {
      seoTextsStore.setFilters(filterValue)
    },

    // * Установить список seoTexts и пагинатор
    set seoTextsList (seoTexts: ResponseData<SeoText>) {
      seoTextsStore.setSeoTextsList(seoTexts)
    },

    // * Очистить форму
    resetCurrentSeoText () {
      seoTextsStore.resetCurrentSeoText()
    },

    resetSeoTexts () {
      seoTextsStore.resetSeoTexts()
    },

    // * Установить текщий seoText
    set currentSeoText (seoText: SeoText) {
      seoTextsStore.setCurrentSeoText(seoText)
    },

    // * Установить SeoTargets
    set targets (targets: SeoTargets) {
      seoTextsStore.setTargets(targets)
    },

    // ? ________ actions ________

    // * Запрос на получение списка seoTexts и пагинатор
    getSeoTexts (pageParams: PageParams | null = null): Promise<ResponseData<SeoText>> {
      return seoTextsStore.getSeoTexts(pageParams)
    },

    // * Запрос на получение seoText по id
    getSeoText (id: number): Promise<SeoText> {
      return seoTextsStore.getSeoText(id)
    },

    // * Запрос на удаление seoText по id
    removeSeoText (id: number): Promise<SeoText> {
      return seoTextsStore.removeSeoText(id)
    },

    // * Запрос на изменение seoText
    editSeoText (): Promise<SeoText> {
      return seoTextsStore.editSeoText()
    },

    // * Запрос на создание seoText
    createSeoText (): Promise<SeoText> {
      return seoTextsStore.createSeoText()
    },

    // * Запрос на получение списка seoTextTargets
    getSeoTextTargets (): Promise<SeoTargets> {
      return seoTextsStore.getSeoTextTargets()
    }
  }
  return api
}
