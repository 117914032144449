import { PageTemplate, PageTemplateKeys, PageTemplatesPlugin } from './interfaces'
import PageTemplatesModule from './store'
import storePluginApi from '~/types/store/store-plugin-api'

export default function getPluginApi (store: PageTemplatesModule): PageTemplatesPlugin {
  const api = storePluginApi<PageTemplate, 'id', PageTemplateKeys, 'code' | 'name'>(store)

  /**
   * @example Пример добавления свойства в плагин модуля стора
   * Object.defineProperty(api, 'example', {
   *  get () { return store.example }
   * })
   */

  return api
}
