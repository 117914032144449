import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ResponseData, PageParams, ValidatorParams } from '../../interfaces'
import { WordPlural } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'wordPlurals',
  stateFactory: true,
  namespaced: true
})

export default class WordPluralsModule extends VuexModule {
  /**
   * * Массив всех wordPlural исходя из запроса
   */
  wordPlurals: ResponseData<WordPlural> = defaultData

  /**
   * * Текущая wordPlural
   */
  wordPlural: WordPlural = {
    single: undefined,
    multi: undefined
  }

  // ? ______________ getters ______________

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      single: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите слово в единственном числе ', trigger: ['blur'] }],
      multi: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите слово в множественном числе', trigger: ['blur'] }]
    }
  }

  /**
   * * Получить массив wordPlurals и пагинацией
   */
  get wordPluralsList (): ResponseData<WordPlural> {
    return this.wordPlurals
  }

  /**
   * * Получить wordPlural из массива wordPlurals
   */
  get wordPluralById () {
    const wordPlurals = this.wordPlurals
    return function (id: number): WordPlural | undefined {
      return wordPlurals.data.find(wordPlural => wordPlural.id === id)
    }
  }

  /**
   * * Получить текущую wordPlural для измения или создания wordPlural
   */
  get currentWordPlural () {
    return this.wordPlural
  }

  // ? ______________ setters ______________

  /**
   * * Установить массив WordPlurals
   * @param wordPlurals массив WordPlurals
   */
  @Mutation
  setWordPluralsList (wordPlurals: ResponseData<WordPlural>) {
    this.wordPlurals = wordPlurals
  }

  /**
   * * Установить CurrentWordPlural для измения или создания wordPlural
   * @param wordPlural текущая WordPlural, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentWordPlural (wordPlural: WordPlural) {
    this.wordPlural = wordPlural
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentWordPlural () {
    this.wordPlural = {
      single: undefined,
      multi: undefined
    }
  }

  @Mutation
  resetWordPlurals () {
    this.wordPlurals = defaultData
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список WordPlurals
   */
  @Action({
    rawError: true,
    commit: 'setWordPluralsList'
  })
  async getWordPlurals (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/seo/word-plural', { params: pageParams })
      const response: ResponseData<WordPlural> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить wordPlural по id
   * @param id id wordPlural, который мы хотим получить
   */
  @Action({
    rawError: true, commit: 'setCurrentWordPlural'
  })
  async getWordPlural (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/seo/word-plural/${id}`)
      const response: WordPlural = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить wordPlural
   */
  @Action({
    rawError: true
  })
  async editWordPlural () {
    try {
      const { id, ...wordPlural } = this.currentWordPlural
      const { data: { data } } = await $axios.put(`/seo/word-plural/${id}`, wordPlural)
      const response: WordPlural = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать WordPlural
   */
  @Action({
    rawError: true
  })
  async createWordPlural () {
    try {
      const { data: { data } } = await $axios.post('/seo/word-plural', this.currentWordPlural)
      const response: WordPlural = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить wordPlural по id
   * @param id  id wordPlural, который мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removeWordPlural (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/seo/word-plural/${id}`)
      const response: WordPlural = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
