import { ArchiveGroup, ArchivePlugin } from './interfaces'
import ArchiveModule from './store'
import { PageParams, ResponseData } from '~/store/interfaces'

export default function getPluginApi (store: ArchiveModule) {
  const api: ArchivePlugin = {
    get archive (): ResponseData<ArchiveGroup> {
      return store.archive
    },

    // ? ________ setters ________
    set archive (archive: ResponseData<ArchiveGroup>) {
      store.setArchive(archive)
    },

    // ? ________ actions ________
    async getArchive (pageParams: PageParams | null = null): Promise<ResponseData<ArchiveGroup>> {
      return await store.getArchive(pageParams)
    }
  }

  return api
}
