import { Component, Vue } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'
// import defaultPageSize from '~/utils/page-sizes'

@Component
export default class UserTypesMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$userTypes.resetCurrentUserType()
    next()
  }

  /**
   * * Запрос на userTypes
   * @param pageParams параметры пагинатора (необязательный)
   * @returns список userTypes
   */
  async getUserTypes (pageParams?: PageParams) {
    try {
      this.$wait.start('getUserTypes')
      return await this.$userTypes.getUserTypes(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getUserTypes') }
  }

  /**
   * * Удаление user/userType по id
   * @param id - id userType
   * @returns userType
   */
  async removeUserType (id: number) {
    try {
      this.$wait.start('removeUserType')
      const data = await this.$userTypes.removeUserType(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Тип пользователя ${data.name} удален`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removeUserType') }
  }

  /**
   * * Создание user/userType
   * @returns userType
   */
  async createUserType () {
    try {
      this.$wait.start('createUserType')
      const data = await this.$userTypes.createUserType()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Тип пользователя ${data.name} создан`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('createUserType') }
  }

  /**
   * * Получение параметров userType
   * @returns userTypeParams
   */
  async getUserTypeParams () {
    try {
      this.$wait.start('getUserTypeParams')
      return await this.$userTypes.getUserTypeParams()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getUserTypeParams') }
  }

  /**
   * * Изменение user/userType
   * @returns userType
   */
  async editUserType () {
    try {
      this.$wait.start('editUserType')
      const data = await this.$userTypes.editUserType()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Тип пользователя ${data.name} изменено`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('editUserType') }
  }
}
