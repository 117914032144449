import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { Filters, Form, FormType } from './interfaces'
import {
  PageParams,
  defaultData,
  FormError,
  ResponseData,
  ValidatorParams
} from '~/store/interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'forms',
  stateFactory: true,
  namespaced: true
})
export default class FormsModule extends VuexModule {
  /**
   * * Фильтры
   */
  filtersValue: Filters = {
    typeId: undefined,
    siteId: undefined,
    userId: undefined,
    active: undefined,
    response: undefined,
    dateFrom: undefined,
    dateTo: undefined
  }

  /**
   * * Список форм
   */
  formsValue: ResponseData<Form> = defaultData

  /**
   * * Форма
   */
  formValue: Form = {
    typeId: undefined,
    userId: undefined,
    siteId: 1,
    text: '',
    response: null,
    active: true,
    params: []
  }

  /**
   * * Список типов форм
   */
  formTypesValue: ResponseData<FormType> = defaultData

  /**
   * * Тип формы
   */
  formTypeValue: FormType = {
    name: '',
    siteId: 1,
    templateId: null
  }

  // ? ________ getters ________

  /**
   * * Получить фильтры
   */
  get filters (): Filters {
    return this.filtersValue
  }

  /**
   * * Получить список форм
   */
  get forms (): ResponseData<Form> {
    return this.formsValue
  }

  get formsValidators (): ValidatorParams {
    return {
      userId: [
        {
          pattern: validatorsPattern.naturalNumbers,
          message: 'Введите корректный id',
          trigger: ['blur', 'change']
        }
      ],
      typeId: [
        {
          required: true,
          message: 'Выберите тип формы',
          trigger: ['blur', 'change']
        }
      ]
    }
  }

  get typeFormsValidators (): ValidatorParams {
    return {
      name: [
        {
          required: true,
          pattern: validatorsPattern.stringEmpty,
          message: 'Введите название типа формы',
          trigger: ['blur', 'change']
        }
      ]
    }
  }

  /**
   * * Валидна ли форма
   */
  get validateDataForm (): boolean {
    return !!(this.form.typeId && this.form.siteId)
  }

  /**
   * * Получить форму
   */
  get form (): Form {
    return this.formValue
  }

  /**
   * * Получить форму по id
   */
  get formById () {
    const forms = this.formsValue.data
    return function (id: number): Form | undefined {
      return forms.find(form => form.id === id)
    }
  }

  /**
   * * Получить список типов форм
   */
  get formTypes (): ResponseData<FormType> {
    return this.formTypesValue
  }

  /**
   * * Получить тип форм
   */
  get formType (): FormType {
    return this.formTypeValue
  }

  /**
   * * Получить тип форм по id
   */
  get formTypeById () {
    return function (id: number): FormType | undefined {
      return this.formTypes.data.find(
        (formType: { id: number }) => formType.id === id
      )
    }
  }

  // ? ________ setters ________

  /**
   * * Установить фильтры
   * @param filters фильтры
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  /**
   * * Сбросить фильтры
   */
  @Mutation
  resetFilters () {
    this.filtersValue = {
      typeId: undefined,
      siteId: undefined,
      userId: undefined,
      active: undefined,
      response: undefined,
      dateFrom: undefined,
      dateTo: undefined
    }
  }

  /**
   * * Сбросить список форм
   */
  @Mutation
  resetForms () {
    this.formsValue = defaultData
  }

  /**
   * * Установить список форм
   * @param forms список форм
   */
  @Mutation
  setForms (forms: ResponseData<Form>) {
    this.formsValue = forms
  }

  /**
   * * Установить форму
   * @param form форма
   */
  @Mutation
  setForm (form: Form) {
    this.formValue = form
  }

  /**
   * * Сбросить форму
   */
  @Mutation
  resetForm () {
    this.formValue = {
      typeId: undefined,
      userId: undefined,
      siteId: 1,
      text: '',
      response: null,
      active: true,
      params: []
    }
  }

  /**
   * * Установить список типов форм
   * @param formTypes список типов форм
   */
  @Mutation
  setFormTypes (formTypes: ResponseData<FormType>) {
    this.formTypesValue = formTypes
  }

  /**
   * * Установить тип форм
   * @param formType тип форм
   */
  @Mutation
  setFormType (formType: FormType) {
    this.formTypeValue = formType
  }

  /**
   * * Сбросить тип форм
   */
  @Mutation
  resetFormType () {
    this.formTypeValue = {
      name: '',
      siteId: 1,
      templateId: null
    }
  }

  /**
   * * Сбросить список типов форм
   */
  @Mutation
  resetFormTypes () {
    this.formTypesValue = defaultData
  }

  // ? ________ actions ________

  /**
   * * Запрос на получение списка форм
   * @param pageParams параметры пагинации
   */
  @Action({
    rawError: true,
    commit: 'setForms'
  })
  async getForms (
    pageParams: PageParams | null = null
  ): Promise<ResponseData<Form>> {
    try {
      const { data } = await $axios.get('/form', {
        params: {
          ...pageParams,
          ...this.filters,
          dateTo: this.filters.dateTo
        }
      })
      const response: ResponseData<Form> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение формы по id
   * @param id id формы
   */
  @Action({
    rawError: true,
    commit: 'setForm'
  })
  async getFormById (id: number): Promise<Form> {
    try {
      const {
        data: { data }
      } = await $axios.get(`/form/${id}`)
      const response: Form = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание формы
   */
  @Action({
    rawError: true
  })
  async createForm (): Promise<Form> {
    try {
      const {
        data: { data }
      } = await $axios.post('/form', {
        ...this.form,
        active: this.form.active ? Number(this.form.active) : 0
      })
      const response: Form = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение формы по id
   */
  @Action({
    rawError: true
  })
  async editForm (): Promise<Form> {
    try {
      const { id, ...newForm } = this.form
      const {
        data: { data }
      } = await $axios.put(`/form/${id}`, {
        ...newForm,
        active: newForm.active ? Number(newForm.active) : 0
      })
      const response: Form = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на удаление формы по id
   * @param id id формы
   */
  @Action({
    rawError: true
  })
  async removeForm (id: number): Promise<Form> {
    try {
      const {
        data: { data }
      } = await $axios.delete(`/form/${id}`)
      const response: Form = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение списка типов форм
   * @param pageParams параметры пагинации
   */
  @Action({
    rawError: true,
    commit: 'setFormTypes'
  })
  async getFormTypes (
    pageParams: PageParams | null = null
  ): Promise<ResponseData<FormType>> {
    try {
      const { data } = await $axios.get('/form/type', { params: pageParams })
      const response: ResponseData<FormType> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение типа форм по id
   * @param id id типа форм
   */
  @Action({
    rawError: true,
    commit: 'setFormType'
  })
  async getFormTypeById (id: number): Promise<FormType> {
    try {
      const {
        data: { data }
      } = await $axios.get(`/form/type/${id}`)
      const response: FormType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание типа форм
   */
  @Action({
    rawError: true
  })
  async createFormType (): Promise<FormType> {
    try {
      const {
        data: { data }
      } = await $axios.post('/form/type', this.formType)
      const response: FormType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение типа форм
   */
  @Action({
    rawError: true
  })
  async editFormType (): Promise<FormType> {
    try {
      const { id, ...newFormType } = this.formType
      const {
        data: { data }
      } = await $axios.put(`/form/type/${id}`, newFormType)
      const response: FormType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на удаление типа форм по id
   * @param id id типа форм
   */
  @Action({
    rawError: true
  })
  async removeFormType (id: number): Promise<FormType> {
    try {
      const {
        data: { data }
      } = await $axios.delete(`/form/type/${id}`)
      const response: FormType = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async getStats () {
    try {
      const { data } = await $axios.get('/form/stats', {
        params: {
          dateFrom: this.filters.dateFrom || 0,
          dateTo: this.filters.dateTo || Math.trunc(Date.now() / 1000)
        },
        responseType: 'blob'
      })
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async updateFormParam (params: any) {
    try {
      const { data } = await $axios.put(`/form/param/${params.id}`, {
        formParamTypeId: params.paramId,
        typeId: params.formId,
        sort: params.value
      })
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
