import { OrderCommentsPlugin, OrderComment, Filters } from './interfaces'
import OrderCommentsModule from './store'
import { PageParams, ResponseData } from '~/store/interfaces'

export default function getPluginApi (store: OrderCommentsModule) {
  const api: OrderCommentsPlugin = {
    // ? ------ getters ------ //

    get orderComments (): ResponseData<OrderComment> {
      return store.orderComments
    },

    get orderComment (): OrderComment {
      return store.orderComment
    },

    get filters (): Filters {
      return store.filters
    },

    getOrderCommentById (id: number): OrderComment | undefined {
      return store.getOrderCommentById(id)
    },

    // ? ------ setters ------ //

    set filters (filters: Filters) {
      store.setFilters(filters)
    },

    resetFilters () {
      store.resetFilters()
    },

    set orderComments (orderComments: ResponseData<OrderComment>) {
      store.setOrderComments(orderComments)
    },

    set orderComment (orderComment: OrderComment) {
      store.setOrderComment(orderComment)
    },

    resetOrderComment () {
      store.resetOrderComment()
    },

    resetOrderComments () {
      store.resetOrderComments()
    },

    // ? ------ actions ------ //

    getOrderComments (params: PageParams): Promise<ResponseData<OrderComment>> {
      return store.getOrderComments(params)
    },

    createOrderComment (): Promise<OrderComment> {
      return store.createOrderComment()
    },

    editOrderComment (): Promise<OrderComment> {
      return store.editOrderComment()
    },

    removeOrderComment (ids: { orderId: number, orderCommentId: number }): Promise<OrderComment> {
      return store.removeOrderComment(ids)
    }

  }

  return api
}
