import { Vue, Component } from 'nuxt-property-decorator'
import { Params } from './interfaces'

@Component
export default class DeliveryMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$delivery.resetDelivery()
    next()
  }

  /**
   * * Получение списка доставок
   * @param params - параметры пагинации и (groupId - id группы доставок)
   * @returns список доставок
   */
  async getDeliveries (params: Params) {
    try {
      this.$wait.start('getDeliveries')
      return await this.$delivery.getDeliveries(params)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getDeliveries')
    }
  }

  /**
   * * Получение доставки по id
   * @param id - id доставки
   * @returns доставка
   */
  async getDeliveryById (id: number) {
    try {
      this.$wait.start('getDeliveryById')
      return await this.$delivery.getDeliveryById(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getDeliveryById')
    }
  }

  /**
   * * Создание доставки
   * @param groupId - id группы доставок
   * @returns доставка
   */
  async createDelivery (groupId: number) {
    try {
      this.$wait.start('createDelivery')
      const data = await this.$delivery.createDelivery(groupId)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлена опция ${data.name}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createDelivery')
    }
  }

  /**
   * * Изменение доставки
   * @returns доставка
   */
  async editDelivery () {
    try {
      this.$wait.start('editDelivery')
      const data = await this.$delivery.editDelivery()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Изменена опция ${data.name}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editDelivery')
    }
  }

  /**
   * * Удаление доставки
   * @param id - id доставки
   * @returns доставка
   */
  async removeDelivery (id: number) {
    try {
      this.$wait.start('removeDelivery')
      const data = await this.$delivery.removeDelivery(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Удалена опция ${data.name}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeDelivery')
    }
  }
}
