import { Component, Vue } from 'nuxt-property-decorator'
import { PriceRoleParam } from '~/store/modules/price-roles/interfaces'

@Component
export default class PriceRolesMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$priceRoles.resetPriceRole()
    next()
  }

  /**
   * * Запрос на получение списка ценовых ролей
   * @returns список ценовых ролей
   */
  async getPriceRoles () {
    this.$wait.start('getPriceRoles')
    try {
      return await this.$priceRoles.getPriceRoles()
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getPriceRoles') }
  }

  /**
   * * Запрос на изменение ценовой роли
   * @returns ценовая роль
   */
  async editPriceRole () {
    try {
      this.$wait.start('changePriceRole')
      const data = await this.$priceRoles.editPriceRole()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Ценовая роль ${data.title} изменена`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('changePriceRole') }
  }

  /**
   * * Запрос на создание ценовой роли
   * @returns ценовая роль
   */
  async createPriceRole (params: any) {
    try {
      this.$wait.start('changePriceRole')
      const data = await this.$priceRoles.createPriceRole(params)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Ценовая роль ${data.title} создана`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('changePriceRole') }
  }

  /**
   * * Запрос на удаление ценовой роли
   * @returns ценовая роль
   */
  async removePriceRole (id: number) {
    try {
      this.$wait.start('removePriceRole')
      const data = await this.$priceRoles.removePriceRole(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Ценовая роль ${data.title} удалена`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removePriceRole') }
  }

  // ? ________ Параметры ценовых ролей ________
  /**
   * * Запрос на параметры ценовых ролей
   * @returns список параметров ценовых ролей
   */
  async getPriceRolesParams () {
    this.$wait.start('getPriceRolesParams')
    try {
      return await this.$priceRoles.getPriceRolesParams()
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getPriceRolesParams') }
  }

  /**
   * * Запрос на параметры текущей роли
   * @returns параметры ценовой роли
   */
  async getCurrentParams () {
    this.$wait.start('getCurrentParams')
    try {
      return await this.$priceRoles.getCurrentParams()
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getCurrentParams') }
  }

  /**
   * * Запрос на создание параметра текущей роли
   * @param param - параметры ценовой роли
   * @returns параметр ценовой роли
   */
  createPriceRoleParam (param: PriceRoleParam) {
    const arrPriceRoleParam = [...this.$priceRoles.priceRole.params]
    arrPriceRoleParam.push({ paramName: param.paramName, paramValue: param.paramValue })
    this.$priceRoles.priceRole = {
      ...this.$priceRoles.priceRole,
      params: arrPriceRoleParam
    }
  }

  /**
   * * Запрос на изменение параметра текущей роли
   * @param param - параметры ценовой роли
   * @returns параметр ценовой роли
   */
  editPriceRoleParam (param: any) {
    const arrPriceRoleParam = [...this.$priceRoles.priceRole.params]
    arrPriceRoleParam[param.indexOfParam] = { ...arrPriceRoleParam[param.indexOfParam], paramName: param.paramName, paramValue: param.paramValue }
    this.$priceRoles.priceRole = {
      ...this.$priceRoles.priceRole,
      params: arrPriceRoleParam
    }
  }

  /**
   * * Запрос на удаление параметра текущей роли
   * @param id - id - параметра ценовой роли
   * @returns параметр ценовой роли
   */
  async deletePriceRoleParam (id: number) {
    this.$wait.start('deletePriceRoleParam')
    try {
      const data = await this.$priceRoles.deletePriceRoleParam(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Параметр ${data.paramName} удален`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('deletePriceRoleParam') }
  }
}
