import { Context } from '@nuxt/types'
import {
  Participant,
  ParticipantsRequestFilters,
  ParticipantsResponse,
  Raffle,
  RafflesPlugin,
  RafflesRequestFilters,
  RafflesResponse,
  RequestParams,
  UploadParticipantsModes
} from './interface'
import RafflesModule from './store'
import { ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (store: RafflesModule) {
  const ctx: Context = this

  const api: RafflesPlugin = {
    // ________________________ GETTERS ________________________ //

    // ------------ RAFFLES ------------ //

    /**
     * * Фильтры розыгрышей
     */
    get rafflesFilters (): RafflesRequestFilters {
      return store.rafflesFilters
    },

    /**
     * * Список розыгрышей
     */
    get raffles (): RafflesResponse {
      return store.raffles
    },

    /**
     * * Розыгрыш
     */
    get raffle (): Raffle {
      return store.raffle
    },

    /**
     * * Получение розыгрыша по id
     * @param id - id розыгрыша
     * @returns - розыгрыш
     */
    raffleById (id: number): Raffle | undefined {
      return store.raffleById(id)
    },

    /**
     * * Валидаторы розыгрыша
     */
    get raffleValidators (): ValidatorParams {
      return store.raffleValidators
    },

    // ------------ PARTICIPANTS ------------ //

    /**
     * * Фильтры учатсников
     */
    get participantsFilters (): ParticipantsRequestFilters {
      return store.participantsFilters
    },

    /**
     * * Список участников
     */
    get participants (): ParticipantsResponse {
      return store.participants
    },

    /**
     * * Участник
     */
    get participant (): Participant {
      return store.participant
    },

    /**
     * * Получение участника по id
     * @param id - id участника
     * @returns участник
     */
    participantById (id: number): Participant | undefined {
      return store.participantById(id)
    },

    /**
     * * Валидаторы участника
     */
    get participantValidators (): ValidatorParams {
      return store.participantValidators
    },

    // ________________________ SETTERS ________________________ //

    // ------------ RAFFLES ------------ //

    /**
     * * Установка фильтров розыгрышей
     */
    set rafflesFilters (filters: RafflesRequestFilters) {
      store.setRafflesFilters(filters)
    },

    /**
     * * Сброс фильтров розыгрышей
     */
    reset_rafflesFilters (): void {
      store.resetRafflesFilters()
    },

    /**
     * * Установка списка розыгрышей
     */
    set raffles (raffles: RafflesResponse) {
      store.setRaffles(raffles)
    },

    /**
     * * Сброс списка розыгрышей
     */
    reset_raffles (): void {
      store.resetRaffles()
    },

    /**
     * * Установка розыгрыша
     */
    set raffle (raffle: Raffle) {
      store.setRaffle(raffle)
    },

    /**
     * * Сброс розыгрыша
     */
    reset_raffle (): void {
      store.resetRaffle()
    },

    // ------------ PARTICIPANTS ------------ //
    /**
     * * Установка фильтров участников
     */

    set participantsFilters (filters: ParticipantsRequestFilters) {
      store.setParticipantsFilters(filters)
    },
    /**
     * * Сброс фильтров участников
     */

    reset_participantsFilters (): void {
      store.resetParticipantsFilters()
    },
    /**
     * * Установка списка участников
     */

    set participants (participants: ParticipantsResponse) {
      store.setParticipants(participants)
    },
    /**
     * * Сброс списка участников
     */

    reset_participants (): void {
      store.resetParticipants()
    },
    /**
     * * Установка участника
     */

    set participant (participant: Participant) {
      store.setParticipant(participant)
    },
    /**
     * * Сброс участника
     */

    reset_participant (): void {
      store.resetParticipant()
    },

    // ________________________ ACTIONS ________________________ //

    // ------------ RAFFLES ------------ //

    /**
     * * Запрос на получение списка розыгрышей
     * @param requestParams - параметры запроса
     * @returns список розыгрышей
     */
    get_raffles (
      requestParams?: RequestParams<keyof Raffle>
    ): Promise<RafflesResponse> {
      return store.getRaffles({
        siteApiUrl: ctx.$config.SITE_API,
        requestParams
      })
    },

    /**
     * * Запрос на создание розыгрыша
     * @returns розыгрыш
     */
    create_raffle (): Promise<Raffle> {
      return store.createRaffle({ siteApiUrl: ctx.$config.SITE_API })
    },

    /**
     * * Запрос на изменение розыгрыша
     * @returns розыгрыш
     */
    edit_raffle (): Promise<Raffle> {
      return store.editRaffle({ siteApiUrl: ctx.$config.SITE_API })
    },

    /**
     * * Запрос на удаление розыгрыша
     * @returns розыгрыш
     */
    remove_raffle ({ code } : {code: string}): Promise<Raffle> {
      return store.removeRaffle({ siteApiUrl: ctx.$config.SITE_API, code })
    },

    // ------------ PARTICIPANTS ------------ //

    /**
     * * Запрос на получение списка участников
     * @param requestParams - параметры запроса
     * @returns список участников
     */
    get_participants (
      requestParams?: RequestParams<keyof Participant>
    ): Promise<ParticipantsResponse> {
      return store.getParticipants({
        siteApiUrl: ctx.$config.SITE_API,
        requestParams
      })
    },

    /**
     * * Запрос на добавление участника
     * @returns розыгрыш
     */
    create_participant (): Promise<Participant> {
      return store.createParticipant({ siteApiUrl: ctx.$config.SITE_API })
    },

    /**
     * * Запрос на изменение участника
     * @returns розыгрыш
     */
    edit_participant (): Promise<Participant> {
      return store.editParticipant({ siteApiUrl: ctx.$config.SITE_API })
    },

    /**
     * * Запрос на удаление участника
     * @returns розыгрыш
     */
    remove_participant ({ id } : { id: number }): Promise<Participant> {
      return store.removeParticipant({ siteApiUrl: ctx.$config.SITE_API, id })
    },

    /**
     * * Запрос на удаление всех участников розыгрыша
     * @param code - код розыгрыша
     * @returns
     */
    remove_participants (code: string): Promise<void> {
      return store.removeParticipants({ siteApiUrl: ctx.$config.SITE_API, code })
    },

    /**
     * * Загрузка участников розыгрыша из excel файла
     * @param param0 - { file, updateExists } - файл с участниками, режим закгрузки
     * @returns
     */
    upload_participants ({
      file,
      updateExists
    }: {
      file: FormData,
      updateExists: UploadParticipantsModes
    }): Promise<void> {
      return store.uploadParticipants({ siteApiUrl: ctx.$config.SITE_API, file, updateExists })
    }
  }

  return api
}
