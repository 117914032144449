import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { ImagesConfig } from '~/types/types'

export default ({ $config }: Context, inject: Inject) => {
  inject(
    'imageUrl',
    (id: number, config: ImagesConfig) =>
      `${$config.SITE_DOMAIN}/api/image-minifier/v1/${id}.${config?.format || 'webp'}` +
      (!config?.noQuery
        ? `?quality=${config?.quality ||
            100}&method=CatmullRom&preserveRatio=true&format=${config?.format ||
            'webp'}${config?.preview === '' ? '' : ('&preview=' + (config?.preview || 'preview'))}`
        : '')
  )
}
