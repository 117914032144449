import { Vue, Component } from 'nuxt-property-decorator'
import { Params, Ids } from './interfaces'

@Component
export default class DeliveryPricesMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$deliveryPrices.resetDeliveryPrice()
    next()
  }

  /**
   * * Получение списка типов доставки
   * @param params - параметры пагинации
   * @returns список типов доставки
   */
  async getDeliveryTypes (params: Params) {
    try {
      this.$wait.start('getDeliveryTypes')
      return await this.$deliveryPrices.getDeliveryPrices(params)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getDeliveryTypes')
    }
  }

  /**
   * * Получение типа доставки по id
   * @param ids - id`s доставки и типа доставки,
   * @returns тип доставки
   */
  async getDeliveryTypeById (ids: Ids) {
    try {
      this.$wait.start('getDeliveryTypeById')
      return await this.$deliveryPrices.getDeliveryPriceById(ids)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getDeliveryTypeById')
    }
  }

  /**
   * * Создание типа доставки
   * @param id - id доставки
   * @returns тип доставки
   */
  async createDeliveryType (id: number) {
    try {
      this.$wait.start('createDeliveryType')
      const data = await this.$deliveryPrices.createDeliveryPrice(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлен тип ${data.name}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createDeliveryType')
    }
  }

  /**
   * * Изменение типа доставки
   * @param id - id доставки
   * @returns тип доставки
   */
  async editDeliveryType (id: number) {
    try {
      this.$wait.start('editDeliveryType')
      const data = await this.$deliveryPrices.editDeliveryPrice(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Тип ${data.name} изменен`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editDeliveryType')
    }
  }

  /**
   * * Удаление типа доставки
   * @param ids - id`s доставки и типа доставки,
   * @returns тип доставки
   */
  async removeDeliveryType (ids: Ids) {
    try {
      this.$wait.start('removeDeliveryType')
      const data = await this.$deliveryPrices.removeDeliveryPrice({
        serviceId: ids.serviceId,
        id: ids.id
      })
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Тип ${data.name} удален`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeDeliveryType')
    }
  }
}
