import { WordAdjectivesPlugin, WordAdjective } from './interfaces'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (wordAdjectivesStore:any) {
  const api: WordAdjectivesPlugin = {
    // ? ________ getters ________

    // * Получить список wordAdjectives и пагинатор
    get wordAdjectivesList (): ResponseData<WordAdjective> {
      return wordAdjectivesStore.wordAdjectivesList
    },

    get validators (): ValidatorParams {
      return wordAdjectivesStore.validators
    },

    // * Получить wordAdjective по id из массва wordAdjectives
    wordAdjectiveById (id: number): WordAdjective | undefined {
      return wordAdjectivesStore.wordAdjectiveById(id)
    },

    // * Получить текущий wordAdjective
    get currentWordAdjective (): WordAdjective {
      return wordAdjectivesStore.currentWordAdjective
    },

    // ? ________ setters ________

    // * Установить список wordAdjectives и пагинатор
    set wordAdjectivesList (wordAdjectives: ResponseData<WordAdjective>) {
      wordAdjectivesStore.setWordAdjectivesList(wordAdjectives)
    },

    // * Очистить форму
    resetCurrentWordAdjective () {
      wordAdjectivesStore.resetCurrentWordAdjective()
    },

    resetWordAdjectives () {
      wordAdjectivesStore.resetWordAdjectives()
    },

    // * Установить текщий wordAdjective
    set currentWordAdjective (wordAdjective: WordAdjective) {
      wordAdjectivesStore.setCurrentWordAdjective(wordAdjective)
    },

    // ? ________ actions ________

    // * Запрос на получение списка wordAdjectives и пагинатор
    getWordAdjectives (pageParams: PageParams | null = null): Promise<ResponseData<WordAdjective>> {
      return wordAdjectivesStore.getWordAdjectives(pageParams)
    },

    // * Запрос на получение wordAdjective по id
    getWordAdjective (id: number): Promise<WordAdjective> {
      return wordAdjectivesStore.getWordAdjective(id)
    },

    // * Запрос на удаление wordAdjective по id
    removeWordAdjective (id: number): Promise<WordAdjective> {
      return wordAdjectivesStore.removeWordAdjective(id)
    },

    // * Запрос на изменение wordAdjective
    editWordAdjective (): Promise<WordAdjective> {
      return wordAdjectivesStore.editWordAdjective()
    },

    // * Запрос на создание wordAdjective
    createWordAdjective (): Promise<WordAdjective> {
      return wordAdjectivesStore.createWordAdjective()
    }
  }
  return api
}
