import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class PriceRoleAssignmentsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$priceRoleAssignment.resetPriceRoleAssignment()
    next()
  }

  /**
   * * Cоздание параметра для ценовой роли
   * @returns параметр для ценовой роли
   */
  async createRoleAssignment () {
    try {
      this.$wait.start('createRoleAssignment')
      const data = await this.$priceRoleAssignment.createPriceRoleAssignment()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Роль успешно привязана'
      })
      return data
    } catch (e:any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('createRoleAssignment') }
  }

  /**
   * * Получение параметров для ценовых ролей
   * @returns параметры для ценовых ролей
   */
  async getPriceRoleAssignmentsParams () {
    try {
      this.$wait.start('getPriceRoleAssignmentsParams')
      return await this.$priceRoleAssignment.getPriceRoleAssignments()
    } catch (e:any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getPriceRoleAssignmentsParams')
    }
  }

  /**
   * * Удаление параметров
   * @param id - id параметра
   * @returns параметр для ценовой роли
   */
  async removePriceRoleAssignmentParam (ids: number[]) {
    try {
      this.$wait.start('removePriceRoleAssignmentParam')
      const data = await this.$priceRoleAssignment.removePriceRoleAssignment(ids[0])
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Параметр удален'
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removePriceRoleAssignmentParam')
    }
  }
}
